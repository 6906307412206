import { isNull } from 'lodash-es'
import { getRequest, postRequest, retryUntilSuccessOrFailure } from '../utils'

export const getExportDocument: RequestTypeVariable = {
  getKey: (id: string) => ['getExportDocument', id],
  request: async (
    id: string,
    documentPath: string,
    params?: Record<string, string | number | boolean>,
  ): Promise<Blob> => {
    const res = await getRequest<Blob>({
      url: `/projects/${id}/export/${documentPath}`,
      responseType: 'blob',
      params: params,
    })
    return res.data as Blob
  },
}

export const computeExportDocumentTask: RequestTypeVariable = {
  getKey: (id: string) => ['computeExportDocumentTask', id],
  request: async (
    projectId: string,
    taskPath: string,
    params?: Record<string, string | number | boolean>,
  ): Promise<void> => {
    const { data: computeDocumentData } = await postRequest<{
      task_id: string
    } | null>({
      url: `/projects/${projectId}/export/${taskPath}/compute`,
      params: params,
    })

    // TODO: is it really necessary to throw a new error here?
    if (isNull(computeDocumentData)) throw new Error('Cannot compute document yet')

    await retryUntilSuccessOrFailure({
      url: `/task-status/${computeDocumentData?.task_id}`,
    })
  },
}

export const getMultiStoreyExportDocument: RequestTypeVariable = {
  getKey: (id: string) => ['getExportDocument', id],
  request: async (
    id: string,
    documentPath: string,
    storeys: Array<string>,
    params?: Record<string, string>,
  ): Promise<Blob> => {
    // const storeysPayload = storeys.map(v => (v === 'ROOF' ? 'ROOF' : Number(v)))
    const res = await postRequest<Blob>({
      url: `/projects/${id}/export/${documentPath}`,
      responseType: 'blob',
      params: params,
      data: {
        storeys: storeys,
      },
    })
    return res.data as Blob
  },
}

export const getPositionGroupingExport: RequestTypeVariable = {
  getKey: (id: string, type: PositionGroupingType) => ['getPositionGroupingExport', id, type],
  request: async (id: string, type: PositionGroupingType): Promise<Blob> => {
    const res = await getRequest<Blob>({
      url: `/projects/${id}/position-grouping/${type}/table`,
      responseType: 'blob',
    })
    return res.data as Blob
  },
}

export const getMemberChecksDocument: RequestTypeVariable = {
  getKey: (id: string, element: string) => ['getMemberChecksDocument', id, element],
  request: async (id: string, element: string): Promise<Blob> => {
    const res = await getRequest<Blob>({
      url: `/projects/${id}/export/member-checks/${element}/document`,
      responseType: 'blob',
    })
    return res.data as Blob
  },
}

import numeral from 'numeral'
import { formatLocalForce } from '@ui/forms/LoadForceField/utils'

export const getLineLoadTitle = (load: LineLoadWithDomain, index: number): string => {
  const { start, end, relative_end, relative_start, domain, load_case } = load

  if (!domain) return `L${index + 1}`

  const loadType = loadCategoryToShort[load_case.category] || '-'
  const domainLength = domain?.length || 1

  const startFormatted = numeral(formatLocalForce(start, domain as Domain)).format('0[.][00]a')
  const endFormatted = numeral(formatLocalForce(end, domain as Domain)).format('0[.][00]a')
  const relativeStart = numeral(relative_start * domainLength).format('0[.][00]a')
  const relativeEnd = numeral(relative_end * domainLength).format('0[.][00]a')

  return `L${
    index + 1
  }: ${startFormatted}kN | ${endFormatted}kN | ${relativeStart}m - ${relativeEnd}m | ${loadType}`
}

export const getPointLoadTitle = (load: PointLoadWithDomain, index: number): string => {
  const { force, relative_position, domain, load_case } = load

  if (!domain) return `P${index + 1}`

  const loadType = loadCategoryToShort[load_case.category] || '-'
  const domainLength = domain?.length || 1

  const forceFormatted = numeral(formatLocalForce(force, domain as Domain)).format('0[.][00]a')
  const relativePosition = numeral(relative_position * domainLength).format('0[.][00]a')

  return `P${index + 1}: ${forceFormatted}kN | ${relativePosition}m | ${loadType}`
}

export const getAreaLoadTitle = (load: AreaLoad, index: number): string => {
  const { load_case } = load

  const loadType = loadCategoryToShort[load_case.category] || '-'

  const forceFormatted = numeral(load.force.z).format('0[.][00]a')

  return `A${index + 1}: ${forceFormatted}N | ${loadType}`
}

export const loadCategoryToShort: Record<LoadCategory, string> = {
  Live: 'L',
  Dead: 'G',
  Snow: 'S',
  SnowPlus: 'S',
  Wind: 'W',
  EarthQuake: 'E',
}

export const loadCaseLabels: Record<
  LoadCategory,
  {
    [K in LoadCaseTypes]?: {
      label: string
      short: string
    }
  }
> = {
  Live: {
    LiveLoad_A1: { label: 'Nutzlast A1', short: 'L A1' },
    LiveLoad_A2: { label: 'Nutzlast A2', short: 'L A2' },
    LiveLoad_A3: { label: 'Nutzlast A3', short: 'L A3' },
    LiveLoad_B1: { label: 'Nutzlast B1', short: 'L B1' },
    LiveLoad_B2: { label: 'Nutzlast B2', short: 'L B2' },
    LiveLoad_B3: { label: 'Nutzlast B3', short: 'L B3' },
    LiveLoad_C1: { label: 'Nutzlast C1', short: 'L C1' },
    LiveLoad_C2: { label: 'Nutzlast C2', short: 'L C2' },
    LiveLoad_C3: { label: 'Nutzlast C3', short: 'L C3' },
    LiveLoad_C4: { label: 'Nutzlast C4', short: 'L C4' },
    LiveLoad_C5: { label: 'Nutzlast C5', short: 'L C5' },
    LiveLoad_C6: { label: 'Nutzlast C6', short: 'L C6' },
    LiveLoad_D1: { label: 'Nutzlast D1', short: 'L D1' },
    LiveLoad_D2: { label: 'Nutzlast D2', short: 'L D2' },
    LiveLoad_D3: { label: 'Nutzlast D3', short: 'L D3' },
    LiveLoad_E1: { label: 'Nutzlast E1', short: 'L E1' },
    LiveLoad_E2: { label: 'Nutzlast E2', short: 'L E2' },
    LiveLoad_F1: { label: 'Nutzlast F1', short: 'L F1' },
    LiveLoad_F2: { label: 'Nutzlast F2', short: 'L F2' },
    LiveLoad_H: { label: 'Nutzlast H', short: 'L H' },
  },
  Dead: {
    DeadLoad: { label: 'Eigengewicht', short: 'G' },
  },
  Snow: {
    SnowFull: { label: 'Schnee', short: 'S' },
  },
  SnowPlus: {
    SnowFull: { label: 'Schnee >1000m Höhe ü. NN', short: 'S >1000m' },
  },
  Wind: {
    Wind_Global_0: { label: 'Wind 0', short: 'W 0' },
    Wind_Global_90: { label: 'Wind 90', short: 'W 90' },
    Wind_Global_180: { label: 'Wind 180', short: 'W 180' },
    Wind_Global_270: { label: 'Wind 270', short: 'W 270' },
  },
  EarthQuake: {
    Earthquake_0: { label: 'Erdbeben 0', short: 'E 0' },
    Earthquake_90: { label: 'Erdbeben 90', short: 'E 90' },
    Earthquake_180: { label: 'Erdbeben 180', short: 'E 180' },
    Earthquake_270: { label: 'Erdbeben 270', short: 'E 270' },
    Earthquake_Spectral_PlusXPlus30Y: { label: 'E +X|+0.3Y', short: 'E +X|+0.3Y' },
    Earthquake_Spectral_PlusXMinus30Y: { label: 'E +X|-0.3Y', short: 'E +X|-0.3Y' },
    Earthquake_Spectral_MinusXPlus30Y: { label: 'E -X|+0.3Y', short: 'E -X|+0.3Y' },
    Earthquake_Spectral_MinusXMinus30Y: { label: 'E -X|-0.3Y', short: 'E -X|-0.3Y' },
    Earthquake_Spectral_PlusYPlus30X: { label: 'E +Y|+0.3X', short: 'E +Y|+0.3X' },
    Earthquake_Spectral_PlusYMinus30X: { label: 'E +Y|-0.3X', short: 'E +Y|-0.3X' },
    Earthquake_Spectral_MinusYPlus30X: { label: 'E -Y|+0.3X', short: 'E -Y|+0.3X' },
    Earthquake_Spectral_MinusYMinus30X: { label: 'E -Y|-0.3X', short: 'E -Y|-0.3X' },
    Earthquake_X_e_max_y: { label: 'E X e_max_y', short: 'E X e_max_y' },
    Earthquake_X_e_max_y_inv: { label: 'E X e_max_y_inv', short: 'E X e_max_y_inv' },
    Earthquake_X_e_min_y: { label: 'E X e_min_y', short: 'E X e_min_y' },
    Earthquake_X_e_min_y_inv: { label: 'E X e_min_y_inv', short: 'E X e_min_y_inv' },
    Earthquake_Y_e_max_x: { label: 'E Y e_max_x', short: 'E Y e_max_x' },
    Earthquake_Y_e_max_x_inv: { label: 'E Y e_max_x_inv', short: 'E Y e_max_x_inv' },
    Earthquake_Y_e_min_x: { label: 'E Y e_min_x', short: 'E Y e_min_x' },
    Earthquake_Y_e_min_x_inv: { label: 'E Y e_min_x_inv', short: 'E Y e_min_x_inv' },
  },
}

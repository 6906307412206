import { ReactElement, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { isUndefined } from 'lodash-es'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { CentimeterField, Select, TextField } from '@ui/forms'
import { KC90TextIcon, UtilizationC90TextIcon } from '@ui/icons/misc'
import { useElementLabel } from '@editorHooks'

interface SecondaryHighlight {
  index: number
  field: 'cs_height' | 'cs_width' | 'setting_length' | 'setting_width'
}

interface Props {
  selectedCheckPosition: number | null | undefined
  setSelectedCheckPosition: (checkPosition?: number) => void
  elementType: ElementTypes
}

const CompressionTable = ({
  selectedCheckPosition,
  setSelectedCheckPosition,
  elementType,
}: Props): ReactElement => {
  const getLabel = useElementLabel()

  const { watch } = useFormContext()

  const [secondaryHighlight, setSecondaryHighlight] = useState<SecondaryHighlight | null>(null)

  const formBundles: CombinedBundleItem[] = watch('formBundles')

  const hasSupportWithCrossSection = useMemo(() => {
    // Leverage default values here so we don't need to work out the supports again
    const hasCrossSections = formBundles.map(
      bundle => bundle.type === 'position' && !isUndefined(bundle.targetCrossSection),
    )

    return hasCrossSections.some(result => result)
  }, [formBundles])

  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: '55vh', width: hasSupportWithCrossSection ? '1000px' : '500px' }}
    >
      <Table stickyHeader size="small" sx={{ '.MuiTableCell-root': { paddingX: 1 } }}>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>
              <UtilizationC90TextIcon />
            </TableCell>
            <TableCell>Pos</TableCell>
            {hasSupportWithCrossSection && (
              <>
                <TableCell align="center">darunterliegendes Bauteil</TableCell>
                <TableCell align="center">Material (des darunterliegenden Bauteils)</TableCell>
                <TableCell align="center">Breite (des darunterliegenden Bauteils)</TableCell>
                <TableCell align="center">Höhe (des darunterliegenden Bauteils)</TableCell>
              </>
            )}
            <TableCell align="center">b (Auflagerbreite)</TableCell>
            <TableCell align="center">l_A + ü (eff. Auflagerlänge)</TableCell>
            <TableCell align="center">
              <KC90TextIcon />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formBundles.map((bundle, index) => {
            const { check } = bundle
            const targetCrossSection = bundle.type === 'position' ? bundle.targetCrossSection : null

            return (
              <TableRow
                key={`${check.relative_position}-${check.element_guid}`}
                onClick={() => setSelectedCheckPosition(check.relative_position)}
                selected={selectedCheckPosition === check.relative_position}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>{check.max_utilization.toFixed(2)}</TableCell>
                <TableCell>{check.relative_position.toFixed(2)}</TableCell>
                {hasSupportWithCrossSection && (
                  <>
                    <TableCell>
                      {targetCrossSection ? getLabel(targetCrossSection.element_guid) : 'n/a'}
                    </TableCell>
                    <TableCell>
                      {targetCrossSection && (
                        <TextField
                          name={`formBundles[${index}].targetCrossSection.element_cs.material.identifier`}
                          disabled={true}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              padding: '0 4px',
                            },
                            '& input': {
                              padding: '4px 8px',
                            },
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {targetCrossSection && (
                        <CentimeterField
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor:
                                  (elementType === 'lintels' || elementType === 'rips') &&
                                  secondaryHighlight?.index === index &&
                                  secondaryHighlight?.field === 'cs_width'
                                    ? 'green'
                                    : 'default',
                              },
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              padding: '0 4px',
                            },
                            '& input': {
                              padding: '4px 8px',
                            },
                          }}
                          name={`formBundles[${index}].targetCrossSection.element_cs.shape.width`}
                          data-cy={`txt-cs-width-${index}`}
                          disabled={targetCrossSection.element_cs.material.kind === 'Steel'}
                          onFocus={() => {
                            setSecondaryHighlight({
                              index,
                              field: 'setting_length',
                            })
                          }}
                          onBlur={() => {
                            setSecondaryHighlight(null)
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {targetCrossSection && (
                        <CentimeterField
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                padding: '0 4px',
                                borderColor:
                                  (elementType === 'lintels' || elementType === 'rips') &&
                                  secondaryHighlight?.index === index &&
                                  secondaryHighlight?.field === 'cs_height'
                                    ? 'green'
                                    : 'default',
                              },
                              '& input': {
                                padding: '4px 8px',
                              },
                            },
                          }}
                          name={`formBundles[${index}].targetCrossSection.element_cs.shape.height`}
                          data-cy={`txt-cs-height-${index}`}
                          disabled={targetCrossSection.element_cs.material.kind === 'Steel'}
                          onFocus={() => {
                            setSecondaryHighlight({
                              index,
                              field: 'setting_width',
                            })
                          }}
                          onBlur={() => {
                            setSecondaryHighlight(null)
                          }}
                        />
                      )}
                    </TableCell>
                  </>
                )}
                <TableCell>
                  <CentimeterField
                    name={`formBundles[${index}].supportConfig.width`}
                    data-cy={`txt-config-width-${index}`}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& .MuiOutlinedInput-notchedOutline': {
                          padding: '0 4px',
                          borderColor:
                            (elementType === 'lintels' || elementType === 'rips') &&
                            secondaryHighlight?.index === index &&
                            secondaryHighlight?.field === 'setting_width'
                              ? 'green'
                              : 'default',
                        },
                        '& input': {
                          padding: '4px 8px',
                        },
                      },
                    }}
                    onFocus={() => {
                      setSecondaryHighlight({
                        index,
                        field: 'cs_height',
                      })
                    }}
                    onBlur={() => {
                      setSecondaryHighlight(null)
                    }}
                  />
                </TableCell>
                <TableCell>
                  <CentimeterField
                    name={`formBundles[${index}].supportConfig.length`}
                    data-cy={`txt-config-length-${index}`}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor:
                            (elementType === 'lintels' || elementType === 'rips') &&
                            secondaryHighlight?.index === index &&
                            secondaryHighlight?.field === 'setting_length'
                              ? 'green'
                              : 'default',
                        },
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        padding: '0 4px',
                      },
                      '& input': {
                        padding: '4px 8px',
                      },
                    }}
                    onFocus={() => {
                      setSecondaryHighlight({
                        index,
                        field: 'cs_width',
                      })
                    }}
                    onBlur={() => {
                      setSecondaryHighlight(null)
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Select
                    size="small"
                    name={`formBundles[${index}].supportConfig.k_c_90`}
                    options={[
                      { value: 1.0, label: '1.0' },
                      { value: 1.25, label: '1.25' },
                      { value: 1.5, label: '1.5' },
                      { value: 1.75, label: '1.75' },
                      { value: 1.4, label: '1.4' },
                      { value: 1.9, label: '1.9' },
                    ]}
                    sx={{
                      '& .MuiSelect-select': {
                        padding: '4px 8px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderWidth: '1px',
                        },
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            )
          })}

          {formBundles.length === 0 && (
            <TableRow>
              <TableCell colSpan={1000} align="center" size="medium">
                <Typography>
                  Keine Daten vorhanden (evtl. müssen die Nachweise neu berechnet werden)
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CompressionTable

import { useMemo } from 'react'
import { reduce } from 'lodash-es'
import { useSystemManagerStore } from '@editorStores'

// TODO: include standard rips for results
// TODO: memoize
const useGuidToCrossSection = () => {
  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  const elementToCrossSection = useMemo(() => {
    return reduce(
      elementCrossSectionAssignment,
      (col, assignment) => ({ ...col, [assignment.element_guid]: assignment.element_cs }),
      {} as Record<string, CrossSection>,
    )
  }, [elementCrossSectionAssignment])

  return elementToCrossSection
}

export default useGuidToCrossSection

import { deleteRequest, postRequest } from 'src/state/utils'

interface CreateProjectInput extends ProjectData {
  model: File
}

export const deleteProject: RequestType = {
  key: ['deleteProject'],
  request: async (id: string): Promise<void> => {
    await deleteRequest<Promise<void>>({
      url: `/projects/${id}`,
    })
  },
}

export const createProject: RequestType = {
  key: ['createProject'],
  request: ({ model, ...data }: CreateProjectInput): Promise<ProjectData> => {
    return new Promise((resolve, reject) => {
      return postRequest<ProjectData>({
        url: '/projects',
        data,
      })
        .then(({ data }) => {
          return createPlanarModel
            .request(data?.guid, model)
            .then(() => resolve(data as ProjectData))
            .catch(reject)
        })
        .catch(reject)
    })
  },
}

export const editProject: RequestTypeVariable = {
  getKey: (projectId: string) => ['editProject', projectId],
  request: async (projectId: string, data: ProjectData): Promise<ProjectData> => {
    const { data: result } = await postRequest<ProjectData>({
      url: `/projects/${projectId}`,
      data,
    })

    return result as ProjectData
  },
}

export const duplicateProject: RequestType = {
  key: ['duplicateProject'],
  request: async (id: string): Promise<ProjectData> => {
    const { data: result } = await postRequest<ProjectData>({
      url: `/projects/${id}/duplicate`,
    })
    return result as ProjectData
  },
}

export const createPlanarModel: RequestType = {
  key: ['createPlanarModel'],
  request: async (id: string, data: File): Promise<PlanarModel> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.addEventListener('load', async event => {
        if (!event?.target) return

        const str = event.target.result || ''
        const json = JSON.parse(str as string)

        try {
          const { data } = await postRequest<Promise<PlanarModel>>({
            url: `/projects/${id}/planar-model`,
            data: json,
          })

          // @ts-ignore
          resolve(data)
        } catch (error) {
          reject(error)
        }
      })

      reader.readAsText(data)
    })
  },
}

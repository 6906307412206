import { ReactElement, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { getSupportsOfElement } from '@domainUtils'
import { useResultsQueryParams } from '@resultsHooks'
import { AxiosError } from 'axios'
import produce from 'immer'
import { cloneDeep, isUndefined, reject } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Form } from '@ui/forms'
import { useResultsStore, useSystemManagerStore } from '@editorStores'
import { useElementType, useElementTypes } from '@editorHooks'
import {
  getElementChecksBundle,
  getElementCrossSectionAssignment,
  getMemberCheckSettings,
  postCalcPositionChecks,
} from '@queries'
import { saveCrossSectionsOnElement, saveSingleMemberCheckSettings } from '@mutations'
import { buildErrorMessage } from 'src/constants'
import { Tab } from '../../../../constants'
import BasicCSAndAPForm from '../BasicCSAndAPForm'
import EnhancedCSAndAPForm from '../EnhancedCSAndAPForm'
import { formBundlesToUpdatedCheckSettings } from '../UtilizationPreview'
import Diagram from './components/Diagram'
import MaterialCheckSettingsWrapper from './components/MaterialCheckSettingsWrapper'
import { useDefaultValues } from './misc'
import { MutationData, schema, FormData } from './schema'

interface Props {
  elementGuid: string
  isLoading: boolean
  onClose?: (resetQueryParams?: boolean) => void
  positionType: PositionGroupingType
  onlyEnableCompressionSettingChanges?: boolean
}

const CSAndAPPopup = ({
  onClose,
  isLoading,
  elementGuid,
  positionType,
  onlyEnableCompressionSettingChanges,
}: Props): ReactElement => {
  const elementTypes = useElementTypes()
  const elementType = useElementType(elementGuid) as ElementTypes
  const queryClient = useQueryClient()
  const { projectId } = useParams()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const setCrossSections = useSystemManagerStore(state => state.setCrossSections)
  const setElementCrossSectionAssignment = useSystemManagerStore(
    state => state.setElementCrossSectionAssignment,
  )
  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  const verticalTransmissionGraph = useResultsStore(state => state.verticalTransmissionGraph)
  const structuralChecks = useResultsStore(state => state.structuralChecks)
  const setStructuralChecks = useResultsStore(state => state.setStructuralChecks)
  const setSingleMemberCheckSetting = useResultsStore(state => state.setSingleMemberCheckSetting)
  const memberCheckSettings = useResultsStore(state => state.memberCheckSettings)
  const setMemberCheckSettings = useResultsStore(state => state.setMemberCheckSettings)

  // Custom HOOKs

  const elementTypeLookup = useElementTypes()

  const {
    actions: { setResultsQueryParams },
  } = useResultsQueryParams()

  // MEMOs

  const elementsSupportingSelected = useMemo(
    () =>
      verticalTransmissionGraph ? getSupportsOfElement(elementGuid, verticalTransmissionGraph) : [],
    [elementGuid, verticalTransmissionGraph],
  )

  const defaultValues = useDefaultValues(elementGuid)

  const { mutateAsync: onSubmit, isLoading: isLoadingInternal } = useMutation(
    async (data: MutationData) => {
      const updateCrossSections = async () => {
        let newCrossSections = [
          ...data.formBundles.map(bundle => {
            if (bundle.type === 'other') return undefined

            return {
              ...bundle.targetCrossSection.element_cs,
              element_guid: bundle.targetCrossSection.element_guid,
            }
          }),
        ]
        if (data.crossSection) {
          newCrossSections = [
            ...newCrossSections,
            {
              ...data.crossSection.element_cs,
              element_guid: data.crossSection.element_guid,
            },
          ]
        }

        const crossSectionsCleaned = reject(newCrossSections, el =>
          isUndefined(el),
        ) as CrossSectionOnElement[]

        await saveCrossSectionsOnElement.request(projectId as string, crossSectionsCleaned)
      }
      return updateCrossSections().then(
        async () =>
          await saveSingleMemberCheckSettings.request(projectId as string, data.settingsOnMember),
      )
    },
    {
      onMutate: async (data: MutationData) => {
        const previousData = {
          structuralChecks: cloneDeep(structuralChecks),
          elementCrossSectionAssignment: cloneDeep(elementCrossSectionAssignment),
          memberCheckSettings: cloneDeep(memberCheckSettings),
        }

        const updatedStructuralChecks = produce(structuralChecks || [], checks => {
          checks = [...reject(checks, check => check.element_guid === elementGuid), ...data.checks]

          data.formBundles.forEach(bundle => {
            if (bundle.type === 'position') {
              checks = [
                ...reject(checks, check => check.element_guid === bundle.targetGuid),
                ...bundle.checksOnSupportingElement,
              ]
            }
          })

          return checks
        })

        setStructuralChecks(updatedStructuralChecks)
        if (data.crossSection) {
          setCrossSections([data.crossSection.element_guid], data.crossSection.element_cs)
        }
        setSingleMemberCheckSetting(data.settingsOnMember)
        data.formBundles.forEach(bundle => {
          if (bundle.type === 'position') {
            const updatedCrossSection = bundle.targetCrossSection
            if (updatedCrossSection)
              setCrossSections([updatedCrossSection.element_guid], updatedCrossSection.element_cs)
          }
        })

        return { previousData }
      },
      onSuccess: () => {
        enqueueSnackbar('Querschnitt und Auflagereinstellungen erfolgreich gespeichert', {
          variant: 'success',
          preventDuplicate: true,
          autoHideDuration: 3000,
        })
        queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))
        const infoMessage = '[Backend] Aktualisiere MemberCheckDependency ...'
        enqueueSnackbar(infoMessage, { variant: 'info', key: infoMessage, preventDuplicate: true })
        const elementsToCompute = [
          elementGuid,
          ...elementsSupportingSelected.map(element => element.guid),
        ]

        const nonBeamLikeElements: ElementTypes[] = [
          'foundation',
          'inner_walls',
          'outer_walls',
          'slabs',
          'roof_slabs',
          'vertical_roof_slabs',
          'vertical_slabs',
        ]

        const beamLikeElementsToCompute = reject(elementsToCompute, guid =>
          nonBeamLikeElements.includes(elementTypes[guid] as ElementTypes),
        )

        postCalcPositionChecks.request(projectId as string, beamLikeElementsToCompute).then(() => {
          closeSnackbar(infoMessage)
          const successMessage = '[Backend] MemberCheckDependency aktualisiert.'
          enqueueSnackbar(successMessage, {
            variant: 'success',
            key: successMessage,
            preventDuplicate: true,
            autoHideDuration: 3000,
          })
          queryClient.invalidateQueries(getElementChecksBundle.getKey(projectId))
        })
      },
      onError: (error: AxiosError, data, context) => {
        // Reset optimistically updated data
        if (!isUndefined(context)) {
          setStructuralChecks(context.previousData.structuralChecks as CombinedPositionCheck[])
          setMemberCheckSettings(context.previousData.memberCheckSettings)
          setElementCrossSectionAssignment(context.previousData.elementCrossSectionAssignment)
        }

        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
        queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
      },
    },
  )

  const handleSubmit = (data: FormData) => {
    const formBundles = data.formBundles
    const newLintelCS =
      data.crossSection &&
      ({
        material: data.crossSection.material,
        materialType: data.crossSection.materialType,
        shape:
          data.crossSection.materialType === 'steelMaterial'
            ? data.crossSection.steelShape
            : data.crossSection.shape,
        usage_class: data.crossSection.usage_class,
        kind: data.crossSection.kind,
      } as CrossSection)

    onSubmit({
      crossSection: newLintelCS
        ? {
            element_guid: elementGuid,
            element_cs: newLintelCS,
          }
        : null,
      settingsOnMember: formBundlesToUpdatedCheckSettings(data.checkSettings, formBundles),
      formBundles: formBundles,
      checks: data.checks,
    })

    onClose?.()
  }

  const handleClickTargetElementGuid = (targetElementGuid: string) => {
    const elementType = elementTypeLookup[targetElementGuid]

    const elementTypeTabMapping: Partial<Record<ElementTypes, Omit<Tab, 'walls'>>> = {
      beams: 'beams',
      columns: 'columns',
      purlins: 'purlins',
      slab_beams: 'slab-beams',
      roof_slab_beams: 'roof-slab-beams',
    }

    if (elementType === 'rips' || elementType === 'lintels') {
      onClose?.(false)

      setResultsQueryParams({
        selectedElements: [targetElementGuid],
        tab: 'walls',
        wallSubTab: elementType === 'rips' ? 'wall-rips' : 'wall-lintels',
        selectedCheckPosition: undefined,
        isLocalMode: false,
        isMultiEditMode: false,
      })
    } else if (elementType === 'inner_walls' || elementType === 'outer_walls') {
      onClose?.(false)

      setResultsQueryParams({
        selectedElements: [targetElementGuid],
        tab: 'walls',
        localTab: 'settings',
        selectedCheckPosition: undefined,
        isLocalMode: true,
        isMultiEditMode: false,
      })
    } else if (elementType && Object.keys(elementTypeTabMapping).includes(elementType)) {
      onClose?.(false)

      setResultsQueryParams({
        selectedElements: [targetElementGuid],
        tab: elementTypeTabMapping[elementType] as Tab,
        wallSubTab: undefined,
        selectedCheckPosition: undefined,
        isLocalMode: false,
        isMultiEditMode: false,
      })
    } else {
      enqueueSnackbar({ message: 'Element kann gerade nicht angewählt werden', variant: 'info' })
    }
  }

  if (!defaultValues) return <></>

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        validationSchema={schema}
        data-cy={`cs-and-ap-popup-form`}
        enableReinitialize
      >
        <MaterialCheckSettingsWrapper>
          {positionType === 'columns' ||
          positionType === 'roof-slab-beams' ||
          positionType === 'slab-beams' ? (
            <BasicCSAndAPForm
              isLoading={isLoading || isLoadingInternal}
              elementType={elementType}
              elementGuid={elementGuid}
              onlyEnableCompressionSettingChanges={onlyEnableCompressionSettingChanges}
            />
          ) : (
            <EnhancedCSAndAPForm
              isLoading={isLoading || isLoadingInternal}
              elementGuid={elementGuid}
              onClickTargetElementGuid={handleClickTargetElementGuid}
              elementType={elementType}
              onlyEnableCompressionSettingChanges={onlyEnableCompressionSettingChanges}
              showQSUncorrelated={elementType !== 'lintels' && elementType !== 'rips'}
              diagram={() => <Diagram elementType={elementType} />}
            />
          )}
        </MaterialCheckSettingsWrapper>
      </Form>
    </>
  )
}

export default CSAndAPPopup

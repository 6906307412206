import { ReactElement } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import OpenIcon from '@mui/icons-material/OpenInNew'
import { IconButton, styled } from '@mui/material'
import {
  DataGridPremium,
  GridColDef,
  GridRowsProp,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium'
import { AlertDialog } from '@ui/feedback'
import { Box } from '@ui/structure'

dayjs.extend(utc)

const StyledDataGridPremium = styled(DataGridPremium)({
  backgroundColor: 'white',
  '& .MuiDataGrid-main': {
    backgroundColor: 'white',
  },
})
interface Props {
  projects: ProjectData[]
  onClick: (project: ProjectData) => void
  onDelete: (project: ProjectData) => void
  onEdit: (project: ProjectData) => void
  onDuplicate: (project: ProjectData) => void
}

const ProjectTable = ({
  projects,
  onClick,
  onDelete,
  onEdit,
  onDuplicate,
}: Props): ReactElement => {
  const rows: GridRowsProp = projects.map(project => ({
    id: project.guid,
    name: project.name,
    created: project.created_timestamp,
    edited: project.last_edited_timestamp,
    project,
  }))

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Projekt Name', flex: 1 },
    {
      field: 'created',
      headerName: 'Erstellt am',
      flex: 1,
      valueFormatter: value => dayjs.utc(value).local().format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      field: 'edited',
      headerName: 'Editiert am',
      flex: 1,
      valueFormatter: value => dayjs.utc(value).local().format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      field: 'actions',
      headerName: 'Aktionen',
      flex: 1,
      renderCell: params => {
        const project = params.row.project
        return (
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => onClick(project)}>
              <OpenIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={async () => onEdit(project)}>
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={async () => onDuplicate(project)}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
            <AlertDialog
              title="Projekt löschen?"
              text={`Bestätigen Sie, dass Sie das Projekt ${project.name} wirklich löschen wollen`}
              onConfirm={async () => onDelete(project)}
            >
              <IconButton>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </AlertDialog>
          </Box>
        )
      },
      align: 'left',
      display: 'flex',
    },
  ]

  return (
    <Box>
      <StyledDataGridPremium
        rows={rows}
        columns={columns}
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        slots={{
          toolbar: () => (
            <Box sx={{ p: 2 }}>
              <GridToolbarQuickFilter sx={{ width: '100%' }} />
            </Box>
          ),
        }}
        initialState={{
          sorting: {
            // show latest projects first
            sortModel: [{ field: 'created', sort: 'desc' }],
          },
          pagination: {
            paginationModel: { pageSize: 50 },
          },
        }}
      />
    </Box>
  )
}

export default ProjectTable

import { ReactElement, useMemo } from 'react'
import { find, filter, isFunction, toNumber } from 'lodash-es'
import { ColorRepresentation } from 'three'
import { useTheme } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { ThreeEvent } from '@react-three/fiber'
import { getPointAtPercentage } from '../utils'
import VerticalTransmitterItem from './VerticalTransmitterItem'

interface PositionedTarget {
  interval: {
    start: ImmutableVector3
    end: ImmutableVector3
  }
  item: ElementSupportItem
  domain: Domain
  support?: ElementSupportItem
}

const VerticalTransmitterMesh = ({
  data,
  domains,
  transmitterGuid,
  onClick,
}: TransmitterMeshProps): ReactElement => {
  const theme = useTheme()

  const supportItems = useMemo(() => {
    return data.element_supports.reduce((acc: PositionedTarget[], support: ElementSupportItem) => {
      const { relative_interval, domain: supportDomain, domain_guid } = support

      const domain = supportDomain || (find(domains, ['guid', domain_guid]) as Domain)

      if (!domain) return acc

      const { start: startPoint, end: endPoint } = domain
      const start = getPointAtPercentage(startPoint, endPoint, toNumber(relative_interval?.lower))
      const end = getPointAtPercentage(startPoint, endPoint, toNumber(relative_interval?.upper))

      return [
        ...acc,
        {
          interval: { start, end },
          item: support,
          domain,
        },
      ]
    }, [])
  }, [data.element_supports, domains])

  const targetItems = useMemo(() => {
    return supportItems.reduce((acc: PositionedTarget[], support) => {
      const { guid } = support.item

      const items = filter(data.support_targets, ['support_guid', guid]).reduce(
        (acc: PositionedTarget[], { target_guid }) => {
          const target = find(data.element_targets, ['guid', target_guid])

          if (!target) return acc

          const { relative_interval, domain_guid } = target
          const domain = find(domains, ['guid', domain_guid])

          if (!domain) return acc

          const { start: startPoint, end: endPoint } = domain
          const start = getPointAtPercentage(
            startPoint,
            endPoint,
            toNumber(relative_interval?.lower),
          )
          const end = getPointAtPercentage(startPoint, endPoint, toNumber(relative_interval?.upper))

          return [
            ...acc,
            {
              interval: { start, end },
              item: target,
              domain,
              support: support.item,
            },
          ]
        },
        [],
      )

      return [...acc, ...items]
    }, [])
  }, [supportItems, data.support_targets, data.element_targets, domains])

  const handleItemClick = (event: ThreeEvent<MouseEvent>, transmitter: ElementSupportItem) => {
    event.stopPropagation()

    if (!isFunction(onClick)) return

    onClick(transmitter)
  }

  return (
    <>
      {supportItems.map(({ interval, item }) => {
        return (
          <VerticalTransmitterItem
            key={item.guid}
            {...interval}
            isActive={item.guid === transmitterGuid}
            onClick={event => handleItemClick(event, item)}
            elementGuid={item.element_guid}
            color={theme.scenePalette.elements3d.supports as ColorRepresentation}
          />
        )
      })}
      {targetItems.map(({ interval, item, support }) => {
        return (
          <VerticalTransmitterItem
            key={item.guid}
            isTarget
            {...interval}
            isActive={support?.guid === transmitterGuid}
            onClick={event => handleItemClick(event, support as ElementSupportItem)}
            elementGuid={item.element_guid}
            color={theme.scenePalette.elements3d.targets}
          />
        )
      })}
    </>
  )
}

export default VerticalTransmitterMesh

import { ReactElement, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { isArray } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { LoadingIndicator, ErrorState } from '@ui/feedback'
import { NavbarPortalCenter, NavBreadcrumbs } from '@ui/navigation'
import {
  ElementSelectionStoreProvider,
  LabelsStoreProvider,
  ResultsStoreProvider,
  ElementLoadStoreProvider,
  EditElementStoreProvider,
  StructuralPlanningStoreProvider,
  ControlStoreProvider,
  ModelStoreProvider,
  SystemManagerStoreProvider,
  SceneStoreProvider,
} from '@editorStores'
import { useReducedNavigation, usePersistentParams } from 'src/hooks'
import useEditorNavigation from '../../hooks/useEditorNavigation'
import { getProjectForApproval } from './queries'

const ReducedEditor = (): ReactElement => {
  useReducedNavigation()
  const { currentRoute: { label: currentRouteName } = {} } = useEditorNavigation()
  const { enqueueSnackbar } = useSnackbar()
  const onError = () => enqueueSnackbar('Fehler beim Laden der Projektdaten', { variant: 'error' })
  const { projectId }: { projectId?: string } = usePersistentParams()

  const queryResult = useQuery({
    queryKey: getProjectForApproval.getKey(projectId),
    queryFn: () => getProjectForApproval.request(projectId),
    onError,
  })

  const queryClient = useQueryClient()
  useEffect(
    () => () => {
      queryClient.removeQueries({
        predicate: el => isArray(el.queryKey) && el.queryKey.includes(projectId),
      })
    },
    [projectId],
  )

  if (queryResult.isLoading) {
    return <LoadingIndicator />
  }

  if (!queryResult.data) {
    return (
      <>
        <ErrorState error="Modell konnte nicht geladen werden" />
      </>
    )
  }
  const projectForApproval = queryResult.data as unknown as ProjectForApproval
  const project = projectForApproval.project

  return (
    <ModelStoreProvider
      refreshAt={queryResult.dataUpdatedAt}
      model={queryResult.data.model}
      project={queryResult.data.project}
    >
      <SystemManagerStoreProvider
        selectedAssemblies={{} as SelectedAssemblies}
        assemblies={[]}
        assemblyAssignment={[]}
        connections={[]}
        prioritization={{
          inner_inner: [],
          inner_foundation: [],
          outer_outer: [],
          outer_foundation: [],
        }}
        elementCrossSections={[]}
        elementCrossSectionsSelection={null}
        elementCrossSectionAssignment={[]}
      >
        <LabelsStoreProvider elementLabels={[]} positionLabels={[]}>
          <SceneStoreProvider>
            <ControlStoreProvider>
              <ElementSelectionStoreProvider>
                <StructuralPlanningStoreProvider>
                  <ResultsStoreProvider>
                    <ElementLoadStoreProvider>
                      <EditElementStoreProvider>
                        <Outlet />
                        <NavbarPortalCenter>
                          <NavBreadcrumbs
                            main={project?.name}
                            crumbs={[currentRouteName as string]}
                          />
                        </NavbarPortalCenter>
                      </EditElementStoreProvider>
                    </ElementLoadStoreProvider>
                  </ResultsStoreProvider>
                </StructuralPlanningStoreProvider>
              </ElementSelectionStoreProvider>
            </ControlStoreProvider>
          </SceneStoreProvider>
        </LabelsStoreProvider>
      </SystemManagerStoreProvider>
    </ModelStoreProvider>
  )
}

export default ReducedEditor

import { useEffect, useMemo, useRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { getSupportsOfElement } from '@domainUtils'
import { mapValueKey } from '@editorUtils'
import { filter, get, isUndefined, isEqual } from 'lodash-es'
import { useMutation } from '@tanstack/react-query'
import { useResultsStore, useSystemManagerStore } from '@editorStores'
import { getCalcChecksForElement, getDefaultCheckSettingsForElement } from '@queries'
import { getDefaultValues as getDefaultValuesSingleCSForm } from 'src/components/pages/Editor/components/SingleElementCSForm/components/SingleCSForm/schema'
import { CrossSectionFormDataType } from 'src/components/pages/Editor/components/SingleElementCSForm/schema'
import { getDefaultFormValues as getDefaultFormValuesCompressionSettingsForm } from '../../misc'
import { FormData } from './schema'

export const useDefaultValues = (elementGuid: string): FormData | undefined | null => {
  const structuralChecks = useResultsStore(state => state.structuralChecks)
  const memberCheckSettings = useResultsStore(state => state.memberCheckSettings)
  const verticalTransmissionGraph = useResultsStore(state => state.verticalTransmissionGraph)

  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  // Memos
  const checksOnElement = useMemo(
    () =>
      (structuralChecks?.filter(
        check => check.element_guid === elementGuid,
      ) as StandardPositionCheck[]) || [],
    [elementGuid, structuralChecks],
  )

  const supportCompressionChecks = useMemo(
    () =>
      (checksOnElement.filter(
        check =>
          check.check_type === 'SupportCompression' ||
          check.check_type === 'SteelSupportCompression',
      ) as SupportCompressionStructuralCheck[] | SteelCompressionCheck[]) || [],
    [checksOnElement],
  )

  const elementGuidToCrossSection = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )

  const crossSectionAssignment = get(elementGuidToCrossSection, elementGuid, undefined)

  const crossSection = crossSectionAssignment?.element_cs

  const checkSettings = useMemo(
    () => memberCheckSettings?.find(settings => settings.element_guid === elementGuid),
    [elementGuid, memberCheckSettings],
  )

  const elementToCheckSettings = useMemo(
    () => mapValueKey(memberCheckSettings, 'element_guid'),
    [memberCheckSettings],
  )

  const elementsSupportingSelected = useMemo(
    () =>
      verticalTransmissionGraph ? getSupportsOfElement(elementGuid, verticalTransmissionGraph) : [],
    [elementGuid, verticalTransmissionGraph],
  )

  return useMemo(
    () =>
      checkSettings &&
      structuralChecks && {
        ...getDefaultFormValuesCompressionSettingsForm(
          elementGuidToCrossSection,
          elementToCheckSettings,
          checkSettings,
          supportCompressionChecks,
          elementsSupportingSelected,
          structuralChecks,
        ),
        ...((isUndefined(crossSection)
          ? { crossSection: null }
          : getDefaultValuesSingleCSForm(crossSection)) as {
          crossSection: CrossSectionFormDataType
        }),
        checks: checksOnElement,
        elementGuid: elementGuid,
      },
    [
      checkSettings,
      checksOnElement,
      crossSection,
      elementGuid,
      elementGuidToCrossSection,
      elementToCheckSettings,
      elementsSupportingSelected,
      structuralChecks,
      supportCompressionChecks,
    ],
  )
}

export const useUpdateCheckSettingsIfMaterialChanges = () => {
  const { setValue } = useFormContext()

  const { projectId } = useParams()

  const elementGuid = useWatch({ name: 'elementGuid' })
  const checkSettings: SettingsOnMember = useWatch({ name: 'checkSettings' })
  const crossSectionFormData: CrossSectionFormDataType | undefined = useWatch({
    name: 'crossSection',
  })

  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  const memberCheckSettings = useResultsStore(state => state.memberCheckSettings)
  const verticalTransmissionGraph = useResultsStore(state => state.verticalTransmissionGraph)
  const structuralChecks = useResultsStore(state => state.structuralChecks)

  const elementGuidToCrossSection = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )

  const elementToCheckSettings = useMemo(
    () => mapValueKey(memberCheckSettings, 'element_guid'),
    [memberCheckSettings],
  )

  const elementsSupportingSelected = useMemo(
    () =>
      verticalTransmissionGraph
        ? getSupportsOfElement(checkSettings.element_guid, verticalTransmissionGraph)
        : [],
    [checkSettings.element_guid, verticalTransmissionGraph],
  )

  // derive values from form state
  const newCS = useMemo(
    () =>
      crossSectionFormData &&
      ({
        material: crossSectionFormData.material,
        shape:
          crossSectionFormData.materialType === 'steelMaterial'
            ? crossSectionFormData.steelShape
            : crossSectionFormData.shape,
        usage_class: crossSectionFormData.usage_class,
      } as CrossSection),
    [crossSectionFormData],
  )

  const calculatedCS = useRef<CrossSection | null>(newCS || null)

  const { mutate } = useMutation(
    async (newCS: CrossSection) => {
      // execute this sequentially
      const defaultCheckSettingsForElement: GetDefaultCheckSettingsForElementResponse =
        await getDefaultCheckSettingsForElement.request(projectId as string, elementGuid, newCS)

      const csSuggestion: CrossSectionSuggestion = await getCalcChecksForElement.request(
        projectId as string,
        elementGuid,
        defaultCheckSettingsForElement.new_settings,
        newCS,
      )

      return {
        new_settings: csSuggestion.settings,
        checks: csSuggestion.checks,
        calculatedCS: newCS,
      }
    },
    {
      onSuccess: (data: {
        new_settings?: SettingsOnMember
        checks?: CombinedPositionCheck[]
        calculatedCS: CrossSection
      }) => {
        if (data.new_settings && data.checks && structuralChecks) {
          calculatedCS.current = data.calculatedCS
          setValue('checkSettings', data.new_settings)
          setValue('checks', data.checks)
          const supportCompressionChecks = filter(data.checks, check =>
            ['SupportCompression', 'SteelSupportCompression'].includes(check.check_type),
          ) as SupportCompressionStructuralCheck[] | SteelCompressionCheck[]

          const newformBundles = getDefaultFormValuesCompressionSettingsForm(
            elementGuidToCrossSection,
            elementToCheckSettings,
            data.new_settings,
            supportCompressionChecks,
            elementsSupportingSelected,
            structuralChecks,
          )

          setValue('formBundles', newformBundles.formBundles, {
            shouldTouch: true,
            shouldDirty: true,
          })
        }
      },
    },
  )

  useEffect(() => {
    if (!isUndefined(newCS)) {
      if (!isEqual(newCS?.shape?.kind_literal, calculatedCS.current?.shape?.kind_literal)) {
        mutate(newCS)
      }
    }
  }, [mutate, newCS])
}

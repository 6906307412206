import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Select, MenuItem, Stack } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SaveButton } from '@ui/actions'
import { Label } from '@ui/forms'
import { useModelStore } from '@editorStores'
import { useResultsInvalidation } from '@editorHooks'
import { getModel } from '@queries'
import { updateWallPlacement } from '@mutations'

interface Props {
  wallGuid: string
}

const PlacementForm = ({ wallGuid }: Props): ReactElement => {
  const { projectId } = useParams()

  const [placement, setPlacement] = useState<WallPlacement>('Internal')

  const { enqueueSnackbar } = useSnackbar()

  const client = useQueryClient()

  const invalidateResults = useResultsInvalidation()

  const { mutate, isLoading } = useMutation({
    mutationFn: () => updateWallPlacement.request(projectId, wallGuid, placement),
    onSuccess: () => {
      client.invalidateQueries(getModel.getKey(projectId))
      invalidateResults(projectId as string)
      enqueueSnackbar('Platzierung der Wand erfolgreich verändert', { variant: 'success' })
    },
    onError: () => {
      enqueueSnackbar('Fehler beim Speichern der Wandplatzierung', { variant: 'error' })
    },
  })

  const walls = useModelStore(state => state.model.walls)

  const wall = useMemo(() => find(walls, { guid: wallGuid }), [walls, wallGuid])

  useEffect(() => {
    if (wall) setPlacement(wall.placement as WallPlacement)
  }, [wall])

  return (
    <Stack>
      <Label>Platzierung</Label>
      <Stack direction="row" spacing={2}>
        <Select
          value={placement}
          onChange={e => setPlacement(e.target.value as WallPlacement)}
          size="small"
          sx={{ width: '100%' }}
          data-cy="select-placement"
        >
          <MenuItem value="Internal" data-cy="menu-internal">
            Intern
          </MenuItem>
          <MenuItem value="External" data-cy="menu-external">
            Extern
          </MenuItem>
        </Select>

        <SaveButton onClick={() => mutate()} loading={isLoading} data-cy="save-btn-placement">
          Speichern
        </SaveButton>
      </Stack>
    </Stack>
  )
}

export default PlacementForm

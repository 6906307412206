import { ReactElement, useCallback, useEffect, useMemo, useState, MouseEvent } from 'react'
import { useParams } from 'react-router-dom'
import { getSupportsOfElement } from '@domainUtils'
import { mapValueKey, naturalSortComparator } from '@editorUtils'
import { useSlabBeamToAssembly } from '@hooks'
import { SLIGHTLY_OVERUTILIZED_THRESHOLD, MAX_UTILIZATION_WARNING_THRESHOLD } from '@resultsConfig'
import { useResultsQueryParams } from '@resultsHooks'
import { makeCrossSectionLabel, makeSlabBeamCrossSectionLabel, getElementType } from '@resultsUtils'
import { AxiosError } from 'axios'
import produce from 'immer'
import { filter, find, findIndex, isUndefined, maxBy, reject, remove } from 'lodash-es'
import { useSnackbar } from 'notistack'
import {
  ArrowForward,
  Check,
  Close,
  Edit,
  Warning,
  EditNote,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@mui/icons-material'
import { Tooltip, IconButton, Stack, Popover, Typography, Checkbox, Box } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
  GridRowId,
  useGridApiRef,
  gridExpandedSortedRowIdsSelector,
  GridRowParams,
  GridCellParams,
} from '@mui/x-data-grid-premium'
import { ErrorBoundary } from '@sentry/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { renderGenericErrorBoundary } from '@ui/errors'
import { UtilizationC90TextIcon, UtilizationTextIcon } from '@ui/icons/misc'
import { useControlStore, useResultsStore, useSystemManagerStore } from '@editorStores'
import {
  useElementLabel,
  useElementTypes,
  useModelClickListeners,
  useSelectionMode,
} from '@editorHooks'
import { getElementCrossSectionAssignment } from '@queries'
import { savePositionGrouping, updateElementCrossSectionAssignment } from '@mutations'
import { buildErrorMessage } from 'src/constants'
import {
  getSlabBeamCrossSectionFromAssembly,
  getSlabBeamStepSizeFromAssembly,
} from 'src/utils/assemblies'
import { elementTypeToPositionGroupingType } from '../../../../utils/typeConversion'
import {
  CustomToolbar,
  CSAndAPPopup,
  GroupDetailView,
  OverhangCell,
  ShearCheckCell,
  StyledDataGrid,
} from './components'
import { EditMode, elementGroupingSnackbarKey } from './misc'

interface Props {
  bundles: MemberPositionBundleWithMaxUtilization[]
  positionType: PositionGroupingType
  isDownloadingExportDocument: boolean
  onClickDownloadExportDocument: () => void
  showShearCheckColumn?: boolean
  showOverhangColumn?: boolean
}

const GenericResultsGrid = ({
  bundles,
  positionType,
  isDownloadingExportDocument,
  onClickDownloadExportDocument,
  showShearCheckColumn = false,
  showOverhangColumn = false,
}: Props): ReactElement => {
  const { projectId } = useParams()
  const {
    params: { selectedElements },
    actions: { setIsLocalMode, selectPosition, setSelectedCheckPosition },
  } = useResultsQueryParams()

  const { setSelectionMode, selectionModeKey, isSelectionMode, unsetSelectionMode } =
    useSelectionMode()
  const editMode =
    selectionModeKey === elementGroupingSnackbarKey ? EditMode.Grouping : EditMode.None

  const queryClient = useQueryClient()

  const getLabel = useElementLabel()
  const elementType = getElementType(positionType)

  // useResultsStore
  const structuralChecks = useResultsStore(state => state.structuralChecks)
  const memberCheckSettings = useResultsStore(state => state.memberCheckSettings)
  const setWallRipsPositionGrouping = useResultsStore(state => state.setWallRipsPositionGrouping)
  const setWallLintelsPositionGrouping = useResultsStore(
    state => state.setWallLintelsPositionGrouping,
  )
  const setSlabBeamsPositionGrouping = useResultsStore(state => state.setSlabBeamsPositionGrouping)
  const setRoofSlabBeamsPositionGrouping = useResultsStore(
    state => state.setRoofSlabBeamsPositionGrouping,
  )
  const setBeamsPositionGrouping = useResultsStore(state => state.setBeamsPositionGrouping)
  const setPurlinsPositionGrouping = useResultsStore(state => state.setPurlinsPositionGrouping)
  const setColumnsPositionGrouping = useResultsStore(state => state.setColumnsPositionGrouping)

  const elementsToGroup = useResultsStore(state => state.elementsToGroup)
  const setElementsToGroup = useResultsStore(state => state.setElementsToGroup)

  // useControlStore
  const isDrawerExpanded = useControlStore(state => state.isDrawerExpanded)

  // useSystemManagerStore
  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  const slabBeamGuidToAssembly = useSlabBeamToAssembly()

  // useState
  const [intermediateBundles, setIntermediateBundles] = useState(bundles)

  // useMemo
  const elementCrossSectionAssignmentToGuid = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )
  const checkSettingsLookup = useMemo(
    () => mapValueKey(memberCheckSettings, 'element_guid'),
    [memberCheckSettings],
  )

  const isSlabBeam = ['slab_beams', 'roof_slab_beams'].includes(elementType)
  const setSingleCrossSection = useSystemManagerStore(state => state.setSingleCrossSection)

  const selectedElement = useMemo(
    () => (selectedElements?.length === 1 ? selectedElements?.[0] : null),
    [selectedElements],
  )

  // onclick callbacks

  // Add this function to handle export changes
  const changeExportedPosition = useCallback(
    (position: string, exported: boolean) => {
      const newBundles = intermediateBundles.map(bundle =>
        bundle.representative_position === position ? { ...bundle, exported } : bundle,
      )
      setIntermediateBundles(newBundles)
    },
    [intermediateBundles],
  )

  const { enqueueSnackbar } = useSnackbar()

  const { mutateAsync: saveCSAssignment, isLoading: isLoadingCSAssignmentRegularly } = useMutation(
    (data: ElementCSAssignment) => {
      return updateElementCrossSectionAssignment.request(projectId as string, data)
    },
    {
      onMutate: async (data: ElementCSAssignment) => {
        // Update the local state directly so no refetch is required
        setSingleCrossSection(data.element_guid, data.element_cs)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
        // Refetch onError to ensure local state is in sync with backend again
        queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
      },
    },
  )

  /* Update comments */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRowUpdate = (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
    const updatedBundles = intermediateBundles.map(bundle =>
      bundle.representative_position === newRow.id
        ? { ...bundle, comment: newRow.comment, extended_comment: newRow.extended_comment }
        : bundle,
    )
    setIntermediateBundles(updatedBundles)
    return newRow
  }

  /* AP configuration */
  const [anchorElementForPopover, setAnchorElementForPopover] = useState<{
    anchor: HTMLButtonElement
    guid: string

    source: 'compression-settings'
  } | null>(null)

  const handleClickForSettingsForm = (event: MouseEvent<HTMLButtonElement>, guid: string) => {
    setAnchorElementForPopover({
      anchor: event.currentTarget,
      guid,
      source: 'compression-settings',
    })
  }
  const handleCloseForPopover = (resetQueryParams = true) => {
    if (resetQueryParams) {
      setSelectedCheckPosition(undefined)
    }
    setAnchorElementForPopover(null)
  }

  const handleGroupingModeEnd = useCallback(() => {
    setElementsToGroup({
      aggregator: null,
      aggregated: [],
    })

    unsetSelectionMode()
  }, [setElementsToGroup, unsetSelectionMode])

  /* Save Button */
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: MemberPositionBundle[]) => {
      return {
        data: await savePositionGrouping.request(projectId as string, positionType, data),
        positionType,
      }
    },
    onSuccess: ({ data, positionType }) => {
      switch (positionType) {
        case 'wall-lintels':
          setWallLintelsPositionGrouping(data)
          break
        case 'wall-rips':
          setWallRipsPositionGrouping(data)
          break
        case 'slab-beams':
          setSlabBeamsPositionGrouping(data)
          break
        case 'roof-slab-beams':
          setRoofSlabBeamsPositionGrouping(data)
          break
        case 'beams':
          setBeamsPositionGrouping(data)
          break
        case 'purlins':
          setPurlinsPositionGrouping(data)
          break
        case 'columns':
          setColumnsPositionGrouping(data)
          break
      }
      enqueueSnackbar('Erfolgreich gespeichert', { variant: 'success' })
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Änderungen'), {
        variant: 'error',
      })
    },
  })

  const handleSave = () => {
    if (editMode === EditMode.Grouping && elementsToGroup.aggregator) {
      const newBundles = getIntermediateBundlesWithUpdatedGroupings(
        elementsToGroup.aggregator,
        elementsToGroup.aggregated,
      )
      mutate(newBundles)
      selectPosition(elementsToGroup.aggregator)
      handleGroupingModeEnd()
    } else if (editMode === EditMode.None) {
      mutate(intermediateBundles)
    }
  }

  const getIntermediateBundlesWithUpdatedGroupings = (
    aggregator: string,
    aggregated: string[],
  ): MemberPositionBundleWithMaxUtilization[] => {
    const newBundles = produce(state => {
      aggregated.forEach(aggregatedGuid => {
        remove(state, ['representative_position', aggregatedGuid])
      })

      const index = findIndex(state, { representative_position: aggregator })

      // We need to find out if any of the representatives have been removed and
      // need to add it to bundle again
      state[index].representative_for.forEach(guid => {
        if (!aggregated.includes(guid)) {
          state.push({
            representative_position: guid,
            representative_for: [],
            exported: false,
          })
        }
      })

      state[index].representative_for = aggregated
      state[index].exported = true
    }, intermediateBundles)()
    return newBundles
  }

  const onClickUngroupMember = useCallback(
    (removedElement: string) => {
      const clickedBundleIdx = findIndex(intermediateBundles, bundle =>
        bundle.representative_for.includes(removedElement),
      )
      if (clickedBundleIdx !== -1) {
        const clickedBundle = intermediateBundles[clickedBundleIdx]
        const currentBundleIsExported = clickedBundle.exported as boolean
        const currentAggregated = clickedBundle.representative_for
        const newAggregated = reject(currentAggregated, el => el === removedElement)
        const newClickedBundle = {
          ...clickedBundle,
          representative_for: newAggregated,
        }
        const newStandaloneBundleForUngroupedElement: MemberPositionBundle = {
          representative_position: removedElement,
          representative_for: [],
          exported: currentBundleIsExported,
        }
        // build new intermediate bundles from a copy of current state
        let newIntermediateBundles = [...intermediateBundles]
        newIntermediateBundles[clickedBundleIdx] = newClickedBundle
        newIntermediateBundles = [...newIntermediateBundles, newStandaloneBundleForUngroupedElement]
        setIntermediateBundles(newIntermediateBundles)
        // not sure if edit mode would have side effects, for that reason let's just not save it in that case
        editMode !== EditMode.Grouping && mutate(newIntermediateBundles)
      }
    },
    [editMode, intermediateBundles, mutate],
  )

  useEffect(() => {
    setIntermediateBundles(bundles)
  }, [bundles])

  const verticalTransmissionGraph = useResultsStore(state => state.verticalTransmissionGraph)

  const elementGuidToCrossSection = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )

  const rows = useMemo(
    () =>
      intermediateBundles.map(bundle => {
        let crossSection: CrossSection | CLTCrossSection
        let crossSectionLabel
        if (isSlabBeam) {
          const assembly = slabBeamGuidToAssembly[bundle.representative_position]
          const stepSize = getSlabBeamStepSizeFromAssembly(assembly)
          crossSection = getSlabBeamCrossSectionFromAssembly(assembly)
          crossSectionLabel = makeSlabBeamCrossSectionLabel(crossSection, stepSize)
        } else {
          crossSection =
            elementCrossSectionAssignmentToGuid[bundle.representative_position]?.element_cs
          crossSectionLabel = crossSection && makeCrossSectionLabel(crossSection)
        }

        const aggregatedElementBundles: AggregatedElementDataSummary[] =
          bundle.representative_for.map(elementGuid => {
            const elementPositionChecks = filter(structuralChecks, { element_guid: elementGuid })
            const maxCheckUtilisation = maxBy(elementPositionChecks, check => check.max_utilization)

            let aggregatedCrossSectionLabel
            if (isSlabBeam) {
              const assembly = slabBeamGuidToAssembly[elementGuid]
              const crossSection = getSlabBeamCrossSectionFromAssembly(assembly)
              const stepSize = getSlabBeamStepSizeFromAssembly(assembly)
              aggregatedCrossSectionLabel = makeSlabBeamCrossSectionLabel(crossSection, stepSize)
            } else {
              const crossSection = elementCrossSectionAssignmentToGuid[elementGuid]?.element_cs
              aggregatedCrossSectionLabel = crossSection && makeCrossSectionLabel(crossSection)
            }
            return {
              guid: elementGuid,
              label: getLabel(elementGuid),
              crossSectionLabel: aggregatedCrossSectionLabel || 'n/a',
              maxUtilisation: maxCheckUtilisation?.max_utilization,
            }
          })

        const overutilisedAggregatedElements = aggregatedElementBundles.filter(bundle => {
          return (
            bundle.maxUtilisation !== undefined &&
            bundle.maxUtilisation > MAX_UTILIZATION_WARNING_THRESHOLD
          )
        })

        const checksOnElement = filter(structuralChecks, {
          element_guid: bundle.representative_position,
        })
        const elementsSupportingSelected = verticalTransmissionGraph
          ? getSupportsOfElement(bundle.representative_position, verticalTransmissionGraph)
          : []

        return {
          id: bundle.representative_position,
          ...bundle,
          label: getLabel(bundle.representative_position),
          crossSection: crossSection,
          crossSectionLabel: crossSectionLabel,
          aggregatedElementBundles,
          overutilisedAggregatedElements,
          checks: checksOnElement,
          elementsSupportingSelected: elementsSupportingSelected,
        } as LintelResultsGridRow
      }),
    [
      elementCrossSectionAssignmentToGuid,
      getLabel,
      intermediateBundles,
      isSlabBeam,
      slabBeamGuidToAssembly,
      structuralChecks,
      verticalTransmissionGraph,
    ],
  )

  const openRow = useMemo(() => {
    if (!selectedElement) return

    const selectedRepresentative = find(rows, row => row.id === selectedElement)

    if (selectedRepresentative && selectedRepresentative.representative_for.length > 0)
      return selectedRepresentative

    const selectedRow = rows.find(row => row.representative_for.includes(selectedElement))

    return selectedRow
  }, [rows, selectedElement])

  /* START Grouping Mode functions */
  const handleGroupingModeStart = useCallback(
    (aggregator: string, aggregated: string[]) => {
      if (elementsToGroup.aggregator !== null) return

      selectPosition(aggregator)
      setElementsToGroup({
        aggregator: aggregator,
        aggregated: aggregated,
      })
      setSelectionMode({
        key: elementGroupingSnackbarKey,
        message: 'Wähle die Bauteile aus der Tabelle, die gruppiert werden sollen.',
        onAbort: () => {
          selectPosition(aggregator)
          handleGroupingModeEnd()
        },
      })
    },
    [
      elementsToGroup.aggregator,
      handleGroupingModeEnd,
      selectPosition,
      setElementsToGroup,
      setSelectionMode,
    ],
  )

  useEffect(() => {
    if (editMode === EditMode.None) {
      handleGroupingModeEnd()
    }
  }, [editMode, handleGroupingModeEnd])

  /* START Cell Render handlers */
  const renderCellRepresentative = useCallback(
    (params: GridRenderCellParams) => (
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        onClick={(event: MouseEvent) => {
          event.stopPropagation()
          if (editMode === EditMode.None) {
            handleGroupingModeStart(
              params.row.representative_position,
              params.row.representative_for,
            )
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        {params.row.overutilisedAggregatedElements.length > 0 && (
          <Tooltip
            title={`Overutilised in group: ${params.row.overutilisedAggregatedElements
              .map((bundle: { guid: string }) => getLabel(bundle.guid))
              .join(', ')}`}
          >
            <Warning />
          </Tooltip>
        )}
        <Tooltip
          title={params.row.representative_for.map((guid: string) => getLabel(guid)).join(', ')}
        >
          <Typography>{params.row.representative_for.length}</Typography>
        </Tooltip>
        {params.row.representative_for.length > 0 && (
          <IconButton
            size="small"
            onClick={(event: { stopPropagation: () => void }) => {
              event.stopPropagation()

              if (params.row.id === openRow?.id) selectPosition(undefined)
              else {
                selectPosition(params.row.representative_position)
              }
            }}
            disabled={editMode === EditMode.Grouping}
            sx={{ padding: 0 }}
          >
            <>
              {openRow && params.row.id === openRow?.id ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )}
            </>
          </IconButton>
        )}
      </Stack>
    ),
    [editMode, getLabel, handleGroupingModeStart, openRow, selectPosition],
  )

  const renderCellShearCheck = useCallback(
    (params: GridRenderCellParams<LintelResultsGridRow>) => {
      const memberGuid = params.row.representative_position
      const memberSettings = find(
        memberCheckSettings,
        setting =>
          setting.member_guid === memberGuid &&
          (setting.setting_type === 'timber' || setting.setting_type === 'timber-slab'),
      ) as TimberCheckSettings | TimberSlabCheckSettings

      if (memberSettings) {
        const shearSettings = memberSettings.shear_check_settings

        return (
          <ShearCheckCell
            memberGuid={memberGuid}
            reducePointLoadsCloseToSupports={shearSettings.reduce_point_loads_close_to_supports}
            reduceShearForce={shearSettings.reduce_shear_force}
          />
        )
      } else
        return (
          <Tooltip title="Element ist nicht aus Holz" placement="left">
            <Typography fontSize="inherit" fontWeight="inherit">
              n/a
            </Typography>
          </Tooltip>
        )
    },
    [memberCheckSettings],
  )

  const renderCellExported = useCallback(
    (params: GridRenderCellParams) => (
      <IconButton
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          changeExportedPosition(params.row.representative_position, !params.row.exported)
        }}
        size="small"
        data-cy={
          params.row.exported
            ? `position-result-grid-export-checked-icon-${params.row.label}`
            : `position-result-grid-export-unchecked-icon-${params.row.label}`
        }
      >
        {params.row.exported ? (
          <Check color="success" fontSize="small" />
        ) : (
          <Close color="disabled" fontSize="small" />
        )}
      </IconButton>
    ),
    [changeExportedPosition],
  )
  /* END Cell Render handlers */

  /* START constant column widths */
  const columnWidths = {
    checkbox: 25,
    grouping: 50,
    label: 75,
    maxUtilWithoutSupportCompression: 60,
    maxUtilOnlySupportCompression: 60,
    crossSectionLabel: 110,
    apAndCSEdit: 40,
    overhang: 100,
    representative: 80,
    exported: 40,
    comment: 100,
    extendedComment: 50,
    goToLocal: 40,
    shearCheck: 60,
  }
  /* END constant column widths */

  const elementTypes = useElementTypes()

  const addIdToGroup = useCallback(
    (id: string) => {
      // Only elements of same type can be grouped together
      const elementType = elementTypes[id]
      if (!elementType || positionType !== elementTypeToPositionGroupingType[elementType]) return

      // Check if id is already part of another bundle
      const existingBundle = find(
        bundles,
        bundle =>
          (bundle.representative_position === id && bundle.representative_for.length > 0) ||
          bundle.representative_for.includes(id),
      )
      if (
        !isUndefined(existingBundle) &&
        existingBundle.representative_position !== elementsToGroup.aggregator
      ) {
        enqueueSnackbar(
          `Dieses Element ${getLabel(id)} ist bereits Teil der Gruppe von ${getLabel(
            existingBundle.representative_position,
          )} und kann deswegen nicht hinzugefügt werden.`,
          { variant: 'info' },
        )
        return
      }

      if (elementsToGroup.aggregated.includes(id)) {
        const newElements = elementsToGroup.aggregated.filter(elementGuid => elementGuid !== id)
        setElementsToGroup({
          aggregator: elementsToGroup.aggregator,
          aggregated: newElements,
        })
      } else {
        setElementsToGroup({
          aggregator: elementsToGroup.aggregator,
          aggregated: [...elementsToGroup.aggregated, id],
        })
      }
    },
    [
      bundles,
      elementTypes,
      elementsToGroup.aggregated,
      elementsToGroup.aggregator,
      enqueueSnackbar,
      getLabel,
      positionType,
      setElementsToGroup,
    ],
  )

  useModelClickListeners(
    event => {
      const guid = event.object.name
      addIdToGroup(guid)
    },
    [addIdToGroup],
    editMode === EditMode.Grouping,
  )

  /* Note: the columns need display: flex to be able to align the content in the cells.
   * This is a known issue with MUI Grid: https://stackoverflow.com/questions/78347666/mui-x-data-grid-doesnt-respect-vertical-cell-alignment-after-update-to-7-2-0
   */
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'checkbox',
        headerName: '',
        width: columnWidths.checkbox,
        minWidth: columnWidths.checkbox,
        align: 'center',
        renderCell: (params: GridRenderCellParams) => {
          const { id, representative_for } = params.row
          return (
            <Checkbox
              size="small"
              disabled={
                elementsToGroup.aggregator === id ||
                representative_for.length > 0 ||
                elementsToGroup.aggregator === null
              }
              checked={[elementsToGroup.aggregator, ...elementsToGroup.aggregated].includes(id)}
              sx={{
                padding: 0,
              }}
              data-cy={`position-result-grid-checkbox-${params.row.label}`}
              // used so row clicking will not happen when clicking checkbox
              onClick={e => e.stopPropagation()}
              onChange={e => {
                e.stopPropagation()
                addIdToGroup(id)
              }}
            />
          )
        },
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        display: 'flex',
      },
      {
        field: 'label',
        headerName: 'Bauteil',
        width: columnWidths.label,
        // essentially disables the on hover tooltip
        // There is an alternative CSS based solution https://stackoverflow.com/a/77992329
        renderHeader: () => (
          <Typography fontWeight="inherit" fontSize="inherit">
            Bauteil
          </Typography>
        ),
        renderCell: (params: GridRenderCellParams) => (
          <Typography
            sx={{ fontWeight: 'inherit' }}
            data-cy={`position-result-grid-element-${params.row.label}`}
          >
            {params.row.label}
          </Typography>
        ),
        disableColumnMenu: true,
        display: 'flex',
        sortComparator: naturalSortComparator,
      },
      {
        field: 'maxUtilWithoutSupportCompression',
        headerName: 'η',
        width: columnWidths.maxUtilWithoutSupportCompression,
        renderHeader: () => <UtilizationTextIcon />,
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => {
          const isVibrationError =
            params.row.maxUtilWithoutSupportCompression?.check_type === 'Vibration' &&
            params.row.maxUtilWithoutSupportCompression?.max_utilization === 1.0

          return (
            <Tooltip title={params.row.maxUtilWithoutSupportCompression?.check_type || ''}>
              <Typography
                sx={{ fontWeight: 'inherit' }}
                data-cy={`position-result-grid-utilization-wo-support-compression-${params.row.label}`}
              >
                {isVibrationError
                  ? 'ERR'
                  : params.row.maxUtilWithoutSupportCompression?.max_utilization?.toFixed(2) ??
                    'n/a'}
              </Typography>
            </Tooltip>
          )
        },
        valueGetter: (value, row) => row.maxUtilWithoutSupportCompression?.max_utilization ?? -1,
        cellClassName: (params: GridCellParams) => {
          const maxUtilizationCheck = params.row.maxUtilWithoutSupportCompression
          const maxUtilization = maxUtilizationCheck?.max_utilization || 0
          const isVibrationError =
            maxUtilizationCheck?.check_type === 'Vibration' &&
            maxUtilizationCheck?.max_utilization === 1.0

          if (isVibrationError) return 'overutilized'
          else if (maxUtilization >= 1.0 && maxUtilization < SLIGHTLY_OVERUTILIZED_THRESHOLD)
            return 'slightly-overutilized'
          else if (SLIGHTLY_OVERUTILIZED_THRESHOLD < maxUtilization) return 'overutilized'
          else return ''
        },
        type: 'number',
        disableColumnMenu: true,
        display: 'flex',
      },
      {
        field: 'maxUtilOnlySupportCompression',
        headerName: 'ηc,90',
        width: columnWidths.maxUtilOnlySupportCompression,
        renderHeader: () => <UtilizationC90TextIcon />,
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip title={params.row.maxUtilOnlySupportCompression?.check_type || ''}>
            <Typography
              sx={{ fontWeight: 'inherit' }}
              data-cy={`position-result-grid-utilization-only-support-compression-${params.row.label}`}
            >
              {params.row.maxUtilOnlySupportCompression?.max_utilization?.toFixed(2) ?? 'n/a'}
            </Typography>
          </Tooltip>
        ),
        cellClassName: (params: GridCellParams) => {
          const maxUtilizationCheck = params.row.maxUtilOnlySupportCompression
          const maxUtilization = maxUtilizationCheck?.max_utilization || 0

          if (maxUtilization >= 1.0 && maxUtilization < SLIGHTLY_OVERUTILIZED_THRESHOLD)
            return 'slightly-overutilized'
          else if (SLIGHTLY_OVERUTILIZED_THRESHOLD < maxUtilization) return 'overutilized'
          else return ''
        },
        valueGetter: (value, row) => row.maxUtilOnlySupportCompression?.max_utilization ?? -1,
        type: 'number',
        disableColumnMenu: true,
        display: 'flex',
      },
      {
        field: 'crossSectionLabel',
        headerName: 'QS',
        headerAlign: 'center',
        width: columnWidths.crossSectionLabel,
        renderCell: (params: GridRenderCellParams) => params.value,
        align: 'center',
        disableColumnMenu: true,
        display: 'flex',
      },
      {
        field: 'ap-edit',
        headerName: 'ap-qs-edit',
        headerAlign: 'center',
        renderHeader: () => null, // show no header
        width: columnWidths.apAndCSEdit,
        minWidth: columnWidths.apAndCSEdit,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <IconButton
              size="small"
              onClick={(e: MouseEvent<HTMLButtonElement>) =>
                handleClickForSettingsForm(e, params.row.representative_position)
              }
              data-cy={`btn-open-settings-form-${params.row.label}`}
            >
              <Edit fontSize="inherit" />
            </IconButton>
          )
        },
        align: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        display: 'flex',
      },
      {
        field: 'shear-check-settings',
        headerName: 'Schubeinstellungen',
        headerAlign: 'center',
        width: columnWidths.shearCheck,
        renderCell: renderCellShearCheck,
        disableColumnMenu: true,
        renderHeader: () => (
          <Tooltip
            title={
              <Box>
                <Box mb={0.2}>
                  <Typography sx={{ fontSize: 10 }}>
                    (1) Auflagernahe Punktlasten abmindern
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: 10 }}>
                    (2) Bemessungsquerkraft im Abstand h vom Auflager
                  </Typography>
                </Box>
              </Box>
            }
            placement="bottom"
          >
            <Typography fontSize="inherit" fontWeight="inherit">
              Schubeinstellungen
            </Typography>
          </Tooltip>
        ),
        align: 'center',
        display: 'flex',
      },
      {
        field: 'representative',
        headerName: 'repr.',
        headerAlign: 'center',
        width: columnWidths.representative,
        renderCell: renderCellRepresentative,
        disableColumnMenu: true,
        valueGetter: (value, row) => row.representative_for.length,
        align: 'center',
        display: 'flex',
      },
      {
        field: 'exported',
        headerName: 'export',
        headerAlign: 'center',
        width: columnWidths.exported,
        minWidth: columnWidths.exported,
        renderCell: renderCellExported,
        align: 'center',
        disableColumnMenu: true,
        display: 'flex',
      },
      {
        field: 'comment',
        headerName: 'Kommentar',
        editable: true,
        sortable: false,
        filterable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Typography
              data-cy={`txt-edit-comment-${params.row.label}`}
              fontSize="inherit"
              fontWeight="inherit"
            >
              {params.row.comment}
            </Typography>
          )
        },
        disableColumnMenu: true,
        display: 'flex',
      },
      {
        field: 'extended_comment',
        headerName: 'Vorbem.',
        width: columnWidths.extendedComment,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip title={params.value || ''}>
            <IconButton
              size="small"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                setIsLocalMode(true, params.row.representative_position, 'settings')
              }}
              sx={({ palette }) => ({
                color: params.value && params.value.length > 0 ? 'none' : `${palette.grey[400]}`, // Mimick 'disabled' colour
              })}
              data-cy={
                params.value && params.value.length > 0
                  ? `icon-extended-comment-${params.row.label}`
                  : `icon-extended-comment-${params.row.label}-disabled`
              }
            >
              <EditNote fontSize="inherit" />
            </IconButton>
          </Tooltip>
        ),
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        display: 'flex',
      },
      {
        field: 'overhang',
        headerName: 'ü [cm]',
        width: columnWidths.overhang,
        minWidth: columnWidths.overhang,
        renderCell: (params: GridRenderCellParams) => {
          const rowValues: LintelResultsGridRow = params.row
          const elementGuid = rowValues.id
          const checkSettings = checkSettingsLookup[elementGuid] as TimberCheckSettings
          return (
            <>
              <OverhangCell
                rowValues={rowValues}
                checkSettings={checkSettings}
                elementGuidToCrossSection={elementGuidToCrossSection}
              />
            </>
          )
        },
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        display: 'flex',
      },
      {
        field: 'go-to-local',
        headerName: '',
        width: columnWidths.goToLocal,
        minWidth: columnWidths.goToLocal,
        renderCell: (params: GridRenderCellParams) => (
          <IconButton
            size="small"
            onClick={(e: { stopPropagation: () => void }) => {
              e.stopPropagation()
              setIsLocalMode(true, params.row.id)
            }}
            data-cy={selectedElement === params.row.id ? 'btn-to-local-active' : undefined}
          >
            <ArrowForward fontSize="inherit" />
          </IconButton>
        ),
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        display: 'flex',
      },
    ],
    [
      addIdToGroup,
      checkSettingsLookup,
      columnWidths.apAndCSEdit,
      columnWidths.checkbox,
      columnWidths.crossSectionLabel,
      columnWidths.exported,
      columnWidths.extendedComment,
      columnWidths.goToLocal,
      columnWidths.label,
      columnWidths.maxUtilOnlySupportCompression,
      columnWidths.maxUtilWithoutSupportCompression,
      columnWidths.overhang,
      columnWidths.representative,
      columnWidths.shearCheck,
      elementGuidToCrossSection,
      elementsToGroup.aggregated,
      elementsToGroup.aggregator,
      renderCellExported,
      renderCellRepresentative,
      renderCellShearCheck,
      selectedElement,
      setIsLocalMode,
    ],
  )

  const isLoadingCSAssignment = isLoadingCSAssignmentRegularly

  /* END Grouping Mode functions */

  const apiRef = useGridApiRef()

  const scrollToNewSelectionModel = useCallback(
    (newSelectionModel: GridRowId[]) => {
      // https://mui.com/x/react-data-grid/scrolling/
      if (!apiRef.current) return
      const selectedRowID = newSelectionModel[0] as string
      if (rows && selectedRowID) {
        const selectedRowIndex = gridExpandedSortedRowIdsSelector(apiRef).findIndex(
          id => id === selectedRowID,
        )
        if (selectedRowIndex !== -1) {
          const currentPaginationModel = apiRef.current.state.pagination.paginationModel
          const pageIndex = Math.floor(selectedRowIndex / currentPaginationModel.pageSize)
          apiRef.current.setPage(pageIndex)
          apiRef.current.scrollToIndexes({
            rowIndex: selectedRowIndex,
            colIndex: 0,
          })
        }
      }
    },
    [apiRef, rows],
  )

  useEffect(() => {
    // this is required to make sure that the selected element is visible in the table
    if (selectedElement && apiRef.current) {
      // note: We want to achieve an ASYMMETRIC behaviour when the grouping mode is turned on.
      // when grouping mode is turned on,
      // When the user clicks an element in the scene, the element should be added to the group.
      // WWhen the user clicks on a row in the table, the element should NOT be added to the group.
      // in order for this to work, we need
      // 1) no rowSelectionModel defined on the Datagrid
      // 2) the row selection to be handled explicitly in the onRowClick
      // 3) use this effecto to check if the current row is already the selected element.
      //    If not check for edit mode and update the grouping.
      const newSelectionModel = [selectedElement] as string[]
      const currentSelectionModel = apiRef.current.getSelectedRows()
      const isCurrentlySelected = currentSelectionModel.has(selectedElement)
      if (!isCurrentlySelected) {
        apiRef.current.setRowSelectionModel([selectedElement] as string[])
        if (editMode === EditMode.Grouping) {
          if (selectedElement !== elementsToGroup.aggregator) {
            const newElementsToGroup = {
              aggregator: elementsToGroup.aggregator,
              aggregated: [...reject(elementsToGroup.aggregated, selectedElement), selectedElement],
            }
            setElementsToGroup(newElementsToGroup)
          }
        }
      }

      setTimeout(() => {
        scrollToNewSelectionModel(newSelectionModel)
      }, 0)
    }
  }, [
    selectedElement,
    rows,
    apiRef,
    editMode,
    elementsToGroup.aggregator,
    elementsToGroup.aggregated,
    scrollToNewSelectionModel,
    setElementsToGroup,
  ])

  const positionFilters = useResultsStore(state => state.positionFilters)
  const setPositionFilter = useResultsStore(state => state.setPositionFilter)

  const positionSort = useResultsStore(state => state.positionSort)
  const setPositionSort = useResultsStore(state => state.setPositionSort)
  const positionPagination = useResultsStore(state => state.positionPagination)
  const setPositionPagination = useResultsStore(state => state.setPositionPagination)

  return (
    <>
      <Stack spacing={2}>
        {openRow && !isSelectionMode && (
          <GroupDetailView
            openRow={openRow}
            renderCellRepresentative={renderCellRepresentative}
            renderCellExported={renderCellExported}
            saveCSAssignment={saveCSAssignment}
            onClickUngroupElement={onClickUngroupMember}
          />
        )}
        <StyledDataGrid
          rows={rows}
          filterModel={positionFilters[positionType]}
          onFilterModelChange={filter => setPositionFilter(positionType, filter)}
          sortModel={positionSort[positionType]}
          onSortModelChange={sort => setPositionSort(positionType, sort)}
          paginationModel={positionPagination[positionType]}
          onPaginationModelChange={pagination => setPositionPagination(positionType, pagination)}
          columns={columns}
          columnVisibilityModel={
            isDrawerExpanded
              ? {
                  'shear-check-settings': showShearCheckColumn,
                  overhang: showOverhangColumn,
                }
              : {
                  crossSectionLabel: false,
                  'ap-edit': false,
                  comment: false,
                  extended_comment: false,
                  'shear-check-settings': showShearCheckColumn,
                  overhang: showOverhangColumn,
                }
          }
          processRowUpdate={handleRowUpdate}
          density="compact"
          disableDensitySelector
          getRowHeight={() => 'auto'}
          sx={{
            '& .MuiDataGrid-cell': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-main': {
              // Target the main container of the DataGrid
              maxHeight: '55vh', // Make sure the grid takes full height of its container
            },
          }}
          slots={{
            // @ts-expect-error
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              // @ts-expect-error
              onSave: handleSave,
              isLoading: isLoading,
              editMode: editMode,
              onCancelEdit: handleGroupingModeEnd,
              isDownloadingExportDocument: isDownloadingExportDocument,
              onClickDownloadExportDocument: onClickDownloadExportDocument,
              positionType: positionType,
            },
          }}
          checkboxSelection={false}
          disableRowSelectionOnClick={isSelectionMode}
          onRowClick={(params: GridRowParams) => {
            if (isSelectionMode) return

            const newSelectedElement = params.id as string
            selectPosition(newSelectedElement)
          }}
          data-cy="position-result-grid"
          initialState={{
            sorting: {
              sortModel: [{ field: 'maxUtilWithoutSupportCompression', sort: 'desc' }],
            },
            pagination: {
              paginationModel: { pageSize: 100 },
            },
          }}
          apiRef={apiRef}
        />
        <Popover
          open={!!anchorElementForPopover}
          anchorEl={anchorElementForPopover?.anchor}
          onClose={() => handleCloseForPopover()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          hideBackdrop
          slotProps={{
            root: {
              sx: {
                pointerEvents: 'none',
                overflowY: 'scroll',
              },
            },
          }}
        >
          {anchorElementForPopover?.source === 'compression-settings' && (
            <Stack
              paddingTop={1}
              paddingX={2}
              paddingBottom={2}
              direction="column"
              sx={{ pointerEvents: 'all' }}
              spacing={1}
            >
              <Box flex={1} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">{getLabel(anchorElementForPopover.guid)}</Typography>
                <IconButton size="small" onClick={() => handleCloseForPopover()}>
                  <Close />
                </IconButton>
              </Box>

              <ErrorBoundary fallback={renderGenericErrorBoundary}>
                <CSAndAPPopup
                  onClose={handleCloseForPopover}
                  isLoading={isLoadingCSAssignment}
                  elementGuid={anchorElementForPopover.guid}
                  positionType={positionType}
                  onlyEnableCompressionSettingChanges={
                    positionType === 'roof-slab-beams' || positionType === 'slab-beams'
                  }
                />
              </ErrorBoundary>
            </Stack>
          )}
        </Popover>
      </Stack>
    </>
  )
}

export default GenericResultsGrid

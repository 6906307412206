import { ReactElement, useMemo } from 'react'
import { useController } from 'react-hook-form'
import { find, isFunction } from 'lodash-es'
import { Autocomplete as MuiAutocomplete, MenuItem, TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import FormControl from '../FormControl'

interface Props {
  name: string
  query: RequestType
  renderOptions: (data: any) => { value: any; label: string }[] // eslint-disable-line
  placeholder?: string
  label?: string
  disabled?: boolean
  onChangeFinished?: () => void
  tooltip?: TooltipContents
  'data-cy'?: string
}

const AutocompleteLoadable = ({
  name,
  query,
  placeholder = 'Bitte auswählen',
  label = '',
  tooltip,
  disabled = false,
  onChangeFinished,
  renderOptions,
  'data-cy': dataCy,
}: Props): ReactElement => {
  const { data, isLoading, error: backendError } = useQuery(query.key, query.request)
  const backendErroMessage = backendError ? 'Daten konnten nicht geladen werden' : undefined
  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
  } = useController({
    name,
  })

  const options = useMemo(() => {
    if (!data) return []

    return renderOptions(data)
  }, [data, renderOptions])

  return (
    <FormControl
      label={label}
      error={error?.message || backendErroMessage}
      isLoading={isLoading}
      tooltip={tooltip}
    >
      <MuiAutocomplete
        options={options}
        getOptionLabel={option => option.label}
        value={find(options, ['value', value]) || null}
        onChange={(event, newValue) => {
          onChange(newValue ? newValue.value : null)
          isFunction(onChangeFinished) && onChangeFinished()
        }}
        renderOption={(props, option, { index }) => (
          <MenuItem
            {...props}
            key={option.value.toString()}
            value={option.value}
            data-cy={`${dataCy}-item-${index}`}
          >
            {option.label}
          </MenuItem>
        )}
        onBlur={onBlur}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            error={!!error || !!backendErroMessage}
            helperText={error?.message || backendErroMessage}
            inputRef={ref}
            name={fieldName}
            disabled={disabled}
          />
        )}
        data-cy={dataCy}
      />
    </FormControl>
  )
}

export default AutocompleteLoadable

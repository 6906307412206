import { ReactElement } from 'react'
import { Button } from '@mui/material'
import { UseMutationResult } from '@tanstack/react-query'
import { SaveButton } from '@ui/actions'
import { DialogBase } from '@ui/feedback'
import ElementCSForm from '../ElementCSForm'

const FORM_ID = 'edit-element-cross-section'

interface Props {
  open: boolean
  onClose: () => void
  elementCrossSection: ElementCS
  mutation:
    | UseMutationResult<ElementCS, unknown, ElementCS, unknown>
    | UseMutationResult<ProjectElementCS, unknown, ProjectElementCS, unknown>
}

const EditElementCSDialog = ({
  open,
  onClose,
  elementCrossSection,
  mutation,
}: Props): ReactElement => {
  const title = 'Element-Querschnitt verändern'

  const handleSubmit = async (data: ElementCS | ProjectElementCS) => {
    await mutation.mutateAsync(data as ElementCS | ProjectElementCS)
    onClose()
  }

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      title={title}
      dialogProps={{
        fullWidth: true,
      }}
    >
      <>
        <ElementCSForm
          id={FORM_ID}
          type={elementCrossSection.kind}
          defaultValues={elementCrossSection}
          onSubmit={handleSubmit}
        />
      </>

      <>
        <Button onClick={onClose}>Abbrechen</Button>
        <SaveButton
          loading={mutation.isLoading}
          type="submit"
          form={FORM_ID}
          data-cy="save-element-cross-section"
        >
          Speichern
        </SaveButton>
      </>
    </DialogBase>
  )
}

export default EditElementCSDialog

import { isNull } from 'lodash-es'
import { getRequest, postRequest, retryUntilSuccessOrFailure } from '../utils'

export const computeCheckResults: RequestType = {
  key: ['computeCheckResults'],
  request: async (projectId: string): Promise<void> => {
    const { data: elementCheckBundleData } = await postRequest<{ task_id: string } | null>({
      url: `/projects/${projectId}/element-check-bundle/compute`,
    })

    if (isNull(elementCheckBundleData)) throw new Error('Cannot compute element check bundle yet')

    await retryUntilSuccessOrFailure({
      url: `/task-status/${elementCheckBundleData?.task_id}`,
    })
  },
}

export const getInnerForcesPlot: RequestTypeVariable = {
  getKey: (projectId: string, position: string) => ['getInnerForcesPlot', projectId, position],
  request: async (projectId: string, position: string): Promise<Blob> => {
    const res = await getRequest<Blob>({
      url: `/projects/${projectId}/inner-forces-plot/${position}`,
      responseType: 'blob',
    })
    return res.data as Blob
  },
}

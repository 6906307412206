import { object, number, array, string } from 'yup'

const nonIntegerNumber = number().test(
  'is-converted-integer',
  'value is not integer',
  function (value) {
    if (value === undefined) return false
    const isInteger = Number.isInteger(value * 1000)
    if (!isInteger)
      return this.createError({
        message: `Wert ist keine Ganzzahl (Wert: ${value * 1000})`,
      })

    return true
  },
)

const schemaSupportConfig = object({
  width: nonIntegerNumber,
  length: nonIntegerNumber,

  k_c_90: number().oneOf([1.0, 1.25, 1.5, 1.75, 1.4, 1.9]).required(),
})

const schemaCrossSection = object({
  element_cs: object({
    material: object({ identifier: string() }),
    shape: object({
      width: nonIntegerNumber,
      height: nonIntegerNumber,
    }),
  }),
})

export const schemaFormBundles = object({
  formBundles: array().of(
    object({
      supportConfig: schemaSupportConfig,
      targetCrossSection: schemaCrossSection.nullable().default(null),
    }),
  ),
})

import { getRequest } from '../utils'

export const getHorizontalForcesPerSegment: RequestTypeVariable = {
  getKey: (id: string) => ['getHorizontalForcesPerSegment', id],
  request: async (projectId): Promise<SegmentForces[] | null> => {
    const { data } = await getRequest<SegmentForces[]>({
      url: `/projects/${projectId}/horizontal-forces-per-segment`,
    })

    return data
  },
}

export const getLoadsPerElement: RequestTypeVariable = {
  getKey: (id: string) => ['getLoadsPerElement', id],
  request: async (projectId): Promise<ElementLoad[] | null> => {
    const { data } = await getRequest<ElementLoad[]>({
      url: `/projects/${projectId}/loads-per-element`,
    })

    return data
  },
}

export const getLoadTracingMap: RequestTypeVariable = {
  getKey: (projectId: string) => ['getLoadTracingMap', projectId],
  request: async (id): Promise<LoadSource[]> => {
    const { data } = await getRequest<LoadSource[]>({
      url: `/projects/${id}/load-tracing-map`,
    })

    return data as LoadSource[]
  },
}

export const getAnchorInterventionTableData: RequestTypeVariable = {
  getKey: (projectId: string) => ['getAnchorInterventionTableData', projectId],
  request: async (id): Promise<AnchorInterventionsTableRowData[]> => {
    const { data } = await getRequest<AnchorInterventionsTableRowData[]>({
      url: `/projects/${id}/anchor-interventions-table-data`,
    })

    return data as AnchorInterventionsTableRowData[]
  },
}

import { cloneDeep } from 'lodash-es'
import create from 'zustand'
import { combine, persist } from 'zustand/middleware'

interface AppStoreState {
  // key: project id, value: session id
  projectSessions: Record<string, string>
  recordSession: boolean
  showSnow: boolean
  /**
   * Show stats panel for debugging purposes
   */
  showStats: boolean

  // Perfomance related settings
  instancedConnectors: boolean
  instancedTransmitters: boolean
}

const initialState: AppStoreState = {
  projectSessions: {},
  recordSession: true,
  showSnow: true,
  showStats: false,

  instancedConnectors: false,
  instancedTransmitters: false,
}

/**
 * App store will be written to localStorage of browser and therefore
 * persistent. Use this store for any app settings that should be kept after
 * refresh
 */
const useAppStore = create(
  persist(
    combine(cloneDeep(initialState), set => ({
      addSession: (projectId: string, sessionId: string) =>
        set(state => ({
          projectSessions: {
            ...state.projectSessions,
            [projectId]: sessionId,
          },
        })),
      setRecordSession: (recordSession: boolean) => set({ recordSession }),
      setShowSnow: (showSnow: boolean) => set({ showSnow }),
      setShowStats: (showStats: boolean) => set({ showStats }),
      setInstancedConnectors: (instancedConnectors: boolean) => set({ instancedConnectors }),
      setInstancedTransmitters: (instancedTransmitters: boolean) => set({ instancedTransmitters }),
    })),
    { name: 'app-storage' },
  ),
)

export default useAppStore

import { ReactElement, useState, MouseEvent } from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  Toolbar,
  Link,
  IconButton,
  Stack,
  Fade,
  Menu,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Tooltip,
  Theme,
  Typography,
  ListSubheader,
  Divider,
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import logoIcon from '@img/logo-icon.png'
import logo from '@img/logo-text-white.png'
import { NestedMenuItem } from '@ui/navigation/NestedMenu'
import { useAppStore, useUIStore } from '@stores'
import { appConfig } from 'src/constants'
import MenuToggleWithMatureness from './components/MaturenessIndicator'
import ProjectSettings from './components/ProjectSettings'
import { Logo } from './styles'

export const COMPUTE_CHECKS_WORKER_STRATEGIES: ComputeChecksWorkerStrategyType[] = [
  'task_group',
  'chunks_synchronous',
  'chunks_write_back',
  'fallback',
]

const Navbar = (): ReactElement => {
  const { logout, user } = useAuth0()

  const recordSession = useAppStore(state => state.recordSession)
  const setRecordSession = useAppStore(state => state.setRecordSession)

  const showSnow = useAppStore(state => state.showSnow)
  const setShowSnow = useAppStore(state => state.setShowSnow)

  const showStats = useAppStore(state => state.showStats)
  const setShowStats = useAppStore(state => state.setShowStats)

  const instancedConnectors = useAppStore(state => state.instancedConnectors)
  const setInstancedConnectors = useAppStore(state => state.setInstancedConnectors)

  const instancedTransmitters = useAppStore(state => state.instancedTransmitters)
  const setInstancedTransmitters = useAppStore(state => state.setInstancedTransmitters)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = !!anchorEl
  const setPortalRootLeft = useUIStore(state => state.setPortalRootLeft)
  const setPortalRootCenter = useUIStore(state => state.setPortalRootCenter)
  const setPortalRootRight = useUIStore(state => state.setPortalRootRight)
  const reducedLogo = useUIStore(state => state.reducedLogo)

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin + '/authorize' } })
  }

  const { projectId }: { projectId?: string } = useParams()

  return (
    <AppBar sx={{ zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar sx={{ display: 'flex', gap: 2 }}>
        <Link
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          component={RouterLink}
          to="/"
        >
          <Logo reduced={reducedLogo} src={reducedLogo ? logoIcon : logo} />
        </Link>
        <Stack direction="row" flexGrow={1} justifyContent="space-between">
          <Stack direction="row" ref={ref => setPortalRootLeft(ref as HTMLDivElement)} />
          <Stack
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate3d(-50%, -50%, 0)',
            }}
            direction="row"
            ref={ref => setPortalRootCenter(ref as HTMLDivElement)}
          />
          <Stack direction="row" ref={ref => setPortalRootRight(ref as HTMLDivElement)} />
        </Stack>
        <Stack direction="row">
          <IconButton
            sx={{ color: 'white' }}
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpen}
            data-cy="navbar-menu"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleOpen}
            TransitionComponent={Fade}
          >
            <ListSubheader>Account</ListSubheader>

            {user && (
              <>
                <MenuItem>
                  <Tooltip
                    title={user.sub}
                    placement="left"
                    onClick={() => {
                      navigator.clipboard.writeText(user.sub || '')
                      enqueueSnackbar({
                        message: 'User ID in Zwischenablage kopiert',
                        variant: 'info',
                      })
                    }}
                  >
                    <Typography>{user.email}</Typography>
                  </Tooltip>
                </MenuItem>

                <MenuItem onClick={handleLogout}>Logout</MenuItem>

                <Divider />
              </>
            )}

            <NestedMenuItem
              label="Allgemein"
              parentMenuOpen={open}
              rightIcon={<ChevronRightIcon />}
            >
              <MenuItem>
                <FormGroup>
                  <Tooltip title="Aufnehmen und Übertragen der Sitzung" placement="left">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={recordSession}
                          onChange={e => setRecordSession(e.target.checked)}
                        />
                      }
                      label="Aufnehmen"
                    />
                  </Tooltip>
                </FormGroup>
              </MenuItem>
              {appConfig.enableSnowEasterEgg && (
                <MenuItem>
                  <FormGroup>
                    <Tooltip title="Schnee in der Scene anzeigen" placement="left">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={showSnow}
                            onChange={e => setShowSnow(e.target.checked)}
                          />
                        }
                        label="Schnee"
                      />
                    </Tooltip>
                  </FormGroup>
                </MenuItem>
              )}
              <MenuItem>
                <FormGroup>
                  <Tooltip title="Scene Stats anzeigen (debugging)" placement="left">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showStats}
                          onChange={e => setShowStats(e.target.checked)}
                        />
                      }
                      label="Scene Stats"
                    />
                  </Tooltip>
                </FormGroup>
              </MenuItem>
            </NestedMenuItem>

            <NestedMenuItem
              label="Performance (UI rendering)"
              parentMenuOpen={open}
              rightIcon={<ChevronRightIcon />}
            >
              <MenuToggleWithMatureness
                checked={instancedConnectors}
                setChecked={setInstancedConnectors}
                tooltipTitle="Rendering der Zugverbindung optimieren"
                menuItemLabel="Zugverbindungen optimieren"
                matureness="mature"
              />

              <MenuToggleWithMatureness
                checked={instancedTransmitters}
                setChecked={setInstancedTransmitters}
                tooltipTitle="Rendering der Vertikalen Verbindungen optimieren"
                menuItemLabel="Vertikale Verbindungen optimieren"
                matureness="mature"
              />
            </NestedMenuItem>

            {projectId && <ProjectSettings parentMenuOpen={open} />}
          </Menu>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar

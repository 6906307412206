import { useEffect, useCallback, useRef } from 'react'
import { isHotkeyPressed } from 'react-hotkeys-hook'
import { isFunction, find } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import { ThreeEvent } from '@react-three/fiber'
import { useControlStore } from '@editorStores'

/**
 * TODO: make configuration via config object (difficult because of rerendering)
 * @param callback
 * @param dependencies
 * @param enable
 * @param isExclusive
 * @param enableOnShift
 * @returns
 */
const useModelClickListeners = (
  callback?: ModelClickListener,
  dependencies: unknown[] = [],
  enable = true,
  isExclusive = false,
  /**
   * Enable this callback while the shift key is pressed
   */
  enableOnShift = false,
): ModelClickListener => {
  const id = useRef<string>(uuid())
  const clickListeners = useControlStore(state => state.clickListeners)
  const setClickListener = useControlStore(state => state.setClickListener)
  const removeClickListener = useControlStore(state => state.removeClickListener)

  const executeListeners = useCallback(
    (event: ThreeEvent<MouseEvent>) => {
      const listeners = Array.from(clickListeners.values())
      const exclusiveListener = find(listeners, ['isExclusive', true])
      const shiftPressed = isHotkeyPressed('shift')

      if (exclusiveListener) {
        exclusiveListener.listener(event)
        return
      }

      listeners.forEach(({ listener, enableOnShift }) => {
        if (shiftPressed && !enableOnShift) return

        listener(event)
      })
    },
    [clickListeners],
  )

  useEffect(() => {
    if (!enable || !isFunction(callback)) return
    setClickListener(id.current, callback, isExclusive, enableOnShift)

    return () => removeClickListener(id.current)
  }, [enable, enableOnShift, ...dependencies])

  return executeListeners
}

export default useModelClickListeners

import { ReactElement } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { UseMutationResult } from '@tanstack/react-query'
import { ElementCSList } from 'src/components/manager/element_cs'

interface Props {
  elementCrossSections: ElementCS[]
  defaultRowsPerPage?: number
  onDelete?: (elementCS: ElementCS) => void

  createMutation:
    | UseMutationResult<ElementCS, unknown, ElementCS, unknown>
    | UseMutationResult<ProjectElementCS, unknown, ProjectElementCS, unknown>

  editMutation:
    | UseMutationResult<ElementCS, unknown, ElementCS, unknown>
    | UseMutationResult<ProjectElementCS, unknown, ProjectElementCS, unknown>
}

const ElementCSSelection = ({
  elementCrossSections,
  onDelete,
  createMutation,
  editMutation,
}: Props): ReactElement => {
  const { setValue } = useFormContext()

  const selection = useWatch({}) as ElementCSSelection

  return (
    <ElementCSList
      elementCrossSections={elementCrossSections}
      onDelete={onDelete}
      createMutation={createMutation}
      editMutation={editMutation}
      onSelect={({ guid, kind }) => {
        if (selection?.[kind.toLowerCase() as 'beam' | 'column' | 'purlin'] === guid) {
          setValue(`[${kind.toLowerCase()}]`, undefined)
        } else {
          setValue(`[${kind.toLowerCase()}]`, guid)
        }
      }}
      selectedElementCrossSections={selection}
      editCSDialogText="Änderungen an diesem Element-Querschnitt werden nur innerhalb des Projektes angewandt und wirken sich nicht auf andere Projekte aus."
    />
  )
}

export default ElementCSSelection

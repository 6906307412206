import { useEffect } from 'react'
import { BufferGeometry, Material } from 'three'
import { Geometry } from 'three-stdlib'

const useDisposeThreeResourceOnUnmount = (resource: Geometry | BufferGeometry | Material) => {
  useEffect(() => {
    return () => resource.dispose()
  }, [resource])
}

export default useDisposeThreeResourceOnUnmount

import { ReactElement, ReactNode, MouseEvent } from 'react'
import { DoDisturb, Check } from '@mui/icons-material'
import { Tooltip, IconButton, Typography, Stack } from '@mui/material'

interface Props {
  label: string
  icon: ReactNode
  open: boolean
  valid: boolean
  onClick: (event: MouseEvent) => void
  dataCy?: string
}

const ProjectStateItem = ({ label, icon, valid, onClick, dataCy }: Props): ReactElement => {
  const ValidIcon = (props: { applyColor?: boolean }) =>
    valid ? (
      <Check
        fontSize="inherit"
        sx={{ fontSize: 14 }}
        color={props.applyColor ? 'success' : undefined}
        data-cy={`project-state-${dataCy}-configured-icon`}
      />
    ) : (
      <DoDisturb
        fontSize="inherit"
        sx={{ fontSize: 14 }}
        color={props.applyColor ? 'warning' : undefined}
        data-cy={`project-state-${dataCy}-not-configured-icon`}
      />
    )

  return (
    <Stack direction="row" alignItems="center" pl={0.5} spacing={1}>
      <Tooltip
        title={
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography fontSize="inherit">{label}</Typography>
            <ValidIcon />
          </Stack>
        }
        placement="right"
      >
        <Stack direction="row" alignItems="center">
          <IconButton
            sx={{ background: 'transparent !important' }}
            size="small"
            color={valid ? 'success' : 'default'}
            onClick={event => onClick(event)}
          >
            {icon}
          </IconButton>
          <ValidIcon applyColor />
        </Stack>
      </Tooltip>
    </Stack>
  )
}

export default ProjectStateItem

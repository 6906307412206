import produce from 'immer'
import { round } from 'lodash-es'
import { Vector3, Matrix4, Quaternion, Sphere } from 'three'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

export const buildGroup = (supportItemsWithoutActive: PositionedTarget[], height: number) => {
  const groups = produce(state => {
    supportItemsWithoutActive.forEach(item => {
      const length = round(item.interval.start.distanceTo(item.interval.end), 3)

      if (state[length] === undefined) {
        const sphere = new Sphere()

        const originStart = new ImmutableVector3(0, 0, 0)
        const originEnd = new ImmutableVector3(0, length, 0)

        const offsettedStart = new ImmutableVector3(
          originStart.x,
          originStart.y,
          originStart.z - height,
        )
        const offsettedEnd = new ImmutableVector3(originEnd.x, originEnd.y, originEnd.z - height)

        const transmitterPoints = [originStart, originEnd, offsettedEnd, offsettedStart]

        const vertices = new Float32Array([
          ...transmitterPoints[0].v.toArray(),
          ...transmitterPoints[1].v.toArray(),
          ...transmitterPoints[2].v.toArray(),
          ...transmitterPoints[3].v.toArray(),
        ])

        // Define the indices for two triangles forming a rectangle
        const indices = new Uint16Array([0, 1, 2, 0, 3, 2])

        state[length] = {
          vertices,
          indices,
          transmitterPoints,
          items: [],
          sphere,
        }
      }

      const start = item.interval.start.v
      const end = item.interval.end.v

      const direction = item.interval.start.directionTo(item.interval.end, true).v

      // Compute rotation: align local Y-axis (0,1,0) to `direction`
      const up = new Vector3(0, 1, 0) // Default up (matches initial line orientation)
      const quaternion = new Quaternion().setFromUnitVectors(up, direction)

      // Create transformation matrix
      const rotationMatrix = new Matrix4().makeRotationFromQuaternion(quaternion)
      const translationMatrix = new Matrix4().makeTranslation(start.x, start.y, start.z)
      const transformationMatrix = new Matrix4().multiplyMatrices(translationMatrix, rotationMatrix)

      state[length].sphere.expandByPoint(start)
      state[length].sphere.expandByPoint(end)

      const enhancedItem: InstancedTransmitterGroupItem = {
        target: item,
        matrix: transformationMatrix,
      }

      state[length].items.push(enhancedItem)
    })
  }, {} as Record<string, InstancedTransmitterGroup>)()

  return groups
}

import { useEffect } from 'react'
import { isArray } from 'lodash-es'
import { Line, BufferGeometry, NormalBufferAttributes, Material, Mesh } from 'three'

/**
 * Helper to dispose manually created threejs object on unmount
 * @param objs
 */
const useDisposeThreeObjectsOnUnmount = (
  objs?: (
    | // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Mesh<BufferGeometry<NormalBufferAttributes>, Material | Material[], any>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | Line<BufferGeometry<NormalBufferAttributes>, Material | Material[], any>
  )[],
) => {
  useEffect(() => {
    return () => {
      objs?.forEach(object3D => {
        object3D.geometry.dispose()
        if (isArray(object3D.material)) {
          object3D.material.forEach(m => m.dispose())
        } else {
          object3D.material.dispose()
        }
      })
    }
  }, [objs])
}

export default useDisposeThreeObjectsOnUnmount

import GenericErrorBoundary from '.'

const renderGenericErrorBoundary = (errorData: {
  error: Error
  componentStack: string | null
  eventId: string | null
  resetError(): void
}) => <GenericErrorBoundary error={errorData.error} />

export default renderGenericErrorBoundary

import { ReactElement, ReactNode } from 'react'
import { useUpdateCheckSettingsIfMaterialChanges } from '../../misc'

interface Props {
  children: ReactNode
}

/**
 * This is used to update the check settings if a material changes (e.g. from
 * timber to steel)
 * @param param0
 * @returns
 */
const MaterialCheckSettingsWrapper = ({ children }: Props): ReactElement => {
  useUpdateCheckSettingsIfMaterialChanges()

  return <>{children}</>
}

export default MaterialCheckSettingsWrapper

import { useMemo } from 'react'
import useElementTypes from './useElementTypes'

const useElementType = (guid: string | null | undefined): ElementTypes | undefined => {
  const elementTypes = useElementTypes()
  const type = useMemo(
    () => (guid === null || guid === undefined ? undefined : elementTypes[guid]),
    [elementTypes, guid],
  )

  return type
}

export default useElementType

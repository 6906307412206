import { ReactElement, useCallback, useMemo, useState } from 'react'
import { memberCheckTypeToReadableMap } from '@domainConstants'
import { useResultsQueryParams } from '@resultsHooks'
import { orderBy } from 'lodash-es'
import { LoadingButton } from '@mui/lab'
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import { UseMutateFunction } from '@tanstack/react-query'
import { Form } from '@ui/forms'
import { schemaSteelColumn } from '../schema'
import { getCheckTypeSortValue } from '../utils'
import MemberCheckRow from './MemberCheckRow'
import SteelBasePlateConfig from './SteelBasePlateConfig'

interface Props {
  positionChecks: CombinedPositionCheck[]
  settingsOnMember: SteelColumnCheckSettings
  onSave: UseMutateFunction<SettingsOnMember, unknown, SettingsOnMember, unknown>
  isLoadingOnSave: boolean
  download: UseMutateFunction<
    {
      data: Blob
      filename: string
    },
    unknown,
    string,
    unknown
  >
  isLoadingDownload: boolean
  disableSaving?: boolean
}

interface CheckWithIndexForSettings {
  check: CombinedPositionCheck
  index: number | undefined
}

const SteelMemberChecksTable = ({
  positionChecks,
  settingsOnMember,
  onSave,
  isLoadingOnSave,
  download,
  isLoadingDownload,
  disableSaving = false,
}: Props): ReactElement | null => {
  const [sortBy, setSortBy] = useState<'type' | 'utilization' | 'position'>('type')
  const [sortAsc, setSortAsc] = useState(true)
  const [openIndex, setOpenIndex] = useState<number | null>(null)
  const {
    actions: { setSelectedCheckPosition },
  } = useResultsQueryParams()

  const sortFilter = useCallback(
    (checkWithSetting: CheckWithIndexForSettings) => {
      const check = checkWithSetting.check

      let sortFilter: MemberCheckType | number = check.max_utilization

      if (
        sortBy === 'position' &&
        check.check_category_type !== 'Vibration' &&
        check.check_type !== 'Vibration' &&
        check.check_category_type !== 'HotDimensioning' &&
        check.check_type !== 'HotDimensioning' &&
        check.check_category_type !== 'HotDimensioningCLT' &&
        check.check_type !== 'HotDimensioningCLT'
      )
        sortFilter = check.relative_position
      if (sortBy === 'type') {
        sortFilter = getCheckTypeSortValue(check.check_type as MemberCheckType)
      }

      return sortFilter
    },
    [sortBy],
  )

  const checkWithIndex: CheckWithIndexForSettings[] = useMemo(() => {
    return positionChecks.map(check => {
      return { check: check, index: undefined }
    })
  }, [positionChecks])

  const sortedChecks = useMemo(
    () => orderBy(checkWithIndex, [sortFilter], sortAsc ? 'asc' : 'desc'),
    [checkWithIndex, sortAsc, sortFilter],
  )

  const { schema, defaultValues } = useMemo(() => {
    if (settingsOnMember.setting_type === 'steel-column') {
      const schema = schemaSteelColumn
      const defaultValues = {
        configuration: settingsOnMember.steel_support_config,
      }
      return { schema, defaultValues }
    } else throw new Error('Unexpected setting type on member check settings')
  }, [settingsOnMember])

  return (
    <Form
      onSubmit={({ configuration }: { configuration: SteelSupportConfig }) => {
        const enriched = {
          element_guid: settingsOnMember.element_guid,
          member_guid: settingsOnMember.member_guid,
          setting_type: settingsOnMember.setting_type,
          steel_support_config: configuration,
        } as SteelColumnCheckSettings
        onSave(enriched)
      }}
      validationSchema={schema}
      defaultValues={defaultValues}
      // Seems I need to provide this for the form to rerender
      key={settingsOnMember.element_guid}
    >
      <Stack spacing={2}>
        <TableContainer>
          <Table stickyHeader size="small">
            <colgroup>
              <col style={{ width: '5%' }} />
              <col style={{ width: '35%' }} />
              <col style={{ width: '25%' }} />
              <col style={{ width: '25%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'type'}
                    direction={sortAsc ? 'asc' : 'desc'}
                    onClick={() => {
                      if (sortBy === 'type') {
                        setSortAsc(!sortAsc)
                      } else setSortBy('type')
                    }}
                  >
                    <Tooltip title="Typ des Nachweises" placement="top">
                      <Typography>Typ</Typography>
                    </Tooltip>
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'utilization'}
                    direction={sortAsc ? 'asc' : 'desc'}
                    onClick={() => {
                      if (sortBy === 'utilization') {
                        setSortAsc(!sortAsc)
                      } else setSortBy('utilization')
                    }}
                  >
                    <Tooltip title="Maximale Ausnutzung" placement="top">
                      <Typography>Ausnutzung</Typography>
                    </Tooltip>
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'position'}
                    direction={sortAsc ? 'asc' : 'desc'}
                    onClick={() => {
                      if (sortBy === 'position') {
                        setSortAsc(!sortAsc)
                      } else setSortBy('position')
                    }}
                  >
                    <Tooltip title="Relative Position" placement="top">
                      <Typography>Position</Typography>
                    </Tooltip>
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody data-cy="member-checks-table-body">
              {sortedChecks.map(({ check }, i) => {
                const typeReadable =
                  memberCheckTypeToReadableMap[check.check_type] || check.check_type

                if (
                  (check.check_category_type === 'Vibration' && check.check_type === 'Vibration') ||
                  (check.check_category_type === 'HotDimensioning' &&
                    check.check_type === 'HotDimensioning') ||
                  (check.check_category_type === 'HotDimensioningCLT' &&
                    check.check_type === 'HotDimensioningCLT')
                )
                  return <></>
                return (
                  <MemberCheckRow
                    isOpen={i === openIndex}
                    setOpenIndex={setOpenIndex}
                    typeReadable={typeReadable}
                    check={check}
                    index={i}
                    key={i}
                    openCheck={setSelectedCheckPosition}
                  ></MemberCheckRow>
                )
              })}
              {positionChecks.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography align="center" m={1}>
                      Keine Nachweise auf dem Element vorhanden
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <SteelBasePlateConfig disabled={disableSaving} />
        <Stack direction="row" justifyContent="space-between" mt={2}>
          <LoadingButton
            loading={isLoadingDownload}
            variant="outlined"
            size="small"
            onClick={() => download(settingsOnMember.element_guid)}
          >
            Export (docx)
          </LoadingButton>
          {!disableSaving && (
            <LoadingButton type="submit" variant="contained" size="small" loading={isLoadingOnSave}>
              Einstellungen speichern
            </LoadingButton>
          )}
        </Stack>
      </Stack>
    </Form>
  )
}

export default SteelMemberChecksTable

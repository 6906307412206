import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useQueries } from '@tanstack/react-query'
import { getWindLoadsResults, getWindLoadSettings } from '@queries'

const useWindLoadsQueries = () => {
  const { projectId }: { projectId?: string } = useParams()

  const { enqueueSnackbar } = useSnackbar()

  const onError = () =>
    enqueueSnackbar('Fehler beim Laden der Windlasten Daten', { variant: 'error' })

  return useQueries({
    queries: [
      {
        queryKey: getWindLoadSettings.getKey(projectId),
        queryFn: () => getWindLoadSettings.request(projectId),
        onError,
        refetchOnMount: 'always',
      },
      {
        queryKey: getWindLoadsResults.getKey(projectId),
        queryFn: () => getWindLoadsResults.request(projectId),
        onError,
        refetchOnMount: 'always',
      },
    ],
  })
}

export default useWindLoadsQueries

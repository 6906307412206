import { ReactElement } from 'react'
import { TableCell, Typography, Stack } from '@mui/material'
import { CentimeterField } from '@ui/forms'
import { SupportLengthTextIcon, SupportWidthTextIcon } from '@ui/icons/misc'
import { renderOverhangFromCS } from '../../../../misc'
import CellSupportConfigKC90 from '../CellSupportConfigKC90'
import { renderRipHeightAndLintelWidthCompatibility } from './utils'

interface Props {
  apEditDisabled: boolean
  supportConfig: SupportGeometry
  ripCS: CrossSection
  newCS: CrossSection
  index: number
  isOverhangApplicable?: boolean
}

const APEditCells = ({
  apEditDisabled,
  supportConfig,
  ripCS,
  newCS,
  index,
  isOverhangApplicable = true,
}: Props): ReactElement => {
  return (
    <>
      <TableCell>
        {apEditDisabled ? (
          <Typography align="center">n/a</Typography>
        ) : (
          <Stack direction="column" spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <SupportLengthTextIcon />
              <CentimeterField
                name={`formBundles[${index}].supportConfig.length`}
                data-cy={`txt-config-length-${index}`}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    padding: '0 4px',
                  },
                  '& input': {
                    padding: '4px 8px',
                  },
                }}
              />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <SupportWidthTextIcon />
              <CentimeterField
                name={`formBundles[${index}].supportConfig.width`}
                data-cy={`txt-config-width-${index}`}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      padding: '0 4px',
                    },
                    '& input': {
                      padding: '4px 8px',
                    },
                  },
                }}
              />
            </Stack>
          </Stack>
        )}
      </TableCell>
      <TableCell align="center">
        <Stack direction="column" spacing={1}>
          <Typography>
            {apEditDisabled || !isOverhangApplicable
              ? 'n/a'
              : renderOverhangFromCS(ripCS, supportConfig)}
          </Typography>
          <Typography>
            {!isOverhangApplicable
              ? 'n/a'
              : renderRipHeightAndLintelWidthCompatibility(ripCS, newCS, supportConfig)}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        {apEditDisabled ? <Typography>n/a</Typography> : <CellSupportConfigKC90 index={index} />}
      </TableCell>
    </>
  )
}

export default APEditCells

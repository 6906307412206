import { ReactElement, useMemo } from 'react'
import { find, filter, isFunction, toNumber, reject } from 'lodash-es'
import { ColorRepresentation } from 'three'
import { useTheme } from '@mui/material'
import { ThreeEvent } from '@react-three/fiber'
import VerticalTransmitterItem from '../VerticalTransmitterMesh/VerticalTransmitterItem'
import { getPointAtPercentage } from '../utils'
import InstancedTransmitterGroup from './components/InstancedTransmitterGroup'
import { buildGroup } from './misc'

const InstancedVerticalTransmitterMesh = ({
  data,
  domains,
  transmitterGuid,
  onClick,
}: TransmitterMeshProps): ReactElement => {
  const theme = useTheme()

  const supportItems = useMemo(() => {
    return data.element_supports.reduce((acc: PositionedTarget[], support: ElementSupportItem) => {
      const { relative_interval, domain: supportDomain, domain_guid } = support

      const domain = supportDomain || (find(domains, ['guid', domain_guid]) as Domain)

      if (!domain) return acc

      const { start: startPoint, end: endPoint } = domain
      const start = getPointAtPercentage(startPoint, endPoint, toNumber(relative_interval?.lower))
      const end = getPointAtPercentage(startPoint, endPoint, toNumber(relative_interval?.upper))

      return [
        ...acc,
        {
          interval: { start, end },
          item: support,
          domain,
        },
      ]
    }, [])
  }, [data.element_supports, domains])

  const targetItems = useMemo(() => {
    return supportItems.reduce((acc: PositionedTarget[], support) => {
      const { guid } = support.item

      const items = filter(data.support_targets, ['support_guid', guid]).reduce(
        (acc: PositionedTarget[], { target_guid }) => {
          const target = find(data.element_targets, ['guid', target_guid])

          if (!target) return acc

          const { relative_interval, domain_guid } = target
          const domain = find(domains, ['guid', domain_guid])

          if (!domain) return acc

          const { start: startPoint, end: endPoint } = domain
          const start = getPointAtPercentage(
            startPoint,
            endPoint,
            toNumber(relative_interval?.lower),
          )
          const end = getPointAtPercentage(startPoint, endPoint, toNumber(relative_interval?.upper))

          return [
            ...acc,
            {
              interval: { start, end },
              item: target,
              domain,
              support: support.item,
            },
          ]
        },
        [],
      )

      return [...acc, ...items]
    }, [])
  }, [supportItems, data.support_targets, data.element_targets, domains])

  const supportItemsWithoutActive = useMemo(
    () => reject(supportItems, supportItem => supportItem.item.guid === transmitterGuid),
    [supportItems, transmitterGuid],
  )

  const targetItemsWithoutActive = useMemo(
    () => reject(targetItems, targetItem => targetItem.support?.guid === transmitterGuid),
    [targetItems, transmitterGuid],
  )

  const activeSupportItems = useMemo(
    () => filter(supportItems, supportItem => supportItem.item.guid === transmitterGuid),
    [supportItems, transmitterGuid],
  )

  const activeTargetItems = useMemo(
    () => filter(targetItems, targetItem => targetItem.support?.guid === transmitterGuid),
    [targetItems, transmitterGuid],
  )

  const handleItemClick = (event: ThreeEvent<MouseEvent>, transmitter: ElementSupportItem) => {
    event.stopPropagation()

    if (!isFunction(onClick)) return

    onClick(transmitter)
  }

  const instanceGroupsSupportItems = useMemo(
    () => buildGroup(supportItemsWithoutActive, -0.1),
    [supportItemsWithoutActive],
  )

  const instanceGroupsTargetItems = useMemo(
    () => buildGroup(targetItemsWithoutActive, 0.1),
    [targetItemsWithoutActive],
  )

  return (
    <>
      {Object.entries(instanceGroupsSupportItems).map(([length, group]) => (
        <InstancedTransmitterGroup
          key={length}
          group={group}
          onClickItem={handleItemClick}
          color={theme.scenePalette.elements3d.supports}
        />
      ))}

      {Object.entries(instanceGroupsTargetItems).map(([length, group]) => (
        <InstancedTransmitterGroup
          key={length}
          group={group}
          onClickItem={handleItemClick}
          color={theme.scenePalette.elements3d.targets}
        />
      ))}

      {activeSupportItems.map(({ interval, item }) => {
        return (
          <VerticalTransmitterItem
            key={item.guid}
            {...interval}
            isActive={item.guid === transmitterGuid}
            onClick={event => handleItemClick(event, item)}
            elementGuid={item.element_guid}
            color={theme.scenePalette.elements3d.supports as ColorRepresentation}
          />
        )
      })}
      {activeTargetItems.map(({ interval, item, support }) => {
        return (
          <VerticalTransmitterItem
            key={item.guid}
            isTarget
            {...interval}
            isActive={support?.guid === transmitterGuid}
            onClick={event => handleItemClick(event, support as ElementSupportItem)}
            elementGuid={item.element_guid}
            color={theme.scenePalette.elements3d.targets}
          />
        )
      })}
    </>
  )
}

export default InstancedVerticalTransmitterMesh

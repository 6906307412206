import { ReactElement, useMemo, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import {
  getShapeLength,
  snapRelativeInputValue,
  getTopDomains,
  getDomainAndRelativePositionFromPoint,
} from '@scene'
import { find, every } from 'lodash-es'
import { useSnackbar } from 'notistack'
import Delete from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AccordionSummary, AccordionDetails, Typography, IconButton } from '@mui/material'
import { ThreeEvent } from '@react-three/fiber'
import { AddButton } from '@ui/actions'
import { FormAccordion } from '@ui/forms'
import { Box } from '@ui/structure'
import { useConfigStore } from '@stores'
import { useStructuralPlanningStore, useModelStore } from '@editorStores'
import {
  useElementLabel,
  useElementTypes,
  useModelClickListeners,
  useSelectionMode,
  useStiffeningSnapValues,
} from '@editorHooks'
import FormFields from './FormFields'

interface Props {
  support: ElementSupportItem
  index: number
}

const TensileTransmitterForm = ({ support, index }: Props): ReactElement => {
  const appConfig = useConfigStore.getState()

  const [activeGuid, setActiveGuid] = useState<string | null>(null)
  const { setSelectionMode, unsetSelectionMode, selectionModeKey } = useSelectionMode()
  const model = useModelStore(state => state.model)
  const updateTargetElement = useStructuralPlanningStore(state => state.updateTargetElement)
  const deleteSupportTarget = useStructuralPlanningStore(state => state.deleteSupportTarget)
  const addSupportTarget = useStructuralPlanningStore(state => state.addSupportTarget)

  const addTensileSupport = useStructuralPlanningStore(state => state.addTensileSupport)
  const deleteTensileSupportByElement = useStructuralPlanningStore(
    state => state.deleteTensileSupportByElement,
  )
  const tensileTransmissionGraph = useStructuralPlanningStore(
    state => state.tensileTransmissionGraph,
  )

  const getElementLabel = useElementLabel()

  const [getSnapValues] = useStiffeningSnapValues()
  const { enqueueSnackbar } = useSnackbar()
  const fieldName = `supports[${index}].targets`

  const { append, remove, fields } = useFieldArray({
    name: `supports[${index}].targets`,
  })

  const elementTypes = useElementTypes()

  const targets = fields as unknown as ElementSupportItem[]

  const isFoundationSupport = useMemo(() => {
    return !!targets.length && every(targets, ['target_type', 'foundation'])
  }, [targets])

  const activeTargetGuid = useMemo(() => {
    if (activeGuid) return activeGuid
    if (targets.length) return targets[0].guid
  }, [activeGuid, targets.length])

  const activeTargetDomainLength = useMemo(() => {
    const target = find(targets, ['guid', activeTargetGuid])

    if (!target) return 0

    const { element_guid, domain_guid } = target

    const domains = find(model.walls, ['guid', element_guid])?.domains || []
    const domain = find(domains, ['guid', domain_guid]) as Domain

    if (!domain) return 0

    return Number(getShapeLength([domain.start, domain.end]).toFixed(2))
  }, [targets, activeTargetGuid, model])

  // update target element
  useModelClickListeners(
    (event: ThreeEvent<MouseEvent>) => {
      const elementGuid = event.object.name

      if (elementGuid === support.element_guid) {
        unsetSelectionMode()
        return enqueueSnackbar('Ziel kann nicht auf dem selben Element liegen', {
          variant: 'error',
        })
      }

      if (!activeTargetGuid) return

      const newDomainGuid = find(model.walls, ['guid', elementGuid])?.domains?.[2].guid

      if (!newDomainGuid) return

      updateTargetElement(activeTargetGuid, elementGuid, newDomainGuid)
      unsetSelectionMode()
    },
    [activeTargetGuid, unsetSelectionMode],
    selectionModeKey === 'update-target-element',
    true,
  )

  const handleDeleteTarget = (
    event: React.MouseEvent,
    target: ElementSupportItem,
    index: number,
  ) => {
    event.stopPropagation()
    enqueueSnackbar('Verbindung entfernt. Änderungen müssen noch gespeichert werden', {
      variant: 'info',
    })

    const targetElementType = elementTypes[target.element_guid]
    if (targetElementType === undefined && target.element_guid !== model.foundation?.guid) {
      throw Error('Encountered strange undefined element type')
    }

    if (targetElementType === 'columns' || targetElementType === 'rips') {
      const supportsOnTarget = tensileTransmissionGraph.support_targets.filter(
        support_target => support_target.target_guid === target.guid,
      )

      // If there is only one target on the column, prior to deleting this one, then
      // we can remove the support too (effectively removing the stiffening
      // from the column / rip)
      if (supportsOnTarget.length === 1) {
        deleteTensileSupportByElement(target.element_guid)
      }
    }

    remove(index)
    deleteSupportTarget(target.guid)
    setActiveGuid(null)
  }

  useModelClickListeners(
    event => {
      const { name: elementGuid } = event.object

      if (elementGuid === support.element_guid) {
        unsetSelectionMode()
        return enqueueSnackbar('Ziel kann nicht auf dem selben Element liegen', {
          variant: 'error',
        })
      }

      const targetElementType = elementTypes[elementGuid]
      if (targetElementType === undefined) throw Error('Encountered strange undefined element type')

      // TODO: Remove this when non-wall tensile is enabled
      if (
        !appConfig.ff_non_wall_tensile &&
        !(targetElementType === 'inner_walls' || targetElementType === 'outer_walls')
      ) {
        return
      }

      if (targetElementType === 'inner_walls' || targetElementType === 'outer_walls') {
        const wallElement = find(model.walls, ['guid', elementGuid])

        if (!wallElement) throw Error('Cant find wall element on model')
        const elementDomains = wallElement.domains as Domain[]
        const topDomains = getTopDomains(elementDomains)

        if (!topDomains.length) throw Error('Cant find top domain of clicked wall')
        const result = getDomainAndRelativePositionFromPoint(topDomains, event.point)

        if (!result) return

        const [domain, relative_position] = result
        const snapValues = getSnapValues(elementGuid)
        const snappedPosition = snapRelativeInputValue(relative_position, snapValues, domain.length)

        const newTarget = addSupportTarget(support.guid, elementGuid, domain.guid, snappedPosition)
        append(newTarget)

        setActiveGuid(newTarget.guid)
        unsetSelectionMode()
      } else if (targetElementType === 'rips') {
        const ripElement = find(model.rips, ['position_guid', elementGuid])
        if (!ripElement) throw Error('Cant find rip element on model')
        const newTarget = addSupportTarget(support.guid, elementGuid, ripElement.domain_guid, 1)
        const supportsOnRip = tensileTransmissionGraph.element_supports.filter(
          support => support.element_guid === elementGuid,
        )
        if (supportsOnRip.length === 0) {
          addTensileSupport(elementGuid, ripElement.domain_guid, 0)
        }
        append(newTarget)
        setActiveGuid(newTarget.guid)
        unsetSelectionMode()
      } else if (targetElementType === 'columns') {
        const columnElement = find(model.columns, ['guid', elementGuid])
        if (!columnElement) throw Error('Cant find column element on model')
        if (!columnElement.domains) throw Error('Cant find domains on column element')
        if (columnElement.domains.length !== 1)
          throw Error('Column element has number of domains other than 1')

        const domain = columnElement.domains[0]

        const newTarget = addSupportTarget(support.guid, elementGuid, domain.guid, 1)

        const supportsOnColumn = tensileTransmissionGraph.element_supports.filter(
          support => support.element_guid === elementGuid,
        )

        if (supportsOnColumn.length === 0) {
          addTensileSupport(elementGuid, domain.guid, 0)
        }

        append(newTarget)
        setActiveGuid(newTarget.guid)
        unsetSelectionMode()
      }
    },
    [model, support.guid],
    selectionModeKey === 'add-new-target',
    true,
  )

  if (isFoundationSupport) {
    return (
      <Box p={2}>
        <Typography variant="body2">Ziel liegt im Fundament</Typography>
      </Box>
    )
  }

  return (
    <Box>
      {targets.map((target, index) => {
        const { guid, element_guid } = target
        const active = activeTargetGuid === guid

        return (
          <FormAccordion
            fields={`supports[${index}].targets[${index}]`}
            key={guid}
            expanded={active}
            onChange={(_, expanded) => {
              if (targets.length === 1) return
              if (expanded) setActiveGuid(guid)
            }}
            data-cy={`tensile-target-form-${index}`}
          >
            <AccordionSummary
              key={guid}
              expandIcon={targets.length > 1 ? <ExpandMoreIcon /> : null}
              aria-controls="panel1a-content"
              id={guid}
              sx={{
                cursor: targets.length === 1 ? 'default !important' : 'pointer',
                background: ({ palette }) => palette.grey['100'],
              }}
            >
              <Box display="flex" justifyContent="space-between" flexGrow="1">
                <Typography data-cy="tensile-target-title" variant="body2">
                  Ziel {index + 1} - {getElementLabel(target.element_guid)}
                </Typography>
                <IconButton
                  sx={{ padding: 0.5, marginY: -0.5 }}
                  onClick={event => handleDeleteTarget(event, target, index)}
                >
                  <Delete fontSize="small" data-cy="btn-delete-target" />
                </IconButton>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                background: ({ palette }) => palette.grey['100'],
              }}
            >
              <FormFields
                elementGuid={element_guid}
                domainLength={activeTargetDomainLength}
                fieldName={`${fieldName}[${index}]`}
                onChangeTargetWall={() =>
                  setSelectionMode({
                    message: 'Klicken Sie ein Wandelement zur Auswahl',
                    key: 'update-target-element',
                  })
                }
                targetElementGuid={target.element_guid}
              />
            </AccordionDetails>
          </FormAccordion>
        )
      })}
      <Box display="flex" justifyContent="end" paddingY={1}>
        <AddButton
          size="small"
          data-cy="btn-add-target"
          onClick={() =>
            setSelectionMode({
              message: 'Klicken Sie ein Wandelement zur Auswahl',
              key: 'add-new-target',
            })
          }
        >
          Ziel Hinzufügen
        </AddButton>
      </Box>
    </Box>
  )
}

export default TensileTransmitterForm

import { ReactElement, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  SelectedElementProblemsList,
  TensileTransmitterList,
  VerticalTransmitterList,
} from '@structuralPlanningComponents'
import { AxiosError } from 'axios'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import { Stack, Tab, Typography } from '@mui/material'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeleteButton } from '@ui/actions'
import { Box } from '@ui/structure'
import { useModelStore, useStructuralPlanningStore } from '@editorStores'
import {
  useBlockScene,
  useElementLabel,
  useElementType,
  useResultsInvalidation,
} from '@editorHooks'
import {
  useStructuralPlanningFormEsc,
  useStructuralPlanningQueryParams,
} from '@structuralPlanningHooks'
import {
  getVerticalTransmissionGraph,
  getAssemblyAssignment,
  getModel,
  getElementCrossSectionAssignment,
} from '@queries'
import {
  createColumn,
  deleteColumn,
  recalculateTensileTargets,
  updateColumn as updateColumnReq,
} from '@mutations'
import { buildErrorMessage } from 'src/constants'
import { useElementHistory } from 'src/hooks'
import { getElementLabels } from 'src/state/queries/labels'
import SingleElementCSForm from '../../../../../../components/SingleElementCSForm'
import { useSaveTensileTransmissionGraph } from '../../../../hooks/useSaveTensileTransmissionGraph'
import GeometryForm from './GeometryForm'
import { columnTabs } from './tabs'

interface Props {
  selectedElement: string
  geometryEditable?: boolean
  activeElement?: string
  onClose: () => void
}

const ColumnForm = ({
  geometryEditable = true,
  selectedElement,
  activeElement,
  onClose,
}: Props): ReactElement | null => {
  const elementType = useElementType(selectedElement)

  const isOrthographic = useCameraStore(state => state.isOrthographic)

  const { columns, purlins } = useModelStore(state => state.model)
  const removeColumn = useModelStore(state => state.removeColumn)
  const updateColumn = useModelStore(state => state.updateColumn)
  const setTypeVisibility = useModelStore(state => state.setTypeVisibility)

  const { enqueueSnackbar } = useSnackbar()
  const { projectId }: { projectId?: string } = useParams()
  const client = useQueryClient()

  const graph = useStructuralPlanningStore(state => state.tensileTransmissionGraph)
  const setTensileTransmissionGraph = useStructuralPlanningStore(
    state => state.setTensileTransmissionGraph,
  )

  const {
    modes: { isDrawMode },
    params: { verticalTransmitter, activeTab },
    actions: { setVerticalTransmitter, setActiveTab },
  } = useStructuralPlanningQueryParams()

  const invalidateResults = useResultsInvalidation()

  const getLabel = useElementLabel()

  const { mutateAsync, isLoading } = useMutation(
    (column: ShapeObjectLine) => createColumn.request(projectId, column),
    {
      onSuccess: async (data: ShapeObjectLine) => {
        setColumnStale(data)
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
          client.invalidateQueries(getElementLabels.getKey(projectId)),
          client.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId)),
        ])

        invalidateResults(projectId as string)
        enqueueSnackbar('Stütze erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Stütze'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: handleUpdate, isLoading: isUpdateing } = useMutation(
    (column: ShapeObjectLine) => updateColumnReq.request(projectId, column),
    {
      onSuccess: async (data: ShapeObjectLine) => {
        setColumnStale(data)
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Stütze erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Stütze'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: handleDelete, isLoading: isDeleting } = useMutation(
    (columnGuid: string) => {
      return deleteColumn.request(projectId, columnGuid)
    },
    {
      onSuccess: async () => {
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Stütze erfolgreich gelöscht', { variant: 'success' })
      },
      onError: (error: Error | AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen der Stütze'), {
          variant: 'error',
        })
      },
    },
  )

  useBlockScene(isLoading || isDeleting || isUpdateing)

  const column = useMemo(() => {
    return find(columns, ['guid', selectedElement]) as ShapeObjectLine
  }, [columns, selectedElement])

  const purlin = useMemo(() => find(purlins, ['guid', activeElement]), [activeElement, purlins])

  const { setStale: setColumnStale } = useElementHistory({
    element: column,
    getCurrentElement: () => {
      const columns = useModelStore.getState().model.columns
      return find(columns, ['guid', selectedElement]) as ShapeObjectLine
    },
    removeElement: column => removeColumn(column.guid),
    resetElement: column => updateColumn(column),
    dependencies: [selectedElement],
  })

  useEffect(() => {
    setTypeVisibility('columns' as ElementTypes, true)
  }, [])

  useStructuralPlanningFormEsc(() => {
    // column has to be saved otherwise remove
    if (column?.is_local) {
      removeColumn(column.guid)
    }
    onClose()
  }, !!selectedElement)

  const { mutate: saveGraph, isLoading: isSavingGraph } = useSaveTensileTransmissionGraph(
    projectId as string,
  )

  const { mutate: recalculateTargets, isLoading: isRecalculating } = useMutation(
    () => recalculateTensileTargets.request(projectId, selectedElement),
    {
      onSuccess: async data => {
        setTensileTransmissionGraph(data)
        invalidateResults(projectId as string)
        enqueueSnackbar('Ziele erfolgreich neu berechnet', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim der Berechnung der Ziele'), {
          variant: 'error',
        })
      },
    },
  )

  const selectedTab = useMemo(() => {
    if (activeTab && find(Object.entries(columnTabs), ([, e]) => e.tab === activeTab))
      return activeTab

    return isOrthographic ? columnTabs.geometry.tab : columnTabs.verticalTransmission.tab
  }, [activeTab, isOrthographic])

  if (!column) return null

  if (column.is_local) {
    return (
      <GeometryForm
        column={column}
        purlin={purlin}
        handleSubmit={() => mutateAsync(column)}
        handleDelete={() => removeColumn(column.guid)}
      />
    )
  }

  return (
    <Box>
      <TabContext value={selectedTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              fontSize: 12,
              paddingX: ({ spacing }) => spacing(1.5),
            },
          }}
        >
          <TabList onChange={(_, value) => setActiveTab(value)} scrollButtons="auto">
            <Tab
              value={columnTabs.geometry.tab}
              label={columnTabs.geometry.label}
              data-cy="tab-geometry"
            />
            <Tab
              value={columnTabs.verticalTransmission.tab}
              label={columnTabs.verticalTransmission.label}
            />
            <Tab
              value={columnTabs.connector.tab}
              label={columnTabs.connector.label}
              data-cy="tab-connector"
            />
          </TabList>
        </Box>
        <TabPanel value={columnTabs.connector.tab}>
          <TensileTransmitterList
            key={`connector-list-${isRecalculating}`}
            selectedElement={selectedElement}
            onSave={() => saveGraph(graph)}
            onRecalculate={() => recalculateTargets()}
            isRecalculating={isRecalculating}
            isLoading={isSavingGraph}
          />
        </TabPanel>
        <TabPanel value={columnTabs.geometry.tab}>
          <Stack direction="column" spacing={2}>
            <Typography variant="h6">{getLabel(selectedElement)}</Typography>

            <SingleElementCSForm
              selectedElement={selectedElement}
              elementType={elementType as ElementTypes}
            />

            {geometryEditable ? (
              <GeometryForm
                column={column}
                purlin={purlin}
                handleSubmit={() => handleUpdate(column)}
                handleDelete={() => handleDelete(column.guid)}
                isDeleting={isDeleting}
              />
            ) : (
              <Box display="flex" width="100%" justifyContent="flex-end">
                <DeleteButton
                  onClick={() => handleDelete(column.guid)}
                  loading={isDeleting}
                  data-cy="delete-column-btn"
                >
                  Löschen
                </DeleteButton>
              </Box>
            )}
          </Stack>
        </TabPanel>
        <TabPanel value={columnTabs.verticalTransmission.tab}>
          <VerticalTransmitterList
            selectedElement={selectedElement}
            verticalTransmitter={verticalTransmitter}
            setVerticalTransmitter={(guid, elementGuid) =>
              setVerticalTransmitter(guid, elementGuid, isDrawMode)
            }
          />
          <SelectedElementProblemsList selectedElement={selectedElement} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default ColumnForm

import { ReactElement } from 'react'
import Delete from '@mui/icons-material/Delete'
import { Accordion, AccordionSummary, Typography, IconButton } from '@mui/material'
import { Box } from '@ui/structure'

interface Props {
  targets: ElementSupportItem[]
  allowDelete?: boolean
  onClickDelete: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    targetGuid: string,
  ) => void
}

const VerticalTransmitterTargetList = ({
  targets,
  allowDelete = true,
  onClickDelete,
}: Props): ReactElement => {
  return (
    <>
      {targets.map(({ guid }, index) => {
        return (
          <Accordion key={guid} expanded>
            <AccordionSummary
              key={guid}
              id={guid}
              sx={{
                cursor: targets.length === 1 ? 'default !important' : 'pointer',
                background: ({ palette }) => palette.grey['100'],
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                flexGrow="1"
                alignItems="flex-start"
              >
                <Box>
                  <Typography variant="body2">Ziel {index + 1}</Typography>
                </Box>

                {allowDelete && (
                  <IconButton
                    sx={{ padding: 0.5, marginY: -0.5 }}
                    onClick={event => onClickDelete(event, guid)}
                    data-cy="btn-delete-vertical-transmitter"
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                )}
              </Box>
            </AccordionSummary>
          </Accordion>
        )
      })}
    </>
  )
}

export default VerticalTransmitterTargetList

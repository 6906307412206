import { ReactElement, useMemo } from 'react'
import { ColorRepresentation, DoubleSide } from 'three'
import { useTheme } from '@mui/material'
import { BasicMesh, BasicMeshProps } from '@modugen/scene/lib'
import { config } from '@modugen/scene/lib/config'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useDisposeThreeResourceOnUnmount } from '@editorHooks'
import { extrudePosition } from '../PositionMeshElement/utils'
import {
  getShapeMatrixTransform,
  getShapeObject,
  getShapeEdges,
  createMeshStandardMaterialMemoized,
  createLineBasicMaterialMemoized,
} from '../utils'

interface Props extends BasicMeshProps {
  start: ImmutableVector3
  end: ImmutableVector3
  coordinateSystem: CoordinateSystem
  height: number
  width: number

  color?: string | ColorRepresentation
  outlinesColor?: string | ColorRepresentation
  outlines?: boolean

  opacity?: number
}

const MemberMeshElement = ({
  start,
  end,
  coordinateSystem,
  height,
  width,

  color,
  outlinesColor,
  outlines = true,

  opacity = 1,

  ...props
}: Props): ReactElement => {
  const theme = useTheme()

  const points = extrudePosition(start, end, coordinateSystem, height, width)

  const matrixTransform = useMemo(() => getShapeMatrixTransform(points), [points])

  const shapeObject = useMemo(
    () => getShapeObject(points, [], matrixTransform),
    [points, matrixTransform],
  )

  const edges = useMemo(() => getShapeEdges(shapeObject, height), [shapeObject])

  useDisposeThreeResourceOnUnmount(edges)

  const material = useMemo(
    () =>
      createMeshStandardMaterialMemoized({
        side: DoubleSide,
        color: (color || theme.scenePalette.elements3d.member) as ColorRepresentation,
        transparent: true,
        opacity,
      }),
    [theme, color, height, width],
  )

  useDisposeThreeResourceOnUnmount(material)

  const outlinesMaterial = useMemo(
    () =>
      createLineBasicMaterialMemoized({
        color: (outlinesColor || theme.scenePalette.outlines) as ColorRepresentation,
        transparent: true,
        opacity,
      }),
    [theme, outlinesColor],
  )

  useDisposeThreeResourceOnUnmount(outlinesMaterial)

  return (
    <BasicMesh matrix={matrixTransform} matrixAutoUpdate={false} material={material} {...props}>
      <extrudeGeometry
        args={[
          shapeObject,
          {
            depth: height,
            bevelEnabled: false,
          },
        ]}
      />

      {outlines && (
        <lineSegments
          layers={config.R3FNonSelectableObjectLayer}
          geometry={edges}
          renderOrder={100}
          visible={props.visible}
          material={outlinesMaterial}
        ></lineSegments>
      )}
    </BasicMesh>
  )
}

export default MemberMeshElement

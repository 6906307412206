import { ReactElement } from 'react'
import { ColorRepresentation, DoubleSide } from 'three'
import { Instances, Instance } from '@react-three/drei'
import { ThreeEvent } from '@react-three/fiber'

interface Props {
  group: InstancedTransmitterGroup
  onClickItem: (event: ThreeEvent<MouseEvent>, item: ElementSupportItem) => void
  color: ColorRepresentation
}

const InstancedTransmitterGroup = ({ group, onClickItem, color }: Props): ReactElement => {
  return (
    <Instances key={length} boundingSphere={group.sphere}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          array={group.vertices}
          count={group.vertices.length / 3}
          itemSize={3}
        />
        <bufferAttribute
          attach="index"
          array={group.indices}
          count={group.indices.length}
          itemSize={1}
        />
      </bufferGeometry>

      <meshStandardMaterial
        polygonOffset
        polygonOffsetUnits={-2000}
        color={color}
        opacity={0.4}
        transparent={true}
        side={DoubleSide}
        depthWrite={false}
      />

      {group.items.map((item, itemIndex) => (
        <Instance
          key={`${length}-${itemIndex}`}
          onClick={event => onClickItem(event, item.target.item)}
          matrix={item.matrix}
          matrixAutoUpdate={false}
        />
      ))}
    </Instances>
  )
}

export default InstancedTransmitterGroup

import { getGluelamMaterials, getSoftWoodMaterials, getSteelMaterials } from '@queries'

export const materialQueryFromMaterialType = (materialType: string) => {
  switch (materialType) {
    case 'softwoodMaterial': {
      return getSoftWoodMaterials
    }
    case 'glulamMaterial': {
      return getGluelamMaterials
    }
    case 'steelMaterial': {
      return getSteelMaterials
    }
    default: {
      return getSoftWoodMaterials
    }
  }
}

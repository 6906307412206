import { ReactElement, useEffect, useMemo, useState } from 'react'
import { find, reject, toNumber } from 'lodash-es'
import { Button, Stack, Typography } from '@mui/material'
import { ThreeEvent } from '@react-three/fiber'
import { SaveButton } from '@ui/actions'
import { LoadingIndicator } from '@ui/feedback'
import { Box } from '@ui/structure'
import { useElementSelectionStore } from '@editorStores'
import { useTypeInteraction } from '@editorHooks'
import { SceneMouseTooltip } from '@editorComponents'
import ElementSelection from '../../../ElementSelection'
import AssignmentForm from '../AssignmentForm'
import { loadCaseCategories } from '../AssignmentForm/constants'

interface Props {
  data?: SnowLoadConfiguration[]
  isLoading: boolean
  isSaving: boolean
  onSave: (data: SnowLoadConfiguration[]) => void
}

const SnowLoads = ({ data, isLoading, isSaving, onSave }: Props): ReactElement => {
  const selectedStandAloneIds = useElementSelectionStore(state => state.selectedStandAloneIds)

  const setHighlightedIds = useElementSelectionStore(state => state.setHighlightedIds)

  useTypeInteraction('roof_slabs')

  const [configurations, setConfigurations] = useState<SnowLoadConfiguration[]>([])

  // keep data in sync
  useEffect(() => {
    if (data) {
      setConfigurations(data)
    }
  }, [data])

  const assignedIds = useMemo(
    () => configurations.map(config => config.element_guid),
    [configurations],
  )

  useEffect(() => {
    setHighlightedIds(assignedIds)
    return () => setHighlightedIds([])
  }, [assignedIds])

  const onPointerOver = (event: ThreeEvent<MouseEvent>) => {
    if (!event.object.name) return null

    const assignment = find(configurations, ['element_guid', event.object.name])

    if (!assignment) return null

    const loadCaseLabel = find(loadCaseCategories, {
      value: assignment.load_case.category,
    })?.label

    return `${loadCaseLabel} - ${assignment.load_per_area} kN/m²`
  }

  if (isLoading) return <LoadingIndicator />

  return (
    <>
      <SceneMouseTooltip onPointerOver={onPointerOver} />
      <ElementSelection />

      <Stack direction="column" spacing={2}>
        <Box p={1} m={-1} mb={0} border={1} borderColor="grey.200" borderRadius={1}>
          <Typography variant="h6" textAlign="center">
            Ausgewählt: {selectedStandAloneIds.size}
          </Typography>
          <Typography mb={1}>
            Weisen Sie einzelnen Dachelementen individuelle Schneelastkonfigurationen zu. Die
            Flächenlast sollte immer inklusive dem Formfaktor mü_i angegeben werden
          </Typography>

          <AssignmentForm
            addConfigurations={configs => setConfigurations([...configurations, ...configs])}
            removeConfigurations={(loadCase, loadPerArea) => {
              const newConfigurations = reject(
                configurations,
                config =>
                  config.load_case.category === loadCase.category &&
                  toNumber(config.load_per_area) === toNumber(loadPerArea),
              )
              setConfigurations(newConfigurations)
            }}
            configurations={configurations}
          />
        </Box>

        <Box>
          <SaveButton
            disabled={isLoading}
            loading={isSaving}
            onClick={() => onSave(configurations)}
            data-cy="save-btn"
          >
            Speichern
          </SaveButton>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="info"
            component="a"
            href="https://app.clickup.com/2590559/v/dc/2f1uz-930/2f1uz-28135?block=block-ada57703-b845-4bb9-8d2e-d140e86ff6b3"
            target="_blank"
            rel="noopener noreferrer"
          >
            Schneelasten an Dachhöhensprüngen
          </Button>
        </Box>
      </Stack>
    </>
  )
}

export default SnowLoads

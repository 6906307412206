// Checks with lower utilisation than this are likely to not be displayed
export const MAX_UTILIZATION_FILTER_THRESHOLD = 0.01

// Checks with higher utilisation than this are likely to be flagged
export const MAX_UTILIZATION_WARNING_THRESHOLD = 1

export const SLIGHTLY_OVERUTILIZED_THRESHOLD = 1.05

// Relative distance of two elements to be considered the same
export const RELATIVE_POSITION_PROXIMITY = 0.001

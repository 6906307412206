import { ReactElement } from 'react'
import { supportTypeToLabelShort } from '@domainConstants'
import { Stack, TableCell, TableRow, Typography } from '@mui/material'
import { CentimeterField } from '@ui/forms'
import { SupportLengthTextIcon, SupportWidthTextIcon } from '@ui/icons/misc'
import { useElementLabel, useElementTypes } from '@editorHooks'
import UtilizationCell from '../../../UtilizationCell'
import CellSupportConfigKC90 from '../CellSupportConfigKC90'
import CellWithRightBorder from '../CellWithRightBorder'
import RootElementQSCell from '../RootElementQSCell'

interface Props {
  rootElementIsSteel: boolean

  apEditDisabled: boolean

  index: number
  isSelected: boolean
  onClick: () => void

  onClickTargetElementGuid?: (targetElementGuid: string) => void

  bundle: OtherElementBundleItem
}

const OtherElementsFieldsTableRow = ({
  rootElementIsSteel,
  apEditDisabled,
  index,
  isSelected,
  onClick,
  onClickTargetElementGuid,
  bundle,
}: Props): ReactElement => {
  const getLabel = useElementLabel()
  const elementTypes = useElementTypes()

  const elementType = elementTypes[bundle.check.element_guid]

  return (
    <TableRow onClick={onClick} selected={isSelected}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <Typography>n/a</Typography>
      </TableCell>
      <TableCell>
        <UtilizationCell
          utilization={bundle.check.max_utilization}
          checkType={bundle.check.check_type}
        />
      </TableCell>
      <TableCell>{bundle.check.relative_position.toFixed(2)}</TableCell>
      <CellWithRightBorder>
        {supportTypeToLabelShort[bundle.supportType] || bundle.supportType}
      </CellWithRightBorder>
      <TableCell align="center">
        {onClickTargetElementGuid && bundle.targetGuid ? (
          <Typography
            component="a"
            sx={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
            onClick={e => {
              e.stopPropagation()
              onClickTargetElementGuid(bundle.targetGuid)
            }}
          >
            {getLabel(bundle.targetGuid)}
          </Typography>
        ) : (
          <Typography>n/a</Typography>
        )}
      </TableCell>
      <TableCell align="center">
        <Typography>n/a</Typography>
      </TableCell>
      <CellWithRightBorder align="center">
        <Typography>n/a</Typography>
      </CellWithRightBorder>
      <CellWithRightBorder>
        <RootElementQSCell rootElementIsSteel={rootElementIsSteel} elementType={elementType} />
      </CellWithRightBorder>
      <TableCell>
        {apEditDisabled ? (
          <Typography align="center">n/a</Typography>
        ) : (
          <Stack direction="column" spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <SupportLengthTextIcon />
              <CentimeterField
                name={`formBundles[${index}].supportConfig.length`}
                data-cy={`txt-config-length-${index}`}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    padding: '0 4px',
                  },
                  '& input': {
                    padding: '4px 8px',
                  },
                }}
              />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <SupportWidthTextIcon />
              <CentimeterField
                name={`formBundles[${index}].supportConfig.width`}
                data-cy={`txt-config-width-${index}`}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      padding: '0 4px',
                    },
                    '& input': {
                      padding: '4px 8px',
                    },
                  },
                }}
              />
            </Stack>
          </Stack>
        )}
      </TableCell>
      <TableCell align="center">
        <Stack direction="column" spacing={1}>
          <Typography>n/a</Typography>
          <Typography>n/a</Typography>
        </Stack>
      </TableCell>
      <TableCell>
        {apEditDisabled ? <Typography>n/a</Typography> : <CellSupportConfigKC90 index={index} />}
      </TableCell>
    </TableRow>
  )
}

export default OtherElementsFieldsTableRow

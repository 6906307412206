import { useWatch } from 'react-hook-form'
import { elementTypeToLabelSingular } from '@domainConstants'
import { checkSettingsAreSteel, crossSectionIsSteel } from '@domainUtils'
import { useResultsQueryParams } from '@resultsHooks'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { ErrorBoundary } from '@sentry/react'
import { ErrorRow } from '@ui/errors'
import { LoadingIndicator } from '@ui/feedback'
import { KC90TextIcon, UtilizationC90TextIcon, UtilizationTextIcon } from '@ui/icons/misc'
import { CrossSectionFormDataType } from 'src/components/pages/Editor/components/SingleElementCSForm/schema'
import CellWithRightBorder from './components/CellWithRightBorder'
import OtherElementsFieldsTableRow from './components/OtherElementsFieldsTableRow'
import PositionFieldsTableRow from './components/PositionFieldsTableRow'

interface Props {
  onClickTargetElementGuid: (elementguid: string) => void
  positionType: ElementTypes
  isLoading: boolean
  isOverhangApplicable?: boolean
}

const FormFields = ({
  onClickTargetElementGuid,
  positionType,
  isLoading,
  isOverhangApplicable,
}: Props) => {
  const {
    params: { selectedCheckPosition },
    actions: { setSelectedCheckPosition },
  } = useResultsQueryParams()

  const formBundles: (PositionBundleItem | OtherElementBundleItem)[] = useWatch({
    name: 'formBundles',
  })
  const crossSectionFormData: CrossSectionFormDataType = useWatch({ name: 'crossSection' })

  const checkSettings: SettingsOnMember = useWatch({ name: 'checkSettings' })

  // derive values from form state
  const newCS = {
    material: crossSectionFormData.material,
    shape:
      crossSectionFormData.materialType === 'steelMaterial'
        ? crossSectionFormData.steelShape
        : crossSectionFormData.shape,
    usage_class: crossSectionFormData.usage_class,
  } as CrossSection

  const requiresNewCheckSettings = !(
    checkSettingsAreSteel(checkSettings) === crossSectionIsSteel(newCS)
  )

  const rootElementIsSteel = newCS.material.kind === 'Steel'
  const apEditDisabled = rootElementIsSteel
  const disableTable = isLoading && requiresNewCheckSettings

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '50vh', width: '100%', maxWidth: '1000px' }}>
      <Table stickyHeader size="small" sx={{ '.MuiTableCell-root': { paddingX: 1 } }}>
        <TableHead>
          <TableRow>
            <CellWithRightBorder align="center" colSpan={5}></CellWithRightBorder>
            <CellWithRightBorder align="center" colSpan={3}>
              darunterliegendes Bauteil
            </CellWithRightBorder>
            <CellWithRightBorder align="center" colSpan={1}>
              {elementTypeToLabelSingular[positionType]}
            </CellWithRightBorder>
            <TableCell align="center" colSpan={3}>
              Einstellungen Auflagerpressung
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>
              <UtilizationTextIcon />
            </TableCell>
            <TableCell>
              <UtilizationC90TextIcon />
            </TableCell>
            <TableCell>Pos</TableCell>
            <CellWithRightBorder>
              <Tooltip title={`Linienauflager (L) | Punktauflager (P)`}>
                <Typography fontSize="inherit" fontWeight="inherit">
                  Typ
                </Typography>
              </Tooltip>
            </CellWithRightBorder>
            <TableCell align="center">Bauteil</TableCell>
            <TableCell align="center">Material</TableCell>
            <CellWithRightBorder align="center">QS</CellWithRightBorder>
            <CellWithRightBorder align="center">
              QS {elementTypeToLabelSingular[positionType]}
            </CellWithRightBorder>
            <TableCell align="center">Geometrie Auflager</TableCell>
            <TableCell align="center" sx={{ width: '100px' }}>
              Überstand
            </TableCell>
            <TableCell align="center">
              <KC90TextIcon />
            </TableCell>
          </TableRow>
        </TableHead>
        {!disableTable ? (
          <TableBody>
            {formBundles.length ? (
              formBundles.map((bundle, index) => {
                if (bundle.type === 'position') {
                  return (
                    <ErrorBoundary key={bundle.targetGuid} fallback={() => <ErrorRow />}>
                      <PositionFieldsTableRow
                        index={index}
                        lintelCSIsSteel={rootElementIsSteel}
                        isSelected={selectedCheckPosition === bundle.check.relative_position}
                        onClick={() => setSelectedCheckPosition(bundle.check.relative_position)}
                        apEditDisabled={apEditDisabled}
                        newCS={newCS}
                        onClickTargetElementGuid={onClickTargetElementGuid}
                        bundle={bundle}
                        isOverhangApplicable={isOverhangApplicable}
                      />
                    </ErrorBoundary>
                  )
                } else
                  return (
                    <ErrorBoundary key={bundle.targetGuid} fallback={() => <ErrorRow />}>
                      <OtherElementsFieldsTableRow
                        index={index}
                        rootElementIsSteel={rootElementIsSteel}
                        isSelected={selectedCheckPosition === bundle.check.relative_position}
                        onClick={() => setSelectedCheckPosition(bundle.check.relative_position)}
                        apEditDisabled={apEditDisabled}
                        onClickTargetElementGuid={onClickTargetElementGuid}
                        bundle={bundle}
                      />
                    </ErrorBoundary>
                  )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={1000} align="center" size="medium">
                  <Typography>
                    Keine Daten vorhanden (evtl. müssen die Nachweise neu berechnet werden)
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        ) : (
          <TableBody>
            <LoadingIndicator />
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}

export default FormFields

import { deleteRequest, patchRequest, postRequest } from '../utils'

export const createElementCrossSection: RequestType = {
  key: ['createElementCrossSection'],
  request: async (data: ElementCS): Promise<ElementCS> => {
    const { data: result } = await postRequest<ElementCS>({
      url: `/element-cross-sections`,
      data,
    })

    return result as ElementCS
  },
}

export const deleteElementCrossSection: RequestType = {
  key: ['deleteElementCrossSection'],
  request: async (id: string): Promise<void> => {
    await deleteRequest<void>({
      url: `/element-cross-sections/${id}`,
    })
  },
}

export const deleteAllElementCrossSections: RequestType = {
  key: ['deleteAllElementCrossSections'],
  request: async (): Promise<void> => {
    await deleteRequest<void>({
      url: `/element-cross-sections`,
    })
  },
}

export const uploadCrossSectionCSVNoProject: RequestType = {
  key: ['uploadCrossSectionCSVNoProject'],
  request: async (file: File): Promise<void> => {
    const formData = new FormData()
    formData.append('document', file) // Ensure the key 'document' matches the parameter name in your FastAPI endpoint

    await postRequest({
      url: `/element-cross-sections/csv`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data', // Optional, as FormData sets it automatically
      },
    })
  },
}

export const createProjectElementCrossSection: RequestTypeVariable = {
  getKey: (id: string) => ['createProjectElementCrossSection', id],
  request: async (id: string, data: ElementCS): Promise<ElementCS> => {
    const { data: result } = await postRequest<ElementCS>({
      url: `/projects/${id}/element-cross-sections`,
      data,
    })

    return result as ElementCS
  },
}

export const patchProjectElementCrossSection: RequestTypeVariable = {
  getKey: (id: string) => ['patchProjectElementCrossSection', id],
  request: async (project: string, id: string, data: ElementCS): Promise<ElementCS> => {
    const { data: result } = await patchRequest<ElementCS>({
      url: `/projects/${project}/element-cross-sections/${id}`,
      data,
    })

    return result as ElementCS
  },
}

export const deleteProjectElementCrossSection: RequestTypeVariable = {
  getKey: (id: string) => ['deleteProjectElementCrossSection', id],
  request: async (project: string, id: string): Promise<void> => {
    await deleteRequest<ElementCS[]>({
      url: `/projects/${project}/element-cross-sections/${id}`,
    })
  },
}

export const saveElementCrossSectionSelection: RequestTypeVariable = {
  getKey: (id: string) => ['saveElementCrossSectionSelection', id],
  request: async ({
    projectId,
    input,
  }: {
    projectId: string
    input: ElementCSSelection
  }): Promise<ElementCSSelection> => {
    const { data } = await postRequest<ElementCSSelection>({
      url: `/projects/${projectId}/selected-element-cross-sections`,
      data: input,
    })

    return data as ElementCSSelection
  },
}

export const saveElementCrossSectionAssignment: RequestTypeVariable = {
  getKey: (id: string) => ['saveElementCrossSectionAssignment', id],
  request: async (
    projectId: string,
    data: { element_guids: string[]; cross_section: CrossSection },
  ): Promise<void> => {
    await postRequest<CrossSectionOnElement[]>({
      url: `/projects/${projectId}/cross-section-assignment`,
      data,
    })
  },
}

export const saveCrossSectionsOnElement: RequestTypeVariable = {
  getKey: (id: string) => ['saveCrossSectionsOnElement', id],
  request: async (projectId: string, data: CrossSectionOnElement[]): Promise<void> => {
    await postRequest<CrossSectionOnElement[]>({
      url: `/projects/${projectId}/cross-section-assignment/set-multiple`,
      data,
    })
  },
}

export const updateElementCrossSectionAssignment: RequestTypeVariable = {
  getKey: (id: string) => ['updateElementCrossSectionAssignment', id],
  request: async (projectId: string, data: ElementCSAssignment): Promise<void> => {
    const dataForRequest = {
      ...data.element_cs,
      element_guid: data.element_guid,
    }

    await patchRequest<CrossSectionOnElement>({
      url: `/projects/${projectId}/cross-section-assignment/${dataForRequest.element_guid}`,
      data: dataForRequest,
    })
  },
}

export const restoreRipCSOnWalls: RequestTypeVariable = {
  getKey: (id: string) => ['restoreRipCSOnWalls', id],
  request: async (projectId: string, element_guids: string[]): Promise<void> => {
    const dataForRequest = {
      element_guids,
    }

    await postRequest<ElementCSAssignment[]>({
      url: `/projects/${projectId}/cross-section-assignment/restore/all-rips-in-walls`,
      data: dataForRequest,
    })
  },
}

export const restoreLintelCSOnWalls: RequestTypeVariable = {
  getKey: (id: string) => ['restoreLintelCSOnWalls', id],
  request: async (projectId: string, element_guids: string[]): Promise<void> => {
    const dataForRequest = {
      element_guids,
    }

    await postRequest<ElementCSAssignment[]>({
      url: `/projects/${projectId}/cross-section-assignment/restore/all-lintels-in-walls`,
      data: dataForRequest,
    })
  },
}

export const restoreSelectedPositionCSFromAssembly: RequestTypeVariable = {
  getKey: (id: string) => ['restoreSelectedPositionCSFromAssembly', id],
  request: async (projectId: string, element_guids: string[]): Promise<void> => {
    const dataForRequest = {
      element_guids,
    }

    await postRequest<ElementCSAssignment[]>({
      url: `/projects/${projectId}/cross-section-assignment/restore/selected-rips-and-lintels`,
      data: dataForRequest,
    })
  },
}

import { ReactElement } from 'react'
import { Select } from '@ui/forms'

interface Props {
  index: number
}

const CellSupportConfigKC90 = ({ index }: Props): ReactElement => {
  return (
    <Select
      size="small"
      name={`formBundles[${index}].supportConfig.k_c_90`}
      options={[
        { value: 1.0, label: '1.0' },
        { value: 1.25, label: '1.25' },
        { value: 1.5, label: '1.5' },
        { value: 1.75, label: '1.75' },
        { value: 1.4, label: '1.4' },
        { value: 1.9, label: '1.9' },
      ]}
      sx={{
        '& .MuiSelect-select': {
          padding: '4px 8px',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderWidth: '1px',
          },
        },
      }}
    />
  )
}

export default CellSupportConfigKC90

import { filter, isUndefined, min } from 'lodash-es'
import { Warning } from '@mui/icons-material'
import { Stack, Tooltip, Typography } from '@mui/material'
import { calculateOverhangLintelToRip, OverhangValue } from '../../misc'

const OverhangCell = ({
  rowValues,
  checkSettings,
  elementGuidToCrossSection,
}: {
  rowValues: LintelResultsGridRow
  checkSettings: TimberCheckSettings
  elementGuidToCrossSection: Record<string, ElementCSAssignment>
}) => {
  const supportCompressionChecks = filter(rowValues.checks, {
    check_type: 'SupportCompression',
  }) as SupportCompressionStructuralCheck[]
  const elementsSupportingSelected = rowValues.elementsSupportingSelected

  const overhangValues = supportCompressionChecks.map(check => {
    const supportConfig = checkSettings?.support_configs.find(
      config => Number(config.relative_position) === check.relative_position,
    )
    const targetGuid = elementsSupportingSelected.find(
      ({ relativePosition }) => Number(relativePosition) === check.relative_position,
    )?.guid
    const targetCrossSection = targetGuid ? elementGuidToCrossSection[targetGuid] : null

    return {
      targetCS: targetCrossSection?.element_cs,
      supportConfig: supportConfig,
    }
  })

  if (isUndefined(elementGuidToCrossSection[rowValues.id])) {
    return <Typography>n/a</Typography>
  }

  const crossSection = elementGuidToCrossSection[rowValues.id].element_cs
  const showOverhang =
    overhangValues &&
    overhangValues.length === 2 &&
    overhangValues[0].targetCS &&
    overhangValues[0].supportConfig &&
    overhangValues[1].targetCS &&
    overhangValues[1].supportConfig

  const minOverhang = 0
  const maxOverhang = 0.06

  let showMinWidthWarning = false
  let showOverhangOutOfRangeWarning = false
  let showWarning = false
  let leftOverhang
  let rightOverhang

  if (overhangValues.length === 2) {
    leftOverhang = calculateOverhangLintelToRip(
      overhangValues[0].supportConfig,
      overhangValues[0].targetCS,
    )
    rightOverhang = calculateOverhangLintelToRip(
      overhangValues[1].supportConfig,
      overhangValues[1].targetCS,
    )

    const isLeftOverhangOutOfRange =
      !!leftOverhang && (leftOverhang < minOverhang || leftOverhang > maxOverhang)
    const isRightOverhangOutOfRange =
      !!rightOverhang && (rightOverhang < minOverhang || rightOverhang > maxOverhang)

    const lintelWidth = crossSection.shape.width
    const leftRipHeight = overhangValues[0].targetCS?.shape.height || 0
    const leftSupportGeometryWidth = overhangValues[0].supportConfig?.width || 0

    const leftSupportMinWidth = min([lintelWidth, leftRipHeight]) as number
    const leftSupportMinWidthIsSmallerThanMinWidth = leftSupportMinWidth < leftSupportGeometryWidth
    const leftSupportMinWidthIsHigherThanMinWidth = leftSupportMinWidth > leftSupportGeometryWidth
    const leftSupportMinWidthNotOk =
      leftSupportMinWidthIsSmallerThanMinWidth || leftSupportMinWidthIsHigherThanMinWidth

    const rightRipHeight = overhangValues[1].targetCS?.shape.height || 0
    const rightSupportGeometryWidth = overhangValues[1].supportConfig?.width || 0
    const rightSupportMinWidth = min([lintelWidth, rightRipHeight]) as number
    const rightSupportMinWidthIsSmallerThanMinWidth =
      rightSupportMinWidth < rightSupportGeometryWidth
    const rightSupportMinWidthIsHigherThanMinWidth =
      rightSupportMinWidth > rightSupportGeometryWidth
    const rightSupportMinWidthNotOk =
      rightSupportMinWidthIsSmallerThanMinWidth || rightSupportMinWidthIsHigherThanMinWidth

    showMinWidthWarning = leftSupportMinWidthNotOk || rightSupportMinWidthNotOk
    showOverhangOutOfRangeWarning = isLeftOverhangOutOfRange || isRightOverhangOutOfRange
    showWarning = showMinWidthWarning || showOverhangOutOfRangeWarning
  }

  return (
    <>
      {showOverhang && (
        <Stack direction="row" spacing={0.5} justifyContent="center">
          {showWarning && (
            <>
              <Tooltip
                title={`${
                  showOverhangOutOfRangeWarning
                    ? 'Überstand außerhalb des gewöhnlichen Wertebereichs des EC 5.'
                    : ''
                } ${
                  showMinWidthWarning
                    ? 'Auflagerbreite passt nicht zu der tatsächlichen Verbindung.'
                    : ''
                }`}
              >
                <Warning fontSize="small" sx={{ verticalAlign: 'middle', color: 'red' }} />
              </Tooltip>
            </>
          )}
          {!isUndefined(leftOverhang) && <OverhangValue overhang={leftOverhang} />}
          <Typography>|</Typography>
          {!isUndefined(rightOverhang) && <OverhangValue overhang={rightOverhang} />}
        </Stack>
      )}
    </>
  )
}

export default OverhangCell

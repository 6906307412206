import { ReactElement, useCallback, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import produce from 'immer'
import { isNull, isUndefined, uniqBy } from 'lodash-es'
import { Grid2 } from '@mui/material'
import { Select, SelectLoadable, UnitConvertedField } from '@ui/forms'
import { getCLTCrossSections } from '@queries'
import { CLTLayerFormState, CLTLayerFormStateLayer, usageClassOptions } from '../schema'

const CLTLayerFormFields = (): ReactElement => {
  const { setValue, getValues } = useFormContext()

  const renederCLTCrossSectionOptions = useCallback((data: CLTCrossSection[]) => {
    const values = data.map(crossSection => ({
      value: crossSection,
      label: crossSection.identifier,
    }))

    return uniqBy(values, 'value')
  }, [])

  const selectedProduct = useWatch({ name: 'selectedProduct' })
  const layer = useWatch({ name: 'layer.clt' })

  useEffect(() => {
    if (isNull(selectedProduct)) return

    const layer: CLTLayerFormStateLayer = {
      clt: selectedProduct,
      kind: 'clt-assembly-layer',
    }

    const mergedLayer = produce(state => {
      const values = getValues() as CLTLayerFormState

      const width = values.layer?.clt?.width
      const usageClass = values.layer?.clt?.usage_class

      // If a width/usage class have already been defined we do not want to overwrite it here
      if (!isUndefined(width)) {
        state.clt.width = width
      }

      if (!isUndefined(usageClass)) {
        state.clt.usage_class = usageClass
      }
    }, layer)()

    setValue('layer', mergedLayer)
  }, [getValues, selectedProduct, setValue])

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <SelectLoadable
          label="Querschnitt"
          name="selectedProduct"
          query={getCLTCrossSections}
          renderOptions={renederCLTCrossSectionOptions}
          placeholder={layer?.identifier || undefined}
        />
      </Grid2>

      {!isNull(layer) && (
        <>
          <Grid2 size={6}>
            <UnitConvertedField
              label="Elementbreite"
              name="layer.clt.width"
              unitLabel="m"
              conversionFactor={1}
            />
          </Grid2>
          <Grid2 size={6}>
            <Select
              label="Nutzungsklasse"
              name="layer.clt.usage_class"
              options={Object.entries(usageClassOptions).map(([value, label]) => ({
                value,
                label,
              }))}
            />
          </Grid2>
        </>
      )}
    </Grid2>
  )
}

export default CLTLayerFormFields

import create from 'zustand'
import { combine } from 'zustand/middleware'
import { appConfig, errors } from 'src/constants'

interface ConfigStoreType {
  apiURL: string
  auth0Domain: string
  auth0ClientID: string
  auth0Audience: string
  auth0Scopes: string
  errors: Record<string, string>
  sentryDSN: string
  environment: string

  // Feature flags
  ff_simplified_seismic: boolean
  ff_non_wall_tensile: boolean
}

const initialState: ConfigStoreType = {
  ...appConfig,
  errors,
}

const useConfigStore = create<ConfigStoreType>(combine(initialState, () => ({})))

export default useConfigStore

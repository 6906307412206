import { ReactElement } from 'react'
import { MemberCheckResults, SupportResults } from '@resultsComponents'
import { Calculate } from '@mui/icons-material'
import { Box, Stack } from '@mui/material'
import { Info } from '@ui/feedback'
import GenericResultsGrid from '../GenericResultsGrid'

interface Props {
  grouping: MemberPositionBundleWithMaxUtilization[] | null
  positionType: PositionGroupingType
  designForces?: DesignSupportForce[]

  // member checks
  checksOfPosition?: CombinedPositionCheck[]
  settingsOnMember?: SettingsOnMember | null

  isDownloadingExportDocument: boolean
  onClickDownloadExportDocument: () => void
}

const GroupingsTab = ({
  grouping,
  designForces,

  checksOfPosition,
  settingsOnMember,

  isDownloadingExportDocument,
  onClickDownloadExportDocument,
  positionType,
}: Props): ReactElement => {
  if (!grouping)
    return (
      <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
        <Info icon={<Calculate />}>Daten noch nicht verfügbar</Info>
      </Box>
    )

  if (grouping.length === 0)
    return (
      <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
        <Info icon={<Calculate />}>Keine Elemente vorhanden</Info>
      </Box>
    )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const horizontalBeamLike: PositionGroupingType[] = [
    'purlins',
    'beams',
    'slab-beams',
    'roof-slab-beams',
    'wall-lintels',
  ]

  return (
    <Stack direction="column" spacing={2}>
      <GenericResultsGrid
        bundles={grouping}
        positionType={positionType}
        isDownloadingExportDocument={isDownloadingExportDocument}
        onClickDownloadExportDocument={onClickDownloadExportDocument}
        showShearCheckColumn={horizontalBeamLike.includes(positionType)}
        showOverhangColumn={positionType === 'wall-lintels'}
      />

      {designForces && <SupportResults designForces={designForces} defaultExpanded />}

      {checksOfPosition && (
        <MemberCheckResults
          checksOfPosition={checksOfPosition}
          settingsOnMember={settingsOnMember || null}
          disableSaving
        />
      )}
    </Stack>
  )
}

export default GroupingsTab

import { ReactElement, useMemo, useState } from 'react'
import { elementTypeToLabelSingular } from '@domainConstants'
import { useSnackbar } from 'notistack'
import { Search } from '@mui/icons-material'
import { Button, Divider, Popover, Stack, TextField, Typography } from '@mui/material'
import { TreeItem, SimpleTreeView } from '@mui/x-tree-view'
import { ToggleButton } from '@ui/actions'
import { Box } from '@ui/structure'
import {
  useElementLabel,
  useElementType,
  useGuidToElementIncludingRips,
  useModelClickListeners,
  useSelectionMode,
} from '@editorHooks'
import { useStructuralPlanningQueryParams } from '@structuralPlanningHooks'
import { useControlStore } from '../../../stores/controlStore'

const selectionKey = 'select-component-detail'

const ComponentDetailControls = (): ReactElement => {
  const [selectedElement, setSelectedElement] = useState<string | null>(null)

  const componentDetailEnabled = useControlStore(state => state.componentDetailEnabled)
  const toggleComponentDetailEnabled = useControlStore(state => state.toggleComponentDetailEnabled)

  const { enqueueSnackbar } = useSnackbar()

  const { isSelectionMode, setSelectionMode, unsetSelectionMode, selectionModeKey } =
    useSelectionMode()

  const {
    actions: { setElements, toggleMode },
  } = useStructuralPlanningQueryParams()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const guidToElement = useGuidToElementIncludingRips()
  const guidToLabel = useElementLabel()
  const availableGuids = Object.keys(guidToElement)

  const labelToGuid = useMemo(() => {
    const mapping: { [label: string]: string } = {}
    availableGuids.forEach(guid => {
      const label = guidToLabel(guid)
      if (label) {
        mapping[label] = guid
      }
    })
    return mapping
  }, [availableGuids, guidToLabel])

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
    toggleComponentDetailEnabled()
  }

  const handleClose = () => {
    setAnchorEl(null)
    toggleComponentDetailEnabled()
  }

  useModelClickListeners(
    event => {
      setSelectedElement(event.object.name)
      unsetSelectionMode()
      toggleComponentDetailEnabled()
    },
    [],
    selectionModeKey === selectionKey && isSelectionMode,
  )

  const getElementLabel = useElementLabel()

  const information = useMemo(() => {
    if (selectedElement) {
      const elementInfo = guidToElement[selectedElement]
      const label = getElementLabel(selectedElement)
      return {
        label: label,
        ...elementInfo,
      }
    }
    return null
  }, [selectedElement, guidToElement, getElementLabel])

  const elementType = useElementType(selectedElement)

  // TREE VIEW SELECTION

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toTree = (data: any, depth: string): ReactElement[] => {
    return Object.entries(data).map(([key, val], index) => {
      const nodeId = `${depth}-${index}`

      if (typeof val === 'object') {
        return (
          <TreeItem key={nodeId} itemId={nodeId} label={key}>
            {toTree(val, nodeId)}
          </TreeItem>
        )
      }

      return (
        <TreeItem
          key={nodeId}
          itemId={nodeId}
          label={`${key}: ${val}`}
          onClick={() => {
            const content = val?.toString() || ''
            navigator.clipboard.writeText(content.replace('.', ','))
            enqueueSnackbar('Wert wurde in die Zwischenablage kopiert', { variant: 'info' })
          }}
        />
      )
    })
  }

  return (
    <>
      <ToggleButton
        hint="Eigenschaften anzeigen"
        onClick={handleClick}
        selected={componentDetailEnabled}
        Icon={Search}
      />
      <Popover
        open={componentDetailEnabled}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            padding: 2,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Stack direction="column" spacing={3}>
            <Button
              onClick={() => {
                setSelectionMode({
                  key: selectionKey,
                  message: 'Wählen Sie ein Bauteil aus um Informationen zu erhalten',
                })
                toggleComponentDetailEnabled()
              }}
              variant="contained"
              size="small"
            >
              Element Anwählen
            </Button>
            <TextField
              label="Element suchen"
              placeholder="Element suchen"
              onChange={event => {
                const guidOrLabel = event.target.value
                const guid = availableGuids.includes(guidOrLabel)
                  ? guidOrLabel
                  : labelToGuid[guidOrLabel]
                setSelectedElement(guid || null)
              }}
              size="small"
            ></TextField>

            {information ? (
              <>
                <Divider />
                <Box>
                  {elementType && (
                    <Typography variant="h5" mb={2}>
                      {elementTypeToLabelSingular[elementType]}
                    </Typography>
                  )}

                  {information && (
                    <SimpleTreeView
                      sx={{ height: 240, flexGrow: 1, width: 400, overflowY: 'auto' }}
                    >
                      {toTree(information, 'root')}
                    </SimpleTreeView>
                  )}
                </Box>
                {selectedElement && (
                  <Button
                    onClick={() => {
                      toggleMode(undefined)
                      setElements([selectedElement])
                    }}
                  >
                    Anzeigen (Erfodert Tragwerksplanung Modus)
                  </Button>
                )}
              </>
            ) : (
              <Typography>Kein Element angewählt</Typography>
            )}
          </Stack>
        </Box>
      </Popover>
    </>
  )
}

export default ComponentDetailControls

import { ReactElement } from 'react'
import { Stack, Typography } from '@mui/material'
import { Box } from '@ui/structure'
import { useEditElementStore } from '@editorStores'
import { useElementType } from '@editorHooks'
import {
  LoadList,
  OpeningForm,
  PositionForm,
  PurlinForm,
  RoofSlabForm,
  SelectionForm,
  WallForm,
  WallRipForm,
  WallLintelForm,
  RoofForm,
  SlabForm,
  VerticalSlabsForm,
  MultiEdit,
} from '..'
import useStructuralPlanningQueryParams from '../../hooks/useStructuralPlanningQueryParams'
import { BeamForm, ColumnForm, FloorplanElementForm } from '../FloorplanDrawer/components'
import FoundationForm from '../Foundation/components/FoundationForm'
import { VerticalRoofForm } from '../VerticalRoof'

const StructuralPlanningDrawer = (): ReactElement => {
  const {
    params: { mode, selectedElements, storey },
    actions: { reset, toggleMode, setElements },
    modes: {
      isStiffeningSelectionMode,
      isBeamMode,
      isColumnMode,
      isPurlinMode,
      isDrawingBeamsMode,
      isDrawingPurlinsMode,
      isDrawingOpeningsMode,
      isRoofMode,
      isRoofSlabMode,
      isWallMode,
      isStiffeningMode,
      isLoadMode,
      isVerticalSlabMode,
      isDrawingVerticalRoofMode,
      isDrawingVerticalSlabsMode,
      isDrawingSlabMode,
      isDrawingRoofMode,
      isPositionMode,
      isRipMode,
      isLintelMode,
      isWallRipMode,
      isWallLintelMode,
      isFoundationMode,
      isMultiEdit,
    },
  } = useStructuralPlanningQueryParams()

  const activeElement = useEditElementStore(state => state.activeElement)
  const setActiveElement = useEditElementStore(state => state.setActiveElement)
  const activeOpening = useEditElementStore(state => state.activeOpening)

  const elementType = useElementType(selectedElements?.[0])

  // RENDERING

  if (isMultiEdit)
    return <MultiEdit resetElements={() => setElements([])} selectedElements={selectedElements} />

  if (isStiffeningSelectionMode)
    return <SelectionForm onSubmitSuccess={() => toggleMode(undefined)} />

  if (isRipMode || isLintelMode || isPositionMode) {
    return (
      <>
        <PositionForm position={selectedElements?.[0] as string} />
      </>
    )
  }

  if ((isWallMode || isStiffeningMode) && selectedElements) {
    return (
      <WallForm
        elementType={elementType as ElementTypes}
        selectedElementGuid={selectedElements?.[0] as string}
        onClose={reset}
      />
    )
  }
  if (isRoofSlabMode && selectedElements) {
    return (
      <RoofSlabForm
        elementType={mode as ElementTypes}
        selectedElements={selectedElements as string[]}
        onClose={reset}
      />
    )
  }
  if (isLoadMode) return <LoadList />
  if (isDrawingOpeningsMode)
    return (
      <OpeningForm
        selectedWall={activeElement}
        selectedOpening={activeOpening}
        deselectWall={() => setActiveElement(null)}
      />
    )

  if (storey) return <FloorplanElementForm />

  if (isWallRipMode)
    return <WallRipForm rip={selectedElements?.[0] || undefined} onClose={() => setElements([])} />

  if (isWallLintelMode) return <WallLintelForm lintel={selectedElements?.[0] || undefined} />

  if (isDrawingVerticalRoofMode && activeElement)
    return (
      <VerticalRoofForm
        key={activeElement}
        selectedElement={activeElement}
        geometryEditable={true}
        onClose={() => setActiveElement(null)}
      />
    )

  if (isDrawingVerticalSlabsMode && activeElement)
    return (
      <VerticalSlabsForm
        key={activeElement}
        selectedElement={activeElement}
        geometryEditable={true}
        onClose={() => setActiveElement(null)}
      />
    )

  if (isDrawingSlabMode)
    return (
      <SlabForm
        selectedElement={activeElement}
        selectElement={setActiveElement}
        elementType="slabs"
      />
    )

  if (isDrawingRoofMode)
    return <RoofForm selectedElement={activeElement} selectElement={setActiveElement} />

  if (isDrawingPurlinsMode && activeElement)
    return <PurlinForm selectedElement={activeElement} onClose={() => setActiveElement(null)} />

  if (isDrawingBeamsMode && activeElement)
    return <BeamForm selectedElement={activeElement} onClose={() => setActiveElement(null)} />

  if (isBeamMode) {
    return (
      <BeamForm selectedElement={selectedElements?.[0] as string} onClose={() => setElements([])} />
    )
  }
  if (isColumnMode) {
    return (
      <ColumnForm
        selectedElement={selectedElements?.[0] as string}
        geometryEditable={true}
        onClose={() => setElements([])}
      />
    )
  }
  if (isPurlinMode) {
    return (
      <PurlinForm
        selectedElement={
          isDrawingPurlinsMode ? (activeElement as string) : (selectedElements?.[0] as string)
        }
        onClose={isDrawingPurlinsMode ? () => setActiveElement(null) : () => setElements([])}
      />
    )
  }
  if (isRoofMode) {
    return (
      <VerticalRoofForm
        key={selectedElements?.[0] as string}
        selectedElement={selectedElements?.[0] as string}
        geometryEditable={true}
        onClose={() => setElements([])}
      />
    )
  }
  if (isVerticalSlabMode) {
    return (
      <VerticalSlabsForm
        key={selectedElements?.[0] as string}
        selectedElement={selectedElements?.[0] as string}
        geometryEditable={true}
        onClose={() => setElements([])}
      />
    )
  }
  if (isFoundationMode) {
    const selectedElement = selectedElements?.[0] as string
    return (
      <>
        <Box
          mt={2}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              fontSize: 12,
              paddingX: ({ spacing }) => spacing(1.5),
            },
          }}
        >
          <Stack direction="column" spacing={2}>
            <Typography variant="h6">{'Fundament'}</Typography>
            <FoundationForm key={selectedElement} selectedElement={selectedElement} />
          </Stack>
        </Box>
      </>
    )
  }

  return <></>
}

export default StructuralPlanningDrawer

import { ReactElement, useMemo } from 'react'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { Instance, InstanceProps } from '@react-three/drei'

interface Props extends Omit<InstanceProps, 'ref' | 'position'> {
  hasTarget?: boolean
  isTarget?: boolean
  height: number
  offset?: number
  position: ImmutableVector3
}

const InstancedCylinderMesh = ({
  isTarget,
  height,
  offset = 0.08,
  position,
  ...props
}: Props): ReactElement => {
  const correctedPosition = useMemo(
    () => position[isTarget ? 'sub' : 'add'](new ImmutableVector3(0, 0, height / 2 + offset)),
    [height, isTarget, offset, position],
  )

  return (
    <Instance
      {...props}
      onPointerOver={() => {
        document.body.style.cursor = 'pointer'
      }}
      onPointerOut={() => {
        document.body.style.cursor = 'auto'
      }}
      position={correctedPosition.v}
    />
  )
}

export default InstancedCylinderMesh

import { CLTLayerFormState } from './schema'

export const layerToFormState = (layer?: CLTAssemblyLayer): CLTLayerFormState | undefined => {
  if (!layer) return

  return {
    layer: layer,
    selectedProduct: layer.clt,
  }
}

export const formStateToLayer = (formState: CLTLayerFormState): CLTAssemblyLayer => {
  const { layer } = formState

  return layer as CLTAssemblyLayer
}

import { ReactElement } from 'react'
import { useFormState, useWatch } from 'react-hook-form'
import { FormFields as SingleElementCSFormFields } from 'src/components/pages/Editor/components/SingleElementCSForm/components/FormFields'

interface Props {
  elementType: ElementTypes
}

const CSFormFields = ({ elementType }: Props): ReactElement => {
  const { defaultValues } = useFormState()
  const crossSection: CrossSection = useWatch({ name: 'crossSection' })

  return (
    <SingleElementCSFormFields
      elementType={elementType}
      isDisabled={false}
      alert={
        crossSection?.usage_class !== defaultValues?.crossSection?.usage_class
          ? 'Nutzungsklasse wird erst nach dem Speichern angewendet'
          : undefined
      }
    />
  )
}

export default CSFormFields

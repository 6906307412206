import { ReactElement, useMemo } from 'react'
import { filter } from 'lodash-es'
import { Button, Stack, Typography } from '@mui/material'
import { DeleteButton, SaveButton } from '@ui/actions'
import { ErrorField, Form } from '@ui/forms'
import { useEditElementStore, useModelStore } from '@editorStores'
import { useElementLabel } from '@editorHooks'
import FormFields from '../FormFields'
import { wallSchema } from './schema'

interface Props {
  handleSubmit: (wall: ShapeObject) => void
  isSubmitting?: boolean
  handleDelete: () => void
  handleReset?: () => void
  isResettable?: boolean
  isDeleting?: boolean
  wall: ShapeObject
  isEditable: boolean
  isPlacementEditable?: boolean
  isSaveable?: boolean
}

const GeometryForm = ({
  handleDelete,
  handleSubmit,
  handleReset,
  isSubmitting,
  isDeleting,
  wall,
  isEditable,
  isSaveable = false,
  isResettable = false,
  isPlacementEditable = true,
}: Props): ReactElement => {
  const activeElement = useEditElementStore(state => state.activeElement)
  const { walls } = useModelStore(state => state.model)

  const getLabel = useElementLabel()

  const label = getLabel(wall.guid)

  const wallsInStorey = useMemo(() => {
    return filter(walls, ['storey', wall.storey])
  }, [walls])

  const defaultValues = useMemo(() => {
    const [start, end] = wall.shape.points

    return {
      guid: wall.guid,
      xStart: start.x,
      yStart: start.y,
      xEnd: end.x,
      yEnd: end.y,
      isIntersecting: false,
      placement: wall.placement ?? 'Internal',
    }
  }, [activeElement, wall])

  return (
    <>
      <Form
        key={activeElement}
        onSubmit={() => handleSubmit(wall)}
        validationSchema={wallSchema}
        validationContext={{ walls: wallsInStorey }}
        defaultValues={defaultValues}
        enableReinitialize={!isEditable}
      >
        <Stack direction="column" spacing={2}>
          <Typography variant="h6">{label}</Typography>

          <FormFields
            wall={wall}
            isEditable={isEditable}
            isPlacementEditable={isPlacementEditable}
          />

          <ErrorField name="isIntersecting" />

          <Stack direction="row" justifyContent="end" spacing={1}>
            <SaveButton
              type="submit"
              loading={isSubmitting}
              fullWidth
              disabled={isDeleting || !isSaveable}
            >
              Speichern
            </SaveButton>
            {isResettable ? (
              <Button variant="outlined" onClick={handleReset} fullWidth>
                Zurücksetzen
              </Button>
            ) : (
              <DeleteButton
                onClick={handleDelete}
                size="small"
                fullWidth
                loading={isDeleting}
                disabled={isSubmitting}
                data-cy="btn-delete-wall"
              >
                Löschen
              </DeleteButton>
            )}
          </Stack>
        </Stack>
      </Form>
    </>
  )
}

export default GeometryForm

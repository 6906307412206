import { ReactElement, useState } from 'react'
import { Error, ErrorOutline } from '@mui/icons-material'
import { Alert, Box, Button, Modal, Paper, Stack, Typography } from '@mui/material'

interface Props {
  error?: Error
}

// eslint-disable-next-line node/handle-callback-err
const GenericErrorBoundary = ({ error }: Props): ReactElement => {
  const [errorInfoModalOpen, setErrorInfoModalOpen] = useState(false)

  return (
    <>
      <Box
        p={2}
        sx={theme => ({
          backgroundColor: theme.palette.grey[100],
          borderColor: theme.palette.grey[200],
          borderWidth: 1,
          borderStyle: 'solid',
        })}
        display="flex"
        flex={1}
        justifyContent="center"
        alignItems="center"
        borderRadius={1}
      >
        <Stack direction="column" spacing={1} justifyContent="center" alignItems="center">
          <Error color="error" fontSize="medium" />
          <Typography variant="h4" align="center">
            Fehler
          </Typography>
          <Typography align="center">
            Hier kam es zu einem Fehler, bitte versuchen Sie es erneut oder wenden Sie sich an das
            DEV-Team.
          </Typography>

          {error && <Button onClick={() => setErrorInfoModalOpen(true)}>mehr anzeigen</Button>}
        </Stack>
      </Box>

      <Modal open={errorInfoModalOpen} onClose={() => setErrorInfoModalOpen(false)}>
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            p: 4,
          }}
        >
          <Stack direction="column" spacing={2}>
            <Typography variant="h5">Fehlertyp: {error?.name}</Typography>

            <Alert icon={<ErrorOutline fontSize="inherit" />} severity="error">
              {error?.message}
            </Alert>
          </Stack>
        </Paper>
      </Modal>
    </>
  )
}

export default GenericErrorBoundary

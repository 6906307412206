import { object, string, number, mixed } from 'yup'

export const defaultCrossSectionFormValues = (): CrossSection => {
  return {
    shape: {
      height: 10,
      width: 10,
      kind_literal: 'RectangularCS',
    },
    material: {
      kind: 'Softwood',
      identifier: 'C24',
    } as Material,
    usage_class: 'One',
    kind: 'CrossSection',
  }
}

// Shape schema based on the type of material
const rectangularCSShapeSchema = object({
  kind_literal: string().oneOf(['RectangularCS']).required(),
  // TODO: it should be strictly larger than 0, but this does not work yet
  width: number().required().min(0, 'Width must be non-negative'),
  height: number().required().min(0, 'Height must be non-negative'),
})

export const steelCSShapeSchema = object({
  kind_literal: string().oneOf(['SteelCS']).required(),
  profile: string().required(),
  profile_type: string()
    .oneOf(['IAndHSection', 'RROAndQROSection', 'ROSection', 'UPNSection', 'UPESection'])
    .required(),
  // all steel cross section have height and width except for the RO section
  height: number().when('profile_type', {
    is: (profile_type: string) => profile_type === 'ROSection',
    then: schema => schema.optional(),
    otherwise: schema => schema.required().positive('Height must be greater than 0'),
  }),
  width: number().when('profile_type', {
    is: (profile_type: string) => profile_type === 'ROSection',
    then: schema => schema.optional(),
    otherwise: schema => schema.required().positive('Width must be greater than 0'),
  }),
  // RO section has a diameter and thickness instead of height and width
  diameter: number().when('profile_type', {
    is: (profile_type: string) => profile_type === 'ROSection',
    then: schema => schema.required().positive('Diameter must be greater than 0'),
    otherwise: schema => schema.optional(),
  }),
  thickness: number().when('profile_type', {
    is: (profile_type: string) => profile_type === 'ROSection',
    then: schema => schema.required().positive('Thickness must be greater than 0'),
    otherwise: schema => schema.optional(),
  }),
})

const materialWithIdentifierSchema = object({
  kind: string().oneOf(['Glulam', 'Softwood', 'LVL', 'Steel']).required(),
  identifier: string().required(),
})

const crossSectionShapeSchema = mixed().test('shape-validation', 'Invalid shape', function (value) {
  if (!value) return false

  switch (value.kind_literal) {
    case 'RectangularCS':
      return rectangularCSShapeSchema.isValidSync(value)
    case 'SteelCS':
      return steelCSShapeSchema.isValidSync(value)
    default:
      return false
  }
})

export const crossSectionSchema = object({
  material: materialWithIdentifierSchema.required(),
  materialType: string().oneOf(['softwoodMaterial', 'glulamMaterial', 'steelMaterial']).required(),
  shape: crossSectionShapeSchema.required(),
  usage_class: string()
    .oneOf(['One', 'Two', 'Three'])
    .when('materialType', {
      is: (materialType: string) =>
        materialType === 'softwoodMaterial' || materialType === 'glulamMaterial',
      then: schema => schema.required(),
      otherwise: schema => schema.optional().nullable(),
    }),
  steelShape: steelCSShapeSchema.optional().nullable(),
})

export const crossSectionFormSchema = object({
  crossSection: crossSectionSchema.required(),
})

export interface CrossSectionFormDataType {
  material: Material
  materialType: string
  shape?: CrossSectionShape
  usage_class: string
  steelShape?: SteelCSShape
  kind: MemberCrossSectionKind
}

import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useResultsInvalidation, useUpdateLoadInfo } from '@editorHooks'
import { getSnowLoads } from '@queries'
import { saveSnowLoads } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import SnowLoads from './components/SnowLoads'

const SnowLoadsTab = (): ReactElement => {
  const { projectId }: { projectId?: string } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const updateLoadInfo = useUpdateLoadInfo()

  const invalidateResults = useResultsInvalidation()

  const { isFetching: isLoadingSnowLoads, data: snowLoadsData } = useQuery(
    getSnowLoads.getKey(projectId),
    () => getSnowLoads.request(projectId),
    {
      onError: () => enqueueSnackbar('Fehler beim Laden der Schneelasten', { variant: 'error' }),
      refetchOnMount: 'always',
    },
  ) as { isFetching: boolean; data?: SnowLoadConfiguration[] }

  const { mutate: mutateSnowLoads, isLoading: isSavingSnowLoads } = useMutation(
    (data: SnowLoadConfiguration[]) => saveSnowLoads.request(projectId, data),
    {
      onSuccess: () => {
        invalidateResults(projectId as string)
        updateLoadInfo()
        enqueueSnackbar('Schneelasten erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Schneelasten'), {
          variant: 'error',
        })
      },
    },
  )

  return (
    <SnowLoads
      isLoading={isLoadingSnowLoads}
      isSaving={isSavingSnowLoads}
      data={snowLoadsData}
      onSave={mutateSnowLoads}
    />
  )
}

export default SnowLoadsTab

import { ReactElement } from 'react'
import { Box } from '@mui/material'
import lintelCompressionDiagram from './lintelCompressionDiagram.png'
import ripCompressionDiagram from './ripCompressionDiagram.png'

interface Props {
  elementType: ElementTypes
}

const Diagram = ({ elementType }: Props): ReactElement => {
  if (elementType === 'lintels') {
    return (
      <Box p={1} border={1} borderColor="grey.200" borderRadius={1}>
        <img
          src={lintelCompressionDiagram}
          alt="Element Compression Diagram"
          style={{ maxWidth: '300px', maxHeight: '300px' }}
        />
      </Box>
    )
  }

  if (elementType === 'rips') {
    return (
      <Box p={1} border={1} borderColor="grey.200" borderRadius={1}>
        <img
          src={ripCompressionDiagram}
          alt="Element Compression Diagram"
          style={{ maxWidth: '300px', maxHeight: '300px' }}
        />
      </Box>
    )
  }

  return <></>
}

export default Diagram

export const elementCSTypeOptions: { value: ElementCSType; label: string }[] = [
  {
    value: 'Beam',
    label: 'Unterzüge',
  },
  {
    value: 'Column',
    label: 'Stützen',
  },
  {
    value: 'Purlin',
    label: 'Pfetten',
  },
  {
    value: 'Rip',
    label: 'Wandrippen',
  },
  {
    value: 'Lintel',
    label: 'Stürze',
  },
]

export const usageClassOptions = [
  {
    value: 'One',
    label: 'NKL 1',
  },
  {
    value: 'Two',
    label: 'NKL 2',
  },
  {
    value: 'Three',
    label: 'NKL 3',
  },
]

import { ReactElement, useMemo } from 'react'
import { VerticalTransmitterMesh } from '@scene'
import { includes } from 'lodash-es'
import { useAppStore } from '@stores'
import ConnectorMesh from '../ConnectorMesh'
import InstancedConnectorMesh from '../InstancedConnectorMesh'
import InstancedVerticalTransmitterMesh from '../InstancedVerticalTransmitterMesh'

const TransmitterMesh = ({
  data,
  hiddenElements = new Set(),
  elementGuids = [],
  ...props
}: TransmitterMeshProps): ReactElement => {
  const instancedTransmitters = useAppStore(state => state.instancedTransmitters)
  const instancedConnectors = useAppStore(state => state.instancedConnectors)

  const [lineGraph, pointGraph] = useMemo(() => {
    const [lineSupports, pointSupports] = data.element_supports.reduce(
      (acc, item) => {
        if (
          hiddenElements.has(item.element_guid) ||
          (elementGuids.length && !includes(elementGuids, item.element_guid))
        ) {
          return acc
        }

        return item.support_type === 'line'
          ? [[...acc[0], item], acc[1]]
          : [acc[0], [...acc[1], item]]
      },
      [[], []] as [ElementSupportItem[], ElementSupportItem[]],
    )

    return [
      {
        ...data,
        element_supports: lineSupports,
      },
      {
        ...data,
        element_supports: pointSupports,
      },
    ]
  }, [data, elementGuids, hiddenElements])

  return (
    <>
      {instancedTransmitters ? (
        <InstancedVerticalTransmitterMesh data={lineGraph} {...props} />
      ) : (
        <VerticalTransmitterMesh data={lineGraph} {...props} />
      )}
      {instancedConnectors ? (
        <InstancedConnectorMesh data={pointGraph} {...props} />
      ) : (
        <ConnectorMesh data={pointGraph} {...props} />
      )}
    </>
  )
}

export default TransmitterMesh

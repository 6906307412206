import { ReactElement, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { MAX_UTILIZATION_FILTER_THRESHOLD } from '@resultsConfig'
import { enqueueSnackbar } from 'notistack'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useModelStore, useResultsStore } from '@editorStores'
import { useElementLabel } from '@editorHooks'
import { getMemberCheckSettings } from '@queries'
import { downLoadFile } from '@utils'
import { getMemberChecksDocument, saveSingleMemberCheckSettings } from '@mutations'
import OtherMemberChecksTable from './components/OtherMemberChecksTable'
import SteelMemberChecksTable from './components/SteelMemberChecksTable'
import TimberMemberChecksTable from './components/TimberMemberChecksTable'

interface Props {
  positionChecks: CombinedPositionCheck[]
  settingsOnMember?: SettingsOnMember | null
  showUnderutilised?: boolean
  disableSaving?: boolean
}

const MemberChecksTable = ({
  positionChecks,
  settingsOnMember = null,
  showUnderutilised = false,
  disableSaving,
}: Props): ReactElement => {
  const { projectId } = useParams()
  const getLabel = useElementLabel()
  const project = useModelStore(state => state.project)
  const queryClient = useQueryClient()

  const setSingleMemberCheckSetting = useResultsStore(state => state.setSingleMemberCheckSetting)

  const { mutate, isLoading } = useMutation(
    (data: SettingsOnMember) => saveSingleMemberCheckSettings.request(projectId as string, data),
    {
      onMutate: async (data: SettingsOnMember) => {
        setSingleMemberCheckSetting(data)
      },
      onSuccess: async () => {
        enqueueSnackbar(
          'Sie müssen die Berechnung neu starten um die neuen Einstellungen zu verwenden',
          { variant: 'warning' },
        )
        enqueueSnackbar('Einstellungen erfolgreich gespeichert', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Einstellungen konnten nicht gespeichert werden', { variant: 'error' })
        // Reset to the backend state when saving fails, to ensure local-state is in sync with backend
        queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))
      },
    },
  )

  const { mutate: downloadExportDocument, isLoading: isDownloadingExportDocument } = useMutation(
    async (element: string) => {
      const data: Blob = await getMemberChecksDocument.request(projectId as string, element)
      return {
        data,
        filename: `Bauteilnachweise ${getLabel(element)} - ${project.name || projectId}.docx`,
      }
    },
    {
      onSuccess: async ({ data, filename }) => {
        if (!data) {
          enqueueSnackbar('Daten noch nicht vorhanden', { variant: 'warning' })
          return
        }

        downLoadFile(
          data,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          filename,
        )
      },
      onError: () => {
        enqueueSnackbar('Fehler beim Abrufen des Dokuments', { variant: 'error' })
      },
    },
  )

  const filteredChecks: CombinedPositionCheck[] = useMemo(() => {
    if (showUnderutilised === false)
      return positionChecks.filter(
        check => check.max_utilization > MAX_UTILIZATION_FILTER_THRESHOLD,
      )
    else return positionChecks
  }, [positionChecks, showUnderutilised])

  if (filteredChecks.length < 0) return <></>

  if (settingsOnMember?.setting_type === 'timber')
    return (
      <TimberMemberChecksTable
        positionChecks={filteredChecks}
        settingsOnMember={settingsOnMember}
        onSave={mutate}
        isLoadingOnSave={isLoading}
        download={downloadExportDocument}
        isLoadingDownload={isDownloadingExportDocument}
        disableSaving={disableSaving}
      />
    )
  else if (settingsOnMember?.setting_type === 'timber-slab')
    return (
      <TimberMemberChecksTable
        positionChecks={filteredChecks}
        settingsOnMember={settingsOnMember}
        onSave={mutate}
        isLoadingOnSave={isLoading}
        download={downloadExportDocument}
        isLoadingDownload={isDownloadingExportDocument}
        disableSaving={disableSaving}
      />
    )
  else if (settingsOnMember?.setting_type === 'steel-column')
    return (
      <SteelMemberChecksTable
        positionChecks={filteredChecks}
        settingsOnMember={settingsOnMember}
        onSave={mutate}
        isLoadingOnSave={isLoading}
        download={downloadExportDocument}
        isLoadingDownload={isDownloadingExportDocument}
        disableSaving={disableSaving}
      />
    )
  else if (settingsOnMember?.setting_type === 'other')
    return (
      <OtherMemberChecksTable
        positionChecks={filteredChecks}
        elementGuid={settingsOnMember.element_guid}
        download={downloadExportDocument}
        isLoadingDownload={isDownloadingExportDocument}
      />
    )
  else return <OtherMemberChecksTable positionChecks={positionChecks} />
}

export default MemberChecksTable

import { getRequest } from 'src/state/utils'

export const getModel: RequestTypeVariable = {
  getKey: (id: string) => ['getModel', id],
  request: async (id: string): Promise<PlanarModel> => {
    const { data } = await getRequest<PlanarModel>({
      url: `/projects/${id}/planar-model`,
    })

    return data as PlanarModel
  },
}

import { ReactElement, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { isNull } from 'lodash-es'
import { Close, Info, SaveOutlined, SyncProblem } from '@mui/icons-material'
import { Box, Button, IconButton, Popover, Stack, Tooltip, Badge } from '@mui/material'
import {
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium'
import { ErrorBoundary } from '@sentry/react'
import { renderGenericErrorBoundary } from '@ui/errors'
import ErrorsPopupContent from '../ErrorsPopupContent'

interface Props {
  onClose: () => void
  onSave: () => void
  anchorNameToNumber: Record<string, number>
  onClickError: (rowId: string, fieldName: string) => void
}

const AnchorDataGridToolbar = ({
  onClose,
  onSave,
  anchorNameToNumber,
  onClickError,
}: Props): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const {
    formState: { isValid, isDirty },
  } = useFormContext()

  return (
    <GridToolbarContainer>
      <Stack direction="row" justifyContent="space-between" display="flex" flex={1} pl={1}>
        <Box>
          <GridToolbarQuickFilter />
          <GridToolbarFilterButton slotProps={{ button: { size: 'small' } }} />
          <GridToolbarColumnsButton
            slotProps={{
              tooltip: {
                slotProps: {
                  popper: {
                    popperOptions: {
                      placement: 'top',
                    },
                  },
                },
              },
            }}
          />
          <GridToolbarExport slotProps={{ button: { size: 'small' } }} />
          <Button
            size="small"
            onClick={onSave}
            variant="text"
            startIcon={<SaveOutlined />}
            data-cy="save-anchor-data-btn"
          >
            Speichern
          </Button>
        </Box>

        <Stack direction="row" spacing={1} alignItems="center">
          <Tooltip title="Zuglast Dokumentation" placement="top" enterDelay={500}>
            <IconButton
              onClick={() =>
                window.open('https://app.clickup.com/2590559/v/dc/2f1uz-930/2f1uz-11535', '_blank')
              }
            >
              <Info fontSize="small" />
            </IconButton>
          </Tooltip>
          {isDirty && (
            <Tooltip
              title={
                isValid
                  ? 'Ungespeicherte Änderungen'
                  : 'Ungespeicherte Änderungen (Fehler in Eingabe)'
              }
            >
              <Badge color="error" overlap="circular" variant="dot" invisible={isValid}>
                <IconButton
                  onClick={event => setAnchorEl(event.currentTarget)}
                  disabled={isValid}
                  data-cy="sync-icon"
                >
                  <SyncProblem
                    fontSize="small"
                    sx={theme => ({
                      color: isValid ? theme.palette.grey[600] : theme.palette.error.main,
                    })}
                    data-cy="unsynced-icon"
                  />
                </IconButton>
              </Badge>
            </Tooltip>
          )}
          <IconButton onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>

      <Popover
        open={!isNull(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        data-cy="anchor-table-error-popover"
      >
        <ErrorBoundary fallback={renderGenericErrorBoundary}>
          <ErrorsPopupContent anchorNameToNumber={anchorNameToNumber} onClickError={onClickError} />
        </ErrorBoundary>
      </Popover>
    </GridToolbarContainer>
  )
}

export default AnchorDataGridToolbar

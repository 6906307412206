import { isCLTCrossSectionKind, isTimberOrSteelCrossSectionKind } from '@domainUtils'
import convert from 'convert'
import numeral from 'numeral'
import { remapMaterialIdentifier } from 'src/components/pages/Editor/components/SingleElementCSForm/components/FormFields'

const makeCrossSectionLabel = (crossSection: CrossSection | CLTCrossSection) => {
  if (isTimberOrSteelCrossSectionKind(crossSection)) {
    const shape = crossSection.shape

    if (shape.kind_literal === 'RectangularCS') {
      const width = numeral(convert(crossSection?.shape.width || 0, 'm').to('cm')).format('0.[0]')
      const height = numeral(convert(crossSection?.shape.height || 0, 'm').to('cm')).format('0.[0]')
      const renderedIdentifier = remapMaterialIdentifier(crossSection.material.identifier)
      return `${width}/${height} ${renderedIdentifier}`
    } else if (shape.kind_literal === 'SteelCS') {
      return `${shape.profile} ${crossSection.material.identifier}`
    }
  } else if (isCLTCrossSectionKind(crossSection)) {
    return `${crossSection.identifier}`
  }
}

const makeSlabBeamCrossSectionLabel = (
  crossSection: CrossSection | CLTCrossSection,
  stepSize: number,
) => {
  if (isTimberOrSteelCrossSectionKind(crossSection)) {
    if (crossSection.shape.kind_literal !== 'RectangularCS') {
      throw new Error('Slab Beam Cross Section can only have timber cross sections')
    } else {
      const csLabel = makeCrossSectionLabel(crossSection)
      // Formatter spec
      // 	1.	At most 2 digits behind the decimal point.
      //	2.	If there are no digits behind the decimal point, don't show them.
      const renderedStepSizeCM = numeral(convert(stepSize, 'm').to('cm')).format('0.[0]')
      return `${csLabel}  a=${renderedStepSizeCM}cm`
    }
  } else {
    const csLabel = makeCrossSectionLabel(crossSection)
    return `${csLabel}`
  }
}

export { makeCrossSectionLabel, makeSlabBeamCrossSectionLabel }

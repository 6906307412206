import { ReactElement, MouseEvent } from 'react'
import { AcUnit, Air } from '@mui/icons-material'
import { Stack } from '@mui/material'
import ProjectStateItem from '../ProjectStateItem'

interface Props {
  open: boolean
  project: ProjectWithLoadInfo
  onClick: (e: MouseEvent, tab: string) => void
}

const ProjectState = ({ open, project, onClick }: Props): ReactElement => {
  const projectState = project?.load_info

  return (
    <Stack direction="column">
      <ProjectStateItem
        label={'Windlasten'}
        icon={<Air fontSize="small" />}
        open={open}
        valid={projectState?.has_wind_loads}
        onClick={e => onClick(e, 'wind-loads')}
        dataCy="wind-loads"
      />

      <ProjectStateItem
        label={'Schneelasten'}
        icon={<AcUnit fontSize="small" />}
        open={open}
        valid={projectState?.has_snow_loads}
        onClick={e => onClick(e, 'snow-loads')}
        dataCy="snow-loads"
      />
    </Stack>
  )
}

export default ProjectState

import { useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { memberCheckTypeToReadableMap } from '@domainConstants'
import { filter, maxBy, reject } from 'lodash-es'
import {
  TableCell,
  Paper,
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Typography,
} from '@mui/material'
import { UtilizationC90TextIcon, UtilizationTextIcon } from '@ui/icons/misc'
import { TableCellProgress } from '@ui/tables'
import { CrossSectionFormDataType } from 'src/components/pages/Editor/components/SingleElementCSForm/schema'
import { useCalcChecksForElement } from '../../hooks'
import UtilizationCell from '../UtilizationCell'

export const formBundlesToUpdatedCheckSettings = (
  formCheckSettings: SettingsOnMember,
  formBundles: (PositionBundleItem | OtherElementBundleItem)[],
) => {
  if (
    formCheckSettings.setting_type === 'steel-column' ||
    formCheckSettings.setting_type === 'other'
  ) {
    return formCheckSettings
  }
  const updatedSupportConfigs = formCheckSettings.support_configs.map(config => {
    const bundle = formBundles.find(
      bundle => bundle.supportConfig.relative_position === config.relative_position,
    )
    return bundle ? bundle.supportConfig : config
  })
  return {
    ...formCheckSettings,
    support_configs: updatedSupportConfigs,
  }
}

const UtilizationPreview = ({ elementGuid }: { elementGuid: string }) => {
  const formBundles: (PositionBundleItem | OtherElementBundleItem)[] = useWatch({
    name: 'formBundles',
  })
  const crossSectionFormData: CrossSectionFormDataType = useWatch({ name: 'crossSection' })
  const checkSettings: SettingsOnMember = useWatch({ name: 'checkSettings' })

  // derive values from form state
  const newLintelCS = useMemo(
    () =>
      ({
        material: crossSectionFormData.material,
        shape:
          crossSectionFormData.materialType === 'steelMaterial'
            ? crossSectionFormData.steelShape
            : crossSectionFormData.shape,
        usage_class: crossSectionFormData.usage_class,
      } as CrossSection),
    [
      crossSectionFormData.material,
      crossSectionFormData.materialType,
      crossSectionFormData.shape,
      crossSectionFormData.steelShape,
      crossSectionFormData.usage_class,
    ],
  )

  const updatedSettingsOnElement = useMemo(
    () => formBundlesToUpdatedCheckSettings(checkSettings as SettingsOnMember, formBundles),
    [checkSettings, formBundles],
  )

  const { data: computedChecksData, isLoading: isComputingChecks } = useCalcChecksForElement(
    elementGuid,
    newLintelCS,
    updatedSettingsOnElement,
    'main',
  )

  const elementPositionChecks = computedChecksData && computedChecksData.checks

  const elementChecksWithoutSupportCompression = useMemo(
    () =>
      reject(elementPositionChecks, check =>
        ['SupportCompression', 'SteelSupportCompression'].includes(check.check_type),
      ),
    [elementPositionChecks],
  )

  const elementChecksOnlySupportCompression = useMemo(
    () =>
      filter(elementPositionChecks, check =>
        ['SupportCompression', 'SteelSupportCompression'].includes(check.check_type),
      ),
    [elementPositionChecks],
  )

  const maxUtilOnlySupportCompression = useMemo(
    () => maxBy(elementChecksOnlySupportCompression, check => check.max_utilization),
    [elementChecksOnlySupportCompression],
  )

  const maxUtilWithoutSupportCompression = useMemo(
    () => maxBy(elementChecksWithoutSupportCompression, check => check.max_utilization),
    [elementChecksWithoutSupportCompression],
  )

  return (
    <TableContainer component={Paper} sx={{ maxWidth: '140px' }}>
      <Table stickyHeader size="small" sx={{ '.MuiTableCell-root': { paddingX: 1 } }}>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <UtilizationTextIcon />
            </TableCell>
            <TableCell align="center">
              <UtilizationC90TextIcon />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">
              {isComputingChecks ? (
                <TableCellProgress />
              ) : (
                <UtilizationCell
                  utilization={maxUtilWithoutSupportCompression?.max_utilization}
                  checkType={maxUtilWithoutSupportCompression?.check_type}
                  dataCy="utilization-without-support-txt"
                />
              )}
            </TableCell>
            <TableCell align="center">
              {isComputingChecks ? (
                <TableCellProgress />
              ) : (
                <UtilizationCell
                  utilization={maxUtilOnlySupportCompression?.max_utilization}
                  checkType={maxUtilOnlySupportCompression?.check_type}
                  dataCy="utilization-only-support-txt"
                />
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell colSpan={2}>
              <Typography align="center">
                {maxUtilWithoutSupportCompression
                  ? memberCheckTypeToReadableMap[maxUtilWithoutSupportCompression.check_type]
                  : 'n/a'}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { UtilizationPreview }

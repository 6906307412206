import { useRef, useMemo } from 'react'
import {
  Vector3,
  ArrowHelper,
  MeshBasicMaterial,
  ColorRepresentation,
  Mesh,
  BufferGeometry,
} from 'three'
import { useTheme } from '@mui/material'
import { useUIStore } from '@stores'
import { useDisposeThreeObjectsOnUnmount } from '@editorHooks'
import Label from '../Label'

interface Props {
  direction: Vector3
  origin: Vector3
  length: number
  label?: string
  color?: string
  highlighted?: boolean
  headLength?: number
  headWidth?: number
  onClick?: () => void
}

const Arrow = ({
  direction,
  origin,
  length,
  label,
  color,
  onClick = () => null,
  highlighted,
  headLength = 0.15,
  headWidth = 0.1,
}: Props) => {
  const arrowRef = useRef<ArrowHelper>(null)
  const theme = useTheme()
  const displayDirection = direction.clone().setZ(-1)
  const arrowColor = color || theme.palette.common.black

  const showHtmlSceneLabels = useUIStore(state => state.showHtmlSceneLabels)

  const cone = useMemo(
    () =>
      new Mesh(
        new BufferGeometry(),
        new MeshBasicMaterial({ depthTest: false, opacity: 0.5, transparent: true }),
      ),
    [],
  )
  useDisposeThreeObjectsOnUnmount([cone])

  return (
    <group key={length}>
      <arrowHelper
        ref={arrowRef}
        args={[
          displayDirection,
          origin,
          length,
          arrowColor as ColorRepresentation,
          headLength,
          headWidth,
        ]}
        cone={cone}
      />
      {label && (
        <Label
          highlighted={highlighted}
          onClick={onClick}
          label={label}
          position={origin}
          visible={showHtmlSceneLabels}
        />
      )}
    </group>
  )
}

export default Arrow

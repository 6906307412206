const positionGroupingTypeToElementTypeMapping: {
  [key in PositionGroupingType]: ElementTypes
} = {
  'wall-rips': 'rips',
  'wall-lintels': 'lintels',
  'slab-beams': 'slab_beams',
  'roof-slab-beams': 'roof_slab_beams',
  beams: 'beams',
  purlins: 'purlins',
  columns: 'columns',
}

export const getElementType = (positionGroupingType: PositionGroupingType): ElementTypes => {
  return positionGroupingTypeToElementTypeMapping[positionGroupingType]
}

export const elementTypeToPositionGroupingType: Record<
  ElementTypes,
  PositionGroupingType | undefined
> = {
  rips: 'wall-rips',
  lintels: 'wall-lintels',
  slab_beams: 'slab-beams',
  roof_slab_beams: 'roof-slab-beams',
  beams: 'beams',
  purlins: 'purlins',
  columns: 'columns',
  outer_walls: undefined,
  inner_walls: undefined,
  slabs: undefined,
  roof_slabs: undefined,
  vertical_slabs: undefined,
  vertical_roof_slabs: undefined,
  standard_rips: undefined,
  foundation: undefined,
}

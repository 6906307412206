import { ReactElement } from 'react'
import { memberCheckTypeToReadableMap } from '@domainConstants'
import { Tooltip, Typography } from '@mui/material'

interface Props {
  utilization?: number
  checkType?: MemberCheckType
  showTooltip?: boolean
  dataCy?: string
}

const UtilizationCell = ({ utilization, checkType, showTooltip, dataCy }: Props): ReactElement => {
  if (!utilization || !checkType) return <Typography>n/a</Typography>
  const roundedValue = utilization.toFixed(2)
  const isOverUtilized = utilization > 1

  if (showTooltip) {
    return (
      <Tooltip title={memberCheckTypeToReadableMap[checkType]}>
        <Typography
          sx={{
            color: isOverUtilized ? 'red' : 'inherit',
            fontWeight: isOverUtilized ? 'bold' : 'inherit',
          }}
          data-cy={dataCy}
        >
          {roundedValue}
        </Typography>
      </Tooltip>
    )
  }

  return (
    <Typography
      sx={{
        color: isOverUtilized ? 'red' : 'inherit',
        fontWeight: isOverUtilized ? 'bold' : 'inherit',
      }}
      data-cy={dataCy}
    >
      {roundedValue}
    </Typography>
  )
}

export default UtilizationCell

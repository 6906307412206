import { ReactElement, useEffect, useMemo, useRef } from 'react'
import { getLinePlaneIntersectionTarget, mapValueKey } from '@editorUtils'
import { PositionMeshElement } from '@scene'
import { find, flatten, isUndefined, last, maxBy, reject } from 'lodash-es'
import { BufferGeometry, Mesh, Plane, Vector3, Material } from 'three'
import { v4 as uuid } from 'uuid'
import { useTheme } from '@mui/material'
import {
  DrawController,
  InteractiveLine,
  useTapelineStore,
  InteractiveLineRef,
} from '@modugen/scene/lib'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import {
  useEditElementStore,
  useControlStore,
  useModelStore,
  useSystemManagerStore,
} from '@editorStores'
import { useDisposeThreeObjectsOnUnmount } from '@editorHooks'

/**
 * the zOffset is here used as a hacky way to not interefere with snapping
 * targets already in the scene
 */
const zOffset = -1000

interface Props {
  selectRip: (rip: string) => void
  selectedRip?: string
}

const WallRipDrawer3D = ({ selectRip, selectedRip }: Props): ReactElement => {
  const { scenePalette } = useTheme()

  const isDrawing = useEditElementStore(state => state.isDrawingRip)
  const setIsDrawing = useEditElementStore(state => state.setIsDrawingRip)

  const snapToCornersAndEdges = useControlStore(state => state.snapToCornersAndEdges)

  const model = useModelStore(state => state.model)

  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  const addRip = useModelStore(state => state.addRip)
  const removeRip = useModelStore(state => state.removeRip)
  const rips = useModelStore(state => state.model.rips)

  const rip = useMemo(() => find(rips, { position_guid: selectedRip }), [selectedRip, rips])

  const lineRef = useRef<InteractiveLineRef>(null)

  const wall = useMemo(() => find(model.walls, { guid: rip?.wall_guid }), [model, rip])

  const wallZ = wall?.shape.points[0].z

  const wallDirection = useMemo(
    () => wall && wall.shape.points[0].directionTo(wall.shape.points[1]),
    [wall],
  )

  const tapelines = useTapelineStore(state => state.lines)

  const snapTargets = useMemo(() => {
    if (!wall || isUndefined(wallZ)) return

    const plane = new Plane().setFromCoplanarPoints(
      wall.shape.points[0].v,
      wall.shape.points[1].v,
      wall.shape.points[2].v,
    )

    const purlinSnapTargets = reject(
      model.purlins.map(purlin =>
        getLinePlaneIntersectionTarget(
          purlin.shape.start,
          purlin.shape.end,
          plane,
          wallZ + zOffset,
        ),
      ),
      target => isUndefined(target),
    ) as unknown as Mesh<BufferGeometry, Material | Material[]>[]

    const beamSnapTargets = reject(
      model.beams.map(beam =>
        getLinePlaneIntersectionTarget(beam.shape.start, beam.shape.end, plane, wallZ + zOffset),
      ),
      target => isUndefined(target),
    ) as unknown as Mesh<BufferGeometry, Material | Material[]>[]

    const ripSnapTargets = rips.map(rip => {
      const startGeometry = new BufferGeometry().setFromPoints([
        new Vector3(rip.start.x, rip.start.y, wallZ + zOffset),
      ])
      return new Mesh(startGeometry)
    })

    const tapelineSnapTargets = flatten(
      tapelines.map(line => {
        const startGeometry = new BufferGeometry().setFromPoints([
          new Vector3(line.start.x, line.start.y, wallZ + zOffset),
        ])
        const startMesh = new Mesh(startGeometry)

        const endGeometry = new BufferGeometry().setFromPoints([
          new Vector3(line.end.x, line.end.y, wallZ + zOffset),
        ])
        const endMesh = new Mesh(endGeometry)

        return [startMesh, endMesh]
      }),
    )

    const wallSnapTargets = wall.shape.points.map(
      p => new Mesh(new BufferGeometry().setFromPoints([p.v])),
    )

    return [
      ...purlinSnapTargets,
      ...wallSnapTargets,
      ...beamSnapTargets,
      ...ripSnapTargets,
      ...tapelineSnapTargets,
    ]
  }, [model, wall, wallZ, tapelines])

  useDisposeThreeObjectsOnUnmount(snapTargets)

  const guidToCrossSection = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )

  useEffect(() => {
    const rips = useModelStore.getState().model.rips
    const rip = find(rips, { position_guid: selectedRip })
    if (rip?.is_local) {
      setIsDrawing(true)
    }
  }, [selectedRip])

  const highestZ = maxBy(wall?.shape.points, 'z')?.z as number

  return (
    <>
      <DrawController
        enableIndicator={false}
        enabled={isDrawing}
        isValidDrawTarget={object => object.name === rip?.wall_guid}
        onMouseMove={state => {
          const drawPoint = state.drawPoint

          if (!drawPoint || !lineRef.current || isUndefined(wallZ)) {
            return
          }

          const start = new ImmutableVector3(drawPoint.x, drawPoint.y, wallZ)
          const end = new ImmutableVector3(drawPoint.x, drawPoint.y, highestZ)

          lineRef.current?.updateLine([start, end])
        }}
        drawingAxis={
          wall && wallDirection
            ? {
                origin: wall.shape.points[0].addScaledVector(
                  new ImmutableVector3(0, 0, 1),
                  zOffset,
                ),
                direction: wallDirection,
              }
            : undefined
        }
        additionalSnapTargets={snapTargets}
        snapToCornersAndEdges={snapToCornersAndEdges}
        onDrawEnd={state => {
          if (!state.drawPoint || !wall || isUndefined(wallZ) || !rip) {
            return
          }

          const drawPoint = state.drawPoint

          const start = new ImmutableVector3(drawPoint.x, drawPoint.y, wallZ)
          const end = new ImmutableVector3(drawPoint.x, drawPoint.y, highestZ)

          const lastPoint = last(wall.shape.points) as ImmutableVector3
          const xDirection = wall.shape.points[0].directionTo(lastPoint, true)
          const yDirection = wall.shape.points[0].directionTo(wall.shape.points[1], true)
          const zDirection = xDirection.cross(yDirection).normalize()

          const newRip: Rip = {
            wall_guid: rip?.wall_guid,
            position_guid: rip?.position_guid || uuid(),
            domain_guid: rip?.position_guid || uuid(), // Generate something, but will be overwritten by backend
            start: start,
            end: end,
            coordinate_system: {
              origin: start,
              x_direction: xDirection,
              y_direction: yDirection,
              z_direction: zDirection,
              TOLERANCE: 0,
            },
            is_local: true,
          }

          if (rip) {
            removeRip(rip.position_guid)
          }
          addRip(newRip)
          selectRip(newRip.position_guid)
          setIsDrawing(false)
        }}
      />

      {rip && isDrawing && (
        <InteractiveLine
          key={selectedRip}
          ref={lineRef}
          line={[rip.start, rip.end]}
          showHandles={false}
          color={scenePalette.elements3d.beams}
          disableDepthTest
        />
      )}

      {rip && !isDrawing && (
        <PositionMeshElement
          position_type="rip"
          data={rip}
          height={guidToCrossSection[rip.position_guid]?.element_cs.shape.height}
          width={guidToCrossSection[rip.position_guid]?.element_cs.shape.width}
          onClick={() => {
            if (rip.is_local) {
              setIsDrawing(true)
            }
          }}
          isSelected
        />
      )}
    </>
  )
}

export default WallRipDrawer3D

import { deleteRequest, postRequest } from '../utils'

export const deleteProjectConnection: RequestTypeVariable = {
  getKey: (projectId: string) => ['deleteProjectConnection', projectId],
  request: async (projectId: string, id: string): Promise<ProjectConnection[]> => {
    const { data } = await deleteRequest<ProjectConnection[]>({
      url: `/projects/${projectId}/connections/${id}`,
    })

    return data as ProjectConnection[]
  },
}

export const createProjectConnection: RequestTypeVariable = {
  getKey: (projectId: string) => ['createProjectConnection', projectId],
  request: async (id: string, data: ProjectConnection): Promise<ProjectConnection> => {
    const { data: result } = await postRequest<ProjectConnection>({
      url: `/projects/${id}/connections`,
      data,
    })

    return result as ProjectConnection
  },
}

export const editProjectConnection: RequestTypeVariable = {
  getKey: (projectId: string) => ['editProjectConnection', projectId],
  request: async (projectId: string, data: ProjectConnection): Promise<ProjectConnection> => {
    const { data: result } = await postRequest<ProjectConnection>({
      url: `/projects/${projectId}/connections/${data.guid}`,
      data,
    })

    return result as ProjectConnection
  },
}

export const createConnection: RequestType = {
  key: ['createConnection'],
  request: async (data: Connection): Promise<Connection> => {
    const { data: result } = await postRequest<Connection>({
      url: `/connections`,
      data,
    })

    return result as Connection
  },
}

export const editConnection: RequestType = {
  key: ['editConnection'],
  request: async (data: Connection): Promise<Connection> => {
    const { data: result } = await postRequest<Connection>({
      url: `/connections/${data.guid}`,
      data,
    })

    return result as Connection
  },
}

export const deleteConnection: RequestType = {
  key: ['deleteConnection'],
  request: async (id: string): Promise<Connection[]> => {
    const { data } = await deleteRequest<Connection[]>({
      url: `/connections/${id}`,
    })

    return data as Connection[]
  },
}

export const saveProjectPrioritization: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveProjectPrioritization', projectId],
  request: async ({ projectId, input }): Promise<ProjectConnectionPrioritization> => {
    const { data } = await postRequest<ProjectConnectionPrioritization>({
      url: `/projects/${projectId}/connection-prioritization`,
      data: input,
    })

    return data as ProjectConnectionPrioritization
  },
}

import { materialTypeFromCS } from '@domainUtils'
import { defaultCrossSectionFormValues } from '../../schema'

export const getDefaultValues = (defaultCS?: CrossSection) => {
  if (defaultCS?.kind === 'CLT') {
    throw new Error('Unexpected error. CLT is not configured to be selected overe here')
  }

  if (defaultCS && defaultCS.shape.kind_literal === `SteelCS`) {
    const new_defaults = {
      crossSection: {
        ...defaultCrossSectionFormValues(),
        steelShape: defaultCS.shape as SteelCSShape,
        material: defaultCS.material,
        materialType: defaultCS ? materialTypeFromCS(defaultCS) : 'softwoodMaterial',
      },
    }
    return new_defaults
  } else {
    const new_defaults = {
      crossSection: {
        ...defaultCrossSectionFormValues(),
        steelShape: '',
        ...defaultCS,
        materialType: defaultCS ? materialTypeFromCS(defaultCS) : 'softwoodMaterial',
      },
    }
    return new_defaults
  }
}

import { useMemo } from 'react'
import { filter, find } from 'lodash-es'
import { BufferGeometry, Vector3, Mesh } from 'three'
import { useModelStore } from '@editorStores'
import { useDisposeThreeObjectsOnUnmount } from '@editorHooks'

const useRipSnapTargets = () => {
  const storeys = useModelStore(state => state.visibleStoreys)

  const rips = useModelStore(state => state.model.rips)
  const model = useModelStore(state => state.model)

  const positionGuidToStorey = useMemo(() => {
    return rips.reduce((collector, position) => {
      const wall = find(model.walls, { guid: position.wall_guid })
      if (!wall) return collector
      return { ...collector, [position.position_guid]: wall?.storey }
    }, {} as Record<string, string>)
  }, [rips, model])

  const visibleRips = useMemo(
    () => filter(rips, rip => storeys.has(positionGuidToStorey[rip.position_guid])),
    [rips, storeys, positionGuidToStorey],
  )

  const snapTargets = useMemo(
    () =>
      visibleRips.map(rip => {
        const geometry = new BufferGeometry().setFromPoints([
          new Vector3(rip.start.x, rip.start.y, 0),
        ])

        return new Mesh(geometry)
      }),

    [visibleRips],
  )

  useDisposeThreeObjectsOnUnmount(snapTargets)

  return snapTargets
}

export default useRipSnapTargets

import { ReactElement, useMemo } from 'react'
import { memberCheckTypeToReadableMap, supportTypeToLabelShort } from '@domainConstants'
import { maxBy, reject } from 'lodash-es'
import { Stack, TableCell, TableRow, Typography } from '@mui/material'
import { CentimeterField, TextField } from '@ui/forms'
import { RipHeightTextIcon, RipWidthTextIcon } from '@ui/icons/misc'
import { TableCellProgress } from '@ui/tables'
import { useElementLabel, useElementType } from '@editorHooks'
import { UtilizationCell } from '../../..'
import { useCalcChecksForElement } from '../../../../hooks'
import APEditCells from '../APEditCells'
import CellWithRightBorder from '../CellWithRightBorder'
import RootElementQSCell from '../RootElementQSCell'

interface Props {
  lintelCSIsSteel: boolean

  apEditDisabled: boolean

  newCS: CrossSection

  index: number
  isSelected: boolean
  onClick: () => void

  onClickTargetElementGuid?: (targetElementGuid: string) => void

  bundle: PositionBundleItem

  isOverhangApplicable?: boolean
}

const PositionFieldsTableRow = ({
  lintelCSIsSteel: rootElementIsSteel,
  newCS,
  apEditDisabled,
  index,
  isSelected,
  onClick,
  onClickTargetElementGuid,
  bundle,
  isOverhangApplicable,
}: Props): ReactElement => {
  const ripCS = bundle.targetCrossSection?.element_cs
  const ripCSIsSteel = ripCS?.material.kind === 'Steel'

  const { check, targetCrossSection, supportConfig, targetSettings, targetGuid } = bundle

  const getLabel = useElementLabel()

  const { isLoading, data: computedChecksData } = useCalcChecksForElement(
    targetGuid,
    ripCS,
    targetSettings,
    'supporting',
  )

  const elementPositionChecks = computedChecksData && computedChecksData.checks

  const elementChecksWithoutSupportCompression = useMemo(
    () =>
      reject(elementPositionChecks, check =>
        ['SupportCompression', 'SteelSupportCompression'].includes(check.check_type),
      ),
    [elementPositionChecks],
  )

  const maxUtilWithoutSupportCompression = useMemo(
    () => maxBy(elementChecksWithoutSupportCompression, check => check.max_utilization),
    [elementChecksWithoutSupportCompression],
  )

  const elementType = useElementType(check.element_guid)

  return (
    <>
      <TableRow onClick={onClick} selected={isSelected}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>
          {isLoading ? (
            <TableCellProgress />
          ) : (
            <UtilizationCell
              utilization={maxUtilWithoutSupportCompression?.max_utilization}
              checkType={maxUtilWithoutSupportCompression?.check_type}
            />
          )}
        </TableCell>
        <TableCell>
          <UtilizationCell utilization={check.max_utilization} checkType={check.check_type} />
        </TableCell>
        <TableCell>{check.relative_position.toFixed(2)}</TableCell>
        <CellWithRightBorder>
          {supportTypeToLabelShort[bundle.supportType] || bundle.supportType}
        </CellWithRightBorder>
        <TableCell>
          {onClickTargetElementGuid && (
            <Typography
              component="a"
              sx={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
              onClick={e => {
                e.stopPropagation()
                onClickTargetElementGuid(targetCrossSection.element_guid)
              }}
            >
              {targetCrossSection ? getLabel(targetCrossSection.element_guid) : 'n/a'}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          {targetCrossSection && (
            <TextField
              name={`formBundles[${index}].targetCrossSection.element_cs.material.identifier`}
              disabled={true}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  padding: '0 4px',
                },
                '& input': {
                  padding: '4px 8px',
                },
              }}
            />
          )}
        </TableCell>
        <CellWithRightBorder>
          {targetCrossSection && (
            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={1} alignItems="center">
                <RipWidthTextIcon color="blue" />
                <CentimeterField
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      padding: '0 4px',
                    },
                    '& input': {
                      padding: '4px 8px',
                    },
                  }}
                  name={`formBundles[${index}].targetCrossSection.element_cs.shape.width`}
                  data-cy={`txt-cs-width-${index}`}
                  disabled={ripCSIsSteel}
                />
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <RipHeightTextIcon color="blue" />
                <CentimeterField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        padding: '0 4px',
                      },
                      '& input': {
                        padding: '4px 8px',
                      },
                    },
                  }}
                  name={`formBundles[${index}].targetCrossSection.element_cs.shape.height`}
                  data-cy={`txt-cs-height-${index}`}
                  disabled={ripCSIsSteel}
                />
              </Stack>
            </Stack>
          )}
        </CellWithRightBorder>
        <CellWithRightBorder>
          <RootElementQSCell rootElementIsSteel={rootElementIsSteel} elementType={elementType} />
        </CellWithRightBorder>
        <APEditCells
          apEditDisabled={apEditDisabled}
          supportConfig={supportConfig}
          ripCS={ripCS}
          newCS={newCS}
          index={index}
          isOverhangApplicable={isOverhangApplicable}
        />
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell colSpan={3}>
          {maxUtilWithoutSupportCompression
            ? memberCheckTypeToReadableMap[maxUtilWithoutSupportCompression.check_type]
            : 'n/a'}
        </TableCell>
      </TableRow>
    </>
  )
}

export default PositionFieldsTableRow

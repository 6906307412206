import { ReactElement } from 'react'
import { Button } from '@mui/material'
import { UseMutationResult } from '@tanstack/react-query'
import SaveButton from '@ui/actions/SaveButton'
import { DialogBase } from '@ui/feedback'
import AssemblyForm from '../AssemblyForm'

const FORM_ID = 'assembly-create-form'

interface Props {
  open: boolean
  onClose: () => void
  mutation: UseMutationResult<CLTAssembly | TimberFrameAssembly, unknown, Assembly, unknown>
  assembly: Assembly
}

const CreateAssemblyDialog = ({ open, onClose, mutation, assembly }: Props): ReactElement => {
  const isDuplicate = !!assembly.guid
  const title = isDuplicate ? `${assembly?.name} duplizieren` : 'Neuen Aufbau erstellen'

  const handleSubmit = async (data: Assembly) => {
    await mutation.mutateAsync(data)
    onClose()
  }

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      title={title}
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
      }}
    >
      <>
        <AssemblyForm defaultValues={assembly} id={FORM_ID} onSubmit={handleSubmit} />
      </>
      <>
        <Button onClick={onClose}>Abbrechen</Button>
        <SaveButton
          loading={mutation.isLoading}
          form={FORM_ID}
          type="submit"
          data-cy="save-assembly"
        >
          Speichern
        </SaveButton>
      </>
    </DialogBase>
  )
}

export default CreateAssemblyDialog

import { countBy, find } from 'lodash-es'
import { AssemblyKind, AssemblyTypeEnum, LayerTypeEnum } from 'src/constants/assemblies'

const getTimberFrameLayer = (assembly: Assembly) => {
  const timberFrameLayer = find(assembly.layers, ['kind', LayerTypeEnum.TimberFrameLayer])
  if (!timberFrameLayer) {
    throw new Error('Could not find timber frame layer for assembly.')
  }
  return timberFrameLayer as TimberFrameLayer
}

const getCltLayer = (assembly: Assembly) => {
  const cltLayer = find(assembly.layers, ['kind', LayerTypeEnum.CLTLayer])
  if (!cltLayer) {
    throw new Error(`Could not find clt layer for assembly.`)
  }
  return cltLayer as CLTAssemblyLayer
}

export const getSlabBeamCrossSectionFromAssembly = (
  assembly: Assembly,
): CrossSection | CLTCrossSection => {
  if (assembly.kind === AssemblyKind.TimberFrame) {
    const timberFrameLayer = getTimberFrameLayer(assembly)
    const slabBeamCrossSection = timberFrameLayer.standard_rip_cs
    return slabBeamCrossSection
  } else if (assembly.kind === AssemblyKind.CLT) {
    const cltLayer = getCltLayer(assembly)
    const slabBeamCrossSection = cltLayer.clt
    return slabBeamCrossSection
  }
  throw new Error(`Unexpected assembly kind: ${assembly.kind}`)
}

export const getSlabBeamStepSizeFromAssembly = (assembly: Assembly) => {
  if (assembly.kind === AssemblyKind.TimberFrame) {
    const timberFrameLayer = getTimberFrameLayer(assembly)
    const stepSize = timberFrameLayer.rip_step_size
    return stepSize
  } else if (assembly.kind === AssemblyKind.CLT) {
    const cltLayer = getCltLayer(assembly)
    const stepSize = cltLayer.clt.width
    return stepSize
  }
  throw new Error(`Unexpected assembly kind: ${assembly.kind}`)
}

export const checkDuplicateAssemblyNames = (assemblies: Assembly[]) => {
  const shortNameCounts = countBy(assemblies, 'short_name')
  const hasDuplicate = assemblies.reduce((hasDuplicate, assembly) => {
    if (
      (assembly.usage_type === AssemblyTypeEnum.Outer ||
        assembly.usage_type === AssemblyTypeEnum.Inner) &&
      assembly.short_name !== '' &&
      shortNameCounts[assembly.short_name] > 1
    ) {
      hasDuplicate = true
    }
    return hasDuplicate
  }, false)
  // test needs to return false if something is wrong
  return !hasDuplicate
}

export const checkWallAssembliesHaveShortName = (assemblies: Assembly[]) => {
  const hasMissingShortName = assemblies.reduce((hasMissingShortName, assembly) => {
    if ([AssemblyTypeEnum.Outer, AssemblyTypeEnum.Inner].includes(assembly.usage_type)) {
      if (assembly.short_name === '') {
        hasMissingShortName = true
      }
    }
    return hasMissingShortName
  }, false)
  // test needs to return false if something is wrong
  return !hasMissingShortName
}

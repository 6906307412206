import { ReactElement, useMemo } from 'react'
import { mapValueKey } from '@editorUtils'
import { find } from 'lodash-es'
import { DataGrid } from '@mui/x-data-grid'
import { useSystemManagerStore } from '@editorStores'
import { useElementLabel } from '@editorHooks'

interface Props {
  elementGuids: string[]
}

/**
 * Use this component to display assembly information of the passed elementGuids
 */
const ElementAssembliesTable = ({ elementGuids }: Props): ReactElement => {
  const assemblies = useSystemManagerStore(state => state.assemblies)
  const assemblyAssignment = useSystemManagerStore(state => state.assemblyAssignment)

  const getLabel = useElementLabel()

  const guidToAssembly = useMemo(() => mapValueKey(assemblies), [assemblies])

  const columns = [
    { field: 'id', headerName: 'Element', flex: 0 },
    { field: 'name', headerName: 'Aufbau (Name)', flex: 1 },
    { field: 'shortName', headerName: 'Aufbau (Kurzbezeichnung)', flex: 1 },
  ]

  const elementsWithAssemblies: {
    id: string
    name: string
    shortName: string
  }[] = useMemo(() => {
    return elementGuids.map(elementGuid => {
      const assignment = find(
        assemblyAssignment,
        assignment => assignment.element_guid === elementGuid,
      )

      if (!assignment)
        return {
          id: getLabel(elementGuid),
          name: 'Fehler',
          shortName: 'Fehler',
        }

      const elementAssembly = guidToAssembly[assignment?.assembly_guid]

      return {
        id: getLabel(elementGuid),
        name: elementAssembly.name,
        shortName: elementAssembly.short_name,
      }
    })
  }, [assemblyAssignment, elementGuids, getLabel, guidToAssembly])

  return (
    <DataGrid
      rows={elementsWithAssemblies}
      columns={columns}
      hideFooterPagination
      hideFooterSelectedRowCount
      hideFooter
      autosizeOnMount
      autosizeOptions={{ includeHeaders: false, expand: true }}
      disableColumnSorting
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      density="compact"
    />
  )
}

export default ElementAssembliesTable

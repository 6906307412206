import { object, string, number, mixed, InferType, array, boolean } from 'yup'

export const usageClassOptions: Record<BaseUsageClass, string> = {
  One: 'NKL 1',
  Two: 'NKL 2',
}

export const memberCross: Record<MemberCrossSectionKind, string> = {
  CLT: 'Brettsperrholz',
  CrossSection: 'Querschnitt',
}

const cltSchema = object({
  width: number().required().default(0),
  usage_class: mixed<BaseUsageClass>()
    .oneOf(Object.keys(usageClassOptions) as BaseUsageClass[])
    .default('One'),

  // These are only for typing
  layers: array().default([]),
  board_width: number().default(0),
  unique_name: string().default('name'),
  producer: string().default('Producer'),
  identifier: string().default(''),
  description: string().default(''),
  narrow_face_bonding: boolean().default(false),
  kind: mixed<MemberCrossSectionKind>()
    .oneOf(Object.keys(memberCross) as MemberCrossSectionKind[])
    .default('CLT'),
})

const cltLayerSchema = object({
  clt: cltSchema,
  kind: mixed<CLTAssemblyLayerKind>().required().default('clt-assembly-layer'),
})

export const cltFormSchema = object({
  layer: cltLayerSchema.nullable().default(null),

  selectedProduct: cltSchema.nullable().default(null),
})

export type CLTLayerFormState = InferType<typeof cltFormSchema>
export type CLTLayerFormStateLayer = InferType<typeof cltLayerSchema>

const _check: CLTAssemblyLayer = {} as CLTLayerFormStateLayer // eslint-disable-line

import { ReactElement, useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Cameraswitch } from '@mui/icons-material'
import { useTapelineStore } from '@modugen/scene/lib'
import { ToggleButton } from '@ui/actions'
import { roofStoreyKey, useModelStore } from '@editorStores'

interface Props {
  isOrthographic: boolean
  setIsOrthographic: (ortho: boolean) => void
}

const OrthographicControls = ({ isOrthographic, setIsOrthographic }: Props): ReactElement => {
  const setStoreyVisibility = useModelStore(state => state.setStoreyVisibility)
  const setXyOnly = useTapelineStore(state => state.setXyOnly)

  useEffect(() => {
    setXyOnly(isOrthographic)
  }, [isOrthographic, setXyOnly])

  const handleClick = () => {
    // when going from 3D -> ortho, turn roof off
    // when going from ortho -> 3D, turn roof back on
    // eslint-disable-next-line no-console
    isOrthographic
      ? setStoreyVisibility(roofStoreyKey, true) // ortho -> 3D
      : setStoreyVisibility(roofStoreyKey, false) // 3D -> ortho
    // toggle state
    setIsOrthographic(!isOrthographic)
  }

  useHotkeys(
    ['o'],
    event => {
      if (event.key === 'o') {
        handleClick()
      }
    },
    { enabled: true },
    [isOrthographic],
  )

  return (
    <ToggleButton
      value="snap"
      size="small"
      onClick={handleClick}
      selected={isOrthographic}
      Icon={Cameraswitch}
      data-cy="btn-ortho-view"
    />
  )
}

export default OrthographicControls

export const checkSettingsAreSteel = (checkSettings: SettingsOnMember) => {
  return checkSettings.setting_type === 'steel-column' || checkSettings.setting_type === 'other'
}

export const crossSectionIsSteel = (crossSection: CrossSection) => {
  return crossSection.material.kind === 'Steel'
}

export const isTimberOrSteelCrossSectionKind = (
  cs: CrossSection | CLTCrossSection,
): cs is CrossSection => {
  return cs.kind === 'CrossSection'
}

export const isCLTCrossSectionKind = (
  cs: CrossSection | CLTCrossSection,
): cs is CLTCrossSection => {
  return cs.kind === 'CLT'
}

export const loadCaseTypeComparator = (v1: LoadCaseTypes, v2: LoadCaseTypes) => {
  const windLoadCaseTypesOrdered = [
    'Wind_Global_0',
    'Wind_Global_90',
    'Wind_Global_180',
    'Wind_Global_270',
  ] as LoadCaseTypes[]
  const earthquakeLoadCaseTypesOrdered = [
    'Earthquake_0',
    'Earthquake_90',
    'Earthquake_180',
    'Earthquake_270',
  ] as LoadCaseTypes[]

  if (windLoadCaseTypesOrdered.includes(v1) && windLoadCaseTypesOrdered.includes(v2)) {
    return windLoadCaseTypesOrdered.indexOf(v1) - windLoadCaseTypesOrdered.indexOf(v2)
  }

  if (earthquakeLoadCaseTypesOrdered.includes(v1) && earthquakeLoadCaseTypesOrdered.includes(v2)) {
    return earthquakeLoadCaseTypesOrdered.indexOf(v1) - earthquakeLoadCaseTypesOrdered.indexOf(v2)
  }

  return v1.localeCompare(v2)
}

import { ReactElement, useCallback, useMemo, useState } from 'react'
import { memberCheckTypeToReadableMap } from '@domainConstants'
import { RELATIVE_POSITION_PROXIMITY } from '@resultsConfig'
import { useResultsQueryParams } from '@resultsHooks'
import { isClose } from '@resultsUtils'
import { findIndex, isNull, orderBy } from 'lodash-es'
import { LoadingButton } from '@mui/lab'
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import { UseMutateFunction } from '@tanstack/react-query'
import { Form } from '@ui/forms'
import { schemaTimber, schemaTimberSlab } from '../schema'
import { getCheckTypeSortValue } from '../utils'
import MemberCheckRow from './MemberCheckRow'
import MemberHotDimensioningSettingsFormFields from './MemberHotDimensioningSettings/components'
import ReducedCrossSectionFireCheckRow from './ReducedCrossSectionFireCheckRow'
import ShearCheckRow from './ShearCheckRow'
import SupportCompressionCheckRow from './SupportCompressionCheckRow'
import VibrationCheckRow from './VibrationCheckRow'

interface Props {
  positionChecks: CombinedPositionCheck[]
  settingsOnMember: TimberCheckSettings | TimberSlabCheckSettings
  onSave: UseMutateFunction<SettingsOnMember, unknown, SettingsOnMember, unknown>
  isLoadingOnSave: boolean
  download: UseMutateFunction<
    {
      data: Blob
      filename: string
    },
    unknown,
    string,
    unknown
  >
  isLoadingDownload: boolean
  disableSaving?: boolean
  checkboxDisabled?: boolean
}

interface CheckWithIndexForSettings {
  check: CombinedPositionCheck
  index: number | undefined
}

const TimberMemberChecksTable = ({
  positionChecks,
  settingsOnMember,
  onSave,
  isLoadingOnSave,
  download,
  isLoadingDownload,
  disableSaving = false,
}: Props): ReactElement | null => {
  const [sortBy, setSortBy] = useState<'type' | 'utilization' | 'position'>('type')
  const [sortAsc, setSortAsc] = useState(true)
  const [openIndex, setOpenIndex] = useState<number | null>(null)
  const {
    actions: { setSelectedCheckPosition },
  } = useResultsQueryParams()

  const sortFilter = useCallback(
    (checkWithSetting: CheckWithIndexForSettings) => {
      const check = checkWithSetting.check
      let sortFilter: MemberCheckType | number = check.max_utilization

      if (
        sortBy === 'position' &&
        check.check_category_type !== 'Vibration' &&
        check.check_type !== 'Vibration' &&
        check.check_category_type !== 'HotDimensioning' &&
        check.check_type !== 'HotDimensioning' &&
        check.check_category_type !== 'HotDimensioningCLT' &&
        check.check_type !== 'HotDimensioningCLT'
      )
        sortFilter = check.relative_position
      if (sortBy === 'type') {
        sortFilter = getCheckTypeSortValue(check.check_type as MemberCheckType)
      }

      return sortFilter
    },
    [sortBy],
  )

  const checkWithIndex: CheckWithIndexForSettings[] = useMemo(() => {
    return positionChecks.map(check => {
      if (check.check_category_type === 'SupportCompression') {
        const supportIndex = findIndex(settingsOnMember.support_configs, supportSetting =>
          isClose(
            Number(supportSetting.relative_position),
            check.relative_position,
            RELATIVE_POSITION_PROXIMITY,
          ),
        )
        return { check: check, index: supportIndex }
      } else {
        return { check: check, index: undefined }
      }
    })
  }, [settingsOnMember, positionChecks])

  const sortedChecks = useMemo(
    () => orderBy(checkWithIndex, [sortFilter], sortAsc ? 'asc' : 'desc'),
    [checkWithIndex, sortAsc, sortFilter],
  )

  const { schema, defaultValues } = useMemo(() => {
    if (settingsOnMember.setting_type === 'timber') {
      const schema = schemaTimber
      const defaultValues = {
        configurations: settingsOnMember.support_configs,
        shear_check_settings: settingsOnMember.shear_check_settings,
        hot_dimensioning_settings: settingsOnMember.hot_dimensioning_settings,
      }
      return { schema, defaultValues }
    } else if (settingsOnMember.setting_type === 'timber-slab') {
      const schema = schemaTimberSlab
      const defaultValues = {
        configurations: settingsOnMember.support_configs,
        vibration: settingsOnMember.vibration_check_settings,
        shear_check_settings: settingsOnMember.shear_check_settings,
        hot_dimensioning_settings: settingsOnMember.hot_dimensioning_settings,
      }
      return { schema, defaultValues }
    } else throw new Error('Unexpected schema')
  }, [settingsOnMember])

  return (
    <Form
      onSubmit={({
        configurations,
        vibration,
        shear_check_settings,
        hot_dimensioning_settings,
      }) => {
        const enriched = {
          element_guid: settingsOnMember.element_guid,
          member_guid: settingsOnMember.member_guid,
          setting_type: settingsOnMember.setting_type,
          support_configs: configurations,
          vibration_check_settings: vibration,
          shear_check_settings: shear_check_settings,
          hot_dimensioning_settings: hot_dimensioning_settings,
        }
        onSave(enriched)
      }}
      validationSchema={schema}
      defaultValues={defaultValues}
      // Seems I need to provide this for the form to rerender
      key={settingsOnMember.element_guid}
    >
      <TableContainer>
        <Table stickyHeader size="small">
          <colgroup>
            <col style={{ width: '5%' }} />
            <col style={{ width: '35%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'type'}
                  direction={sortAsc ? 'asc' : 'desc'}
                  onClick={() => {
                    if (sortBy === 'type') {
                      setSortAsc(!sortAsc)
                    } else setSortBy('type')
                  }}
                >
                  <Tooltip title="Typ des Nachweises" placement="top">
                    <Typography>Typ</Typography>
                  </Tooltip>
                </TableSortLabel>
              </TableCell>

              <TableCell>
                <TableSortLabel
                  active={sortBy === 'utilization'}
                  direction={sortAsc ? 'asc' : 'desc'}
                  onClick={() => {
                    if (sortBy === 'utilization') {
                      setSortAsc(!sortAsc)
                    } else setSortBy('utilization')
                  }}
                >
                  <Tooltip title="Maximale Ausnutzung" placement="top">
                    <Typography>Ausnutzung</Typography>
                  </Tooltip>
                </TableSortLabel>
              </TableCell>

              <TableCell>
                <TableSortLabel
                  active={sortBy === 'position'}
                  direction={sortAsc ? 'asc' : 'desc'}
                  onClick={() => {
                    if (sortBy === 'position') {
                      setSortAsc(!sortAsc)
                    } else setSortBy('position')
                  }}
                >
                  <Tooltip title="Relative Position" placement="top">
                    <Typography>Position</Typography>
                  </Tooltip>
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody data-cy="member-checks-table-body">
            {sortedChecks.map(({ check, index: indexForSetting }, i) => {
              const typeReadable =
                memberCheckTypeToReadableMap[check.check_type] || check.check_type

              const key = i

              if (check.check_type === 'Vibration' && check.check_category_type === 'Vibration') {
                return (
                  <VibrationCheckRow
                    typeReadable={typeReadable}
                    check={check}
                    isOpen={i === openIndex}
                    setOpenIndex={setOpenIndex}
                    index={i}
                    key={key}
                    openCheck={setSelectedCheckPosition}
                  ></VibrationCheckRow>
                )
              } else if (
                check.check_category_type === 'SupportCompression' &&
                indexForSetting !== undefined
              ) {
                return (
                  <SupportCompressionCheckRow
                    typeReadable={typeReadable}
                    check={check}
                    isOpen={i === openIndex}
                    setOpenIndex={setOpenIndex}
                    index={i}
                    key={key}
                    supportIndex={indexForSetting}
                    disableInput={disableSaving}
                    openCheck={setSelectedCheckPosition}
                  />
                )
              } else if (
                check.check_type === 'ShearStressY' ||
                check.check_type === 'ShearStressZ' ||
                check.check_type === 'TwoWayShearStress' ||
                check.check_type === 'TwoWayShearStressCLTParallelLayer' ||
                check.check_type === 'TwoWayShearStressCLTOrthogonalLayer'
              ) {
                return (
                  <ShearCheckRow
                    isOpen={i === openIndex}
                    setOpenIndex={setOpenIndex}
                    typeReadable={typeReadable}
                    check={check}
                    index={i}
                    key={key}
                    checkboxDisabled={disableSaving}
                    openCheck={setSelectedCheckPosition}
                  ></ShearCheckRow>
                )
              } else if (
                check.check_type === 'TwoWayShearStressHot' ||
                check.check_type === 'TwoWayShearStressCLTParallelLayerHot' ||
                check.check_type === 'TwoWayShearStressCLTOrthogonalLayerHot'
              ) {
                return (
                  <ShearCheckRow
                    isOpen={i === openIndex}
                    setOpenIndex={setOpenIndex}
                    typeReadable={typeReadable}
                    check={check}
                    index={i}
                    key={key}
                    checkboxDisabled={disableSaving}
                    openCheck={setSelectedCheckPosition}
                  ></ShearCheckRow>
                )
              } else if (
                (check.check_type === 'HotDimensioning' &&
                  check.check_category_type === 'HotDimensioning') ||
                (check.check_type === 'HotDimensioningCLT' &&
                  check.check_category_type === 'HotDimensioningCLT')
              ) {
                return (
                  <ReducedCrossSectionFireCheckRow
                    isOpen={i === openIndex}
                    setOpenIndex={setOpenIndex}
                    typeReadable={typeReadable}
                    check={check}
                    index={i}
                    key={key}
                    openCheck={setSelectedCheckPosition}
                  />
                )
              } else {
                return (
                  <MemberCheckRow
                    isOpen={i === openIndex}
                    setOpenIndex={setOpenIndex}
                    typeReadable={typeReadable}
                    check={check}
                    index={i}
                    key={key}
                    openCheck={setSelectedCheckPosition}
                  ></MemberCheckRow>
                )
              }
            })}
            {positionChecks.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography align="center" m={1}>
                    Keine Nachweise auf dem Element vorhanden
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!isNull(settingsOnMember.hot_dimensioning_settings) && (
        <MemberHotDimensioningSettingsFormFields disableInput={disableSaving} />
      )}
      <Stack direction="row" justifyContent="space-between" mt={2}>
        <LoadingButton
          loading={isLoadingDownload}
          variant="outlined"
          size="small"
          onClick={() => download(settingsOnMember.element_guid)}
        >
          Export (docx)
        </LoadingButton>
        {!disableSaving && (
          <LoadingButton type="submit" variant="contained" size="small" loading={isLoadingOnSave}>
            Einstellungen speichern
          </LoadingButton>
        )}
      </Stack>
    </Form>
  )
}

export default TimberMemberChecksTable

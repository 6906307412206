import { ReactElement } from 'react'
import { elementTypeToLabelSingular } from '@domainConstants'
import { Stack, Typography } from '@mui/material'
import { CentimeterField } from '@ui/forms'

interface Props {
  rootElementIsSteel: boolean
  elementType?: ElementTypes
}

const RootElementQSCell = ({ rootElementIsSteel, elementType }: Props): ReactElement => {
  if (rootElementIsSteel) return <Typography align="center">n/a</Typography>

  if (elementType === 'rips') {
    return (
      <Stack direction="column" spacing={1} alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography fontSize="inherit" color="orange">
            B<sub>{elementType ? elementTypeToLabelSingular[elementType] : ''}</sub>
          </Typography>
          <CentimeterField
            name={`crossSection.shape.width`}
            data-cy={`element-cs-width`}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                padding: '0 4px',
              },
              '& input': {
                padding: '4px 8px',
              },
            }}
          />
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center">
          <Typography fontSize="inherit" color="orange">
            H<sub>{elementType ? elementTypeToLabelSingular[elementType] : ''}</sub>
          </Typography>
          <CentimeterField
            name={`crossSection.shape.height`}
            data-cy={`element-cs-height`}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                padding: '0 4px',
              },
              '& input': {
                padding: '4px 8px',
              },
            }}
          />
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack direction="column" spacing={1} alignItems="center">
      <Stack direction="row" spacing={1} alignItems="center">
        {'...'}
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography fontSize="inherit" color="orange">
          B<sub>{elementType ? elementTypeToLabelSingular[elementType] : ''}</sub>
        </Typography>
        <CentimeterField
          name={`crossSection.shape.width`}
          data-cy={`lintel-cs-width`}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              padding: '0 4px',
            },
            '& input': {
              padding: '4px 8px',
            },
          }}
        />
      </Stack>
    </Stack>
  )
}

export default RootElementQSCell

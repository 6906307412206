import { every } from 'lodash-es'

const lineLike: ElementTypes[] = ['beams', 'columns', 'purlins', 'rips', 'lintels']

const multiEditConfig = (elements: (ShapeObject | ShapeObjectLine | PositionForVertical)[]) => {
  const onlyOuterWalls = every(elements, el => 'type' in el && el.type === 'outer_walls')
  const onlyInnerWalls = every(elements, el => 'type' in el && el.type === 'inner_walls')
  const onlyVerticalSlabs = every(elements, el => 'type' in el && el.type === 'vertical_slabs')
  const onlyVerticalRoofSlabs = every(
    elements,
    el => 'type' in el && el.type === 'vertical_roof_slabs',
  )
  const onlyLineLike = every(
    elements,
    el => ('type' in el && lineLike.includes(el.type as ElementTypes)) || 'position_guid' in el,
  )

  return {
    onlyOuterWalls,
    onlyInnerWalls,
    onlyVerticalSlabs,
    onlyVerticalRoofSlabs,
    onlyLineLike,
  }
}

export default multiEditConfig

import { ReactElement, ReactNode } from 'react'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'

interface TooltipProps {
  enterDelay?: number
  leaveDelay?: number
  placement?: 'top' | 'bottom' | 'left' | 'right'
}

interface Props {
  isLoading: boolean
  onClick: () => void
  children: ReactNode
  tooltip?: string
  tooltipProps?: TooltipProps
}

const IconButtonProgress = ({
  isLoading,
  onClick,
  children,
  tooltip,
  tooltipProps,
}: Props): ReactElement => (
  <Tooltip title={tooltip} {...tooltipProps}>
    <IconButton onClick={onClick} disabled={isLoading}>
      {isLoading ? <CircularProgress size={18.5} /> : children}
    </IconButton>
  </Tooltip>
)

export default IconButtonProgress

import { ReactElement, useMemo } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import EditIcon from '@mui/icons-material/Edit'
import { TableCell, TableRow, IconButton } from '@mui/material'
import { DraggableProvided } from '@hello-pangea/dnd'
import { Box } from '@ui/structure'
import { layerNames } from '../../constants'
import { convertToPlotLayer } from '../../utils'

interface Props {
  layer: Layer
  assemblyType: AssemblyTypes
  onEdit: () => void
  onDelete: () => void
  isDragging: boolean
  provided: DraggableProvided
  'data-cy'?: string
}

const LayerListItem = ({
  layer,
  assemblyType,
  onEdit,
  onDelete,
  isDragging,
  provided,
  'data-cy': dataCy,
}: Props): ReactElement => {
  const isWallAssembly = assemblyType === 'Outer' || assemblyType === 'Inner'

  const conversion = useMemo(() => {
    return layer.kind ? convertToPlotLayer(layer, isWallAssembly) : ({} as PlotLayer)
  }, [layer, isWallAssembly])
  const { product, thickness, density, weight } = conversion

  return (
    <TableRow
      ref={provided.innerRef}
      {...provided.draggableProps}
      sx={({ shadows }) => ({
        ...provided.draggableProps.style,
        width: '100%',
        display: isDragging ? 'table' : 'table-row',
        background: '#fff',
        boxShadow: isDragging ? shadows[1] : 'none',
      })}
    >
      <TableCell sx={{ width: '25%' }}>
        <Box display="flex" alignItems="center">
          <Box ml={-2} mr={2} mt={0.5} {...provided.dragHandleProps}>
            <DragIndicatorIcon fontSize="small" />
          </Box>
          <span>{layer.kind && layerNames[layer.kind]}</span>
        </Box>
      </TableCell>
      <TableCell sx={{ width: '20%' }}>{product}</TableCell>
      <TableCell sx={{ width: '15%' }} align="right">
        {thickness}
      </TableCell>
      <TableCell sx={{ width: '15%' }} align="right">
        {density}
      </TableCell>
      <TableCell sx={{ width: '15%' }} align="right" data-cy={`${dataCy}-weight`}>
        {weight && weight.toFixed(3)}
      </TableCell>
      <TableCell sx={{ width: '10%' }} align="right">
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={onEdit}>
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={onDelete}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default LayerListItem

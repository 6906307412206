import { ReactElement, useMemo, useState } from 'react'
import { loadCategoryToShort, loadCaseLabels } from '@editorUtils'
import { orderBy } from 'lodash-es'
import numeral from 'numeral'
import { KeyboardArrowUp, KeyboardArrowDown, FilterAltOutlined } from '@mui/icons-material'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Tooltip,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Checkbox,
  Collapse,
  TableSortLabel,
  Stack,
  Grid,
} from '@mui/material'
import { VisibilityToggle } from '@ui/actions'
import { formatLocalForce } from '@ui/forms/LoadForceField/utils'
import { Box } from '@ui/structure'
import { LoadCaseSelect, TextButtonDropDown } from '..'
import LoadSourceLabel from '../LoadSourceLabel'

interface Props {
  lineLoads: LineLoadWithDomain[]
  selectLoad: (load: string | null) => void
  openLoad?: string | null

  hiddenLoads: string[]
  onToggleHideLoad: (load: string) => void

  onToggleShowSingleLoad: (load: string) => void

  selectedCategories: LoadCategory[]
  onSelectCategories: (categories: LoadCategory[]) => void

  loadTracingMap: LoadSource[]
}

const LineLoadTable = ({
  lineLoads,
  selectLoad,
  openLoad,
  hiddenLoads,
  onToggleHideLoad,

  onToggleShowSingleLoad,

  selectedCategories,
  onSelectCategories,

  loadTracingMap,
}: Props): ReactElement => {
  const [sortForceAsc, setSortForceAsc] = useState(false)
  const [sortByLoadCase, setSortByLoadCase] = useState(false)

  const sorted = useMemo(
    () =>
      orderBy(
        lineLoads,
        sortByLoadCase
          ? [load => load.load_case.category, load => formatLocalForce(load.start, load.domain)]
          : [load => formatLocalForce(load.end, load.domain)],
        sortForceAsc ? 'asc' : 'desc',
      ),
    [lineLoads, sortForceAsc, sortByLoadCase],
  )

  return (
    <>
      <TableContainer sx={{ minHeight: 300, overflowX: 'none' }}>
        <Table stickyHeader size="small" sx={{ borderCollapse: 'collapse' }}>
          <TableHead>
            <TableRow sx={{ '& > .MuiTableCell-root': { backgroundColor: 'transparent' } }}>
              <TableCell align="center"></TableCell>

              <TableCell align="center">
                <TableSortLabel
                  active={true}
                  direction={sortForceAsc ? 'asc' : 'desc'}
                  onClick={() => setSortForceAsc(!sortForceAsc)}
                >
                  <Tooltip title="Kraft" placement="top">
                    <Typography>Kraft</Typography>
                  </Tooltip>
                </TableSortLabel>
              </TableCell>

              <TableCell align="center">
                <TextButtonDropDown
                  title={
                    <TableSortLabel
                      active={true}
                      direction={'desc'}
                      IconComponent={FilterAltOutlined}
                      sx={{
                        alignItems: 'center',
                        alignSelf: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <Tooltip title="Lastfall" placement="top">
                        <Typography>
                          Fall
                          {selectedCategories.length !== 6 && (
                            <Typography> ({selectedCategories.length}/6)</Typography>
                          )}
                        </Typography>
                      </Tooltip>
                    </TableSortLabel>
                  }
                >
                  <Stack direction="column" spacing={2} mb={2} mt={2}>
                    <LoadCaseSelect
                      selectedCategories={selectedCategories}
                      onSelectCategories={onSelectCategories}
                    />
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography>Nach Lastfall gruppieren</Typography>
                      <Checkbox
                        checked={sortByLoadCase}
                        onChange={e => setSortByLoadCase(e.target.checked)}
                      />
                    </Stack>
                  </Stack>
                </TextButtonDropDown>
              </TableCell>

              <Tooltip title="Quelle" placement="top">
                <TableCell align={'left'}>Quelle</TableCell>
              </Tooltip>

              <Tooltip title="Start" placement="top">
                <TableCell align="center">Start</TableCell>
              </Tooltip>

              <Tooltip title="Ende" placement="top">
                <TableCell align="center">Ende</TableCell>
              </Tooltip>
              <TableCell align="center" sx={{ padding: 0 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted.map(
              ({ guid, load_case, domain, relative_start, relative_end, comment, end }) => {
                const startPosition = (domain?.length as number) * relative_start
                const endPosition = (domain?.length as number) * relative_end

                const loadType = loadCategoryToShort[load_case.category] || '-'
                const loadLabel = load_case.load_case_type
                  ? loadCaseLabels[load_case.category][load_case.load_case_type]?.label
                  : load_case.category

                const loadSource = loadTracingMap.find(
                  loadSource => loadSource.load_guid === guid,
                )?.source_guid

                return (
                  <>
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={guid}
                      sx={{
                        '& > .MuiTableCell-root': { borderBottom: 0, p: 0 },
                      }}
                    >
                      <TableCell sx={{ paddingLeft: 0 }}>
                        <IconButton
                          size="small"
                          onClick={() => selectLoad(openLoad === guid ? null : guid)}
                        >
                          {openLoad === guid ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                      </TableCell>

                      <TableCell align="center">
                        {numeral(formatLocalForce(end, domain as Domain)).format('0[.][00]a')}
                        kN/m
                      </TableCell>

                      <TableCell align="center">
                        <Tooltip
                          title={
                            loadCaseLabels[load_case.category][
                              load_case.load_case_type as LoadCaseTypes
                            ]?.label
                          }
                        >
                          <Typography>{loadType}</Typography>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="center">
                        <LoadSourceLabel source={loadSource} />
                      </TableCell>

                      <TableCell align="center">{numeral(startPosition).format('0.00')}m</TableCell>

                      <TableCell align="center">{numeral(endPosition).format('0.00')}m</TableCell>

                      <TableCell align="center" sx={{ paddingRight: 0 }}>
                        <Box display="flex" alignItems="center">
                          <VisibilityToggle
                            visible={!hiddenLoads.includes(guid)}
                            onClick={() => onToggleHideLoad(guid)}
                            onRightClick={() => onToggleShowSingleLoad(guid)}
                            size="small"
                          />
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}></TableCell>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                        <Collapse in={openLoad === guid} timeout="auto" unmountOnExit>
                          <Box sx={{ mb: 2, mt: 2, display: 'block' }}>
                            <Grid container mt={0} ml={0}>
                              <Grid xs={12}>
                                <Typography sx={{ fontSize: 14 }}>
                                  <b>Konkreter Lastfall:</b> {loadLabel}
                                </Typography>
                              </Grid>
                              <Grid xs={12}>
                                <Typography sx={{ fontSize: 14 }}>
                                  <b>Kantenlänge:</b> {numeral(domain?.length).format('0.00')}m
                                </Typography>
                              </Grid>
                              {comment && (
                                <Grid xs={12}>
                                  <Typography sx={{ fontSize: 14 }}>
                                    <b>Kommentar:</b> {comment}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                )
              },
            )}

            {sorted.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography align="center" m={1}>
                    Keine Lasten vorhanden
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default LineLoadTable

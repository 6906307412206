import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { find, maxBy, toNumber } from 'lodash-es'
import { useQuery } from '@tanstack/react-query'
import { useModelStore } from '@editorStores'
import { getSlabStiffening } from '@queries'

// Returns the storeys that should be used for the seismic calculationso
// if the upper-most slab is not-stiffening (i.e. a roof composite is applied)
// we expect one storey less for the whole configuration.
const useStoreysForSeismic = () => {
  const { projectId }: { projectId?: string } = useParams()
  const model = useModelStore(state => state.model)

  const { data: slabStiffening } = useQuery({
    queryKey: getSlabStiffening.getKey(projectId),
    queryFn: () => getSlabStiffening.request(projectId),
  })

  const upperMostCeiling = useMemo(() => maxBy(model.slabs, 'storey'), [model.slabs])
  const upperMostCeilingStiffening = find(slabStiffening, {
    element_guid: upperMostCeiling?.guid,
  })

  const storeys = Object.keys(model.storey_boundaries).map(s => toNumber(s))
  if (upperMostCeilingStiffening) {
    if (upperMostCeilingStiffening.stiffening === 'NotStiffening') {
      // Return one storey less for the seismic
      return storeys.slice(0, -1)
    }
  }
  return storeys
}

export default useStoreysForSeismic

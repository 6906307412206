import { useCallback, useMemo } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { findIndex } from 'lodash-es'
import { navigationConfig } from '../constants'

const useEditorNavigation = () => {
  const { projectId } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const currentConfig = useMemo(
    () =>
      navigationConfig.map(routeConfig => ({
        ...routeConfig,
        route: routeConfig.route(projectId as string),
      })),
    [projectId],
  )

  const currentRouteIndex = useMemo(() => {
    return findIndex(currentConfig, ({ route }) => {
      return pathname === route
    })
  }, [currentConfig, pathname])

  const handleNext = useCallback(() => {
    const { route } = currentConfig[currentRouteIndex + 1]

    if (!route) return

    navigate(route)
  }, [currentConfig, currentRouteIndex, navigate])

  const handlePrev = useCallback(() => {
    const { route } = currentConfig[currentRouteIndex - 1]

    if (!route) return

    navigate(route)
  }, [currentConfig, currentRouteIndex, navigate])

  return {
    handleNext,
    handlePrev,
    currentRoute: currentConfig[currentRouteIndex],
    currentRouteIndex,
    currentConfig,
  }
}

export default useEditorNavigation

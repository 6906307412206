import { reduce } from 'lodash-es'

/**
 * We often need to transform a list to a value key mapping. This hook does this based on a key in the data
 * @param data
 * @param key
 * @returns
 */
const mapValueKey = <T>(data: T[], key = 'guid'): Record<string, T> => {
  return reduce(
    data,
    (collector, item) => ({
      ...collector,
      // @ts-ignore
      [item[key]]: item,
    }),
    {} as Record<string, T>,
  )
}

export default mapValueKey

import { getRequest } from 'src/state/utils'

export const getProjects: RequestType = {
  key: ['getProjects'],
  request: async (): Promise<ProjectData[]> => {
    const res = await getRequest<ProjectData[]>({
      url: '/projects',
    })

    return res.data as ProjectData[]
  },
}

export const getProject: RequestTypeVariable = {
  getKey: (id: string) => ['getProject', id],
  request: async (id: string): Promise<ProjectWithLoadInfo> => {
    const res = await getRequest<ProjectWithLoadInfo>({
      url: `/projects/${id}`,
    })

    return res.data as ProjectWithLoadInfo
  },
}

export const getResponsibleEngineers: RequestType = {
  key: ['getResponsibleEngineers'],
  request: async (): Promise<string[]> => {
    const res = await getRequest<string[]>({
      url: `/all-responsible-engineers`,
    })

    return res.data as string[]
  },
}

export const getProcessorNames: RequestType = {
  key: ['getProcessorNames'],
  request: async (): Promise<string[]> => {
    const res = await getRequest<string[]>({
      url: `/all-processor-names`,
    })

    return res.data as string[]
  },
}

export const getConstructionCompanies: RequestType = {
  key: ['getConstructionCompanies'],
  request: async (): Promise<string[]> => {
    const res = await getRequest<string[]>({
      url: `/all-construction-companies`,
    })

    return res.data as string[]
  },
}

export const getBackendPerformanceSettings: RequestTypeVariable = {
  getKey: (projectId: string) => ['getBackendPerformanceSettings', projectId],
  request: async (id: string): Promise<BackendPerformanceSettings> => {
    const { data } = await getRequest<BackendPerformanceSettings>({
      url: `/projects/${id}/performance-settings`,
    })

    return data as BackendPerformanceSettings
  },
}

import { ReactElement } from 'react'
import { UnitField, UnitFieldProps } from '../UnitField'

type CentimeterFieldProps<F> = Omit<
  UnitFieldProps<F>,
  'internalUnit' | 'displayUnit' | 'numeralFormatter'
>

const CentimeterField = <F,>({ ...textFieldProps }: CentimeterFieldProps<F>): ReactElement => {
  return (
    <UnitField {...textFieldProps} internalUnit="m" displayUnit="cm" numeralFormatter="0.[0]" />
  )
}

export default CentimeterField

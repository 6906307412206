import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Box,
  Collapse,
} from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { UtilizationTextIcon, UtilizationC90TextIcon } from '@ui/icons/misc'
import { useControlStore } from '@editorStores'
import GroupedElementsRow from '../GroupedElementsRow'

interface GroupDetailViewProps {
  openRow: PositionResultsGridRow
  renderCellRepresentative: (params: GridRenderCellParams) => React.ReactNode
  renderCellExported: (params: GridRenderCellParams) => React.ReactNode
  saveCSAssignment: (data: ElementCSAssignment) => Promise<void>
  onClickUngroupElement: (guid: string) => void
}

const GroupDetailView: React.FC<GroupDetailViewProps> = ({
  openRow,
  renderCellRepresentative,
  renderCellExported,
  saveCSAssignment,
  onClickUngroupElement,
}) => {
  const isDrawerExpanded = useControlStore(state => state.isDrawerExpanded)
  return (
    <TableContainer component={Paper} sx={{ maxHeight: '55vh' }} data-cy="group-detail-view">
      <Table stickyHeader size="small" sx={{ '.MuiTableCell-root': { paddingX: 1 } }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography fontSize="small">Bauteil</Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="small">
                <UtilizationTextIcon />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="small">
                <UtilizationC90TextIcon />
              </Typography>
            </TableCell>
            {isDrawerExpanded && (
              <TableCell>
                <Typography fontSize="small">QS</Typography>
              </TableCell>
            )}
            <TableCell>
              <Typography fontSize="small">repr.</Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="small">export</Typography>
            </TableCell>
            {isDrawerExpanded && (
              <TableCell>
                <Typography fontSize="small">Kommentar</Typography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography fontSize="small" sx={{ fontWeight: 'bold' }}>
                {openRow.label}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="small" sx={{ fontWeight: 'bold' }}>
                {openRow.maxUtilWithoutSupportCompression?.max_utilization?.toFixed(2) ?? 'n/a'}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontSize="small" sx={{ fontWeight: 'bold' }}>
                {openRow.maxUtilOnlySupportCompression?.max_utilization?.toFixed(2) ?? 'n/a'}
              </Typography>
            </TableCell>
            {isDrawerExpanded && (
              <TableCell>
                <Typography fontSize="small" sx={{ fontWeight: 'bold' }}>
                  {openRow.crossSectionLabel}
                </Typography>
              </TableCell>
            )}
            <TableCell>
              <Box component="div" sx={{ fontWeight: 'bold', fontSize: 'small' }}>
                {renderCellRepresentative({ row: openRow } as GridRenderCellParams)}
              </Box>
            </TableCell>
            <TableCell>
              <Box component="div" sx={{ fontWeight: 'bold', fontSize: 'small' }}>
                {renderCellExported({ row: openRow } as GridRenderCellParams)}
              </Box>
            </TableCell>
            {isDrawerExpanded && (
              <TableCell>
                <Typography fontSize="small" sx={{ fontWeight: 'bold' }}>
                  {openRow.comment}
                </Typography>
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
              <Collapse in={true} timeout="auto" unmountOnExit>
                <GroupedElementsRow
                  aggregatorCrossSection={openRow.crossSection}
                  aggregatorCrossSectionLabel={openRow.label}
                  aggregatedElementBundles={openRow.aggregatedElementBundles}
                  onSaveCrossSectionAssignment={saveCSAssignment}
                  onClickUngroupElement={onClickUngroupElement}
                />
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default GroupDetailView

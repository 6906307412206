import { ReactElement } from 'react'
import { Button } from '@mui/material'
import { UseMutationResult } from '@tanstack/react-query'
import { SaveButton } from '@ui/actions'
import { DialogBase } from '@ui/feedback'
import ConnectionForm from '../ConnectionForm'

const FORM_ID = 'connection-create-form'

interface Props {
  open: boolean
  onClose: () => void
  connection: Connection
  mutation:
    | UseMutationResult<Connection, unknown, Connection, unknown>
    | UseMutationResult<ProjectConnection, unknown, ProjectConnection, unknown>
}

const EditConnectionDialog = ({ open, onClose, connection, mutation }: Props): ReactElement => {
  const handleSubmit = async (data: Connection | ProjectConnection) => {
    await mutation.mutateAsync(data as Connection & ProjectConnection)
    onClose()
  }

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      title={`${connection.name} editieren`}
      dialogProps={{
        maxWidth: 'lg',
        fullWidth: true,
      }}
    >
      <>
        <ConnectionForm defaultValues={connection} id={FORM_ID} onSubmit={handleSubmit} />
      </>
      <>
        <Button onClick={onClose}>Abbrechen</Button>
        <SaveButton loading={mutation.isLoading} form={FORM_ID} type="submit">
          Speichern
        </SaveButton>
      </>
    </DialogBase>
  )
}

export default EditConnectionDialog

import { ReactElement, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CircularProgress, ButtonBase, Stack } from '@mui/material'
import { useIsMutating } from '@tanstack/react-query'
import { saveSingleMemberCheckSettings } from 'src/state/mutations/checks'
import ShearCheckIndicator from '../ShearCheckIndicator'
import ShearCheckSettingsPopupForm from '../ShearCheckSettingsPopupForm'

interface Props {
  reducePointLoadsCloseToSupports: boolean
  reduceShearForce: boolean
  memberGuid: string
}

const ShearCheckCell = ({
  memberGuid,
  reducePointLoadsCloseToSupports,
  reduceShearForce,
}: Props): ReactElement => {
  const { projectId } = useParams()

  const mutationKey = useMemo(
    () => saveSingleMemberCheckSettings.getKey(projectId as string, memberGuid),
    [projectId, memberGuid],
  )

  const isLoading = useIsMutating(mutationKey)

  const [shearCheckAnchorElement, setShearCheckAnchorElement] = useState<HTMLButtonElement | null>(
    null,
  )

  return (
    <>
      {isLoading ? (
        <CircularProgress size={14} />
      ) : (
        <ButtonBase
          sx={{
            display: 'flex',
            flex: 1,
            height: '100%',
          }}
          onClick={e => setShearCheckAnchorElement(e.currentTarget)}
          data-cy="shear-check-cell"
        >
          <Stack direction="row" alignItems="center" spacing={1} display="flex" flex={1} px={2}>
            <ShearCheckIndicator
              variant={reducePointLoadsCloseToSupports ? 'enabled' : 'disabled'}
              dataCy="shear-check-indicator-reduce-point-loads"
            />
            <ShearCheckIndicator variant={reduceShearForce ? 'enabled' : 'disabled'} />
          </Stack>
        </ButtonBase>
      )}

      <ShearCheckSettingsPopupForm
        memberGuid={memberGuid}
        onClose={() => setShearCheckAnchorElement(null)}
        anchorEl={shearCheckAnchorElement || undefined}
      />
    </>
  )
}

export default ShearCheckCell

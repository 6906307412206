import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import produce from 'immer'
import { filter, find, isUndefined, reject, round } from 'lodash-es'
import { useQuery } from '@tanstack/react-query'
import { getCalcChecksForElement } from '@queries'

const useCalcChecksForElement = (
  elementGuid: string,
  newCS: CrossSection,
  checkSettings: SettingsOnMember,
  elementType: 'main' | 'supporting',
  onSuccess?: (props: {
    newSettings?: SettingsOnMember
    newChecks?: CombinedPositionCheck[]
  }) => void,
) => {
  // unpack form data
  const { setValue } = useFormContext()

  const formBundles: (PositionBundleItem | OtherElementBundleItem)[] = useWatch({
    name: 'formBundles',
  })

  const { projectId } = useParams()

  const query = useQuery(
    getCalcChecksForElement.getKey(projectId as string, elementGuid, checkSettings, newCS),
    async () =>
      getCalcChecksForElement.request(projectId as string, elementGuid, checkSettings, newCS),
    {
      onSuccess: (data: { settings?: SettingsOnMember; checks?: CombinedPositionCheck[] }) => {
        onSuccess?.({
          newSettings: data.settings,
          newChecks: data.checks,
        })
      },
    },
  )

  useEffect(() => {
    const data = query.data
    if (isUndefined(data)) return

    if (elementType === 'main') {
      setValue('checks', data.checks)
      setValue('checkSettings', data.settings)

      const elementChecksOnlySupportCompression = filter(data.checks, check =>
        ['SupportCompression', 'SteelSupportCompression'].includes(check.check_type),
      ) as SupportCompressionStructuralCheck[] | SteelCompressionCheck[]

      formBundles.forEach((bundle, idx) => {
        const correspondingCheck = find(elementChecksOnlySupportCompression, {
          relative_position: bundle.check.relative_position,
        })
        if (
          correspondingCheck &&
          // Not so nice way to check if we even have to set value
          JSON.stringify(correspondingCheck) !== JSON.stringify(formBundles[idx].check)
        ) {
          setValue(`formBundles.${idx}.check`, correspondingCheck)
        }

        if (
          data.settings?.setting_type !== 'steel-column' &&
          data.settings?.setting_type !== 'other'
        ) {
          const correspondingSupportConfig = data.settings?.support_configs.find(
            // TODO: Not perfect, but rounding should be fine, see here:
            // https://app.clickup.com/t/2590559/MDG-3506?comment=90150108322599&threadedComment=90150108328170
            config =>
              round(Number(config.relative_position), 3) ===
              round(bundle.check.relative_position, 3),
          )

          if (!correspondingSupportConfig) {
            throw new Error('Support configuration not found')
          }

          const roundedSupportConfig = produce(state => {
            // Rounded as support config is expected to be an Integer (in cm)
            state.length = round(state.length, 2)
            state.width = round(state.width, 2)
          }, correspondingSupportConfig)()

          if (
            // Not so nice way to check if we even have to set value
            JSON.stringify(roundedSupportConfig) !== JSON.stringify(formBundles[idx].supportConfig)
          ) {
            setValue(`formBundles.${idx}.supportConfig`, roundedSupportConfig)
          }
        }
      })
    } else {
      formBundles.forEach((bundle, idx) => {
        if (elementType === 'supporting' && bundle.targetGuid === elementGuid && data.checks) {
          const checksOnSupportingElement = [
            ...reject(
              formBundles[idx].checksOnSupportingElement,
              check => check.element_guid === elementGuid,
            ),
            ...data.checks,
          ]

          if (
            // Not so nice way to check if we even have to set value
            JSON.stringify(checksOnSupportingElement) !==
            JSON.stringify(formBundles[idx].checksOnSupportingElement)
          ) {
            setValue(`formBundles[${idx}].checksOnSupportingElement`, checksOnSupportingElement)
          }
        }
      })
    }
  }, [elementGuid, elementType, setValue, query.data, formBundles])

  return query
}

export default useCalcChecksForElement

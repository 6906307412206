export const elementTypeToLabelSingular: Record<ElementTypes, string> = {
  beams: 'Unterzug',
  outer_walls: 'Außenwand',
  inner_walls: 'Innenwand',
  slabs: 'Decke',
  roof_slabs: 'Dach',
  vertical_slabs: 'Deckenposition',
  vertical_roof_slabs: 'Dachposition',
  columns: 'Stütze',
  purlins: 'Pfette',
  rips: 'Rippe',
  lintels: 'Sturz',
  slab_beams: 'Deckenbalken',
  roof_slab_beams: 'Dachbalken',
  standard_rips: 'Standardrippe',
  foundation: 'Fundament',
}

export const supportTypeToLabel: Record<SupportType, string> = {
  line: 'Linienauflager',
  point: 'Punktauflager',
}

export const supportTypeToLabelShort: Record<SupportType, string> = {
  line: 'L',
  point: 'P',
}

export const memberCheckTypeToReadableMap: Record<MemberCheckType, string> = {
  BendingYZ: 'zweiachsige Biegung',
  BendingY: 'Biegung Y',
  BendingZ: 'Biegung Z',
  BendingWithTension: 'Biegung und Zug',
  BendingWithCompression: 'Biegung und Druck',
  TensionOnly: 'Zug',
  CompressionOnly: 'Druck',
  TwoWayShearStress: 'zweiachsiger Schub',
  ShearStressY: 'Schub Y',
  ShearStressZ: 'Schub Z',
  TwoWayShearStressCLTParallelLayer: 'Schub in Längslage',
  TwoWayShearStressCLTOrthogonalLayer: 'Rollschub',
  BendingYZHot: 'zweiachsige Biegung bei Brand',
  BendingWithTensionHot: 'Biegung und Zug bei Brand',
  BendingWithCompressionHot: 'Biegung und Druck bei Brand',
  TensionOnlyHot: 'Zug bei Brand',
  CompressionOnlyHot: 'Druck bei Brand',
  TwoWayShearStressHot: 'zweiachsiger Schub bei Brand',
  TwoWayShearStressCLTParallelLayerHot: 'Schub in Längslage bei Längslage',
  TwoWayShearStressCLTOrthogonalLayerHot: 'Rollschub bei Längslage',
  HotDimensioning: 'Querschnittsreduzierung bei Brand',
  HotDimensioningCLT: 'Querschnittsreduzierung bei Brand',
  DisplacementNetFinZ: 'Enddurchbiegung Z',
  DisplacementNetFinY: 'Enddurchbiegung Y',
  DisplacementNetFinYZ: 'zweiachsige Enddurchbiegung',
  DisplacementInstY: 'Durchbiegung Y',
  DisplacementInstZ: 'Durchbiegung Z',
  DisplacementInstYZ: 'Durchbiegung YZ',
  SupportCompression: 'Auflagerpressung',
  Vibration: 'Schwingung',
  SteelBasicCheck: 'Kombiniert',
  SteelDisplacement: 'Durchbiegung',
  SteelCompression: 'Druck',
  SteelSupportCompression: 'Auflagerpressung',
  SteelBasePlateWeld: 'Schweißnaht Fußplatte',
  FireProtectionRip: 'vereinfachte Heißbemessung Rippe',
}

export const assemblyKindToLabel: Record<AssemblyKind, string> = {
  'clt-assembly': 'CLT',
  'timber-frame-assembly': 'Holzrahmen',
}

import { toNumber, isUndefined, last } from 'lodash-es'
import { GridCellParams, GridTreeNode, GridColumnGroupingModel } from '@mui/x-data-grid-premium'
import { GridProSlotProps } from '@mui/x-data-grid-pro/models/gridProSlotProps'
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro'
import { formulaToNumber } from '@utils'
import {
  styledDataGridErrorClass,
  styledDataGridWarningClass,
  styledDataGridSuccessClass,
  styledDataGridHighlightClass,
} from './components/StyledDataGrid'

export const loadSources: Record<LoadSourceType, string> = {
  ripGround: 'Rippe EG',
  ripUpper: 'Rippe OG',
  beam: 'Unterzug',
  wallCorner: 'Wand über Eck',
  ceiling: 'Decke',
  ceilingBelow: 'Decke darunter',
  additional: 'weitere Auflast',
  empty: '(leeren)',
}

export const slotProps: GridProSlotProps = {
  basePopper: {
    placement: 'top-start',
  },
}

export const getCellClassName = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: GridCellParams<any, any, any, GridTreeNode>,
) => {
  if (params.field.startsWith('manual_load_')) {
    if (!isUndefined(params.value)) {
      try {
        formulaToNumber(params.value as unknown as string)
      } catch {
        return styledDataGridErrorClass
      }
    }

    // if manual load is set, manual source needs to be set as well
    const manualSourceField = `manual_source_${last(params.field)}`
    if (isUndefined(params.value) && !isUndefined(params.row[manualSourceField])) {
      return styledDataGridErrorClass
    }
  }

  // if manual source is set, manual load needs to be set as well
  if (params.field.startsWith('manual_source_')) {
    const manualLoadField = `manual_load_${last(params.field)}`
    if (isUndefined(params.value) && !isUndefined(params.row[manualLoadField])) {
      return styledDataGridErrorClass
    }

    if (params.value === loadSources.wallCorner) {
      return styledDataGridHighlightClass
    }

    if (!isUndefined(params.value) && !Object.values(loadSources).includes(params.value)) {
      return styledDataGridErrorClass
    }
  }

  if (params.field === 'load_sum' && params.formattedValue === 'Fehler') {
    return styledDataGridErrorClass
  }

  if (params.field === 'original_design_force' || params.field === 'load_sum') {
    if (toNumber(params.value) > 0) return styledDataGridWarningClass
    else if (toNumber(params.value) <= 0) return styledDataGridSuccessClass
  }

  if (params.field === 'anchor_utilization' && toNumber(params.value) > 1) {
    return styledDataGridErrorClass
  }

  return ''
}

export const gridColumnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'manual1',
    headerName: 'zusätzliche Last 1 [kN]',
    headerAlign: 'center',
    children: [{ field: 'manual_load_1' }, { field: 'manual_source_1' }],
  },
  {
    groupId: 'manual2',
    headerName: 'zusätzliche Last 2 [kN]',
    headerAlign: 'center',
    children: [{ field: 'manual_load_2' }, { field: 'manual_source_2' }],
  },
  {
    groupId: 'manual3',
    headerName: 'zusätzliche Last 3 [kN]',
    headerAlign: 'center',
    children: [{ field: 'manual_load_3' }, { field: 'manual_source_3' }],
  },
  {
    groupId: 'checks',
    headerName: 'Nachweise (Speichern erforderlich)',
    children: [{ field: 'anchor_name' }, { field: 'anchor_utilization' }, { field: 'comment' }],
  },
  {
    groupId: 'load_sum_unit',
    headerName: '[kN]',
    headerAlign: 'center',
    children: [{ field: 'load_sum' }],
  },
  {
    groupId: 'original_design_force_unit',
    headerName: '[kN]',
    headerAlign: 'center',
    children: [{ field: 'original_design_force' }],
  },
]

export const initialState: GridInitialStatePro = {
  pinnedColumns: {
    left: ['index'],
  },
  columns: {
    columnVisibilityModel: {
      manual_load_2: false,
      manual_source_2: false,
      manual_load_3: false,
      manual_source_3: false,
    },
  },
  filter: {
    filterModel: {
      items: [],
      quickFilterExcludeHiddenColumns: true,
    },
  },
}

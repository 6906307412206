import { useMemo } from 'react'
import { filter, flatten, max, toNumber } from 'lodash-es'
import { Vector3, BufferGeometry, Line } from 'three'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useModelStore } from '@editorStores'
import { useDisposeThreeObjectsOnUnmount } from '@editorHooks'
import { useFilterState } from '../../../hooks/useFilterState'
import { getSnapLinesFromPolygon } from '../utils'

type ElementSnapCornerConfig = Record<ElementTypes, boolean>

const defaultElementSnapCornerConfig = {
  outer_walls: true,
  inner_walls: true,
  slabs: true,
  roof_slabs: true,
  vertical_slabs: true,
  vertical_roof_slabs: true,
  beams: true,
  columns: true,
  purlins: true,
  rips: true,
  lintels: true,
  slab_beams: true,
  roof_slab_beams: true,
  standard_rips: true,
} as ElementSnapCornerConfig

const useModelSnapTargets = ({
  xyOnly,
  wallFilter,
  elementTypeSnapCornerConfig,
}: {
  xyOnly?: boolean
  wallFilter?: (wall: ShapeObject) => boolean
  elementTypeSnapCornerConfig?: Record<string, boolean>
} = {}) => {
  const model = useModelStore(state => state.model)
  const {
    showBeams,
    showColumns,
    showPurlins,
    showSlabs,
    showRoofSlabs,
    showVerticalSlabs,
    showVerticalRoofSlabs,
    showRips,
  } = useFilterState()
  const storeys = useModelStore(state => state.visibleStoreys)

  const maxStorey = useMemo(
    () => max(Object.keys(model.storey_boundaries).map(s => toNumber(s)))?.toString() as string,
    [model],
  )

  const filteredWalls = useMemo(
    () =>
      storeys
        ? filter(
            model.walls,
            wall => storeys.has(wall.storey) && (wallFilter ? wallFilter(wall) : true),
          )
        : model.walls,
    [model, storeys, wallFilter],
  )

  // use defaults and then overwrite with function input
  const fullElementTypeSnapCornerConfigFilled = useMemo(
    () => ({
      ...defaultElementSnapCornerConfig,
      ...elementTypeSnapCornerConfig,
    }),
    [elementTypeSnapCornerConfig],
  )

  const wallSnapTargets = useMemo(
    () =>
      flatten(
        filteredWalls.map(({ guid, shape }) => {
          const lines = shape.points.map((start, i) => {
            const end: Point = i + 1 === shape.points.length ? shape.points[0] : shape.points[i + 1]

            const p1 = new Vector3(start.x, start.y, start.z)
            const p2 = new Vector3(end.x, end.y, end.z)

            if (xyOnly) {
              p1.setZ(0)
              p2.setZ(0)
            }

            const geometry = new BufferGeometry().setFromPoints([p1, p2])
            const line = new Line(geometry)

            line.userData.guid = guid

            return line
          })

          return lines
        }),
      ),
    [filteredWalls, xyOnly],
  )

  useDisposeThreeObjectsOnUnmount(wallSnapTargets)

  const snapRipTargets = useMemo(() => {
    if (fullElementTypeSnapCornerConfigFilled && !fullElementTypeSnapCornerConfigFilled.rips)
      return []
    if (!showRips) return []
    const filteredWallGuids = filteredWalls.map(wall => wall.guid)

    const filteredRips = filter(model.rips, rips => filteredWallGuids.includes(rips.wall_guid))

    const snapTargets = filteredRips.map(({ position_guid, end, start }) => {
      const p1 = xyOnly ? new ImmutableVector3(start.x, start.y, 0) : start
      const p2 = xyOnly ? new ImmutableVector3(end.x, end.y, 0) : end

      const geometry = new BufferGeometry().setFromPoints([p1.v, p2.v])
      const line = new Line(geometry)

      line.userData.guid = position_guid

      return line
    })

    return flatten(snapTargets)
  }, [model.rips, showRips, xyOnly, fullElementTypeSnapCornerConfigFilled, filteredWalls])

  useDisposeThreeObjectsOnUnmount(snapRipTargets)

  const snapColumnTargets = useMemo(() => {
    if (fullElementTypeSnapCornerConfigFilled && !fullElementTypeSnapCornerConfigFilled.columns)
      return []
    if (!showColumns) return []

    const filteredColumns = storeys
      ? filter(model.columns, column => storeys.has(column.storey))
      : model.columns

    const snapTargets = filteredColumns.map(({ guid, shape: { end, start } }) => {
      const p1 = xyOnly ? new ImmutableVector3(start.x, start.y, 0) : start
      const p2 = xyOnly ? new ImmutableVector3(end.x, end.y, 0) : end

      const geometry = new BufferGeometry().setFromPoints([p1.v, p2.v])
      const line = new Line(geometry)

      line.userData.guid = guid

      return line
    })

    return flatten(snapTargets)
  }, [model, showColumns, xyOnly, storeys, fullElementTypeSnapCornerConfigFilled])

  useDisposeThreeObjectsOnUnmount(snapColumnTargets)

  const snapBeamTargets = useMemo(() => {
    if (fullElementTypeSnapCornerConfigFilled && !fullElementTypeSnapCornerConfigFilled.beams)
      return []
    if (!showBeams) return []

    const filteredBeams = storeys
      ? filter(model.beams, beam => storeys.has(beam.storey))
      : model.beams

    const snapTargets = filteredBeams.map(({ guid, shape: { end, start } }) => {
      const p1 = xyOnly ? new ImmutableVector3(start.x, start.y, 0) : start
      const p2 = xyOnly ? new ImmutableVector3(end.x, end.y, 0) : end

      const geometry = new BufferGeometry().setFromPoints([p1.v, p2.v])
      const line = new Line(geometry)

      line.userData.guid = guid

      return line
    })

    return flatten(snapTargets)
  }, [model, showBeams, xyOnly, storeys, fullElementTypeSnapCornerConfigFilled])

  useDisposeThreeObjectsOnUnmount(snapBeamTargets)

  const snapSlabTargets = useMemo(() => {
    if (fullElementTypeSnapCornerConfigFilled && !fullElementTypeSnapCornerConfigFilled.slabs)
      return []
    const slabs = showSlabs ? [...model.slabs] : []
    const filteredSlabs = storeys ? filter(slabs, slab => storeys.has(slab.storey)) : slabs
    const targets = filteredSlabs.map(slab => getSnapLinesFromPolygon(slab, xyOnly))

    return flatten(targets)
  }, [model, showSlabs, xyOnly, fullElementTypeSnapCornerConfigFilled, storeys])

  const snapVerticalSlabTargets = useMemo(() => {
    if (
      fullElementTypeSnapCornerConfigFilled &&
      !fullElementTypeSnapCornerConfigFilled.vertical_slabs
    )
      return []
    const verticalSlabs = showVerticalSlabs ? [...model.vertical_slabs] : []
    const filteredSlabs = storeys
      ? filter(verticalSlabs, slab => storeys.has(slab.storey))
      : verticalSlabs
    const targets = filteredSlabs.map(slab => getSnapLinesFromPolygon(slab, xyOnly))

    return flatten(targets)
  }, [model, showVerticalSlabs, xyOnly, fullElementTypeSnapCornerConfigFilled, storeys])

  useDisposeThreeObjectsOnUnmount(snapVerticalSlabTargets)

  // ROOF ELEMENTS

  const snapRoofSlabTargets = useMemo(() => {
    if (fullElementTypeSnapCornerConfigFilled && !fullElementTypeSnapCornerConfigFilled.roof_slabs)
      return []
    if (!showRoofSlabs) return []

    if (!storeys.has('Dach')) return []

    const targets = model.roof_slabs.map(slab => getSnapLinesFromPolygon(slab, xyOnly))

    return flatten(targets)
  }, [model, showRoofSlabs, xyOnly, storeys, fullElementTypeSnapCornerConfigFilled])

  useDisposeThreeObjectsOnUnmount(snapRoofSlabTargets)

  const verticalRoofSlabSnapTargets = useMemo(() => {
    if (
      fullElementTypeSnapCornerConfigFilled &&
      !fullElementTypeSnapCornerConfigFilled.vertical_roof_slabs
    )
      return []
    if (!showVerticalRoofSlabs) return []
    if (!storeys.has(maxStorey)) return []
    const targets = model.vertical_roof_slabs.map(slab => getSnapLinesFromPolygon(slab, xyOnly))

    return flatten(targets)
  }, [
    model,
    showVerticalRoofSlabs,
    xyOnly,
    storeys,
    maxStorey,
    fullElementTypeSnapCornerConfigFilled,
  ])

  useDisposeThreeObjectsOnUnmount(verticalRoofSlabSnapTargets)

  const snapPurlinTargets = useMemo(() => {
    if (fullElementTypeSnapCornerConfigFilled && !fullElementTypeSnapCornerConfigFilled.purlins)
      return []
    if (!showPurlins) return []

    if (!storeys.has(maxStorey)) return []

    const snapTargets = model.purlins.map(({ guid, shape: { end, start } }) => {
      const p1 = xyOnly ? new ImmutableVector3(start.x, start.y, 0) : start
      const p2 = xyOnly ? new ImmutableVector3(end.x, end.y, 0) : end

      const geometry = new BufferGeometry().setFromPoints([p1.v, p2.v])
      const line = new Line(geometry)

      line.userData.guid = guid

      return line
    })

    return flatten(snapTargets)
  }, [model, showPurlins, xyOnly, storeys, maxStorey, fullElementTypeSnapCornerConfigFilled])

  useDisposeThreeObjectsOnUnmount(snapPurlinTargets)

  return [
    ...wallSnapTargets,
    ...snapColumnTargets,
    ...snapBeamTargets,
    ...snapSlabTargets,
    ...snapVerticalSlabTargets,
    ...snapPurlinTargets,
    ...snapRoofSlabTargets,
    ...verticalRoofSlabSnapTargets,
    ...snapRipTargets,
  ]
}

export default useModelSnapTargets

import { ReactElement, useMemo } from 'react'
// TODO: move to global utils
import { makeCrossSectionLabel } from '@resultsUtils'
import { DataGrid } from '@mui/x-data-grid'
import { useElementLabel, useGuidToCrossSection } from '@editorHooks'
import { usageClassToLabel } from 'src/constants/elements'

interface Props {
  elementGuids: string[]
}

/**
 * Use this component to display cross section information of the passed
 * elementGuids
 */
const ElementCSTable = ({ elementGuids }: Props): ReactElement => {
  const guidToCrossSection = useGuidToCrossSection()

  const getLabel = useElementLabel()

  const columns = [
    { field: 'id', headerName: 'Element', flex: 0 },
    { field: 'qs', headerName: 'Querschnitt', flex: 1 },
    { field: 'nkl', headerName: 'Nutzungsklasse', flex: 1 },
  ]

  const elementsWithQS: {
    id: string
    qs: string
    nkl: string
  }[] = useMemo(() => {
    return elementGuids.map(elementGuid => {
      const cs = guidToCrossSection[elementGuid]

      if (!cs)
        return {
          id: getLabel(elementGuid),
          qs: 'Fehler',
          nkl: 'Fehler',
        }

      const crossSectionLabel = makeCrossSectionLabel(cs)

      return {
        id: getLabel(elementGuid),
        qs: crossSectionLabel || 'Fehler',
        nkl: usageClassToLabel[cs.usage_class],
      }
    })
  }, [elementGuids, getLabel, guidToCrossSection])

  return (
    <DataGrid
      rows={elementsWithQS}
      columns={columns}
      hideFooterPagination
      hideFooterSelectedRowCount
      hideFooter
      autosizeOnMount
      autosizeOptions={{ includeHeaders: false, expand: true }}
      disableColumnSorting
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      density="compact"
    />
  )
}

export default ElementCSTable

import { styled } from '@mui/material'
import { DataGridPremium, gridClasses } from '@mui/x-data-grid-premium'

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '& .MuiDataGrid-cell': {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
  },
  [`.${gridClasses.cell}.slightly-overutilized`]: {
    backgroundColor: theme.results.utilization.slightlyOverutilized.light,
  },
  [`.${gridClasses.cell}.overutilized`]: {
    backgroundColor: theme.results.utilization.overutilized.light,
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
}))

export default StyledDataGrid

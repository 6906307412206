import { ChangeEvent, ReactElement, useMemo, useState } from 'react'
import chunk from 'lodash-es/chunk'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
} from '@mui/material'
import { UseMutationResult } from '@tanstack/react-query'
import { Box } from '@ui/structure'
import { connectionSchema } from '../ConnectionForm/schema'
import ConnectionListItem from '../ConnectionListItem'
import CreateConnectionDialog from '../CreateConnectionDialog'
import EditConnectionDialog from '../EditConnectionDialog'

interface Props {
  connections: Array<Connection | ProjectConnection>
  onDelete: (connection: Connection | ProjectConnection) => void
  createMutation:
    | UseMutationResult<Connection, unknown, Connection, unknown>
    | UseMutationResult<ProjectConnection, unknown, ProjectConnection, unknown>
  editMutation:
    | UseMutationResult<Connection, unknown, Connection, unknown>
    | UseMutationResult<ProjectConnection, unknown, ProjectConnection, unknown>
  defaultRowsPerPage?: 5 | 10 | 25
}

const ConnectionList = ({
  connections,
  onDelete,
  createMutation,
  editMutation,
  defaultRowsPerPage = 25,
}: Props): ReactElement => {
  const [createValues, setCreateValues] = useState<Connection | null>(null)
  const [editValues, setEditValues] = useState<Connection | null>(null)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage)

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const SelectButton = (
    <Button
      onClick={() =>
        setCreateValues({
          ...connectionSchema.getDefault(),
        })
      }
      variant="outlined"
      color="primary"
      size="small"
      sx={{
        alignSelf: 'center',
      }}
      data-cy="create-connection"
    >
      anlegen
    </Button>
  )

  const chunks = useMemo(() => chunk(connections, rowsPerPage), [connections, rowsPerPage])
  const connectionsInPage = chunks[page] || chunks[page - 1] || []

  const hasConnections = !!connections.length

  return (
    <>
      <Box>
        <Box display="flex" mb={1} justifyContent="space-between">
          <Typography variant="h6" mb={1}>
            Zugverbinder {`(${connections.length})`}
          </Typography>
          {hasConnections && SelectButton}
        </Box>
        {!hasConnections && (
          <Paper>
            <Box padding={3} textAlign="center">
              <Typography>Sie haben noch keine Zugverbindungen angelegt</Typography>
              <Box mt={2}>{SelectButton}</Box>
            </Box>
          </Paper>
        )}
        {hasConnections && (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Kommentar im Export</TableCell>
                    <TableCell>
                      {
                        <>
                          R<sub>d</sub> Erdbeben (kN)
                        </>
                      }
                    </TableCell>
                    <TableCell>
                      {
                        <>
                          R<sub>d</sub> Wind (kN)
                        </>
                      }
                    </TableCell>
                    <TableCell align="right">Aktionen</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {connectionsInPage.map(connection => {
                    return (
                      <ConnectionListItem
                        key={connection.guid}
                        connection={connection}
                        onDelete={() => onDelete(connection)}
                        onEdit={() => setEditValues(connection)}
                      />
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage="Zugverbindungen pro Seite"
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}–${to} von ${count !== -1 ? count : `mehr als ${to}`}`
              }}
              component="div"
              count={connections.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(e, p) => setPage(p)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Box>
      {createValues && (
        <CreateConnectionDialog
          mutation={createMutation}
          open
          onClose={() => setCreateValues(null)}
          connection={createValues}
        />
      )}
      {editValues && (
        <EditConnectionDialog
          mutation={editMutation}
          open
          onClose={() => setEditValues(null)}
          connection={editValues}
        />
      )}
    </>
  )
}

export default ConnectionList

import { first } from 'lodash-es'
import { create, all } from 'mathjs'
import {
  ABS,
  ACOS,
  ACOSH,
  ACOT,
  ACOTH,
  AGGREGATE,
  ARABIC,
  ASIN,
  ASINH,
  ATAN,
  ATAN2,
  ATANH,
  BASE,
  CEILING,
  CEILINGMATH,
  CEILINGPRECISE,
  COMBIN,
  COMBINA,
  COS,
  COSH,
  COT,
  COTH,
  CSC,
  CSCH,
  DECIMAL,
  EVEN,
  EXP,
  FACT,
  FACTDOUBLE,
  FLOOR,
  FLOORMATH,
  FLOORPRECISE,
  GCD,
  INT,
  ISEVEN,
  ISODD,
  LCM,
  LN,
  LOG,
  LOG10,
  MOD,
  MROUND,
  MULTINOMIAL,
  ODD,
  POWER,
  PRODUCT,
  QUOTIENT,
  RADIANS,
  RAND,
  RANDBETWEEN,
  ROUND,
  ROUNDDOWN,
  ROUNDUP,
  SEC,
  SECH,
  SIGN,
  SIN,
  SINH,
  SQRT,
  SQRTPI,
  SUBTOTAL,
  SUM,
  SUMIF,
  SUMIFS,
  SUMPRODUCT,
  SUMSQ,
  SUMX2MY2,
  SUMX2PY2,
  SUMXMY2,
  TAN,
  TANH,
  TRUNC,
  AVEDEV,
  AVERAGE,
  AVERAGEA,
  AVERAGEIF,
  AVERAGEIFS,
  BETADIST,
  BETAINV,
  BINOMDIST,
  CORREL,
  COUNT,
  COUNTA,
  COUNTBLANK,
  COUNTIF,
  COUNTIFS,
  UNIQUE,
  COVARIANCEP,
  COVARIANCES,
  DEVSQ,
  EXPONDIST,
  FDIST,
  FINV,
  FISHER,
  FISHERINV,
  FORECAST,
  FREQUENCY,
  GAMMA,
  GAMMALN,
  GAUSS,
  GEOMEAN,
  GROWTH,
  HARMEAN,
  HYPGEOMDIST,
  INTERCEPT,
  KURT,
  LARGE,
  LINEST,
  LOGNORMDIST,
  LOGNORMINV,
  MAX,
  MAXA,
  MEDIAN,
  MIN,
  MINA,
  MODEMULT,
  MODESNGL,
  NORMDIST,
  NORMINV,
  NORMSDIST,
  NORMSINV,
  PEARSON,
  PERCENTILEEXC,
  PERCENTILEINC,
  PERCENTRANKEXC,
  PERCENTRANKINC,
  PERMUT,
  PERMUTATIONA,
  PHI,
  POISSONDIST,
  PROB,
  QUARTILEEXC,
  QUARTILEINC,
  RANKAVG,
  RANKEQ,
  RSQ,
  SKEW,
  SKEWP,
  SLOPE,
  SMALL,
  STANDARDIZE,
  STDEVA,
  STDEVP,
  STDEVS,
  STEYX,
  TDIST,
  TINV,
  TRIMMEAN,
  VARA,
  VARP,
  VARPA,
  VARS,
  WEIBULLDIST,
  ZTEST,
} from '@formulajs/formulajs'

const mathJsExcelLike = create(all)

mathJsExcelLike.import({
  ABS,
  ACOS,
  ACOSH,
  ACOT,
  ACOTH,
  AGGREGATE,
  ARABIC,
  ASIN,
  ASINH,
  ATAN,
  ATAN2,
  ATANH,
  BASE,
  CEILING,
  CEILINGMATH,
  CEILINGPRECISE,
  COMBIN,
  COMBINA,
  COS,
  COSH,
  COT,
  COTH,
  CSC,
  CSCH,
  DECIMAL,
  EVEN,
  EXP,
  FACT,
  FACTDOUBLE,
  FLOOR,
  FLOORMATH,
  FLOORPRECISE,
  GCD,
  INT,
  ISEVEN,
  ISODD,
  LCM,
  LN,
  LOG,
  LOG10,
  MOD,
  MROUND,
  MULTINOMIAL,
  ODD,
  POWER,
  PRODUCT,
  QUOTIENT,
  RADIANS,
  RAND,
  RANDBETWEEN,
  ROUND,
  ROUNDDOWN,
  ROUNDUP,
  SEC,
  SECH,
  SIGN,
  SIN,
  SINH,
  SQRT,
  SQRTPI,
  SUBTOTAL,
  SUM,
  SUMIF,
  SUMIFS,
  SUMPRODUCT,
  SUMSQ,
  SUMX2MY2,
  SUMX2PY2,
  SUMXMY2,
  TAN,
  TANH,
  TRUNC,
  AVEDEV,
  AVERAGE,
  AVERAGEA,
  AVERAGEIF,
  AVERAGEIFS,
  BETADIST,
  BETAINV,
  BINOMDIST,
  CORREL,
  COUNT,
  COUNTA,
  COUNTBLANK,
  COUNTIF,
  COUNTIFS,
  UNIQUE,
  COVARIANCEP,
  COVARIANCES,
  DEVSQ,
  EXPONDIST,
  FDIST,
  FINV,
  FISHER,
  FISHERINV,
  FORECAST,
  FREQUENCY,
  GAMMA,
  GAMMALN,
  GAUSS,
  GEOMEAN,
  GROWTH,
  HARMEAN,
  HYPGEOMDIST,
  INTERCEPT,
  KURT,
  LARGE,
  LINEST,
  LOGNORMDIST,
  LOGNORMINV,
  MAX,
  MAXA,
  MEDIAN,
  MIN,
  MINA,
  MODEMULT,
  MODESNGL,
  NORMDIST,
  NORMINV,
  NORMSDIST,
  NORMSINV,
  PEARSON,
  PERCENTILEEXC,
  PERCENTILEINC,
  PERCENTRANKEXC,
  PERCENTRANKINC,
  PERMUT,
  PERMUTATIONA,
  PHI,
  POISSONDIST,
  PROB,
  QUARTILEEXC,
  QUARTILEINC,
  RANKAVG,
  RANKEQ,
  RSQ,
  SKEW,
  SKEWP,
  SLOPE,
  SMALL,
  STANDARDIZE,
  STDEVA,
  STDEVP,
  STDEVS,
  STEYX,
  TDIST,
  TINV,
  TRIMMEAN,
  VARA,
  VARP,
  VARPA,
  VARS,
  WEIBULLDIST,
  ZTEST,
})

const formulaToNumber = (formula: string) => {
  const shortenedFormula = first(formula) === '=' ? formula.slice(1) : formula

  return mathJsExcelLike.evaluate(shortenedFormula)
}

export default formulaToNumber

import { ReactElement, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Box, IconButton, Stack } from '@mui/material'
import { useModelStore } from '@editorStores'
import { useEditorNavigation } from '@editorHooks'
import { useUIStore } from 'src/state/stores'
import NavigationItem from './components/NavigationItem'
import ProjectState from './components/ProjectState'
import { Drawer, CloseIcon } from './styles'

interface Props {
  transparent?: boolean
}

const ProcessNavigation = ({ transparent = true }: Props): ReactElement => {
  const open = useUIStore(state => state.editorNavOpen)
  const toggleOpen = useUIStore(state => state.toggleEditorNavOpen)
  const { currentRouteIndex, currentConfig } = useEditorNavigation()
  const navigate = useNavigate()

  const project = useModelStore(state => state.project)

  return (
    <Drawer variant="permanent" open={open} transparent={!open && transparent}>
      <Box display="flex" flexDirection="column" width="100%" alignItems="start" px={1.5}>
        {currentConfig.map(({ icon: Icon, route, label }, index) => {
          const active = currentRouteIndex === index

          return (
            <NavigationItem
              key={route + label}
              onClick={() => navigate(route)}
              open={open}
              active={active}
              transparent={transparent}
              label={label}
              icon={<Icon width={20} height={20} />}
            >
              {route.endsWith('global-loads') && (
                <ProjectState
                  open={open}
                  project={project}
                  onClick={(e: MouseEvent, tab: string) => {
                    e.stopPropagation()
                    navigate(`${route}?tab=${tab}`)
                  }}
                />
              )}
            </NavigationItem>
          )
        })}
      </Box>

      <Stack direction="column" width="100%" alignItems="start" px={1.5}>
        <IconButton onClick={() => toggleOpen()} className="open-nav" sx={{ alignSelf: 'center' }}>
          <CloseIcon open={open}>
            <ArrowRightAltIcon fontSize="small" />
          </CloseIcon>
        </IconButton>
      </Stack>
    </Drawer>
  )
}

export default ProcessNavigation

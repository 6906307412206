import { ReactElement } from 'react'
import { UnitField, UnitFieldProps } from '../UnitField'

type MillimeterFieldProps<F extends object | never> = Omit<
  UnitFieldProps<F>,
  'internalUnit' | 'displayUnit' | 'numeralFormatter'
> & {
  numeralFormatter?: string
}

const MillimeterField = <F extends object | never>({
  numeralFormatter = '0',
  ...textFieldProps
}: MillimeterFieldProps<F>): ReactElement => {
  return (
    <UnitField
      {...textFieldProps}
      internalUnit="m"
      displayUnit="mm"
      numeralFormatter={numeralFormatter}
    />
  )
}

export default MillimeterField

import { getRequest } from '../utils'

export const getElementCrossSections: RequestType = {
  key: ['getElementCrossSections'],
  request: async (): Promise<ElementCS[]> => {
    const { data } = await getRequest<ElementCS[]>({
      url: `/element-cross-sections`,
    })

    return data as ElementCS[]
  },
}

export const getProjectElementCrossSections: RequestTypeVariable = {
  getKey: id => ['getProjectElementCrossSections', id],
  request: async (id: string): Promise<ElementCS[]> => {
    const { data } = await getRequest<ElementCS[]>({
      url: `/projects/${id}/element-cross-sections`,
    })

    return data as ElementCS[]
  },
}

export const getElementCrossSectionsSelection: RequestTypeVariable = {
  getKey: id => ['getElementCrossSectionsSelection', id],
  request: async (id: string): Promise<ElementCSSelection> => {
    const { data } = await getRequest<ElementCSSelection>({
      url: `/projects/${id}/selected-element-cross-sections`,
    })

    return data as ElementCSSelection
  },
}

export const getElementCrossSectionAssignment: RequestTypeVariable = {
  getKey: id => ['getElementCrossSectionAssignment', id],
  request: async (id: string): Promise<ElementCSAssignment[]> => {
    const { data } = await getRequest<CrossSectionOnElement[]>({
      url: `/projects/${id}/cross-section-assignment`,
    })
    const dataOut = data?.map(element => ({ ...element, element_cs: element }))

    return dataOut as ElementCSAssignment[]
  },
}

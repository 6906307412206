import { LoadingButton } from '@mui/lab'
import { Stack, Button, Typography } from '@mui/material'
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium'
import { SaveButton } from '@ui/actions'
import { EditMode } from '../../misc'

interface Props {
  onSave: () => void
  isLoading: boolean
  editMode: EditMode
  onCancelEdit: () => void
  isDownloadingExportDocument: boolean
  onClickDownloadExportDocument: () => void
  positionType: PositionGroupingType
}

const CustomToolbar = ({
  onSave,
  isLoading,
  editMode,
  onCancelEdit,
  isDownloadingExportDocument,
  onClickDownloadExportDocument,
  positionType,
}: Props) => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter />
      <Stack direction="row" spacing={2} alignItems="center" sx={{ ml: 0.5, my: 0.5 }}>
        <SaveButton
          loading={isLoading}
          onClick={onSave}
          size="small"
          data-cy={
            editMode === EditMode.Grouping ? 'btn-submit-position-group' : 'btn-submit-results-grid'
          }
        >
          {editMode === EditMode.Grouping ? 'Gruppierung speichern' : 'Speichern'}
        </SaveButton>
        {editMode === EditMode.Grouping && (
          <>
            <Button variant="outlined" size="small" onClick={onCancelEdit}>
              Abbrechen
            </Button>
            <Typography>
              Modus Gruppieren: Wähle die Bauteile aus, die gruppiert werden sollen
            </Typography>
          </>
        )}
        <LoadingButton
          loading={isDownloadingExportDocument}
          variant="contained"
          size="small"
          onClick={onClickDownloadExportDocument}
          data-cy={`download-${positionType}-btn`}
        >
          Bauteilliste exportieren
        </LoadingButton>
      </Stack>
    </GridToolbarContainer>
  )
}

export default CustomToolbar

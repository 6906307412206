import { useCallback } from 'react'
import { isUndefined, isNull } from 'lodash-es'
import { useElementLabel } from '@editorHooks'

const useSegmentLabel = () => {
  const getLabel = useElementLabel()

  return useCallback(
    (value: string, elementGuid: string) => {
      if (isUndefined(value) || isNull(value)) return value

      // in order to make the column smaller we remove the duplicate
      // information (the element label) that is already shown in the element
      // column
      const label = getLabel(value)
      const elementLabel = getLabel(elementGuid)
      if (label.startsWith(elementLabel) && label !== elementLabel) {
        const shortLabel = label.substring(elementLabel.length)
        return shortLabel.startsWith('.') ? shortLabel.substring(1) : shortLabel
      }

      return label
    },
    [getLabel],
  )
}

export default useSegmentLabel

import { ReactElement } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, SxProps, Theme } from '@mui/material'

interface Props {
  visible: boolean
  onClick: (visible: boolean, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onRightClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  size?: 'small' | 'medium' | 'large'
  sx?: SxProps<Theme>
}

const VisibilityToggle = ({ visible, onClick, onRightClick, size, sx }: Props): ReactElement => {
  if (visible)
    return (
      <IconButton
        onClick={event => onClick(false, event)}
        onContextMenu={event => {
          event.preventDefault()
          onRightClick?.(event)
        }}
        sx={sx}
      >
        <Visibility fontSize={size} color="success" />
      </IconButton>
    )

  return (
    <IconButton
      onClick={event => onClick(true, event)}
      onContextMenu={event => {
        event.preventDefault()
        onRightClick?.(event)
      }}
      sx={sx}
    >
      <VisibilityOff fontSize={size} color="disabled" />
    </IconButton>
  )
}

export default VisibilityToggle

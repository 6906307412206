import produce from 'immer'
import { filter, round } from 'lodash-es'
import { Warning } from '@mui/icons-material'
import { Tooltip, Typography } from '@mui/material'
import { convertUnit } from 'src/utils/units'

export const renderAsCM = (value: number, numeralFormatter = '0.[0]', showUnit = true) => {
  const internalValue = 'm'
  const displayUnit = 'cm'
  const convertedValueAsString = convertUnit(value, numeralFormatter, internalValue, displayUnit)
  const renderValue = `${convertedValueAsString}${showUnit ? displayUnit : ''}`
  return renderValue
}

export const OverhangOutOfRangeTooltip = () => {
  return (
    <Tooltip title="Überstand außerhalb des gewöhnlichen Wertebereichs des EC 5">
      <Warning fontSize="small" sx={{ verticalAlign: 'middle', color: 'red' }} />
    </Tooltip>
  )
}

export const OverhangValue = ({ overhang }: { overhang: number }) => {
  const isOutOfRange = overhang < 0 || overhang > 0.06
  const overhangCM = renderAsCM(overhang, '0', false)
  return <Typography color={isOutOfRange ? 'red' : 'inherit'}>{overhangCM}</Typography>
}

export const renderOverhangFromCS = (
  ripCS: CrossSection,
  supportGeometry: SupportGeometry,
  showUnit = true,
) => {
  const overhang = calculateOverhangLintelToRip(supportGeometry, ripCS)
  return renderOverhang(overhang, showUnit)
}

export const renderOverhang = (value: number | undefined, showUnit = true) => {
  if (value === undefined) return 'n/a'

  const overhangCM = renderAsCM(value, '0', showUnit)
  const isOutOfRange = value < 0 || value > 0.06

  return (
    <Typography color={isOutOfRange ? 'error' : 'inherit'} alignItems="center">
      {isOutOfRange && (
        <Tooltip title="Überstand außerhalb des gewöhnlichen Wertebereichs des EC 5">
          <Warning fontSize="small" sx={{ verticalAlign: 'middle' }} />
        </Tooltip>
      )}
      {overhangCM}
    </Typography>
  )
}

export const getDefaultFormValues = (
  elementGuidToCrossSection: Record<string, ElementCSAssignment>,
  elementGuidToCheckSettings: Record<string, SettingsOnMember>,
  checkSettings: SettingsOnMember,
  supportCompressionChecks: SupportCompressionStructuralCheck[] | SteelCompressionCheck[],
  elementsSupportingSelected: TargetGuidAndRelativePosition[],
  structuralChecks: CombinedPositionCheck[],
) => {
  let formBundles: CombinedBundleItem[] = []
  if (checkSettings.setting_type !== 'steel-column' && checkSettings.setting_type !== 'other') {
    formBundles = supportCompressionChecks.map(check => {
      const supportConfig = checkSettings.support_configs.find(
        // TODO: Not perfect, but rounding should be fine, see here:
        // https://app.clickup.com/t/2590559/MDG-3506?comment=90150108322599&threadedComment=90150108328170
        config => round(Number(config.relative_position), 3) === round(check.relative_position, 3),
      )

      if (!supportConfig) {
        throw new Error('Support configuration not found')
      }

      const target = elementsSupportingSelected.find(
        ({ relativePosition }) => Number(relativePosition) === check.relative_position,
      )

      const targetGuid = target?.guid as string
      const supportType = target?.supportType || 'line'

      const targetCrossSection = targetGuid ? elementGuidToCrossSection[targetGuid] : undefined
      const targetSettings = elementGuidToCheckSettings[targetGuid] as SettingsOnMember

      const checksOnSupportingElement = filter(
        structuralChecks,
        check => check.element_guid === targetGuid,
      )

      const roundedSupportConfig = produce(state => {
        // Rounded as support config is expected to be an Integer (in cm)
        state.length = round(state.length, 2)
        state.width = round(state.width, 2)
      }, supportConfig)()

      if (!targetCrossSection) {
        return {
          type: 'other',
          targetGuid,
          supportConfig: roundedSupportConfig,
          check,
          checksOnSupportingElement,
          supportType: supportType,
        }
      }

      return {
        type: 'position',
        check,
        supportConfig,
        targetCrossSection,
        targetSettings,
        targetGuid,
        checksOnSupportingElement,
        supportType,
      }
    })
  }

  return { formBundles, checkSettings }
}

// adding enums to types.d.ts files is not possible hence storing it here
export enum EditMode {
  None,
  Grouping,
  // Add more modes here in the future, e.g.:
  // Splitting,
  // Renaming,
  // etc.
}

export const calculateOverhangLintelToRip = (
  supportConfig: SupportGeometry | undefined,
  ripCS: CrossSection | undefined,
): number | undefined => {
  const lSupportConfig = supportConfig?.length
  if (ripCS?.material.kind === 'Steel') {
    // Breite parallel zum Flansch im Fall der Sturz - Rippe konstellation
    const bRip = ripCS?.shape?.width
    return lSupportConfig && bRip ? lSupportConfig - bRip : undefined
  } else {
    // its a wood cross section
    const bRip = ripCS?.shape?.width
    return lSupportConfig && bRip ? lSupportConfig - bRip : undefined
  }
}

export const elementGroupingSnackbarKey = 'snk-group-elements'

import { ReactElement, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import produce from 'immer'
import { find, findIndex, maxBy } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Stack, Button, Typography, Divider, Tooltip } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { SaveButton } from '@ui/actions'
import { Form, Switch } from '@ui/forms'
import { Box } from '@ui/structure'
import { useModelStore, useStructuralPlanningStore } from '@editorStores'
import { useElementTypes, useResultsInvalidation } from '@editorHooks'
import {
  getHorizontalDistributionSettings,
  getSimplifiedSeismicModelConfig,
  getSimplifiedSeismicModelInputs,
  getSimplifiedSeismicResults,
  getSlabStiffening,
  getWallDistributionSetting,
} from '@queries'
import { setWallDistributionSetting, updateSlabStiffening } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import { saveHorizontalDistributionSettings } from 'src/state/mutations/distributionSettings'
import { slabDistributionSchema } from '../schema'
import FormFields from './FormFields'

interface Props {
  onClose: () => void
  selectedElements: string[]
}

const RoofSlabForm = ({ onClose, selectedElements }: Props): ReactElement | null => {
  const model = useModelStore(state => state.model)

  const upperMostCeilingNotStiffening = useStructuralPlanningStore(
    state => state.upperMostCeilingNotStiffening,
  )
  const horizontalDistributionSettings = useStructuralPlanningStore(
    state => state.horizontalDistributionSettings,
  )

  const { enqueueSnackbar } = useSnackbar()

  const { projectId }: { projectId?: string } = useParams()

  const queryClient = useQueryClient()

  const invalidateResults = useResultsInvalidation()

  const elementTypes = useElementTypes()

  // MEMOS

  const upperMostCeiling = useMemo(() => maxBy(model.slabs, 'storey'), [model.slabs])

  const selectedElementSlab = useMemo(() => {
    if (selectedElements.length === 1) {
      return find(model.slabs, { guid: selectedElements[0] })
    }
  }, [selectedElements, model.slabs])

  const wallDistributionSpecQuery = useQuery({
    queryKey: getWallDistributionSetting.getKey(projectId),
    queryFn: () => getWallDistributionSetting.request(projectId),
    onError: () => {
      enqueueSnackbar('Fehler beim Laden der Einstellungen für die Schubverteilung von Wänden.')
    },
  }) as { isFetching: boolean; data: WallDistributionSetting }

  const { mutateAsync: mutateAsyncWallDistribution, isLoading: isSavingWallDistribution } =
    useMutation(
      (data: WallDistributionSetting) => setWallDistributionSetting.request(projectId, data),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(getWallDistributionSetting.getKey(projectId))
          invalidateResults(projectId as string)
          enqueueSnackbar('Verteilung angepasst', { variant: 'success' })
        },
        onError: (error: AxiosError) => {
          enqueueSnackbar(
            buildErrorMessage(error, 'Fehler beim Speichern der Verteilung für Wände'),
            {
              variant: 'error',
            },
          )
        },
      },
    )

  const { mutateAsync: mutateAsyncSlabStiffening, isLoading: isLoadingSlabStiffening } =
    useMutation((data: SetSlabStiffening) => updateSlabStiffening.request(projectId, data), {
      onSuccess: () => {
        queryClient.invalidateQueries(getSlabStiffening.getKey(projectId))
        queryClient.invalidateQueries(getSimplifiedSeismicModelConfig.getKey(projectId))
        queryClient.invalidateQueries(getSimplifiedSeismicModelInputs.getKey(projectId))
        queryClient.invalidateQueries(getSimplifiedSeismicResults.getKey(projectId))
        invalidateResults(projectId as string)
        enqueueSnackbar('Verteilung angepasst', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(
            error,
            'Fehler beim Speichern der Verteilung des obersten Deckenelements',
          ),
          {
            variant: 'error',
          },
        )
      },
    })

  const { mutateAsync: mutateTranmissionGraph, isLoading: isSavingTransmissionGraph } = useMutation(
    (distribution: SlabDistributionOption) => {
      const newSettings = produce(
        horizontalDistributionSettings as HoirzontalDistributionSettings,
        draft => {
          selectedElements.forEach(element_guid => {
            const elementType = elementTypes[element_guid]
            const specsKey =
              elementType === 'slabs' ? 'slab_distribution_specs' : 'roof_distribution_specs'
            const index = findIndex(draft[specsKey], ['element_guid', element_guid])
            const result = {
              element_guid,
              distribution_x: distribution,
              distribution_y: distribution,
            }

            if (index === -1) {
              draft[specsKey].push(result)
            } else {
              draft[specsKey][index] = result
            }
          })
        },
      )

      return saveHorizontalDistributionSettings.request(projectId, newSettings)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getHorizontalDistributionSettings.getKey(projectId))
        invalidateResults(projectId as string)
        enqueueSnackbar('Distributionskonfiguration erfolgreich gespeichert', {
          variant: 'success',
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Distributionskonfiguration'),
          {
            variant: 'error',
          },
        )
      },
    },
  )

  const spec = useMemo((): SlabDistributionSpec => {
    const elements = selectedElements[0]
    const elementType = elementTypes[elements]
    const specsKey = elementType === 'slabs' ? 'slab_distribution_specs' : 'roof_distribution_specs'

    const data = horizontalDistributionSettings?.[specsKey] || []

    return find(data, ['element_guid', selectedElements[0]]) || slabDistributionSchema.getDefault()
  }, [horizontalDistributionSettings, elementTypes, selectedElements])

  return (
    <>
      <Form
        key={selectedElements.join()}
        onSubmit={({ distribution, upperMostCeilingNotStiffening }, event) => {
          // @ts-ignore
          if (event.nativeEvent.submitter.name === 'deactivate-distribution') {
            if (selectedElementSlab && upperMostCeiling?.guid === selectedElementSlab?.guid) {
              mutateAsyncSlabStiffening({
                element_guid: selectedElementSlab.guid,
                stiffening: upperMostCeilingNotStiffening ? 'NotStiffening' : 'Stiffening',
              })
            }
          } else {
            mutateTranmissionGraph(distribution)
          }
        }}
        defaultValues={{
          distribution: spec.distribution_x,
          upperMostCeilingNotStiffening,
        }}
      >
        <Box p={1} border={1} borderColor="grey.200" borderRadius={1}>
          <Typography variant="h6">Kraftverteilung Decke</Typography>
          <FormFields
            selectedElement={selectedElementSlab?.guid}
            upperMostCeiling={upperMostCeiling?.guid}
            isSavingDistributionDeactivation={isLoadingSlabStiffening}
            isSavingTransmissionGraph={isSavingTransmissionGraph}
          />

          <Stack direction="row" justifyContent="end" spacing={1} mt={1}>
            <SaveButton
              loading={isSavingTransmissionGraph}
              disabled={isLoadingSlabStiffening}
              onClick={() => null}
              size="small"
              fullWidth
              type="submit"
            >
              Speichern
            </SaveButton>
            <Button onClick={onClose} size="small" variant="outlined" fullWidth>
              Auswahl aufheben
            </Button>
          </Stack>
        </Box>
      </Form>
      <Divider />
      <Form
        key={'wall-distribution-settings'}
        defaultValues={wallDistributionSpecQuery.data}
        onSubmit={data => {
          mutateAsyncWallDistribution(data)
        }}
        enableReinitialize
      >
        <Box p={1} border={1} borderColor="grey.200" borderRadius={1}>
          <Typography variant="h6">Kraftverteilung Wände</Typography>
          <Tooltip title="Statt direkt in die Wände der Ebene darunter. Gilt für alle Wände im Gebäude">
            <Stack direction="row" alignItems="center">
              <Switch
                name="connect_walls_to_slabs"
                size="small"
                disabled={wallDistributionSpecQuery.isFetching}
              />
              <Typography sx={{ p: 1 }} noWrap>
                Schub aus Wänden immer in die Decke einleiten
              </Typography>
            </Stack>
          </Tooltip>
          <SaveButton
            loading={isSavingWallDistribution}
            disabled={wallDistributionSpecQuery.isFetching}
            onClick={() => null}
            size="small"
            fullWidth
            type="submit"
          >
            Speichern
          </SaveButton>
        </Box>
      </Form>
    </>
  )
}

export default RoofSlabForm

import { forwardRef, MouseEvent, ReactNode, useRef, useState, useImperativeHandle } from 'react'
import Menu from '@mui/material/Menu'
import { MenuItemData } from '../definitions'
import { nestedMenuItemsFromObject } from './nestedMenuItemsFromObject'

export type ContextMenuProps = {
  children?: ReactNode
  menuItems?: ReactNode[]
  menuItemsData?: MenuItemData[]
}

type Position = {
  top: number
  left: number
}

export const ContextMenu = forwardRef<HTMLDivElement, ContextMenuProps>(function ContextMenu(
  { children, menuItems, menuItemsData },
  ref,
) {
  const localRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => localRef.current as HTMLDivElement)

  const [menuPosition, setMenuPosition] = useState<Position | null>(null)

  const [mouseDownPosition, setMouseDownPosition] = useState<Position | null>(null)

  const handleItemClick = () => setMenuPosition(null)

  const handleMouseDown = (e: MouseEvent) => {
    if (menuPosition !== null) setMenuPosition(null)

    if (e.button !== 2) return

    const current = localRef.current
    if (!current) return

    const wrapperBounds = current.getBoundingClientRect()

    if (
      e.clientX < wrapperBounds.left ||
      e.clientX > wrapperBounds.right ||
      e.clientY < wrapperBounds.top ||
      e.clientY > wrapperBounds.bottom
    ) {
      return
    }

    setMouseDownPosition({
      left: e.clientX,
      top: e.clientY,
    })
  }

  const handleMouseUp = (e: MouseEvent) => {
    const top = e.clientY
    const left = e.clientX

    if (mouseDownPosition === null) return

    if (mouseDownPosition.top === top && mouseDownPosition.left === left) {
      setMenuPosition({
        left: e.clientX,
        top: e.clientY,
      })
    }
  }

  const menuContents =
    menuItems ??
    (menuItemsData &&
      nestedMenuItemsFromObject({
        handleClose: handleItemClick,
        isOpen: !!menuPosition,
        menuItemsData: menuItemsData,
      }))

  return (
    <div
      ref={localRef}
      onContextMenu={e => e.preventDefault()}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {menuPosition && (
        <Menu
          onContextMenu={e => e.preventDefault()}
          open={!!menuPosition}
          onClose={() => setMenuPosition(null)}
          anchorReference="anchorPosition"
          anchorPosition={menuPosition}
        >
          {menuContents}
        </Menu>
      )}
      {children}
    </div>
  )
})

import { ReactElement } from 'react'
import { ModelPositionGroupingLabels, PositionGroupingLabels } from '@scene'
import useActiveBundleLabels from './hooks/useActiveBundleLabels'

interface Props {
  selectedElement: string | null
}

const GroupingLabels = ({ selectedElement }: Props): ReactElement => {
  const { positionBundleLabels, elementBundleLabels } = useActiveBundleLabels(
    selectedElement || undefined,
  )

  return (
    <>
      {positionBundleLabels && <PositionGroupingLabels data={[positionBundleLabels]} />}

      {elementBundleLabels && <ModelPositionGroupingLabels data={[elementBundleLabels]} />}
    </>
  )
}

export default GroupingLabels

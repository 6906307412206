import { ReactElement, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { mapValueKey } from '@editorUtils'
import { useElementPositionType, useResultsQueryParams } from '@resultsHooks'
import { PositionMeshElement, SimpleStiffeningMesh } from '@scene'
import { filter } from 'lodash-es'
import { useQuery } from '@tanstack/react-query'
import {
  useControlStore,
  useModelStore,
  useResultsStore,
  useSystemManagerStore,
} from '@editorStores'
import { useGuidToElement, useModelClickListeners, useSelectionMode } from '@editorHooks'
import { getProjectForApproval } from '../queries'

const FreigabeScene = (): ReactElement => {
  const { projectId } = useParams()
  const { isSelectionMode } = useSelectionMode()
  const onModelClick = useModelClickListeners()

  const visibility = useResultsStore(state => state.resultElementVisibility)

  // QUERY PARAMS
  const {
    params: { selectedElements },
  } = useResultsQueryParams()

  // STORE

  const model = useModelStore(state => state.model)

  const visibleStoreys = useModelStore(state => state.visibleStoreys)

  const addHiddenElementIds = useControlStore(state => state.addHiddenElementIds)
  const removeHiddenElementIds = useControlStore(state => state.removeHiddenElementIds)

  const queryResult = useQuery({
    queryKey: getProjectForApproval.getKey(projectId),
    queryFn: () => getProjectForApproval.request(projectId),
  })
  const projectForApproval = queryResult.data as unknown as ProjectForApproval
  const stiffeningIntervals = projectForApproval.model.stiffeningIntervals

  const rips = useModelStore(state => state.model.rips)
  const lintels = useModelStore(state => state.model.lintels)

  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  const guidToCrossSection = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )

  const guidToElement = useGuidToElement()

  // MEMO

  const selectedElement = useMemo(
    () => (selectedElements?.length === 1 ? selectedElements?.[0] : null),
    [selectedElements],
  )

  // FILTERED DATA

  const stiffeningIntervalsInStorey = useMemo(
    () =>
      filter(stiffeningIntervals, interval => {
        const element = guidToElement[interval.element_guid]
        if (!element) return true
        return visibleStoreys.has(element.storey)
      }),
    [guidToElement, stiffeningIntervals, visibleStoreys],
  )

  const selectedPositionType = useElementPositionType(selectedElement || undefined)

  // EFFECTS

  const beamIds = useMemo(() => model.beams.map(({ guid }) => guid), [model])
  const columnIds = useMemo(() => model.columns.map(({ guid }) => guid), [model])
  const purlinIds = useMemo(() => model.purlins.map(({ guid }) => guid), [model])

  useEffect(() => {
    const hiddenIds = [
      ...(selectedPositionType === 'beams' ? [] : beamIds),
      ...(selectedPositionType === 'columns' ? [] : columnIds),
      ...(selectedPositionType === 'purlins' ? [] : purlinIds),
    ]

    if (isSelectionMode) addHiddenElementIds(hiddenIds)
    else removeHiddenElementIds(hiddenIds)
  }, [
    addHiddenElementIds,
    beamIds,
    columnIds,
    isSelectionMode,
    purlinIds,
    removeHiddenElementIds,
    selectedPositionType,
  ])

  return (
    <>
      <SimpleStiffeningMesh model={model} intervals={stiffeningIntervalsInStorey} />)
      {rips &&
        visibility.wallPositions &&
        rips.map(rip => (
          <PositionMeshElement
            key={rip.position_guid}
            position_type="rip"
            data={rip}
            height={guidToCrossSection[rip.position_guid]?.element_cs.shape.height}
            width={guidToCrossSection[rip.position_guid]?.element_cs.shape.width}
            onClick={e => onModelClick?.(e)}
            isSelected={false}
            isHighlighted={false}
            visible={true}
            isTarget={false}
          />
        ))}
      {lintels &&
        visibility.wallPositions &&
        lintels.map(lintel => (
          <PositionMeshElement
            key={lintel.position_guid}
            position_type="lintel"
            data={lintel}
            height={guidToCrossSection[lintel.position_guid]?.element_cs.shape.height}
            width={guidToCrossSection[lintel.position_guid]?.element_cs.shape.width}
            onClick={e => onModelClick?.(e)}
            isSelected={false}
            isHighlighted={false}
            visible={true}
            isTarget={false}
          />
        ))}
    </>
  )
}

export default FreigabeScene

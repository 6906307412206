export const materialTypeFromCS = (crossSection: CrossSection): MaterialType => {
  switch (crossSection.material.kind) {
    case 'Softwood': {
      return 'softwoodMaterial'
    }
    case 'Glulam': {
      return 'glulamMaterial'
    }
    case 'Steel': {
      return 'steelMaterial'
    }
    default: {
      return 'softwoodMaterial'
    }
  }
}

export const renameSWxxToCxxIfApplicable = (identifier: string): string => {
  // MDG 2214 - https://app.clickup.com/t/2590559/MDG-2214
  if (identifier.startsWith('SW')) {
    return identifier.replace('SW', 'C')
  } else {
    return identifier
  }
}

import { ReactElement, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { merge } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Stack } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SaveButton } from '@ui/actions'
import { Errors, Form } from '@ui/forms'
import { useSystemManagerStore } from '@editorStores'
import { useResultsInvalidation } from '@editorHooks'
import {
  getElementCrossSectionAssignment,
  getElementCrossSectionsSelection,
  getProjectElementCrossSections,
} from '@queries'
import {
  createProjectElementCrossSection,
  deleteProjectElementCrossSection,
  patchProjectElementCrossSection,
  saveElementCrossSectionSelection,
} from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import ElementCSSelection from '../ElementCSSelection'
import { schema } from './schema'

const ElementCSManagerForm = (): ReactElement => {
  const { projectId } = useParams()
  const queryClient = useQueryClient()
  const invalidateResults = useResultsInvalidation()

  const elementCrossSections = useSystemManagerStore(state => state.elementCrossSections)
  const elementCrossSectionSelection = useSystemManagerStore(
    state => state.elementCrossSectionsSelection,
  )

  const defaultValues = useMemo<ElementCSSelection>(() => {
    const schemaDefault = schema.getDefault()
    const server: ElementCSSelection | undefined = elementCrossSectionSelection || undefined

    return merge(schemaDefault, server)
  }, [elementCrossSectionSelection])

  const { enqueueSnackbar } = useSnackbar()

  const { mutate: onSave, isLoading: isSaving } = useMutation(
    (selectedElementCS: ElementCSSelection) =>
      // @ts-ignore
      saveElementCrossSectionSelection.request({ projectId, input: selectedElementCS }),
    {
      onSuccess: async () => {
        enqueueSnackbar('Standard-Querschnitte erfolgreich gespeichert', {
          variant: 'success',
        })

        await queryClient.invalidateQueries(getElementCrossSectionsSelection.getKey(projectId))
        await queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        invalidateResults(projectId as string)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Auswahl der Standard-Querschnitte'),
          {
            variant: 'error',
          },
        )
      },
    },
  )

  const { mutate: handleDeleteElementCS } = useMutation(
    (crossSectionId: string) =>
      deleteProjectElementCrossSection.request(projectId as string, crossSectionId),
    {
      onSuccess: async () => {
        enqueueSnackbar('Element-Querschnitt erfolgreich gelöscht', { variant: 'success' })

        await queryClient.invalidateQueries(getProjectElementCrossSections.getKey(projectId))
        await queryClient.invalidateQueries(getElementCrossSectionsSelection.getKey(projectId))
        await queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        invalidateResults(projectId as string)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen der Element-Querschnitte'), {
          variant: 'error',
        })
      },
    },
  )

  const createElementCrossSectionMutationResult = useMutation(
    (data: ElementCS) => createProjectElementCrossSection.request(projectId as string, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getProjectElementCrossSections.getKey(projectId))
        await queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        invalidateResults(projectId as string)

        enqueueSnackbar('Element-Querschnitt erfolgreich angelegt', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern des Element-Querschnitts'),
          { variant: 'error' },
        )
      },
    },
  )

  const editElementCrossSectionMutationResult = useMutation(
    (data: ElementCS) => {
      return patchProjectElementCrossSection.request(projectId as string, data.guid, data)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getProjectElementCrossSections.getKey(projectId))
        await queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        invalidateResults(projectId as string)

        enqueueSnackbar('Element-Querschnitt erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern des Element-Querschnitts'),
          { variant: 'error' },
        )
      },
    },
  )

  return (
    <>
      <Form
        id="project-system-manager-element-cs"
        onSubmit={selectedElementCS => onSave(selectedElementCS)}
        validationSchema={schema}
        defaultValues={defaultValues}
      >
        <Stack spacing={2}>
          <ElementCSSelection
            elementCrossSections={elementCrossSections}
            onDelete={({ guid }) => handleDeleteElementCS(guid)}
            createMutation={createElementCrossSectionMutationResult}
            editMutation={editElementCrossSectionMutationResult}
          />
          <Errors />
          <SaveButton
            loading={isSaving}
            type="submit"
            data-cy="system-manager-element-cs-submit"
            sx={{
              width: 'fit-content',
              alignSelf: 'end',
            }}
          >
            Speichern
          </SaveButton>
        </Stack>
      </Form>
    </>
  )
}

export default ElementCSManagerForm

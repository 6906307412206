import { ReactElement, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useElementHistory } from '@hooks'
import { AxiosError } from 'axios'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { TypeOf } from 'yup'
import { Button, Stack } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeleteButton, SaveButton } from '@ui/actions'
import { Form } from '@ui/forms'
import { useEditElementStore, useModelStore } from '@editorStores'
import { useResultsInvalidation } from '@editorHooks'
import { useStructuralPlanningFormEsc } from '@structuralPlanningHooks'
import { getModel } from '@queries'
import { deleteRoof, updateRoofSlab } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import { schema } from '../../schema'
import FormFields from '../FormFields'

interface Props {
  selectedElement: string
  onClose: () => void
}

const RoofFormBase = ({ selectedElement, onClose }: Props): ReactElement => {
  const { projectId } = useParams()

  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const invalidateResults = useResultsInvalidation()

  const roofSlabs = useModelStore(state => state.model.roof_slabs)
  const updateRoof = useModelStore(state => state.updateRoof)
  const setActiveElement = useEditElementStore(state => state.setActiveElement)

  const roof = useMemo(
    () => (selectedElement ? find(roofSlabs, { guid: selectedElement }) : undefined),
    [roofSlabs, selectedElement],
  ) as ShapeObject

  const defaultValues = useMemo(() => {
    return {
      ...schema.getDefault(),
      guid: roof?.guid,
      points: roof?.shape.points.map(p => ({ x: p.x, y: p.y, z: p.z })),
    }
  }, [roof])

  useStructuralPlanningFormEsc(() => {
    onClose()
  }, !!selectedElement)

  // HISTORY

  const { setStale: setRoofStale } = useElementHistory({
    element: roof,
    getCurrentElement: () => {
      const roofSlabs = useModelStore.getState().model.roof_slabs
      return find(roofSlabs, { guid: selectedElement }) as ShapeObject
    },
    removeElement: () => {
      // do nothing
    },
    resetElement: roof => updateRoof(roof),
    dependencies: [selectedElement],
  })

  // MUTATIONS

  const { mutateAsync, isLoading } = useMutation(
    (data: TypeOf<typeof schema>) =>
      updateRoofSlab.request(projectId as string, data.guid, data.points),
    {
      onSuccess: async () => {
        invalidateResults(projectId as string)

        setRoofStale(roof)

        enqueueSnackbar('Dachfläche erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Dachfläche'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: deleteAsync, isLoading: isDeleting } = useMutation(
    (guid: string) => deleteRoof.request(projectId as string, guid),
    {
      onSuccess: async () => {
        setActiveElement(null)
        await queryClient.invalidateQueries(getModel.getKey(projectId))
        invalidateResults(projectId as string)
        enqueueSnackbar('Dachfläche erfolgreich gelöscht', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen der Dachfläche'), {
          variant: 'error',
        })
      },
    },
  )

  return (
    <>
      <Form
        key={selectedElement}
        onSubmit={data => mutateAsync(data)}
        defaultValues={defaultValues}
        validationSchema={schema}
        enableReinitialize
      >
        {selectedElement && (
          <FormFields selectedElement={selectedElement} slabs={roofSlabs} updateSlab={updateRoof} />
        )}
        <Stack direction="row" justifyContent="end" spacing={1} mt={1}>
          <Button variant="outlined" onClick={() => setActiveElement(null)}>
            Auswahl aufheben
          </Button>
        </Stack>
        <Stack direction="row" justifyContent="end" spacing={1} mt={1}>
          <SaveButton
            type="submit"
            loading={isLoading}
            fullWidth
            disabled={false}
            data-cy="btn-submit"
          >
            Speichern
          </SaveButton>
          <DeleteButton
            onClick={() => deleteAsync(selectedElement)}
            size="small"
            fullWidth
            loading={isDeleting}
            disabled={false}
          >
            Löschen
          </DeleteButton>
        </Stack>
      </Form>
    </>
  )
}

export default RoofFormBase

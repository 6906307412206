import { ReactElement } from 'react'
import { useWatch } from 'react-hook-form'
import { materialTypeOptions } from '@domainConstants'
import { Grid } from '@mui/material'
import { SelectLoadable, Select, CentimeterField } from '@ui/forms'
import { getSteelCSShapes } from '@queries'
import { elementCSTypeOptions, usageClassOptions } from '../constants'
import { materialQueryFromMaterialType } from './utils'

interface Props {
  showTypeOptions?: boolean
}

const ElementCSFormFields = ({ showTypeOptions = false }: Props): ReactElement => {
  const materialType = useWatch({ name: 'materialType' })

  return (
    <>
      <Grid container spacing={2} marginX={-2}>
        {showTypeOptions && (
          <Grid item xs={12}>
            <Select label="Elementtyp" name="kind" options={elementCSTypeOptions} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Select label="Materialtyp" name="materialType" options={materialTypeOptions} />
        </Grid>
        <Grid item xs={12}>
          <SelectLoadable
            label="Material"
            name="cross_section.material"
            placeholder="Material auswählen"
            query={materialQueryFromMaterialType(materialType)}
            renderOptions={(data: Material[] = []) =>
              data.map(material => ({ value: material, label: material.identifier }))
            }
            data-cy="crosssection-material"
          />
        </Grid>
        {(materialType === 'softwoodMaterial' || materialType === 'glulamMaterial') && (
          <>
            <Grid item xs={6}>
              <CentimeterField
                label="Querschnittsbreite"
                name="cross_section.shape.width"
                data-cy="crosssection-width-input"
              />
            </Grid>
            <Grid item xs={6}>
              <CentimeterField
                label="Querschnittshöhe"
                name="cross_section.shape.height"
                data-cy="crosssection-height-input"
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                label="Nutzungsklasse"
                name="cross_section.usage_class"
                options={usageClassOptions}
              />
            </Grid>
          </>
        )}
        {materialType === 'steelMaterial' && (
          <Grid item xs={12}>
            <SelectLoadable
              label="Stahl Querschnitt"
              name="cross_section.shape"
              placeholder="Querschnitt auswählen"
              query={getSteelCSShapes}
              renderOptions={(data: SteelCSShape[] = []) =>
                data.map(csShape => ({ value: csShape, label: csShape.profile }))
              }
              data-cy="crosssection-shape-select"
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default ElementCSFormFields

import { mixed, number, object, string } from 'yup'
import {
  CrossSectionFormDataType,
  crossSectionSchema,
  steelCSShapeSchema,
} from 'src/components/pages/Editor/components/SingleElementCSForm/schema'
import { schemaFormBundles } from '../BasicCSAndAPForm/schema'

export interface FormData {
  crossSection: CrossSectionFormDataType | null
  formBundles: (PositionBundleItem | OtherElementBundleItem)[]
  checkSettings: SettingsOnMember
  checks: StandardPositionCheck[]
}

export interface MutationData {
  crossSection: ElementCSAssignment | null
  settingsOnMember: SettingsOnMember
  formBundles: (PositionBundleItem | OtherElementBundleItem)[]
  checks: StandardPositionCheck[]
}

const rectangularCSShapeSchema = object({
  kind_literal: string().oneOf(['RectangularCS']).required(),
  width: number().required().positive('Breite muss größer als 0 sein'),
  height: number().required().positive('Höhe muss größer als 0 sein'),
})

const strictlyPositiveCrossSectionShapeSchema = mixed().test(
  'shape-validation',
  'Querschnitt ist ungültig',
  function (value) {
    if (!value) return false

    try {
      switch (value.kind_literal) {
        case 'RectangularCS':
          rectangularCSShapeSchema.validateSync(value, { abortEarly: false })
          return true
        case 'SteelCS':
          steelCSShapeSchema.validateSync(value, { abortEarly: false })
          return true
        default:
          return false
      }
    } catch (error) {
      if (error instanceof Error) {
        // Forward the validation error message
        return this.createError({ message: error.message })
      }
      return false
    }
  },
)

export const schema = object({
  formBundles: schemaFormBundles.fields.formBundles, // Get the formBundles field from schemaFormBundles
  crossSection: object({
    material: crossSectionSchema.fields.material,
    materialType: crossSectionSchema.fields.materialType,
    shape: strictlyPositiveCrossSectionShapeSchema.required(),
    usage_class: crossSectionSchema.fields.usage_class,
  }).nullable(),
  checks: mixed().required(),
})

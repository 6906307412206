import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { NavbarPortalCenter, NavBreadcrumbs } from '@ui/navigation'
import { Box, Drawer, LayoutDrawer } from '@ui/structure'
import SceneControlled from '../../components/SceneControlled'
import FreigabeScene from './FreigabeScene'
import { getProjectForApproval } from './queries'
import SendItForm from './sendItForm'

const Freigabe = (): ReactElement => {
  const { projectId } = useParams()
  const {
    data: { project },
  } = useQuery({
    queryKey: getProjectForApproval.getKey(projectId),
    queryFn: () => getProjectForApproval.request(projectId),
  })

  return (
    <>
      <NavbarPortalCenter>
        <NavBreadcrumbs extension />
      </NavbarPortalCenter>

      <LayoutDrawer>
        <SceneControlled>
          <FreigabeScene />
        </SceneControlled>
        <Drawer variant="persistent" expanded={false} bottomDrawerExpanded={false}>
          <Stack direction="column" spacing={2} sx={{ mx: 1 }}>
            <Typography variant="h3" align="center">
              Freigabeanfrage
            </Typography>
            <Box p={1} border={2} borderColor="grey.200" borderRadius={1}>
              <Stack direction="column" spacing={1}>
                <Typography variant="h5" align="center">
                  {project?.hand_in_project_name}
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={5} alignContent="center">
                    Adresse
                  </Grid>
                  <Grid item xs={5}>
                    <Typography align="center">{project?.address}</Typography>
                  </Grid>
                  <Grid item xs={5} alignContent="center">
                    Tragwerksplanung
                  </Grid>
                  <Grid item xs={5} alignContent="center">
                    <Typography align="center">{project?.processor_name}</Typography>
                  </Grid>
                  <Grid item xs={5} alignContent="center">
                    Bauherr
                  </Grid>
                  <Grid item xs={5} alignContent="center">
                    <Typography align="center">{project?.owner_name}</Typography>
                  </Grid>
                </Grid>
              </Stack>
            </Box>
            <SendItForm />
          </Stack>
        </Drawer>
      </LayoutDrawer>
    </>
  )
}

export default Freigabe

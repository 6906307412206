import { ReactElement } from 'react'
import { useWatch } from 'react-hook-form'
import { useResultsQueryParams } from '@resultsHooks'
import { Box, Stack } from '@mui/material'
import { Errors } from '@ui/forms'
import SubmitButton from '@ui/forms/SubmitButton'
import { CrossSectionInfo } from '@editorComponents'
import Diagram from '../CSAndAPPopup/components/Diagram'
import CSFormFields from '../CSFormFields'
import { UtilizationPreview } from '../UtilizationPreview'
import CompressionTable from './components/CompressionTable'

interface Props {
  isLoading: boolean
  elementType: ElementTypes
  elementGuid: string
  disableCrossSectionChanges?: boolean
  onlyEnableCompressionSettingChanges?: boolean
}

const BasicCSAndAPForm = ({
  isLoading,
  elementType,
  elementGuid,
  disableCrossSectionChanges = false,
  onlyEnableCompressionSettingChanges,
}: Props): ReactElement | null => {
  const {
    params: { selectedCheckPosition },
    actions: { setSelectedCheckPosition },
  } = useResultsQueryParams()

  const crossSection: CrossSection = useWatch({ name: 'crossSection' })

  return (
    <Stack direction="column" spacing={2} maxWidth="900px">
      {!onlyEnableCompressionSettingChanges && (
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <UtilizationPreview elementGuid={elementGuid} />
          <Box p={1} border={1} borderColor="grey.200" borderRadius={1} display="flex" flex={1}>
            {disableCrossSectionChanges ? (
              <CrossSectionInfo crossSection={crossSection} showBorder={false} />
            ) : (
              <CSFormFields elementType={elementType} />
            )}
          </Box>
          <Diagram elementType={elementType} />
        </Stack>
      )}
      <CompressionTable
        selectedCheckPosition={selectedCheckPosition}
        setSelectedCheckPosition={setSelectedCheckPosition}
        elementType={elementType}
      />
      <Errors />
      <SubmitButton
        loading={isLoading}
        fullWidth={false}
        data-cy={`btn-compression-settings-submit`}
      />
    </Stack>
  )
}

export default BasicCSAndAPForm

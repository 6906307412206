import convert from 'convert'
import numeral from 'numeral'

numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: '€',
  },
})
numeral.locale('de')

export const newtonToKilonewton = (input: number): number => {
  const conversion = Number(input / 1000)

  return conversion
}

export const kilogramToKilonewton = (input: number, round = true): number => {
  const conversion = Number((input * 9.81) / 1000)

  if (round) return Number(conversion.toFixed(3))

  return conversion
}

export const millimeterToMeter = (input: number, round = true): number => {
  const conversion = Number(input / 1000)

  if (round) return Number(conversion.toFixed(2))

  return conversion
}

export const meterToMillimeter = (input: number, round = true): number => {
  const conversion = Number(input * 1000)

  if (round) return Number(conversion.toFixed(2))

  return conversion
}

export const convertUnit = (
  value: number,
  numeralFormatter: string,
  fromUnit: string,
  toUnit: string,
) => {
  // @ts-ignore
  const convertedValueAsString = numeral(convert(Number(value), fromUnit).to(toUnit)).format(
    numeralFormatter,
  )
  return convertedValueAsString
}

export const to2DecimalPlaces = (val: number) => numeral(val).format('0.[00]')

import { min } from 'lodash-es'
import { Warning } from '@mui/icons-material'
import { Typography, Tooltip } from '@mui/material'
import { convertUnit } from 'src/utils/units'

export const renderRipHeightAndLintelWidthCompatibility = (
  ripCS: CrossSection,
  lintelCS: CrossSection,
  supportGeometry: SupportGeometry,
) => {
  const ripHeight = ripCS.shape.height
  const lintelWidth = lintelCS.shape.width
  const minWidth = min([ripHeight, lintelWidth])
  if (!minWidth) {
    return <Typography>{'...'}</Typography>
  }
  const supportGeometryWidthIsSmallerThanMinWidth = supportGeometry.width < minWidth
  const supportGeometryWidthIsHigherThanMinWidth = supportGeometry.width > minWidth

  const supportGeometryWidthCm = convertUnit(supportGeometry.width, '0', 'm', 'cm')
  const minWidthCm = convertUnit(minWidth, '0', 'm', 'cm')

  let tooltipText = ''
  if (supportGeometryWidthIsSmallerThanMinWidth) {
    tooltipText = `Auflagerbreite b (${supportGeometryWidthCm} cm) ist kleiner als die Dimension der tatsächlichen Verbindung (min (H<sub>Rippe</sub>, B<sub>Sturz</sub>) = ${minWidthCm} cm)`
  } else if (supportGeometryWidthIsHigherThanMinWidth) {
    tooltipText = `Auflagerbreite b (${supportGeometryWidthCm} cm) ist größer als die Dimension der tatsächlichen Verbindung (min (H<sub>Rippe</sub>, B<sub>Sturz</sub>) = ${minWidthCm} cm)`
  } else {
    tooltipText = `Auflagerbreite b (${supportGeometryWidthCm} cm) entspricht der Dimension der tatsächlichen Verbindung (min (H<sub>Rippe</sub>, B<sub>Sturz</sub>) = ${minWidthCm} cm)`
  }
  const showWarning =
    supportGeometryWidthIsSmallerThanMinWidth || supportGeometryWidthIsHigherThanMinWidth

  return (
    <Tooltip title={<span dangerouslySetInnerHTML={{ __html: tooltipText }} />}>
      <Typography color={showWarning ? 'error' : 'inherit'} alignItems="center">
        {showWarning ? (
          <>
            <Warning sx={{ verticalAlign: 'middle' }} />
            {`!= ${minWidthCm} cm`}
          </>
        ) : (
          <Typography alignItems="center">OK</Typography>
        )}
      </Typography>
    </Tooltip>
  )
}

import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useModelStore } from '@editorStores'
import { getProject } from '@queries'

const useUpdateLoadInfo = () => {
  const { projectId } = useParams<{ projectId: string }>()
  const updateProjectLoadInfo = useModelStore(state => state.updateProjectLoadInfo)

  const { refetch } = useQuery({
    queryKey: getProject.getKey(projectId),
    queryFn: () => getProject.request(projectId),
    onSuccess: (project: ProjectWithLoadInfo) => updateProjectLoadInfo(project.load_info),
  })

  return () => refetch()
}

export default useUpdateLoadInfo

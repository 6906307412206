import { ReactElement } from 'react'
import { Error } from '@mui/icons-material'
import { TableRow, TableCell, Typography, Stack, Tooltip } from '@mui/material'

const ErrorRow = (): ReactElement => {
  return (
    <TableRow>
      <TableCell colSpan={1000} align="center" size="medium">
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Error color="error" fontSize="medium" />

          <Tooltip title="Hier kam es zu einem Fehler, bitte versuchen Sie es erneut oder wenden Sie sich an das DEV-Team">
            <Typography>Fehler bei diesem Element</Typography>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  )
}

export default ErrorRow

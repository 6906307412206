import { ReactNode } from 'react'
import { useWatch } from 'react-hook-form'
import { Info } from '@mui/icons-material'
import { Alert, Box, Stack } from '@mui/material'
import { Errors } from '@ui/forms'
import SubmitButton from '@ui/forms/SubmitButton'
import { useElementTypes } from '@editorHooks'
import { CrossSectionInfo } from '@editorComponents'
import CSFormFields from '../CSFormFields'
import EnhancedCompressionSettingsFormFields from '../EnhancedCompressionSettingsFormFields'
import { UtilizationPreview } from '../UtilizationPreview'

interface Props {
  elementGuid: string
  isLoading: boolean

  onClickTargetElementGuid: (elementguid: string) => void
  onlyEnableCompressionSettingChanges?: boolean
  disableCrossSectionChanges?: boolean
  elementType: ElementTypes
  showQSUncorrelated?: boolean

  diagram?: () => ReactNode
}

const EnhancedCSAndAPForm = ({
  elementGuid,
  isLoading,
  onClickTargetElementGuid,
  elementType,
  onlyEnableCompressionSettingChanges,
  disableCrossSectionChanges = false,
  showQSUncorrelated = false,
  diagram,
}: Props) => {
  const elementGuidToType = useElementTypes()

  const crossSection: CrossSection = useWatch({ name: 'crossSection' })

  return (
    <Stack direction="column" spacing={2} maxWidth="1000px">
      {!onlyEnableCompressionSettingChanges && (
        <Stack direction="row" spacing={2}>
          <UtilizationPreview elementGuid={elementGuid} />
          <Box
            p={1}
            border={1}
            borderColor="grey.200"
            borderRadius={1}
            display="flex"
            flex={1}
            maxWidth={500}
          >
            {disableCrossSectionChanges ? (
              <CrossSectionInfo crossSection={crossSection} showBorder={false} />
            ) : (
              <CSFormFields elementType={elementType} />
            )}
          </Box>

          {diagram?.()}
        </Stack>
      )}

      {showQSUncorrelated && (
        <Alert
          icon={<Info fontSize="inherit" />}
          severity="info"
          sx={{ paddingX: 1, paddingY: 0.5 }}
        >
          Die Querschnitte in der Tabelle korrelieren ggf. nicht mit den Geometrien der
          Auflagerpressungen.
        </Alert>
      )}

      <EnhancedCompressionSettingsFormFields
        onClickTargetElementGuid={onClickTargetElementGuid}
        positionType={elementGuidToType[elementGuid] as ElementTypes}
        isLoading={isLoading}
        isOverhangApplicable={elementType === 'lintels'}
      />
      <Errors />
      <SubmitButton loading={isLoading} fullWidth={false} data-cy={`btn-cs-and-ap-submit`} />
    </Stack>
  )
}

export default EnhancedCSAndAPForm

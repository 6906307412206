export type FieldToHeaderName<T extends string> = Record<T, { field: T; headerName: string }>

const field1: FieldToHeaderName<'element_guid'> = {
  element_guid: {
    field: 'element_guid',
    headerName: 'Element',
  },
}

const field2: FieldToHeaderName<'segment_guid'> = {
  segment_guid: {
    field: 'segment_guid',
    headerName: 'Segment',
  },
}

const field3: FieldToHeaderName<'support_guid'> = {
  support_guid: {
    field: 'support_guid',
    headerName: 'Anker',
  },
}

const field4: FieldToHeaderName<'original_design_force'> = {
  original_design_force: {
    field: 'original_design_force',
    headerName: 'Fd [kN]',
  },
}

const field5: FieldToHeaderName<'original_design_force_source'> = {
  original_design_force_source: {
    field: 'original_design_force_source',
    headerName: 'maßgebender Lastfall',
  },
}

const field6: FieldToHeaderName<'manual_load_1'> = {
  manual_load_1: {
    field: 'manual_load_1',
    headerName: 'Last [kN] - 1',
  },
}

const field7: FieldToHeaderName<'manual_load_2'> = {
  manual_load_2: {
    field: 'manual_load_2',
    headerName: 'Last [kN] - 2',
  },
}

const field8: FieldToHeaderName<'manual_load_3'> = {
  manual_load_3: {
    field: 'manual_load_3',
    headerName: 'Last [kN] - 3',
  },
}

const field9: FieldToHeaderName<'manual_source_1'> = {
  manual_source_1: {
    field: `manual_source_1`,
    headerName: `Herkunft - 1`,
  },
}

const field10: FieldToHeaderName<'manual_source_2'> = {
  manual_source_2: {
    field: `manual_source_2`,
    headerName: `Herkunft - 2`,
  },
}

const field11: FieldToHeaderName<'manual_source_3'> = {
  manual_source_3: {
    field: `manual_source_3`,
    headerName: `Herkunft - 3`,
  },
}

const field12: FieldToHeaderName<'load_sum'> = {
  load_sum: {
    field: 'load_sum',
    headerName: 'Neu Fd[kN]',
  },
}

const field13: FieldToHeaderName<'anchor_utilization'> = {
  anchor_utilization: {
    field: 'anchor_utilization',
    headerName: 'Ausnutzung',
  },
}

const field14: FieldToHeaderName<'anchor_name'> = {
  anchor_name: {
    field: 'anchor_name',
    headerName: 'Zuganker',
  },
}

const field15: FieldToHeaderName<'comment'> = {
  comment: {
    field: 'comment',
    headerName: 'Hinweis',
  },
}

export const fieldToHeaderName = {
  ...field1,
  ...field2,
  ...field3,
  ...field4,
  ...field5,
  ...field6,
  ...field7,
  ...field8,
  ...field9,
  ...field10,
  ...field11,
  ...field12,
  ...field13,
  ...field14,
  ...field15,
}

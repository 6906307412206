import { ReactElement, useCallback, useMemo } from 'react'
import { find, isFunction, filter, toNumber, reject } from 'lodash-es'
import { Euler, Box3, Sphere } from 'three'
import { useTheme } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { Instances } from '@react-three/drei'
import { ThreeEvent } from '@react-three/fiber'
import { getPointAtPercentage } from '../utils'
import CylinderMesh from './CylinderMesh'
import InstancedCylinderMesh from './InstancedCylinderMesh'

const WIDTH = 0.06
const HEIGHT = 0.3

interface PositionedSupport {
  item: ElementSupportItem
  position: ImmutableVector3
}

interface PositionedTarget extends PositionedSupport {
  supportItem: ElementSupportItem
  supportPosition: ImmutableVector3
}

const InstancedConnectorMesh = ({
  data,
  domains,
  onClick,
  transmitterGuid,
}: TransmitterMeshProps): ReactElement => {
  const theme = useTheme()
  const supportItems = useMemo(() => {
    const supportPositions = data.element_supports.reduce((acc: PositionedSupport[], support) => {
      const { domain_guid, relative_position } = support

      const domain = find(domains, ['guid', domain_guid])

      if (!domain) return acc

      return [
        ...acc,
        {
          position: getPointAtPercentage(domain.start, domain.end, toNumber(relative_position)),
          item: support,
        },
      ]
    }, [])

    return supportPositions
  }, [data.element_supports, domains])

  const supportTargetMap = useMemo(() => {
    return supportItems.reduce(
      (
        acc: {
          [key: string]: boolean
        },
        { item },
      ) => {
        const hasTarget = !!find(data.support_targets, ['support_guid', item.guid])

        return {
          ...acc,
          [item.guid]: hasTarget,
        }
      },
      {},
    )
  }, [supportItems, data.support_targets])

  const targetItems = useMemo(() => {
    return supportItems.reduce(
      (acc: PositionedTarget[], { item: supportItem, position: supportPosition }) => {
        const { guid: supportGuid } = supportItem
        const items = filter(data.support_targets, ['support_guid', supportGuid]).reduce(
          (itemAcc: PositionedTarget[], { target_guid }) => {
            const target = find(data.element_targets, ['guid', target_guid])

            if (!target) return itemAcc

            const { domain_guid, relative_position } = target
            const domain = find(domains, ['guid', domain_guid])

            if (!domain) return itemAcc

            return [
              ...itemAcc,
              {
                position: getPointAtPercentage(
                  domain.start,
                  domain.end,
                  toNumber(relative_position),
                ),
                item: target,
                supportItem: supportItem,
                supportPosition,
              },
            ]
          },
          [],
        )

        return [...acc, ...items]
      },
      [],
    )
  }, [supportItems, data.support_targets, data.element_targets, domains])

  const handleConnectorClick = useCallback(
    (event: ThreeEvent<MouseEvent>, connector: ElementSupportItem) => {
      event.stopPropagation()

      if (!isFunction(onClick)) return

      onClick(connector)
    },
    [onClick],
  )

  const supportItemsWithoutActive = useMemo(
    () => reject(supportItems, supportItem => supportItem.item.guid === transmitterGuid),
    [supportItems, transmitterGuid],
  )

  const targetItemsWithoutActive = useMemo(
    () => reject(targetItems, targetItem => targetItem.supportItem.guid === transmitterGuid),
    [targetItems, transmitterGuid],
  )

  const activeSupportItems = useMemo(
    () => filter(supportItems, supportItem => supportItem.item.guid === transmitterGuid),
    [supportItems, transmitterGuid],
  )

  const activeTargetItems = useMemo(
    () => filter(targetItems, targetItem => targetItem.supportItem.guid === transmitterGuid),
    [targetItems, transmitterGuid],
  )

  const [boundingBox, sphere] = useMemo(() => {
    const objects = [
      ...supportItemsWithoutActive,
      ...targetItemsWithoutActive,
      ...activeSupportItems,
      ...activeTargetItems,
    ]

    const box = new Box3()
    const sphere = new Sphere()

    objects.forEach(el => {
      box.expandByPoint(el.position.v)
      sphere.expandByPoint(el.position.v)
    })

    return [box, sphere]
  }, [activeSupportItems, activeTargetItems, supportItemsWithoutActive, targetItemsWithoutActive])

  return (
    <>
      <group>
        <Instances boundingBox={boundingBox} boundingSphere={sphere}>
          <cylinderGeometry attach="geometry" args={[WIDTH, WIDTH, HEIGHT, 64]} />
          <meshStandardMaterial opacity={0.5} transparent={true} />

          {supportItemsWithoutActive.map(({ position, item }, index) => {
            const hasTarget = supportTargetMap[item.guid]

            return (
              <InstancedCylinderMesh
                key={index}
                hasTarget={hasTarget}
                onClick={event => handleConnectorClick(event, item)}
                rotation={new Euler(Math.PI / 2)}
                position={position}
                height={HEIGHT}
                color={
                  hasTarget ? theme.scenePalette.elements3d.supports : theme.scenePalette.invalid
                }
              />
            )
          })}

          {targetItemsWithoutActive.map(({ position, supportItem }, index) => {
            return (
              <InstancedCylinderMesh
                key={index}
                isTarget
                onClick={event => handleConnectorClick(event, supportItem)}
                rotation={new Euler(Math.PI / 2)}
                position={position}
                height={HEIGHT}
                color={theme.scenePalette.elements3d.targets}
              />
            )
          })}
        </Instances>
      </group>

      {activeSupportItems.map(({ position, item }, index) => {
        return (
          <CylinderMesh
            onClick={event => handleConnectorClick(event, item)}
            key={index}
            position={position}
            isActive
            color={
              supportTargetMap[item.guid]
                ? theme.scenePalette.elements3d.supports
                : theme.scenePalette.invalid
            }
            disableDepthTest
          />
        )
      })}
      {activeTargetItems.map(({ position, supportItem, supportPosition }, index) => {
        return (
          <CylinderMesh
            onClick={event => handleConnectorClick(event, supportItem)}
            key={index}
            position={position}
            supportPosition={supportPosition}
            isActive
            isTarget
            color={theme.scenePalette.elements3d.targets}
            disableDepthTest
          />
        )
      })}
    </>
  )
}

export default InstancedConnectorMesh

import {
  CSSProperties,
  FormEvent,
  forwardRef,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
} from 'react'
import { useForm, FormProvider, UseFormReturn } from 'react-hook-form'
import { isArray } from 'lodash-es'
import { yupResolver } from '@hookform/resolvers/yup'
import StoreSubscription from './StoreSubscription'

interface FieldSubscription {
  name: string
  value: string | number
}

interface Props {
  onSubmit: (
    values: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    event: FormEvent<HTMLFormElement>,
    isDirty: boolean,
  ) => void | Promise<void | unknown>
  children?: ReactNode | ((methods: UseFormReturn) => ReactNode)
  id?: string
  validationSchema?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  validationContext?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  defaultValues?: { [key: string]: any | undefined } // eslint-disable-line @typescript-eslint/no-explicit-any
  enableReinitialize?: boolean
  'data-cy'?: string
  fieldSubscriptions?: FieldSubscription[] | FieldSubscription
  storeSubscription?: StoreSubscription
  style?: CSSProperties
  resetIsDirtyAfterSubmit?: boolean
}

const Form = forwardRef(function (
  {
    onSubmit,
    children,
    defaultValues = {},
    validationSchema,
    id = '',
    enableReinitialize = false,
    validationContext,
    'data-cy': dataCy,
    fieldSubscriptions,
    storeSubscription,
    style,
    resetIsDirtyAfterSubmit = false,
  }: Props,
  ref,
): ReactElement {
  const methods: UseFormReturn = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
    context: validationContext,
    ...(validationSchema ? { resolver: yupResolver(validationSchema) } : {}),
  })

  useEffect(() => {
    if (enableReinitialize) methods.reset(defaultValues)
  }, [defaultValues, enableReinitialize])

  useEffect(() => {
    if (!fieldSubscriptions) return

    if (!isArray(fieldSubscriptions)) {
      methods.setValue(fieldSubscriptions.name, fieldSubscriptions.value)
    }

    if (isArray(fieldSubscriptions)) {
      fieldSubscriptions.forEach(({ name, value }) => {
        methods.setValue(name, value)
      })
    }
  }, [fieldSubscriptions])

  const isDirty = useMemo(() => methods.formState.isDirty, [methods.formState.isDirty])

  return (
    <FormProvider {...methods}>
      {storeSubscription && <StoreSubscription {...storeSubscription} />}
      <form
        id={id}
        data-cy={dataCy || id}
        onSubmit={event => {
          // Doint most of the code twice in order to not break anything in this
          // cycle. I think the lower method can be removed once it proves to work
          if (resetIsDirtyAfterSubmit) {
            if (onSubmit) {
              event.stopPropagation()
              methods.handleSubmit(async data => {
                await onSubmit(data, event, isDirty)
                if (resetIsDirtyAfterSubmit) {
                  methods.reset(data, { keepValues: true })
                }
              })(event)
            }
          } else {
            if (onSubmit) {
              event.stopPropagation()
              methods.handleSubmit(data => onSubmit(data, event, isDirty))(event)
            }
          }
        }}
        // @ts-expect-error
        ref={ref}
        style={style}
      >
        {typeof children === 'function' ? children(methods) : children}
      </form>
    </FormProvider>
  )
})

Form.displayName = 'Form'

export default Form

import { ReactElement } from 'react'
import DeleteForever from '@mui/icons-material/DeleteForever'
import Preview from '@mui/icons-material/Preview'
import Straighten from '@mui/icons-material/Straighten'
import { Stack } from '@mui/material'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { ToggleButtonGroup, ToggleButton } from '@ui/actions'

const TapelineControls = (): ReactElement => {
  // no state picking as we use almost all store methods
  const tapelineStore = useTapelineStore()

  return (
    <Stack direction="row" spacing={1}>
      {/* we need to disable this button while marquee selection
      is active as this will also lock the camera  */}
      <ToggleButtonGroup size="small">
        <ToggleButton
          hint="Maßband zeichnen"
          value="active"
          size="small"
          onClick={() => {
            tapelineStore.setIsActive(!tapelineStore.isActive)
            tapelineStore.isVisible && tapelineStore.setIsVisible(false)
          }}
          selected={tapelineStore.isActive}
          Icon={Straighten}
        />

        <ToggleButton
          hint="Maßband anzeigen"
          value="visible"
          size="small"
          onClick={() => {
            tapelineStore.setIsVisible(!tapelineStore.isVisible)
            tapelineStore.isActive && tapelineStore.setIsActive(false)
          }}
          selected={tapelineStore.isVisible}
          Icon={Preview}
        />
      </ToggleButtonGroup>

      {tapelineStore.isActive && (
        <ToggleButton
          hint="Alle Maßbänder entfernen"
          value="delete"
          size="small"
          onClick={() => tapelineStore.removeAllLines()}
          Icon={DeleteForever}
        />
      )}
    </Stack>
  )
}

export default TapelineControls

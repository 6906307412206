export const ElementTypeEnum = {
  OuterWalls: 'outer_walls' as ElementTypes,
  InnerWalls: 'inner_walls' as ElementTypes,
  Slabs: 'slabs' as ElementTypes,
  RoofSlabs: 'roof_slabs' as ElementTypes,
  VerticalRoofSlabs: 'vertical_roof_slabs' as ElementTypes,
  Beams: 'beams' as ElementTypes,
  Columns: 'columns' as ElementTypes,
  Purlins: 'purlins' as ElementTypes,
  Rips: 'rips' as ElementTypes,
  Lintels: 'lintels' as ElementTypes,
  SlabBeams: 'slab_beams' as ElementTypes,
  RoofSlabBeams: 'roof_slab_beams' as ElementTypes,
  StandardRips: 'standard_rips' as ElementTypes,
}

export const usageClassToLabel: Record<UsageClass, string> = {
  One: 'NKL 1',
  Two: 'NKL 2',
  Three: 'NKL 3',
}

export const usageToElementType = {
  Outer: 'outer_walls',
  Inner: 'inner_walls',
  Ceiling: 'slabs',
  Roof: 'roof_slabs',
}

export const MemberCrossSectionKindEnum = Object.freeze({
  CrossSection: 'CrossSection' as MemberCrossSectionKind,
  CLT: 'CLT' as MemberCrossSectionKind,
})

export const seismicStandardOptions = [
  // DIN 4109 was a typo in the backend, but it would require a migration to change it
  // mapping it to DIN 4149 label to display correctly in the FE
  { value: 'DIN_4109', label: 'DIN 4149' },
  { value: 'SIA_261', label: 'SIA 261' },
  { value: 'DIN_EN_1998', label: 'DIN EN 1998' },
]

export const groundTypeCombinationOptions = [
  { value: 'A-R', label: 'A-R' },
  { value: 'B-R', label: 'B-R' },
  { value: 'C-R', label: 'C-R' },
  { value: 'B-T', label: 'B-T' },
  { value: 'C-T', label: 'C-T' },
  { value: 'C-S', label: 'C-S' },
]

export const SIA_groundTypeCombinationOptions = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'E', label: 'E' },
]

export const seismicZoneOptions = [
  { value: 'Zone 1', label: 'Zone 1' },
  { value: 'Zone 2', label: 'Zone 2' },
  { value: 'Zone 3', label: 'Zone 3' },
]

export const SIA_seismicZoneOptions = [
  { value: 'Z_1_A', label: 'Z1a' },
  { value: 'Z_1_B', label: 'Z1b' },
  { value: 'Z_2', label: 'Z2' },
  { value: 'Z_3_A', label: 'Z3a' },
  { value: 'Z_3_B', label: 'Z3b' },
]

import { MutableRefObject } from 'react'
import { isUndefined, toNumber } from 'lodash-es'
import { Typography } from '@mui/material'
import {
  getGridNumericOperators,
  GridColDef,
  GridComparatorFn,
  GridFilterItem,
  GridRenderCellParams,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid-premium'
import { GridApiCommunity } from '@mui/x-data-grid/models/api/gridApiCommunity'
import { formulaToNumber } from '@utils'
import { loadCaseTypeComparator } from 'src/utils/loads'
import { fieldToHeaderName } from './constants'

export const manualSourceSetter =
  (key: string) => (val: string, row: AnchorInterventionsTableRowData) => {
    if (val === 'empty') return { ...row, [key]: undefined }

    const valAsNumber = toNumber(val)
    const manual_source = isNaN(valAsNumber) ? val : valAsNumber * 1000
    return { ...row, [key]: manual_source }
  }

export const autoRowHeight: (params: GridRowHeightParams) => GridRowHeightReturnValue = () => 'auto'

export const manualLoadSetter =
  (field: `manual_load_${1 | 2 | 3}`) => (val: string, row: AnchorInterventionsTableRowData) => ({
    ...row,
    [field]: val === '' ? undefined : typeof val === 'string' ? val.trim() : val,
  })

const numericOperators = getGridNumericOperators()

const numericOperatorsWithFormula = numericOperators.map(operator => ({
  ...operator,
  getApplyFilterFn: (
    filterItem: GridFilterItem,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    column: GridColDef<any, string | number | null, any>,
  ) => {
    return (
      value: string,
      row: AnchorInterventionsTableRowData,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      columnInner: GridColDef<AnchorInterventionsTableRowData, any, any>,
      apiRef: MutableRefObject<GridApiCommunity>,
    ) => {
      let parsedValue: string | number | null = null
      if (!isUndefined(filterItem.value)) {
        try {
          parsedValue = formulaToNumber(value).toString()
        } catch {
          // Do nothing
        }
      }

      return operator.getApplyFilterFn(filterItem, column)?.(
        parsedValue,
        row,
        columnInner,
        apiRef,
      ) as boolean
    }
  },
}))

export const createManualLoadField = (
  fieldName: `manual_load_${1 | 2 | 3}`,
): GridColDef<AnchorInterventionsTableRowData> => ({
  ...fieldToHeaderName[fieldName],
  renderHeader: () => <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>Last</Typography>,
  headerAlign: 'center',
  editable: true,
  align: 'right',
  width: 75,
  renderCell: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: GridRenderCellParams<AnchorInterventionsTableRowData, any, any, GridTreeNodeWithRender>,
  ) => {
    try {
      if (params.cellMode === 'view') {
        return formulaToNumber(params.value).toFixed(2)
      }
    } catch {
      // Do nothing
    }
    return params.value
  },
  valueSetter: manualLoadSetter(fieldName),
  groupable: false,
  getApplyQuickFilterFn: quickFilterDisabledFn,
  filterOperators: numericOperatorsWithFormula,
})

export const quickFilterDisabledFn = () => {
  return (): boolean => {
    return false
  }
}

export const loadCaseTypeGridComparator: GridComparatorFn<LoadCaseTypes> = (v1, v2) => {
  return loadCaseTypeComparator(v1, v2)
}

import { ReactElement, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useMaxUtilizationOver1, useResultsQueryParams } from '@resultsHooks'
import { filter, find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useTheme } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { ColoredTab } from '@ui/navigation'
import { Box } from '@ui/structure'
import { useModelStore, useResultsStore } from '@editorStores'
import { downLoadFile } from '@utils'
import { getPositionGroupingExport } from '@mutations'
import addUtilizationToPositionBundle from '../../utils/maxUtilizationToMemberPositionBundle'
import { WallSubTab, GroupingsTab } from './components'
import { positionGroupingTypeToFilename, tabConfig } from './constants'

const GlobalTabs = (): ReactElement => {
  const { palette } = useTheme()

  const { projectId } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const {
    params: { tab, selectedElements },
    actions: { setTab },
  } = useResultsQueryParams()

  const project = useModelStore(state => state.project)

  const structuralChecks = useResultsStore(state => state.structuralChecks)

  const slabBeamsPositionGrouping = useResultsStore(state => state.slabBeamsPositionGrouping)
  const roofSlabBeamsPositionGrouping = useResultsStore(
    state => state.roofSlabBeamsPositionGrouping,
  )
  const columnsPositionGrouping = useResultsStore(state => state.columnsPositionGrouping)
  const purlinsPositionGrouping = useResultsStore(state => state.purlinsPositionGrouping)
  const beamsPositionGrouping = useResultsStore(state => state.beamsPositionGrouping)

  const designForces = useResultsStore(state => state.designForces)
  const settings = useResultsStore(state => state.memberCheckSettings)

  // MEMOS

  const selectedElement = useMemo(
    () => (selectedElements?.length === 1 ? selectedElements?.[0] : null),
    [selectedElements],
  )

  const slabBeamsPositionGroupingWithUtilization = useMemo(
    () =>
      slabBeamsPositionGrouping && structuralChecks
        ? addUtilizationToPositionBundle(slabBeamsPositionGrouping, structuralChecks)
        : [],
    [slabBeamsPositionGrouping, structuralChecks],
  )

  const anySlabBeamUtilizationOver1 = useMaxUtilizationOver1(
    slabBeamsPositionGroupingWithUtilization,
  )

  const roofSlabBeamsPositionGroupingWithUtilization = useMemo(
    () =>
      roofSlabBeamsPositionGrouping && structuralChecks
        ? addUtilizationToPositionBundle(roofSlabBeamsPositionGrouping, structuralChecks)
        : [],
    [roofSlabBeamsPositionGrouping, structuralChecks],
  )

  const anyRoofSlabBeamUtilizationOver1 = useMaxUtilizationOver1(
    roofSlabBeamsPositionGroupingWithUtilization,
  )

  const columnsPositionGroupingWithUtilization = useMemo(
    () =>
      columnsPositionGrouping && structuralChecks
        ? addUtilizationToPositionBundle(columnsPositionGrouping, structuralChecks)
        : [],
    [columnsPositionGrouping, structuralChecks],
  )

  const anyColumnUtilizationOver1 = useMaxUtilizationOver1(columnsPositionGroupingWithUtilization)

  const purlinsPositionGroupingWithUtilization = useMemo(
    () =>
      purlinsPositionGrouping && structuralChecks
        ? addUtilizationToPositionBundle(purlinsPositionGrouping, structuralChecks)
        : [],
    [purlinsPositionGrouping, structuralChecks],
  )

  const anyPurlinUtilizationOver1 = useMaxUtilizationOver1(purlinsPositionGroupingWithUtilization)

  const beamsPositionGroupingWithUtilization = useMemo(
    () =>
      beamsPositionGrouping && structuralChecks
        ? addUtilizationToPositionBundle(beamsPositionGrouping, structuralChecks)
        : [],
    [beamsPositionGrouping, structuralChecks],
  )

  const anyBeamUtilizationOver1 = useMaxUtilizationOver1(beamsPositionGroupingWithUtilization)

  const { mutate: downloadExportDocument, isLoading: isDownloadingExportDocument } = useMutation(
    async (type: PositionGroupingType) => {
      const data = await getPositionGroupingExport.request(projectId as string, type)
      return {
        data,
        filename: `Bauteilliste ${positionGroupingTypeToFilename[type]} - ${
          project.name || projectId
        }.xlsx`,
      }
    },
    {
      onSuccess: async ({ data, filename }) => {
        if (!data) {
          enqueueSnackbar('Daten noch nicht vorhanden', { variant: 'warning' })
          return
        }

        downLoadFile(
          data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          filename,
        )
      },
      onError: () => {
        enqueueSnackbar('Fehler beim Abrufen des Dokuments', { variant: 'error' })
      },
    },
  )

  const selectedElementDesignForces = useMemo(
    () => find(designForces, ['element_guid', selectedElement]),
    [designForces, selectedElement],
  )

  const checksOfPosition = useMemo(
    () =>
      structuralChecks
        ? filter(structuralChecks, { element_guid: selectedElement || undefined })
        : [],
    [structuralChecks, selectedElement],
  )

  const settingsOnMember: SettingsOnMember | null = useMemo(
    () => find(settings, { element_guid: selectedElement || undefined }) || null,
    [settings, selectedElement],
  )

  const tabToColor: { [k in keyof typeof tabConfig]?: string | undefined } = {
    beams: anyBeamUtilizationOver1 ? palette.error.main : undefined,
    columns: anyColumnUtilizationOver1 ? palette.error.main : undefined,
    'slab-beams': anySlabBeamUtilizationOver1 ? palette.error.main : undefined,
    'roof-slab-beams': anyRoofSlabBeamUtilizationOver1 ? palette.error.main : undefined,
    purlins: anyPurlinUtilizationOver1 ? palette.error.main : undefined,
  }

  return (
    <Box>
      <TabContext value={tab || 'walls'}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <TabList onChange={(e, v) => setTab(v)} variant="scrollable" scrollButtons="auto">
            {Object.values(tabConfig).map(({ value, label }) => (
              <ColoredTab
                key={value}
                value={value}
                label={label}
                data-cy={`tab-${value}`}
                tabColor={tabToColor[value]}
              />
            ))}
          </TabList>
        </Box>

        <TabPanel value={tabConfig.walls.value} sx={{ paddingBottom: 0 }}>
          <WallSubTab />
        </TabPanel>

        <TabPanel value={tabConfig['slab-beams'].value}>
          <GroupingsTab
            grouping={slabBeamsPositionGroupingWithUtilization}
            isDownloadingExportDocument={isDownloadingExportDocument}
            onClickDownloadExportDocument={() => downloadExportDocument('slab-beams')}
            positionType="slab-beams"
            designForces={selectedElementDesignForces?.design_support_forces}
            checksOfPosition={checksOfPosition}
            settingsOnMember={settingsOnMember}
          />
        </TabPanel>

        <TabPanel value={tabConfig['roof-slab-beams'].value}>
          <GroupingsTab
            grouping={roofSlabBeamsPositionGroupingWithUtilization}
            isDownloadingExportDocument={isDownloadingExportDocument}
            onClickDownloadExportDocument={() => downloadExportDocument('roof-slab-beams')}
            positionType="roof-slab-beams"
            designForces={selectedElementDesignForces?.design_support_forces}
            checksOfPosition={checksOfPosition}
            settingsOnMember={settingsOnMember}
          />
        </TabPanel>

        <TabPanel value={tabConfig.beams.value}>
          <GroupingsTab
            grouping={beamsPositionGroupingWithUtilization}
            isDownloadingExportDocument={isDownloadingExportDocument}
            onClickDownloadExportDocument={() => downloadExportDocument('beams')}
            positionType="beams"
            designForces={selectedElementDesignForces?.design_support_forces}
            checksOfPosition={checksOfPosition}
            settingsOnMember={settingsOnMember}
          />
        </TabPanel>

        <TabPanel value={tabConfig.purlins.value}>
          <GroupingsTab
            grouping={purlinsPositionGroupingWithUtilization}
            isDownloadingExportDocument={isDownloadingExportDocument}
            onClickDownloadExportDocument={() => downloadExportDocument('purlins')}
            positionType="purlins"
            designForces={selectedElementDesignForces?.design_support_forces}
            checksOfPosition={checksOfPosition}
            settingsOnMember={settingsOnMember}
          />
        </TabPanel>

        <TabPanel value={tabConfig.columns.value}>
          <GroupingsTab
            grouping={columnsPositionGroupingWithUtilization}
            isDownloadingExportDocument={isDownloadingExportDocument}
            onClickDownloadExportDocument={() => downloadExportDocument('columns')}
            positionType="columns"
            designForces={selectedElementDesignForces?.design_support_forces}
            checksOfPosition={checksOfPosition}
            settingsOnMember={settingsOnMember}
          />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default GlobalTabs

import { isEmpty, isUndefined } from 'lodash-es'
import { object, string } from 'yup'

const createElementCrossSectionSelectionSchema = (label: string) => {
  return string()
    .default(undefined)
    .test({
      name: 'require-standard-cs',
      test: function (value: string | undefined) {
        if (isUndefined(value) || isEmpty(value)) {
          return this.createError({
            message: `Bitte wählen Sie für ${label} einen Standard-Querschnitt fest`,
          })
        }

        return true
      },
    })
}

export const schema = object({
  beam: createElementCrossSectionSelectionSchema('Unterzüge'),
  purlin: createElementCrossSectionSelectionSchema('Pfetten'),
  column: createElementCrossSectionSelectionSchema('Stützen'),
})

import { ReactElement, useMemo } from 'react'
import convert from 'convert'
import { isNull } from 'lodash-es'
import numeral from 'numeral'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material'
import {
  fireExposedSidesToReadable,
  mPerSecondToMmPerMinute,
} from './AdditionalCheckInformation/utils'

interface Props {
  check: ReducedCrossSectionFireCheckCLT
}

const AdditionalReducedCrossSectionFireCheckInformationCLT = ({
  check,
}: Props): ReactElement | null => {
  const renderTableBody = (check: ReducedCrossSectionFireCheckCLT): ReactElement | null => {
    return (
      <>
        {!isNull(check.fire_duration) && (
          <TableRow>
            <TableCell>t</TableCell>
            <TableCell>
              {numeral(convert(check.fire_duration, 'second').to('minute')).format('0,0.00a')} min
            </TableCell>
          </TableRow>
        )}

        {!isNull(check.fire_exposed_sides) && (
          <TableRow>
            <TableCell>brandbeanspruchte Seiten</TableCell>
            <TableCell>{fireExposedSidesToReadable(check.fire_exposed_sides)}</TableCell>
          </TableRow>
        )}

        {!isNull(check.beta_n) && (
          <TableRow>
            <TableCell>
              β<sub>n</sub>
            </TableCell>
            <TableCell>
              {numeral(mPerSecondToMmPerMinute(check.beta_n)).format('0,0.00a')} mm/min
            </TableCell>
          </TableRow>
        )}

        {!isNull(check.notional_charring_depth_top) && (
          <TableRow>
            <TableCell>
              d<sub>char,n</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.notional_charring_depth_top, 'meter').to('millimeter')).format(
                '0,0.00a',
              )}{' '}
              mm
            </TableCell>
          </TableRow>
        )}

        {!isNull(check.notional_charring_depth_bottom) && (
          <TableRow>
            <TableCell>
              d<sub>char,n</sub>
            </TableCell>
            <TableCell>
              {numeral(
                convert(check.notional_charring_depth_bottom, 'meter').to('millimeter'),
              ).format('0,0.00a')}{' '}
              mm
            </TableCell>
          </TableRow>
        )}

        {!isNull(check.notional_charring_depth_left) && (
          <TableRow>
            <TableCell>
              d<sub>char,n</sub>
            </TableCell>
            <TableCell>
              {numeral(
                convert(check.notional_charring_depth_left, 'meter').to('millimeter'),
              ).format('0,0.00a')}{' '}
              mm
            </TableCell>
          </TableRow>
        )}

        {!isNull(check.notional_charring_depth_right) && (
          <TableRow>
            <TableCell>
              d<sub>char,n</sub>
            </TableCell>
            <TableCell>
              {numeral(
                convert(check.notional_charring_depth_right, 'meter').to('millimeter'),
              ).format('0,0.00a')}{' '}
              mm
            </TableCell>
          </TableRow>
        )}

        {!isNull(check.k_0) && (
          <TableRow>
            <TableCell>
              k<sub>0</sub>
            </TableCell>
            <TableCell>{numeral(check.k_0).format('0,0.00a')}</TableCell>
          </TableRow>
        )}

        {!isNull(check.d_0) && (
          <TableRow>
            <TableCell>
              d<sub>0</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.d_0, 'meter').to('millimeter')).format('0,0.00a')} mm
            </TableCell>
          </TableRow>
        )}

        {!isNull(check.effective_charring_depth_top) && (
          <TableRow>
            <TableCell>
              d<sub>eff</sub>
            </TableCell>
            <TableCell>
              {numeral(
                convert(check.effective_charring_depth_top, 'meter').to('millimeter'),
              ).format('0,0.00a')}{' '}
              mm
            </TableCell>
          </TableRow>
        )}

        {!isNull(check.effective_charring_depth_bottom) && (
          <TableRow>
            <TableCell>
              d<sub>eff</sub>
            </TableCell>
            <TableCell>
              {numeral(
                convert(check.effective_charring_depth_bottom, 'meter').to('millimeter'),
              ).format('0,0.00a')}{' '}
              mm
            </TableCell>
          </TableRow>
        )}

        {!isNull(check.effective_charring_depth_left) && (
          <TableRow>
            <TableCell>
              d<sub>eff</sub>
            </TableCell>
            <TableCell>
              {numeral(
                convert(check.effective_charring_depth_left, 'meter').to('millimeter'),
              ).format('0,0.00a')}{' '}
              mm
            </TableCell>
          </TableRow>
        )}

        {!isNull(check.effective_charring_depth_right) && (
          <TableRow>
            <TableCell>
              d<sub>eff</sub>
            </TableCell>
            <TableCell>
              {numeral(
                convert(check.effective_charring_depth_right, 'meter').to('millimeter'),
              ).format('0,0.00a')}{' '}
              mm
            </TableCell>
          </TableRow>
        )}

        {!isNull(check.reduced_height) && (
          <TableRow>
            <TableCell>
              h<sub>eff</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.reduced_height, 'meter').to('centimeter')).format('0,0.00a')}{' '}
              cm
            </TableCell>
          </TableRow>
        )}

        {!isNull(check.reduced_width) && (
          <TableRow>
            <TableCell>
              b<sub>eff</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.reduced_width, 'meter').to('centimeter')).format('0,0.00a')} cm
            </TableCell>
          </TableRow>
        )}
      </>
    )
  }

  const tableBody = useMemo(() => renderTableBody(check), [check])

  if (isNull(tableBody)) return null

  return (
    <TableContainer>
      <Table
        stickyHeader
        size="small"
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Berechnung</TableCell>

            <TableCell>Wert</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>{tableBody}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default AdditionalReducedCrossSectionFireCheckInformationCLT

import { transform, capitalize } from 'lodash'
import { isNull } from 'lodash-es'
import { getRequest } from 'src/state/utils'

export const getSelectedAssemblies: RequestTypeVariable = {
  getKey: (projectId: string) => ['getSelectedAssemblies', projectId],
  request: async (projectId): Promise<{ [key: string]: SelectedAssembly } | null> => {
    const { data } = await getRequest<{ [key: string]: SelectedAssembly }>({
      url: `projects/${projectId}/selected-assemblies`,
    })

    // object key casing is not compatible with rest of types
    return isNull(data)
      ? data
      : transform(
          data as { [key: string]: SelectedAssembly },
          (result: { [key: string]: SelectedAssembly }, val, key: string) => {
            result[capitalize(key)] = val
          },
        )
  },
}

export const getAssemblyAssignment: RequestTypeVariable = {
  getKey: (projectId: string) => ['getAssemblyAssignment', projectId],
  request: async (projectId: string): Promise<AssemblyAssignment[]> => {
    const { data } = await getRequest<AssemblyAssignment[]>({
      url: `/projects/${projectId}/assembly-assignment`,
    })

    return data as AssemblyAssignment[]
  },
}

export const getAssemblies: RequestType = {
  key: ['getAssemblies'],
  request: async (): Promise<Assembly[]> => {
    const { data } = await getRequest<Assembly[]>({
      url: '/assemblies',
    })

    return data as Assembly[]
  },
}

export const getProjectAssemblies: RequestTypeVariable = {
  getKey: id => ['getProjectAssemblies', id],
  request: async (id: string): Promise<Assembly[]> => {
    const { data } = await getRequest<Assembly[]>({
      url: `/projects/${id}/assemblies`,
    })

    return data as Assembly[]
  },
}

export const getSoftWoodMaterials: RequestType = {
  key: ['getSoftWoodMaterials'],
  request: async (): Promise<Material[]> => {
    const { data } = await getRequest<Material[]>({
      url: '/products/softwood-materials',
    })

    return data as Material[]
  },
}

export const getSteelCSShapes: RequestType = {
  key: ['getSteelCSShapes'],
  request: async (): Promise<SteelCSShape[]> => {
    const { data } = await getRequest<SteelCSShape[]>({
      url: '/products/steel-cs-shapes',
    })

    return data as SteelCSShape[]
  },
}

export const getSteelMaterials: RequestType = {
  key: ['getSteelMaterials'],
  request: async (): Promise<Material[]> => {
    const { data } = await getRequest<Material[]>({
      url: '/products/steel-materials',
    })

    return data as Material[]
  },
}

export const getGluelamMaterials: RequestType = {
  key: ['getGluelamMaterials'],
  request: async (): Promise<Material[]> => {
    const { data } = await getRequest<Material[]>({
      url: '/products/glulam-materials',
    })

    return data as Material[]
  },
}

export const getStructuralPlates: RequestType = {
  key: ['getStructuralPlates'],
  request: async (): Promise<PlateCrossSection[]> => {
    const { data } = await getRequest<PlateCrossSection[]>({
      url: '/products/plates',
    })

    return data as PlateCrossSection[]
  },
}

export const getFasteners: RequestType = {
  key: ['getFasteners'],
  request: async (): Promise<Fastener[]> => {
    const { data } = await getRequest<Fastener[]>({
      url: '/products/staples',
    })

    return data as Fastener[]
  },
}

export const getCLTCrossSections: RequestType = {
  key: ['getCLTCrossSections'],
  request: async (): Promise<CLTCrossSection[]> => {
    const { data } = await getRequest<CLTCrossSection[]>({
      url: '/products/clt-cross-sections',
    })

    return data as CLTCrossSection[]
  },
}

export const getProjectAssemblyExportDocument: RequestTypeVariable = {
  getKey: (projectId: string, assemblyId: string) => [
    'getProjectAssemblyExportDocument',
    projectId,
    assemblyId,
  ],
  request: async (projectId: string, assemblyId: string): Promise<Blob> => {
    const res = await getRequest<Blob>({
      url: `/projects/${projectId}/assemblies/${assemblyId}/export`,
      responseType: 'blob',
    })
    return res.data as Blob
  },
}

export const getAssemblyExportDocument: RequestTypeVariable = {
  getKey: (assemblyId: string) => ['getAssemblyExportDocument', assemblyId],
  request: async (assemblyId: string): Promise<Blob> => {
    const res = await getRequest<Blob>({
      url: `/assemblies/${assemblyId}/export`,
      responseType: 'blob',
    })
    return res.data as Blob
  },
}

import { ReactElement } from 'react'
import { ColorRepresentation, Euler } from 'three'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { a, useSpring } from '@react-spring/three'
import { ThreeEvent } from '@react-three/fiber'

interface Props {
  position: ImmutableVector3
  color?: string | ColorRepresentation
  onClick?: (event: ThreeEvent<MouseEvent>) => void
  isActive?: boolean
  disableDepthTest?: boolean
  maxScale?: number
  height?: number
}

const SimpleCylinderMesh = ({
  position,
  color = 'green',
  onClick,
  isActive = false,
  disableDepthTest = false,
  maxScale = 1.7,
  height = 0.3,
}: Props): ReactElement => {
  const WIDTH = 0.06

  const { scale } = useSpring({
    to: async next => {
      // eslint-disable-next-line no-unmodified-loop-condition
      while (isActive) {
        await next({ scale: 1 })
        await next({ scale: maxScale })
      }
      if (!isActive) {
        await next({ scale: 1 })
      }
    },
    from: { scale: 1 },
    reset: true,
    config: { duration: 800 },
  })

  return (
    <a.mesh
      onClick={onClick}
      rotation={new Euler(Math.PI / 2)}
      position={position.v}
      scale={scale}
      onPointerOver={() => {
        document.body.style.cursor = 'pointer'
      }}
      onPointerOut={() => {
        document.body.style.cursor = 'auto'
      }}
    >
      <cylinderGeometry attach="geometry" args={[WIDTH, WIDTH, height, 64]} />
      <meshStandardMaterial
        color={color as ColorRepresentation}
        opacity={isActive ? 0.9 : 0.5}
        transparent={true}
        depthTest={!disableDepthTest}
      />
    </a.mesh>
  )
}

export default SimpleCylinderMesh

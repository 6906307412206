const MEMBER_CHECK_TYPE_SORT_ORDER = [
  'TensionOnly',
  'CompressionOnly',
  'BendingY',
  'BendingZ',
  'BendingYZ',
  'BendingWithTension',
  'BendingWithCompression',
  'ShearStressY',
  'ShearStressZ',
  'TwoWayShearStress',
  'TwoWayShearStressCLTParallelLayer',
  'TwoWayShearStressCLTOrthogonalLayer',
  'SupportCompression',
  'DisplacementInstY',
  'DisplacementInstZ',
  'DisplacementInstYZ',
  'DisplacementNetFinY',
  'DisplacementNetFinZ',
  'DisplacementNetFinYZ',
  'Vibration',
  'HotDimensioning',
  'HotDimensioningCLT',
  'FireProtectionRip',
  'TensionOnlyHot',
  'CompressionOnlyHot',
  'BendingYZHot',
  'BendingWithTensionHot',
  'BendingWithCompressionHot',
  'TwoWayShearStressHot',
  'TwoWayShearStressCLTParallelLayerHot',
  'TwoWayShearStressCLTOrthogonalLayerHot',
  'SteelCompression',
  'SteelBasicCheck',
  'SteelSupportCompression',
  'SteelBasePlateWeld',
  'SteelDisplacement',
] as const

export const getCheckTypeSortValue = (checkType: MemberCheckType): number => {
  const index = MEMBER_CHECK_TYPE_SORT_ORDER.indexOf(
    checkType as typeof MEMBER_CHECK_TYPE_SORT_ORDER[number],
  )
  if (index === -1) {
    return MEMBER_CHECK_TYPE_SORT_ORDER.length
  }
  return index
}

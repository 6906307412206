import { ReactElement, ReactNode } from 'react'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'

interface Props {
  onClick?: () => void
  open: boolean
  active: boolean
  transparent: boolean
  label: string
  icon: ReactNode
  children?: ReactNode
}

const NavigationItem = ({
  onClick,
  open,
  active,
  transparent,
  label,
  icon,
  children,
}: Props): ReactElement => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        width: '100%',
        background: ({ palette }) =>
          (open && active) || (active && !open && !transparent) ? palette.grey[100] : 'transparent',
        border: ({ palette }) =>
          `1px solid ${!open && active && transparent ? palette.grey[400] : 'transparent'}`,
        borderRadius: '4px',
        marginRight: ({ spacing }) => (open ? spacing(1) : 0),
        paddingLeft: '1px',

        '&:hover': {
          cursor: active ? 'pointer' : 'default',
        },

        '&:after': {
          // content: active && index + 1 !== itemsLength ? '""' : 'none',
          position: 'absolute',
          bottom: 0,
          left: '16px',
          // left: '28px',
          transform: ({ spacing }) => `translateY(calc(100% + ${spacing(1)}))`,
          height: ({ spacing }) => spacing(5),
          width: '1px',
          background: ({ palette }) => palette.grey[700],
        },
      }}
    >
      <Stack direction="column" spacing={0.5} width="100%">
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            paddingLeft: active ? '8px' : undefined,
            transition: 'padding-left 0.25s ease',
          }}
        >
          <Tooltip title={open ? '' : label} placement="right">
            <IconButton sx={{ background: 'transparent !important' }}>{icon}</IconButton>
          </Tooltip>
          <Typography
            sx={{
              display: open ? 'block' : 'none',
              fontSize: 12,
              fontWeight: 500,
              marginLeft: ({ spacing }) => spacing(1),
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            {label}
          </Typography>
        </Stack>

        {children}
      </Stack>
    </Box>
  )
}

export default NavigationItem

import { ReactElement, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { flatten, toNumber } from 'lodash-es'
import { Divider, List, ListItem, ListItemButton, ListItemText, Stack } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid-premium'
import { ErrorFieldBase } from '@ui/forms'
import { useElementLabel } from '@editorHooks'
import { fieldToHeaderName } from '../../constants'
import useSegmentLabel from '../../hooks/useSegmentLabel'

interface ErrorMessage {
  rowId: string
  elementLabel: string
  segmentLabel: string
  anchorLabel: number
  message: string
  fieldName: keyof typeof fieldToHeaderName
  supportGuid: string
}

interface Props {
  anchorNameToNumber: Record<string, number>
  onClickError: (rowId: string, fieldName: string) => void
}

const ErrorsPopupContent = ({ anchorNameToNumber, onClickError }: Props): ReactElement => {
  const {
    formState: { errors },
    watch,
  } = useFormContext()

  const getLabel = useElementLabel()
  const getSegmentLabel = useSegmentLabel()

  const rows: GridRowsProp<AnchorInterventionsTableRowData> = watch('rows')

  const errorMessages: ErrorMessage[] = useMemo(() => {
    const errorMessages =
      'rows' in errors
        ? flatten(
            Object.entries(errors.rows as object).map(([rowId, error]) => {
              const row = rows[toNumber(rowId)]

              const elementLabel = getLabel(row.element_guid)
              const segmentLabel = getSegmentLabel(row.segment_guid || '', row.element_guid)
              const anchorLabel = anchorNameToNumber[row.support_guid]

              return Object.entries(error).map(([fieldName, fieldError]) => {
                return {
                  rowId,
                  elementLabel,
                  segmentLabel,
                  anchorLabel,
                  supportGuid: row.support_guid,
                  fieldName: fieldName as keyof typeof fieldToHeaderName,
                  message:
                    // @ts-expect-error
                    fieldError.message,
                }
              })
            }),
          )
        : []

    return errorMessages
  }, [anchorNameToNumber, errors, getLabel, getSegmentLabel, rows])

  return (
    <List sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper' }}>
      <Stack direction="column" p={2} divider={<Divider />} spacing={1}>
        {errorMessages.map(error => (
          <ListItem
            alignItems="flex-start"
            key={
              error.elementLabel +
              error.segmentLabel +
              error.anchorLabel +
              error.fieldName +
              error.message
            }
          >
            <ListItemButton onClick={() => onClickError(error.supportGuid, error.fieldName)}>
              <Stack direction="column">
                <ListItemText
                  primary={`Fehler bei Zuganker ${error.anchorLabel} von Segment ${error.segmentLabel} des Elements ${error.elementLabel}`}
                  secondary={<>Feld: {fieldToHeaderName[error.fieldName].headerName}</>}
                />
                <ErrorFieldBase message={error?.message as string} severity="error" />
              </Stack>
            </ListItemButton>
          </ListItem>
        ))}
      </Stack>
    </List>
  )
}

export default ErrorsPopupContent

import { isHotkeyPressed } from 'react-hotkeys-hook'
import { useSnackbar } from 'notistack'
import useModelClickListeners from './useModelClickListeners'

interface Options {
  addElement: (elementId: string) => void
  removeElement: (elementId: string) => void
  selectedElements: string[]
  shouldBeAdded?: (elementGuid: string, selectedElements: string[]) => boolean
}

/**
 * Right now implementation of selected elements differs from view to view
 * (results/structural planning). Right now we do not have one approach that is
 * reused throughout the app. The idea of this hook is to have one centralised
 * interface to call for multi selection purposes that can be used to have one
 * final approach in the future. This will work by gradually moving to the same
 * behaviour (e.g. using the same stores instead of the query params for the
 * selected elements).
 *
 * Behaviour: While shift key is active multi selection will be active. Every
 * element clicked in the scene will be added to selection
 */
const useMultiSelection = ({
  addElement,
  removeElement,
  selectedElements,
  shouldBeAdded = () => true,
}: Options) => {
  const { enqueueSnackbar } = useSnackbar()

  useModelClickListeners(
    event => {
      const guid = event.object.name

      const isShiftPressed = isHotkeyPressed('shift')

      if (isShiftPressed) {
        if (selectedElements.includes(guid)) {
          removeElement(guid)
        } else if (shouldBeAdded(guid, selectedElements)) {
          addElement(guid)
        } else
          enqueueSnackbar({
            message:
              'Das Element kann nicht zur bestehenden Auswahl hinzugefügt werden (z.B. weil man Dächer nicht zusammen mit Unterzügen editieren kann)',
            variant: 'info',
          })
      }
    },
    [addElement, selectedElements, shouldBeAdded],
    true,
    false,
    true,
  )

  return {
    selectedElements,
  }
}

export default useMultiSelection

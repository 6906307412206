import { getRequest, postRequest, retryUntilSuccessOrFailure } from '../utils'

export const getMemberCheckSettings: RequestTypeVariable = {
  getKey: id => ['getMemberCheckSettings', id],
  request: async (id: string): Promise<SettingsOnMember[]> => {
    const { data } = await getRequest<SettingsOnMember[]>({
      url: `/projects/${id}/member-check-settings`,
    })

    return data as SettingsOnMember[]
  },
}

export const getAnchorChecks: RequestTypeVariable = {
  getKey: id => ['getAnchorChecks', id],
  request: async (id: string): Promise<AnchorCheckOnSupport[]> => {
    const { data } = await getRequest<AnchorCheckOnSupport[]>({
      url: `/projects/${id}/decisive-anchor-checks`,
    })

    return data as AnchorCheckOnSupport[]
  },
}

export const getCalcChecksForElement = {
  getKey: (
    id: string,
    elementGuid: string,
    settings: SettingsOnMember,
    crossSection: CrossSection,
  ) => [
    'getCalcChecksForElement',
    id,
    elementGuid,
    JSON.stringify(settings),
    JSON.stringify(crossSection),
  ],
  request: async (
    projectId: string,
    elementGuid: string,
    settings: SettingsOnMember,
    crossSection: CrossSection,
  ): Promise<CrossSectionSuggestion> => {
    const dataForRequest = {
      element_guid: elementGuid,
      settings: settings,
      crossSection: crossSection,
    }

    const { data } = await postRequest<CrossSectionSuggestion>({
      url: `/projects/${projectId}/calc-element-checks`,
      data: dataForRequest,
    })

    return data as CrossSectionSuggestion
  },
}

export const getDefaultCheckSettingsForElement = {
  getKey: (id: string, elementGuid: string, newCS: CrossSection) => [
    'getDefaultCheckSettingsForElement',
    id,
    elementGuid,
    JSON.stringify(newCS),
  ],
  request: async (
    projectId: string,
    elementGuid: string,
    newCS: CrossSection,
  ): Promise<GetDefaultCheckSettingsForElementResponse> => {
    const dataForRequest = {
      new_cs: newCS,
    }

    const { data } = await postRequest<GetDefaultCheckSettingsForElementResponse>({
      url: `/projects/${projectId}/updated-check-settings/${elementGuid}`,
      data: dataForRequest,
    })

    return data as GetDefaultCheckSettingsForElementResponse
  },
}

export const postCalcPositionChecks: RequestTypeVariable = {
  getKey: (projectId: string) => ['postCalcPositionChecks', projectId],
  request: async (id: string, elementGuids: string[]): Promise<void> => {
    const { data } = await postRequest<{ task_id: string }>({
      url: `/projects/${id}/calc-position-checks/compute`,
      data: {
        elements: elementGuids,
      },
    })

    await retryUntilSuccessOrFailure({
      url: `/task-status/${data?.task_id}`,
    })
  },
}

import { MenuItem, FormGroup, Tooltip, FormControlLabel, Switch, Stack, Box } from '@mui/material'

interface Props {
  checked: boolean
  setChecked: (checked: boolean) => void
  tooltipTitle: string
  menuItemLabel: string
  matureness: 'mature' | 'experimental'
}

const MenuToggleWithMatureness = ({
  checked,
  setChecked,
  tooltipTitle,
  menuItemLabel,
  matureness,
}: Props) => {
  return (
    <MenuItem>
      <FormGroup>
        <Tooltip
          title={`${tooltipTitle} ${matureness === 'mature' ? '(ausgereift)' : '(experimentell)'}`}
          placement="left"
        >
          <FormControlLabel
            control={<Switch checked={checked} onChange={e => setChecked(e.target.checked)} />}
            label={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box>{menuItemLabel}</Box>
                <Box
                  width={12}
                  height={12}
                  borderRadius={12}
                  sx={theme => ({
                    backgroundColor:
                      matureness === 'mature'
                        ? theme.palette.success.main
                        : theme.palette.warning.main,
                  })}
                ></Box>
              </Stack>
            }
          />
        </Tooltip>
      </FormGroup>
    </MenuItem>
  )
}

export default MenuToggleWithMatureness

import { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid } from '@mui/material'
import { Select, TextField, UnitConvertedField } from '@ui/forms'
import {
  SIA_groundTypeCombinationOptions,
  groundTypeCombinationOptions,
  seismicStandardOptions,
  seismicZoneOptions,
  SIA_seismicZoneOptions,
} from '../constants'

const SeismicBaseParameters = (): ReactElement => {
  const { watch } = useFormContext()

  const seismicStandard: SeismicStandard = watch('standard_used')

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Select label="Norm" name="standard_used" options={seismicStandardOptions} />
      </Grid>

      {seismicStandard === 'DIN_EN_1998' ? (
        <Grid item xs={6}>
          <UnitConvertedField
            label={
              <span>
                S<sub>aP,R</sub>
              </span>
            }
            name="spectral_acceleration"
            type="number"
            unitLabel={
              <span>
                m/s<sup>2</sup>
              </span>
            }
            conversionFactor={1.0}
          />
        </Grid>
      ) : (
        <Grid item xs={6}>
          <Select
            label="Erdbebenzone"
            name="seismic_zone"
            options={seismicStandard === 'DIN_4109' ? seismicZoneOptions : SIA_seismicZoneOptions}
          />
        </Grid>
      )}

      <Grid item xs={6}>
        <Select
          label="Untergrundverhältnisse"
          name="ground_type_combination"
          options={
            seismicStandard === 'SIA_261'
              ? SIA_groundTypeCombinationOptions
              : groundTypeCombinationOptions
          }
        />
      </Grid>

      <Grid item xs={12}>
        <TextField label="Q-Verhaltensbeiwert" name="q_value" type="number" />
      </Grid>
    </Grid>
  )
}

export default SeismicBaseParameters
